import RouterView from '@/pc/views/alumni-circle/router-view.vue'; // 圈子中心router-view 壳'
import CircleHome from '@/pc/views/alumni-circle/circle-home.vue'; // 圈子首页
import DynamicDetail from '@/pc/views/alumni-circle/dynamic-detail.vue'; // 动态详情页面
import MoreCircle from '@/pc/views/alumni-circle/more-circle.vue'; // 更多圈子页面
import CircleDetail from '@/pc/views/alumni-circle/circle-detail.vue'; // 圈子详情页面
import DynamicUpdate from '@/pc/views/alumni-circle/dynamic-update.vue'; // 动态编辑页面

export default {
  path: '/circle',
  meta: { isPC: true, name: '校友圈主页', hideFooter: true },
  component: RouterView,
  children: [
    {
      path: '',
      meta: { isPC: true },
      name: 'circle-index', // 圈子主页
      component: CircleHome,
      props: router => ({
        channelId: Number(router.query.channelId) || 0,
      }),
    },
    {
      path: 'more',
      name: 'more-circle', // 更多圈子页面
      meta: { isPC: true, hideFooter: true },
      component: MoreCircle,
    },
    {
      path: 'detail',
      name: 'circle-detail', // 圈子详情页
      meta: { isPC: true, name: '圈子详情页' },
      component: CircleDetail,
      props: router => ({
        circleId: Number(router.query.circleId) || 0,
      }),
    },
    {
      path: 'dynamic/detail',
      meta: { isPC: true },
      name: 'dynamic-detail', // 动态详情页
      component: DynamicDetail,
      props: router => ({
        type: router.query.type || 'discussion', //  discussion-讨论、longArticle-长文
        dynamicId: Number(router.query.dynamicId) || 0,
        seq: Number(router.query.seq) || '',
        listKey: router.query.listKey || '',
      }),
    },
    {
      path: 'dynamic/update',
      meta: { isPC: true, hideFooter: true },
      name: 'dynamic-update', // 动态编辑页
      component: DynamicUpdate,
      props: router => ({
        type: router.query.type || 'discussion', //  discussion-讨论、longArticle-长文
      }),
    },
  ],
};
