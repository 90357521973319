





























































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import FacingCard from '@/pc/views/my-coin/facing/_component/facing-card.vue';
import { getEasyToWrongQuestions, getQuestionsFavorites } from '@/api/ask';

@Component({
  components: {
    FacingCard,
  },
})
export default class ChooseCoupon extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Object, default: () => {} }) activeCategory: any;
  activeInfo: any = {};

  isLoading: boolean = true;
  tabType: string = 'mistakes'; // 选择题目类型 mistakes 错题 ｜ favorite 收藏
  searchOptions: any = [
    {
      value: 'key',
      label: '关键字',
    },
    {
      value: 'id',
      label: '题目ID',
    },
  ];
  searchType: string = 'key'; // 关键字 key ｜ 题目ID id
  searchContent: string = ''; // 搜索内容
  searchResultList: any = []; // 搜索结果
  searchResultStatus: boolean = false; // 是否在搜索

  questionTypes: any = {
    single_choice: '单选',
    choice: '多选',
    uncertain_choice: '不定项选择',
    fill: '填空',
    determine: '判断',
    essay: '问答',
    material: '材料',
  };

  pagination: any = {
    // 分页器数据
    total: 0,
    page: 1,
    limit: 5,
  };

  get activeQId() {
    return (this.activeInfo.question && this.activeInfo.question.id) || 0;
  }

  // 切换搜索类型
  changeSearchType() {
    this.searchContent = '';
  }

  // 重置列表
  async resteList() {
    this.pagination.total = 0;
    this.pagination.page = 1;
    this.searchContent = '';
    this.activeInfo = {};
    await this.getquestionList();
  }
  // 搜索
  async searchList() {
    if (this.searchType === 'id' && (isNaN(Number(this.searchContent)) || this.searchContent.indexOf('.') !== -1)) {
      this.$message.warning('ID必须为数字');
      return;
    }
    this.pagination.page = 1;
    await this.getquestionList();
  }

  @Watch('syncVisible')
  async watchVisible(val) {
    // 关闭重置
    if (!val) {
      this.activeInfo = {};
      await this.watchCategoryId(this.activeCategory.id);
    }
  }

  // 分类修改，则重新拉一下列表
  @Watch('activeCategory.id', { immediate: true })
  async watchCategoryId(id) {
    if (id) {
      this.tabType = 'mistakes';
      this.searchType = 'key';
      await this.resteList();
    }
  }

  // 类型修改，也重新拉一下列表、
  async changeTabType(tab, event) {
    console.log(tab, event);
    await this.resteList();
  }

  // 获取题目数据
  async getquestionList() {
    this.isLoading = true;
    if (this.tabType === 'mistakes') {
      await this.getMistakesList();
    }
    if (this.tabType === 'favorite') {
      await this.getFavoriteList();
    }
    this.isLoading = false;
  }

  // 获取错题列表
  async getMistakesList() {
    try {
      let params: any = {
        categoryId: this.activeCategory.id,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        isEasyWrong: 0, // 非易错题
      };
      if (this.searchContent) {
        params.questionId = this.searchType === 'id' ? this.searchContent : undefined;
        params.stem = this.searchType === 'id' ? undefined : this.searchContent;
      }
      this.searchResultStatus = !!(params.questionId || params.stem);
      const { count, rows } = await getEasyToWrongQuestions(params);
      this.pagination.total = count;
      this.searchResultList = rows;
      console.log('获取错题列表', params, { count, rows });
    } catch (e) {
      console.log('获取错题列表失败', e);
    }
  }

  // 获取收藏题目的列表
  async getFavoriteList() {
    try {
      let params: any = {
        categoryId: this.activeCategory.id,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        needCount: 1,
      };
      if (this.searchContent) {
        params.questionIds = this.searchType === 'id' ? this.searchContent + '' : undefined;
        params.stem = this.searchType === 'id' ? undefined : this.searchContent;
      }
      this.searchResultStatus = !!(params.questionIds || params.stem);
      const { count, rows } = await getQuestionsFavorites(params);
      this.pagination.total = count;
      rows.forEach(item => {
        item['question'] = {
          type: item.type,
          id: item.questionId,
          stem: item.stem,
          isDel: item.isDel,
        };
      });
      this.searchResultList = rows;
      console.log('获取收藏题目的列表', params, { count, rows });
    } catch (e) {
      console.log('获取收藏题目的列表失败', e);
    }
  }

  // 切换页数
  async handleCurrentChange(val) {
    this.pagination.page = val;
    await this.getquestionList();
    console.log('切换页数', val, this.pagination.page);
  }

  // 选择题目
  chooseQ(item) {
    // 已删除的不可选中
    if (item.question.isDel) return;
    this.activeInfo = item;
  }

  // 关闭弹窗
  closeDialog() {
    this.syncVisible = false;
  }

  // 确定
  submitHandler() {
    this.$emit('chooseQuestion', this.activeInfo);
    this.syncVisible = false;
  }
}
