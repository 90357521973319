































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { adoptExplanation, addExplanationFavorite, delExplanationFavorite, likeExplanation, dislikeExplanation } from '@/api/ask';

import CreateDiscussion from '../ask-create-discussion.vue';

@Component({
  components: {
    CreateDiscussion,
  },
})
export default class AskAnswer extends Vue {
  // 卡片的数据
  @Prop({ type: Object, default: () => {} }) detail: any;
  // @ts-ignore 开通的能力 【 imgPreview ｜ 图片预览 】
  @Prop({ type: Array, default: () => [] }) abilities: any;
  // 当前问答详情
  @Prop({ type: Object, default: () => {} }) askInfo: any;
  // 当前问答是否有采纳记录
  @Prop({ type: Boolean, default: false }) askIsAdopted: any;
  // 当前用户的userId
  @Prop({ type: Number, default: 0 }) myUserId: any;

  discussionVisible: boolean = false; // 创建追问

  // 当前问答状态 [opened, closed, resolved]（正常打开状态/关闭问题/问题已解决）
  get askStatus() {
    return this.askInfo.status;
  }

  // 是否我的问答
  get isMyAsk() {
    return this.askInfo.userId === this.myUserId;
  }

  // 允许操作，该问题开启&&未有采纳答案过&&是本人的问题
  get canOperate() {
    return this.askStatus === 'opened' && !this.askIsAdopted && this.isMyAsk;
  }
  // 答主个人信息
  get userInfo() {
    return this.detail.userInfo || {};
  }

  // 答疑教师标签信息
  get teacherTagInfo() {
    return this.detail.teacherTagInfo || {};
  }

  // 追问追答
  get discussionList() {
    return this.detail.discussionList;
  }

  // 附加图片
  get images() {
    return this.detail.images;
  }

  // 当前答案，是否为答主回答
  get isTeacherAnswerer() {
    return this.detail.isTeacherAnswerer || false;
  }

  // 当前答案，是否采纳
  get isAdopted() {
    return this.detail.isAdopted || false;
  }

  // 认证评语相关信息
  get approveInfo() {
    return this.detail.approveInfo || {};
  }

  // 可用的追问次数
  get traceAskCount() {
    return this.detail.traceAskCount || 0;
  }

  // 打开采纳弹窗
  async toAdopted() {
    await this.$confirm('您确定要采纳回答，并把悬赏分给该答主吗？', '采纳回答', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    });
    try {
      await adoptExplanation({ explanationId: this.detail.id });
      // 刷新数据
      this.$emit('updateFun');
      this.$message.success('采纳回答成功，悬赏已分给答主');
    } catch (e) {
      console.log('采纳失败', e);
    }
  }

  // 创建追问
  openDiscussion() {
    this.discussionVisible = true;
  }

  // 收藏/取消收藏问答回答
  favoriteLoading: boolean = false;
  async changeFavorite() {
    if (this.favoriteLoading) return;
    this.favoriteLoading = true;
    try {
      if (this.detail.isFavorite) {
        await delExplanationFavorite({ explanationId: this.detail.id });
        this.detail.isFavorite = 0;
        this.detail.favoriteCount = this.detail.favoriteCount - 1;
      } else {
        await addExplanationFavorite({ explanationId: this.detail.id });
        this.detail.isFavorite = 1;
        this.detail.favoriteCount = this.detail.favoriteCount + 1;
      }
      this.$message.success('操作成功');
    } catch (e) {
      console.log('收藏/取消收藏失败', e);
    } finally {
      this.favoriteLoading = false;
    }
  }
  // 点赞/取消点赞问答回答
  likeLoading: boolean = false;
  async changeLike() {
    if (this.likeLoading) return;
    this.likeLoading = true;
    try {
      if (this.detail.isLike) {
        await dislikeExplanation({ explanationId: this.detail.id });
        this.detail.isLike = 0;
        this.detail.likeCount = this.detail.likeCount - 1;
      } else {
        await likeExplanation({ explanationId: this.detail.id });
        this.detail.isLike = 1;
        this.detail.likeCount = this.detail.likeCount + 1;
      }
      this.$message.success('操作成功');
    } catch (e) {
      console.log('收藏/取消收藏失败', e);
    } finally {
      this.likeLoading = false;
    }
  }
}
