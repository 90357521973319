<template>
  <span :class="string">
    <span v-if="item.type === 'become_auditor'"
      >成为了班级 <router-link :to="`/classroom/${item.properties.classroom.id}`" class="link-dark">{{ item.properties.classroom.title }}</router-link
      >的旁听生
    </span>
    <span v-else-if="item.type === 'become_student'">
      <span v-if="item.properties.course"
        >加入课程
        <router-link :to="`/course/${item.properties.course.id}`" class="link-dark" v-if="path.indexOf(`/course/${item.properties.course.id}`) === -1">{{ item.properties.course.title }}</router-link>
        <span class="link-dark default-style" v-else>{{ item.properties.course.title }}</span>
      </span>
      <span v-if="item.properties.classroom"
        >加入班级
        <router-link :to="`/classroom/${item.properties.classroom.id}`" class="link-dark">{{ item.properties.classroom.title }}</router-link>
      </span>
    </span>
    <span v-else-if="item.type === 'favorite_course'"
      >收藏了课程
      <router-link :to="`/course/${item.properties.course.id}`" class="link-dark" v-if="path.indexOf(`/course/${item.properties.course.id}`) === -1">{{ item.properties.course.title }}</router-link>
      <span class="link-dark default-style" v-else>{{ item.properties.course.title }}</span>
    </span>
    <span v-else-if="item.type === 'finished_exercise'"
      >完成了课时
      <router-link :to="`/course/${item.properties.course.id}`" class="link-dark">{{ item.properties.course.title }}</router-link>
      下的练习 {{ item.properties.lesson.title }}
    </span>
    <span v-else-if="item.type === 'finished_homework'"
      >完成了课时
      <router-link :to="`/course/${item.properties.course.id}`" class="link-dark">{{ item.properties.course.title }}</router-link>
      下的作业 {{ item.properties.lesson.title }}
    </span>
    <span v-else-if="item.type === 'finished_testpaper'">完成了考试 {{ item.properties.testpaper.name }}</span>
    <span v-else-if="item.type === 'learned_lesson'"
      >学完了课时
      <router-link :to="`/course/${item.properties.course.id}`" class="link-dark">{{ item.properties.lesson.title }}</router-link>
    </span>
    <span v-else-if="item.type === 'reviewed_homework'">{{ item.properties.lesson.title }} 的作业已被批阅</span>
    <span v-else-if="item.type === 'reviewed_testpaper'">试卷 {{ item.properties.testpaper.name }} 已被批阅</span>
    <span v-else-if="item.type === 'start_learn_lesson'"
      >开始学习课时
      <router-link :to="`/course/${item.properties.course.id}`" class="link-dark">{{ item.properties.lesson.title }}</router-link>
    </span>
    <span v-else-if="item.type === 'teacher_thread_post'">问题 {{ item.properties.thread.title }} 已被教师回复</span>
  </span>
</template>

<script>
export default {
  name: 'UserOperateModel',
  props: {
    item: Object,
    string: String,
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
span.color-8a9499 {
  color: #8a9499 !important;
  span,
  a {
    color: #8a9499 !important;
  }

  a:hover {
    color: #1e73ff !important;
  }
}

span.default-style {
  &:hover {
    color: #313131 !important;
  }
}
</style>
