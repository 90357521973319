// @ts-ignore
import CryptoJS from 'crypto-js';
const SECRET_KEY = "FnJL7EDzbtWjcaY9";
const SECRET_IV = "FnJL7EDzbtWjcaY9";

export function encrypt(dataStr, key = SECRET_KEY, iv = SECRET_IV) {
    // 将 key 和 iv 转换为 WordArray 对象
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const ivHex = CryptoJS.enc.Utf8.parse(iv);
  
    // 加密
    const encrypted = CryptoJS.AES.encrypt(dataStr, keyHex, {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  
    return encrypted.toString();
  }

export function decrypt(dataStr, key = SECRET_KEY, iv = SECRET_IV) {
    // 将 key 和 iv 转换为 WordArray 对象
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const ivHex = CryptoJS.enc.Utf8.parse(iv);

    // 解密
    const decrypted = CryptoJS.AES.decrypt(dataStr, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}

const appKey = 'sierra_2023'; // 固定的key   


export function genAuthorization(requestConfig) {
    const timestamp = requestConfig.timestamp; // 服务器时间
    const { access_token } = requestConfig;
    const randomStr = getRandomStr();// 8位所有的随机数 
    const str = `${timestamp}_${access_token}_${appKey}_${randomStr}`;
    // 最终的Authorization，每个请求都重新生成
    const authorization = CryptoJS.MD5(CryptoJS.MD5(str) + str);
    return {
      authorization: authorization.toString() + randomStr,
      randomStr,
    };
}

export function getRandomStr(options?: any) {
  const { length = 8 } = options || {};
  const getStr8 = () => Math.random().toString(36).substring(2, 10);
  const callCount = Math.ceil(length / 8);
  let res = '';
  for(let i = 0; i < callCount; i++) {
    res += getStr8();
  }
  return res.slice(0, length);
}