/* eslint-disable */
import Vue from 'vue';
// 引入神策埋点
import * as sensors from 'sa-sdk-javascript';
const pkg = require('../../../../package.json');

(sensors as any).init({
  server_url: process.env.VUE_APP_SHENCE_UP_URL, // 'http://192.168.10.93:8080/sc/data',
  // 全埋点配置
  heatmap: { scroll_notice_map: 'not_collect', collect_element: false, clickmap: 'not_collect' },
  is_track_single_page: false, //表示是否开启自动采集 web 浏览事件 $pageview 的功能
  use_client_time: true,
  send_type: 'ajax', //beacon ajax
  // 开启批量发送
  batch_send: {
    datasend_timeout: 6000, //一次请求超过多少毫秒的话自动取消，防止请求无响应。
    send_interval: 6000, //间隔多少毫秒发一次数据。
    one_send_max_length: 6, // 一次请求最大发送几条数据，防止数据太大
  },
  show_log: false, // 是否在控制台显示埋点数据（上线需要隐藏）
});
const registerParams: any = {
  current_url: location.href,
  referrer: document.referrer,
  $app_id: 'phoneix',
  env: 'pcBrowe',
};
if (pkg) {
  registerParams.$app_id = pkg.name;
  registerParams.$app_version = `v${pkg.version}`;
}
// 注册公共属性
// @ts-ignore
sensors.registerPage(registerParams);

export default sensors;
