<template>
  <div class="home-category">
    <div class="h-row" v-if="Array.isArray(data)">
      <template v-for="(category, index) in data">
        <div :key="index" class="h-col">
          <div class="area" @mouseenter="mouseenter(index)" @mouseleave="mouseleave">
            <div class="area-panel">
              <div class="top"><img class="top-icon" :src="category.icon" :alt="category.name" /></div>
              <div class="bottom">
                <span class="name">{{ category.name }}</span>
              </div>
            </div>
            <transition name="fade" mode="out-in" appear>
              <div class="hover-menu" v-show="active === index || !hasMounted">
                <div class="top">
                  <span class="name">{{ category.name }}</span>
                </div>
                <div class="bottom">
                  <div :class="`menu ${menus[category.id] && menus[category.id].length > 5 ? 'flex' : ''}`">
                    <template v-if="menus[category.id] && menus[category.id].length > 5">
                      <div v-for="index in [0, 1]" :key="index" class="menu-flex">
                        <template v-for="(menu, i) in menus[category.id]">
                          <a v-if="i % 2 === index && i < 10" :href="getMenuUrl(category, menu)" target="_blank" :key="i">{{ menu.name }}</a>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <template v-for="(menu, index) in menus[category.id]">
                        <a :href="getMenuUrl(category, menu)" target="_blank" :key="index" v-if="index < 10">{{ menu.name }}</a>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </template>
    </div>
    <div class="h-row" v-else>
      <template v-for="(category, index) in 6">
        <div :key="index" class="h-col">
          <div class="area">
            <div class="area-panel loading"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },
  data() {
    return {
      active: null,
      hasMounted: false,
    };
  },
  mounted() {
    this.hasMounted = true;
  },
  methods: {
    mouseenter(index) {
      this.active = index;
    },
    mouseleave() {
      this.active = null;
    },
    getMenuUrl(category, menu) {
      // if (menu.code === 'baojian') {
      //   return 'http://page.btclass.cn/baojian/index.html';
      // } else {
      // return `/course/explore/${category.code}?subCategory=${menu.code}`;
      return `/course/explore?categoryId=${menu.id}`;
      // }
    },
  },
  computed: {
    menus() {
      let menus = [];
      this.data.map(e => {
        menus[e.id] = e.children;
      });
      return menus;
    },
  },
  // watch: {
  //   data(arr) {
  //     arr.map(e => (this.menus[e.id] = e.children));
  //   }
  // }
};
</script>
