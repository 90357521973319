






import { Vue, Component, Prop } from 'vue-property-decorator';
import NP from 'number-precision';

@Component
export default class Progress extends Vue {
  @Prop() startTime!: number;
  @Prop() endTime!: number;
  nowTime = Date.now();

  get tranLeft() {
    const summation = NP.minus(this.endTime, this.startTime);
    const progressTime = NP.minus(this.nowTime, this.startTime);
    const percentage = NP.divide(progressTime, summation);
    if (progressTime < 0) {
      return 0;
    }
    return `${(percentage > 1 ? 1 : percentage) * 100}%`;
  }
}
