
























import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class EditorImage extends Vue {
  @Prop({ type: Object }) qiniu?: any;
  @PropSync('visible', { type: Boolean, default: false }) dialogVisible?: boolean;
  listObj = {};
  fileList = [];

  checkAllSuccess() {
    return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess);
  }

  handleSubmit() {
    const arr = Object.keys(this.listObj).map(v => this.listObj[v]);
    if (!this.checkAllSuccess()) {
      this.$message('图片未上传成功, 请稍等或是刷新当前页面重新上传');
      return;
    }
    this.$emit('successCBK', arr);
    this.listObj = {};
    this.fileList = [];
    this.dialogVisible = false;
  }

  handleSuccess(response, file) {
    const obj = this.listObj[file.uid];
    let query = '';
    if (obj) {
      query = `?w=${obj.width}&h=${obj.height}`;
    }
    const uid = file.uid;
    const objKeyArr = Object.keys(this.listObj);
    for (let i = 0, len = objKeyArr.length; i < len; i++) {
      if (this.listObj[objKeyArr[i]].uid === uid) {
        this.listObj[objKeyArr[i]].url = `${response.url}${query}`;
        this.listObj[objKeyArr[i]].hasSuccess = true;
        return;
      }
    }
  }

  handleRemove(file) {
    const uid = file.uid;
    const objKeyArr = Object.keys(this.listObj);
    for (let i = 0, len = objKeyArr.length; i < len; i++) {
      if (this.listObj[objKeyArr[i]].uid === uid) {
        delete this.listObj[objKeyArr[i]];
        return;
      }
    }
  }

  beforeUpload(file) {
    if (!/.(jpg|jpeg|png)$/.test(file.type)) {
      this.$message.error('图片格式不正确!');
      return false;
    }
    const _self = this;
    const _URL = window.URL || window.webkitURL;
    const fileName = file.uid;
    this.listObj[fileName] = {};
    return new Promise(resolve => {
      const img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: img.width, height: img.height };
      };
      // this.qiniu.config.key = `${this.qiniu.config.folderName}/${new Date().getTime()}--${file.name}`;
      resolve(true);
    });
  }
}
