



































import { Vue, Component, Prop } from 'vue-property-decorator';
import TextEllipsisCom from './text-ellipsis.vue';
import { CircleItem } from '@/typings';
import { joinCircle, quitCircle } from '@/api/circle';
import { mapGetters, mapMutations } from 'vuex';

@Component({
  components: {
    TextEllipsisCom,
  },
  filters: {
    transMaxNum(val) {
      return val > 9999 ? '9999+' : val;
    },
  },
  computed: {
    ...mapGetters('my', ['isLogin']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class CircleCardCom extends Vue {
  @Prop({ type: Object, default: () => ({}) }) itemData!: CircleItem;

  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;
  isJoing: boolean = false;

  async joinCircleHandle({ id, isEntered }) {
    // 加入圈子前判断是否已登录
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    if (this.isJoing) return;
    try {
      this.isJoing = true;
      if (isEntered) {
        this.$confirm('确定退出圈子吗？', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'circle-confirm-dialog',
        }).then(async () => {
          await quitCircle(id);
          this.itemData['isEntered'] = 0;
          this.$message.success('操作成功');
          this.$emit('refresh');
        });
      } else {
        await joinCircle(id);
        this.itemData['isEntered'] = 1;
      }
    } finally {
      this.isJoing = false;
      this.$emit('joinUpdate');
    }
  }

  // 去校友圈详情页面
  goCircleDetail() {
    if (!this.itemData) return;
    const { href } = this.$router.resolve({
      name: 'circle-detail',
      query: {
        circleId: this.itemData.id + '',
      },
    });
    window.open(href);
  }
}
