import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { state, getters, mutations, actions } from './base';

import my from './modules/my';
import info from './modules/info';
import course from './modules/course';
import user from './modules/user';
import commentStore from './modules/group/comments';
import entry from './modules/entry';
import order from './modules/order';
import home from './modules/home';
import group from './modules/group/index';
import courseSeo from './modules/course_seo';
import exam from './modules/exam';
import lesson from './modules/lesson';
import customPage from './modules/custom-page';
import teacher from './modules/teacher';

Vue.use(Vuex);

// export default new Vuex.Store<{ menu: any[]; bottomLink: any; my?: any }>({
//   strict: process.env.NODE_ENV !== 'production',
//   modules: {
//     my,
//     info,
//     course,
//     user,
//     openCourse,
//     commentStore,
//     entry,
//     order,
//   },
//   state,
//   getters,
//   mutations,
//   actions,
//   plugins: [
//     createPersistedState({
//       storage: window.localStorage,
//     }),
//   ],
// });
export let store;
export function createStore() {
  let plugins: any = [];
  // @ts-ignore
  if (SSR_ENV === 'client') {
    plugins.push(
      createPersistedState({
        storage: window.sessionStorage,
      })
    );
  }
  store = new Vuex.Store<{ menu: any[]; my?: any }>({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
      my,
      info,
      course,
      user,
      commentStore,
      entry,
      order,
      home,
      group,
      courseSeo,
      exam,
      lesson,
      customPage,
      teacher,
    },
    state,
    getters,
    mutations,
    actions,
    plugins,
  });
  console.log('createStore', store);
  Vue.prototype.$store = store;
  return store;
}

// export default store;