





























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UserAvator from '../user-com/user-avator.vue';
import BindMobile from '../bind-mobile.vue';
import { mapGetters, mapMutations } from 'vuex';
import { getUserHomePage } from '@/api/circle';
import { CircleUserInfo } from '@/typings';

@Component({
  components: {
    UserAvator,
    BindMobile,
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
  filters: {
    maxNum(val) {
      return val > 9999 ? '9999+' : val;
    },
  },
})
export default class modulesToAsk extends Vue {
  @Prop({ type: Number, default: 0 }) categoryId: any;

  isLogin: any;
  me: any;
  SET_LOGIN_DIALOG_VISIBLE: any;
  isBindDialogVisible: boolean = false;

  searchText: any = '';
  userInfo: CircleUserInfo | null = null;
  btnList = [{}];
  isLoading: boolean = false;
  curType: string = '';

  @Watch('me', { deep: true, immediate: true })
  async getUserInfo() {
    if (!this.me.id) return;
    try {
      this.isLoading = true;
      this.userInfo = await getUserHomePage(this.me.id);
    } finally {
      this.isLoading = false;
    }
  }

  // 去搜索
  toSearch() {
    if (!this.searchText || !this.searchText.trim()) {
      this.$message.warning('请输入搜索内容');
      return;
    }

    // 未登录需要先跳转登录页
    const link = this.$router.resolve({ name: 'search', query: { searchText: encodeURIComponent(this.searchText), type: 'circle' } });
    if (!this.isLogin) {
      const loginLink = this.$router.resolve({
        name: 'login',
        query: { goto: link.href },
      });
      window.open(loginLink.href, '_blank');
      return;
    }

    // // @ts-ignore
    // const link = this.$router.resolve({ name: 'search', query: { searchText: encodeURIComponent(this.searchText) } });
    window.open(link.href, '_blank');
  }

  // 登录方法
  loginHandle() {
    this.SET_LOGIN_DIALOG_VISIBLE(true);
  }

  // 编辑动态
  goEditDynamic(type) {
    this.curType = type;
    const { href } = this.$router.resolve({
      name: 'dynamic-update',
      query: {
        type,
      },
    });
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
      // window.open(`/login?goto=${href}`);
    } else {
      if (!this.me.verifiedMobile) {
        this.isBindDialogVisible = true;
        return;
      }
      window.open(href);
    }
  }

  // 去提问页面
  goAsk() {
    this.curType = 'ask';
    window.open('/ask/create');
  }

  // 手机号绑定成功后的回调
  bindMobileSuccess() {
    // 绑定成功后跳转对应页面
    if (!this.curType) return;
    // 去编辑页面
    const { href } = this.$router.resolve({
      name: 'dynamic-update',
      query: {
        type: this.curType,
      },
    });
    window.open(href);
  }

  // 跳转用户详情页面
  goUserInfoPage(userId) {
    if (!userId) return;
    window.open(`/new-user/${userId}`);
    console.log('跳转个人页面');
  }
}
