




















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CommentItem from './comment-item.vue';
import CommentReplayDialog from './comment-replay-dialog.vue';
import LoadingIcon from '@/pc/views/ask/components/modules/loading-icon.vue';
import { getCircleDynamicDetail, addCircleDynamicComment, getCircleCommentList, getDynamicHotComment } from '@/api/circle';
import { DynamicItem, CircleCommentItem } from '@/typings';
import { mapGetters, mapMutations } from 'vuex';
import { throttle } from '@/utils/helper';

@Component({
  components: {
    CommentItem,
    CommentReplayDialog,
    LoadingIcon,
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
  filters: {
    maxNum(val) {
      if (!val) return '';
      return val > 99 ? '99+' : val;
    },
  },
})
export default class DynamicComment extends Vue {
  @Prop({ type: Object, default: () => ({}) }) dynamicData!: DynamicItem;
  @Prop({ type: Boolean, default: true }) isNeedLoadMore?: boolean; // 判断是否需要懒加载，不需要的话跳转至详情页
  @Prop({ type: String }) listKey?: string;
  @Prop({ type: Boolean, default: false }) isVialot;
  @Prop({ type: Number, default: 0 }) commentNum;

  me: any;
  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  curCommentNum: number = 0;
  pagination: any = {
    page: 0,
    limit: 4,
    total: 0,
    isLoadAll: false,
  };
  isLoading: boolean = false;
  hotComment: CircleCommentItem[] = []; // 热门评论列表
  commentList: CircleCommentItem[] = []; //所有评论列表
  commentContent: string = '';
  isSubmitting: boolean = false;
  isLoadingMore: boolean = false; // 更多接口请求加载中
  hasStartGet: boolean = false; // 是否点击了正在获取接口

  curEditComment: any = null; // 当前编辑弹窗
  isCommentReplayDialogVisible: boolean = false; // 回复弹窗
  scrollHandleThrottled: any = null;

  @Watch('commentNum', { immediate: true })
  commentNumChange(val) {
    console.log('val', val);
    if (!val) {
      this.curCommentNum = 0;
      return;
    }
    this.curCommentNum = val;
  }

  async mounted() {
    this.getCommentList();
    await this.$nextTick();
    this.scrollHandleThrottled = throttle(this.scrollHandle, 200);
    window.addEventListener('scroll', this.scrollHandleThrottled);
  }
  unmounted() {
    window.removeEventListener('scroll', this.scrollHandleThrottled);
  }

  get statuText() {
    if (this.hasStartGet) {
      if (this.pagination.isLoadAll) {
        return '已经加载全部评论';
      }
      return this.isLoadingMore ? '更多内容加载中' : '查看更多';
    } else {
      return '查看全部评论>';
    }
  }

  async getCommentList() {
    try {
      this.hasStartGet = false;
      this.isLoadingMore = false;
      this.pagination = {
        page: 0,
        limit: 4,
        total: 0,
        isLoadAll: false,
      };
      this.isLoading = true;
      const { id } = this.dynamicData;
      const params = {
        page: this.pagination.page,
        limit: this.pagination.limit,
        needCount: 1,
        dynamicId: id,
      };
      const [{ count, rows: circleCommentList }, hotCommentData] = await Promise.all([getCircleCommentList(params), getDynamicHotComment(id)]);
      this.pagination.total = count;
      this.pagination.isLoadAll = (this.pagination.page + 1) * this.pagination.limit >= count;
      this.hotComment = hotCommentData.id ? [hotCommentData] : [];
      this.commentList = circleCommentList;
      // console.log('circleCommentList', circleCommentList, 'hotComment', hotCommentData);
    } finally {
      this.isLoading = false;
    }
  }
  // 出发开启更多
  moreHandle() {
    // 懒加载逻辑
    if (this.isNeedLoadMore) {
      this.hasStartGet = true;
      this.scrollHandle();
    } else {
      //非懒加载跳转至圈子详情页面
      const { seq, id, dynamicType } = this.dynamicData;
      const { href } = this.$router.resolve({
        name: 'dynamic-detail',
        query: {
          dynamicId: id + '',
          seq: seq + '',
          type: dynamicType,
          listKey: this.listKey,
        },
      });
      window.open(href);
    }
  }

  // 获取更多评论
  async getMoreCommentList() {
    if (this.isLoadingMore) return;
    const { page, limit, total } = this.pagination;
    if (this.isLoadingMore) return;
    if (total > 4 && (page + 1) * limit < total) {
      try {
        this.isLoadingMore = true;
        this.pagination.page += 1;
        const params = {
          page: this.pagination.page,
          limit: this.pagination.limit,
          needCount: 1,
          dynamicId: this.dynamicData.id,
        };
        const { rows, count } = await getCircleCommentList(params);
        this.pagination.total = count;
        this.pagination.isLoadAll = (this.pagination.page + 1) * this.pagination.limit >= count;
        this.commentList = this.commentList.concat(rows);
      } finally {
        this.isLoadingMore = false;
      }
    }
  }

  // 增加评论
  async addComment() {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      if (!this.commentContent) {
        this.$message.warning('请先填写评论');
        return;
      }
      this.isSubmitting = true;
      await addCircleDynamicComment({ dynamicId: this.dynamicData.id, content: this.commentContent });
      this.$message.success('发布成功');
      this.commentContent = '';
      this.getCommentList();
      this.numChange();
    } finally {
      this.isSubmitting = false;
    }
  }

  scrollHandle() {
    // 没点击加载全部或者已经加载完了，不在继续loading后续数据
    if (!this.hasStartGet || this.pagination.isLoadAll) return;
    const isInView = this.isInViewPortOfTwo(this.$refs.moreRef);
    if (isInView) {
      this.getMoreCommentList();
    }
  }

  isInViewPortOfTwo(el) {
    const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const top = el.getBoundingClientRect() && el.getBoundingClientRect().top;
    return top <= viewPortHeight;
  }

  // 查看收起的回复
  seeAllReplay(data) {
    this.curEditComment = Object.assign({}, data);
    this.isCommentReplayDialogVisible = true;
  }

  // 弹窗关闭后更新列表的数据
  commentChange(data) {
    const hotTagetIndex = this.hotComment.findIndex(item => item.id == data.id);
    const normalCommentIndex = this.commentList.findIndex(item => item.id == data.id);
    if (hotTagetIndex != -1) {
      this.$set(this.hotComment, hotTagetIndex, data);
    }
    if (normalCommentIndex != -1) {
      this.$set(this.commentList, normalCommentIndex, data);
    }
    this.numChange();
  }

  // 评论数量改变的时候更新
  async numChange() {
    try {
      const { commentNum } = await getCircleDynamicDetail(this.dynamicData.id, {});
      this.$emit('updateCommentNum', commentNum);
      this.curCommentNum = commentNum;
    } catch (e) {
      console.log('出现错误');
    }
  }

  goMyCenter() {
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    window.open(`/new-user/${this.me.id}`);
  }
}
