
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class modulesMyAskFavorite extends Vue {
  @Prop({ type: Number, default: 0 }) categoryId: any;
  // 我的提问
  goQuestion() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-my-ask', query: { categoryId: this.categoryId } });
    window.open(link.href, '_blank');
    console.log('去提问', this.categoryId);
  }

  // 我的收藏
  goFavorite() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-my-favorite', query: { categoryId: this.categoryId } });
    window.open(link.href, '_blank');
    console.log('去提问', this.categoryId);
  }
}
