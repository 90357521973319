














import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import MainLoading from '@/pc/components/loading/main-loading.vue';

import { getBookErrataPages } from '@/api/book-errata';

@Component({
  components: {
    MainLoading,
  },
})
export default class TabsPage extends Vue {
  @Prop({ type: Number, default: 0 }) bookId!: any;
  @PropSync('pagesList', { type: Array, default: [] }) pagesListSync: any; // 页码列表
  @PropSync('activePage', { type: Number, default: 0 }) activePageSync: any; // 选择的页码

  isLoading: boolean = true;
  mockList: any = [{ id: -1 }, { id: -2 }];

  // 切换tab时，开始执行该逻辑
  async created() {
    // 获取教材的修订页码列表
    await this._getBookErrataPages();
  }

  // 获取所有页码
  async _getBookErrataPages() {
    try {
      const { pages } = await getBookErrataPages({
        bookId: this.bookId,
        unread: 1
      });
      this.pagesListSync = pages || [];
    } catch (e) {
      console.log('获取页码失败', e);
    } finally {
      // sync同步有延迟，用$nextTick包起来
      this.$nextTick(() => {
        console.log('当前教材所有页码', this.pagesListSync);
        if (!this.pagesListSync.length) {
          // 没有页码，那就清空数据，显示兜底页
          this.$emit('showNoResult', 1);
        } else {
          // 有页码列表，获取页码对应的修订数据，页码优先级，路由>本地缓存>默认第一个
          const { pageNum: sPageNum } = this.getStorage();
          const pageNum = +(this.$route.query.pageNum || sPageNum || this.pagesListSync[0].page);
          console.log('初始化选择页码', { pageNum, init: true });
          this.$emit('getPageErrata', { pageNum, init: true });
        }
        this.isLoading = false;
      });
    }
  }

  // 切换页码
  onPageChange(page: any) {
    this.$emit('getPageErrata', { pageNum: page.page, init: false });
  }

  // 获取缓存 按页码查看：bookDetailsPage、 按章节查看： ChapterDetailsPage
  getStorage() {
    const bookErrata = JSON.parse(localStorage.getItem('book-errata') || `{}`);
    const bookInfo = bookErrata[this.bookId] || {};
    return bookInfo['bookDetailsPage'] || {};
  }
}
