<template>
  <transition name="el-loading-fade" @after-leave="handleAfterLeave">
    <div v-show="visible" class="el-loading-mask" :style="{ backgroundColor: background || '' }" :class="[customClass, { 'is-fullscreen': fullscreen }]">
      <div class="el-loading-spinner" :style="loadingStyle">
        <!-- <lottie v-if="!spinner" :options="videoLottieOption" :height="100"></lottie> <i v-else :class="spinner"></i>
        <p v-if="text" class="el-loading-text">{{ text }}</p> -->
      </div>
    </div>
  </transition>
</template>

<script>
import btLogoLoading from '@/assets/images/live-entry/bt-logo-loading.json';
// import btLogoEntry from '@/assets/images/live-entry/bt-logo-entry.json';
export default {
  components: {
    Lottie: () => import('@/pc/components/lottie/Lottie')
  },

  data() {
    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: '',
      top: '',
      loadingLogoName: ''
    };
  },

  computed: {
    // videoLottieOption() {
    //   let animationData = '';
    //   switch (this.loadingLogoName) {
    //     case 'entry':
    //       animationData = btLogoEntry;
    //       break;
    //     case 'default':
    //     default:
    //       animationData = btLogoLoading;
    //   }
    //   return { animationData };
    // },
    loadingStyle() {
      return {
        top: this.top ? this.top : ''
      };
    }
  },

  methods: {
    handleAfterLeave() {
      this.$emit('after-leave');
    },
    setText(text) {
      this.text = text;
    },
    setLogoName(name) {
      this.loadingLogoName = name;
    },
    setTop(top) {
      this.top = top;
    }
  }
};
</script>
<style scoped lang="scss">
.el-loading-spinner {
  top: 38%;
}
</style>
