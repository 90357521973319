






























































import { Vue, Component } from 'vue-property-decorator';
import LiveLessonItem from './live-lesson-item.vue';
import { getChoicenessPageLiveItems } from '@/api/home';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import BindMobile from '@/pc/components/bind-mobile/index.vue';
import SuccessDialog from './success-dialog.vue';
import OperatPlaceCom from '@/pc/views/home/components/OperatPlace.vue';

@Component({
  components: {
    LiveLessonItem,
    swiper,
    swiperSlide,
    BindMobile,
    SuccessDialog,
    OperatPlaceCom,
  },
})
export default class LiveCom extends Vue {
  dataIsReady: boolean = false;
  lessons: any[] = [];
  curLessons: any[] = [];
  editLesson: any = null;
  isBindDialogVisible: boolean = false;
  isReserveSuccess: boolean = false;
  swiperOption: any = {
    navigation: {
      nextEl: '.swiper-right-btn',
      prevEl: '.swiper-left-btn',
    },
    loop: false,
    grabCursor: true,
    spaceBetween: 10,
    initialSlide: 0,
    direction: 'horizontal',
    slidesPerView: 'auto',
    allowTouchMove: false,
  };
  left: boolean = true;
  right: boolean = false;

  mounted() {
    this.init();
  }

  isToday(time) {
    let td = new Date();
    td = new Date(td.getFullYear(), td.getMonth(), td.getDate());
    time = time.toString().length === 10 ? time * 1000 : time;
    let od = new Date(time);
    od = new Date(od.getFullYear(), od.getMonth(), od.getDate());
    let xc = Math.abs((Number(od) - Number(td)) / 1000 / 60 / 60 / 24);
    return xc === 0;
  }

  async init() {
    try {
      this.dataIsReady = false;
      await this.getLessonData();
    } catch (e) {
      console.log('获取直播失败');
    } finally {
      this.dataIsReady = true;
    }
  }

  async getLessonData() {
    try {
      // 获取直播课时数据
      this.lessons = (await getChoicenessPageLiveItems()) || [];
      // this.curLessons=this.lessons;
      this.curLessons = this.lessons.filter(item => this.isToday(item.lesson.startTime));
    } catch (e) {
      console.log('获取直播失败');
    }
  }

  change(index) {
    this.left = index === 0;
    this.right = index === this.curLessons.length - 1;
  }
  preHandle() {
    if (!this.left) {
      (this.$refs.curLiveRef as any).prev();
    }
  }
  nextHandle() {
    if (!this.right) {
      (this.$refs.curLiveRef as any).next();
    }
  }
  // 绑定手机号结束后自动调用预约逻辑
  closeHandle() {
    if (!this.editLesson) return;
    (this.$refs[`refLesson${this.editLesson.id}`] as any)[0].sendReserveRequest();
  }
}
