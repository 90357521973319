







import { Vue, Component, Prop } from 'vue-property-decorator';
import EditDiscussiion from './components/edit-dynamic/edit-discussion.vue';
import EditLongArticle from './components/edit-dynamic/edit-long-article.vue';

@Component({
  components: {
    EditDiscussiion,
    EditLongArticle,
  },
})
export default class DynamicUpdate extends Vue {
  @Prop({ type: String, default: '' }) type!: string; // discussion-讨论、longArticle-长文

  created() {
    this.$emit('breadChange', {
      show: true,
      data: [
        { name: 'BT教育', path: '/' },
        { name: '校友圈主页', handle: 'backHandle' },
        { name: this.type === 'discussion' ? '发布讨论' : '发布长文', path: this.$route.path },
      ],
    });
  }

  // 创建成功后跳转至对应详情页面
  createSuccessHandle(id) {
    this.$router.replace({
      name: 'dynamic-detail',
      query: {
        dynamicId: id,
      },
    });
  }

  backHandle() {
    console.log('back---');
    (this.$refs[`${this.type}Ref`] as any).cancleHandle();
  }
}
