import Vue from 'vue';
import { getBrowser } from '@/utils/helper';

const browser = getBrowser(navigator.userAgent);
const isMobile = browser.mobile;

const Browser = {
  install(Vue, options) {
    Vue.prototype.$mobile = () => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if ((!flag && document.body.clientWidth < 768) || isMobile) {
        return true;
      }
      return flag;
    };
    Vue.prototype.$isMobile = isMobile;
    Vue.prototype.$browser = browser;
  }
};

Vue.use(Browser);
