





















import { Vue, Component, Prop } from 'vue-property-decorator';
import NormalCourseItem from '@/pc/views/home/components/components/CourseList.vue';
import { getPageData } from '@/api/activity';
import { getCourseListForUser } from '@/api/course';

@Component({
  components: {
    NormalCourseItem,
  },
})
export default class Page extends Vue {
  @Prop() pageId!: number;

  pageTitle: string = '福利免费领';
  items: any[] = [];
  coursesMap: any = {};
  isLoading: boolean = false;
  async created() {
    try {
      this.isLoading = true;
      const { title, items } = await getPageData(this.pageId);
      this.pageTitle = title;
      this.items = items
        .filter(({ code, content }) => {
          // 对数据进行处理，防止运营配置出非课程的数据(先依据课程id配置，后续看会不会改为根据配置类型)
          return content.courseIds && content.courseIds.length;
        })
        .map(item => {
          item.courseIds = item.content.courseIds;
          item.title = item.content.title;
          return item;
        });
      // 考虑到页面配置课程数量不会很多，会一次性获取完所有的数据
      let allCourseIds = [];
      this.items.forEach(item => {
        allCourseIds = allCourseIds.concat(item.content.courseIds);
      });
      let { rows } = await getCourseListForUser({ids: allCourseIds.join(','), needPriceDetail: 1});
      rows.forEach(item => {
        this.coursesMap[+item.id] = item;
      });
      this.$forceUpdate();
      this.isLoading = false;
    } catch (e) {
      console.log('数据错误');
    }
  }
}
