































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import DynamicItemCom from './components/dynamic-item.vue';
import DynamicComment from './components/comment/index.vue';
import RecommendDynamic from './components/recommend-dynamic.vue';
import SharePopover from './components/share-popover.vue';
import AboutUser from './components/side-bar/about-user.vue';
import HotLesson from './components/side-bar/hot-lesson.vue';
import RecommendCircle from './components/side-bar/recommend-circle.vue';
import HotStoried from './components/side-bar/hot-storied.vue'; // 热门动态
import ComplainDialog from './components/complain-dailog.vue';
import { getCircleDynamicDetail, likeCircleDynamic, cancelLikeCircleDynamic, deleteCircleDynamic } from '@/api/circle';
import { DynamicItem } from '@/typings';
import { mapGetters, mapMutations } from 'vuex';

@Component({
  components: {
    DynamicItemCom,
    DynamicComment,
    SharePopover,
    AboutUser,
    HotLesson,
    RecommendCircle,
    RecommendDynamic,
    ComplainDialog,
    HotStoried,
  },
  filters: {
    maxNum(val) {
      if (!val) return '';
      return val > 999 ? '999+' : val;
    },
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class CircleDetailCom extends Vue {
  @Prop({ type: Number }) dynamicId!: number;
  @Prop({ type: [Number, String] }) seq!: number | '';
  @Prop({ type: String }) listKey?: string;

  isLoading: boolean = false;
  dynamicData: DynamicItem | null = null;
  isChanging: boolean = false;
  isComplainDialogVisible: boolean = false; // 投诉弹窗

  me: any;
  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;
  commetnTop: number = 0;

  get isVialot() {
    return this.dynamicData && this.dynamicData.checkStatus == 'abnormal';
  }

  get isMe() {
    if (!this.me || !this.me.id || !this.dynamicData) return false;
    return this.me.id === this.dynamicData.userId;
  }

  async mounted() {
    this.getDynamicDetail();
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });

    this.$emit('breadChange', {
      show: true,
      data: [
        { name: 'BT教育', path: '/' },
        { name: '校友圈主页', to: { path: '/circle' } },
        { name: '动态详情页', path: this.$route.path },
      ],
    });
  }

  // 评论的点赞
  async likeDynamicCommentHandle() {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      if (!this.dynamicData) return;
      if (this.isChanging) return;
      this.isChanging = true;
      const { isLiked, id } = this.dynamicData;
      if (isLiked) {
        await cancelLikeCircleDynamic(id);
        this.dynamicData['likeNum'] -= 1;
        this.dynamicData.isLiked = 0;
      } else {
        await likeCircleDynamic(id);
        this.dynamicData['likeNum'] += 1;
        this.dynamicData.isLiked = 1;
      }
      this.$forceUpdate();
    } finally {
      await this.$nextTick();
      this.isChanging = false;
    }
  }

  // 获取动态详情
  async getDynamicDetail() {
    try {
      this.isLoading = true;
      const params: any = {
        seq: this.seq,
        listKey: this.listKey,
      };

      if (this.seq === undefined || this.seq === '') delete params['seq'];
      if (!this.listKey) delete params['listKey'];
      this.dynamicData = await getCircleDynamicDetail(this.dynamicId, params);
      console.log('dynamicData', this.dynamicData);
      //@ts-ignore
    } catch (err: any) {
      console.log('错误', err);
      if (!err || !err.code) return;
      const codeMap = {
        2019: {
          status: 'abnormal',
          errTxt: '该内容涉嫌违规不可见',
        },
        2004: {
          status: 'delete',
          errTxt: '动态已删除',
        },
        2021: {
          status: 'closed',
          errTxt: '动态不存在',
        },
        2003: {
          status: 'closed',
          errTxt: '动态不存在',
        },
      };
      if (codeMap[err.code]) {
        //@ts-ignore
        this.dynamicData = {
          errStatus: codeMap[err.code].status,
          errTxt: codeMap[err.code].errTxt,
        };
      }
    } finally {
      this.isLoading = false;
      this.updateCommenOffset();
    }
  }

  async updateCommenOffset() {
    await this.$nextTick();
    this.commetnTop = ((this.$refs.dynamicCommentRef as any) && (this.$refs.dynamicCommentRef as any).$el.offsetTop) || 0;
  }

  // 跳转至评论部分
  goCommentCon() {
    window.scrollTo(0, this.commetnTop - 150);
  }

  // 跳转上一篇或者下一篇动态
  async goDynamicDetail({ id, seq, listKey }) {
    const query = {
      dynamicId: id,
      seq: seq || '',
      listKey: listKey || '',
    };
    if (!seq) delete query['seq'];
    if (!listKey) delete query['listKey'];

    const { href } = this.$router.resolve({
      name: 'dynamic-detail',
      query,
    });
    window.open(href);
  }

  // 删除或投诉事件
  commandHandle(type) {
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    if (type == 'delete') {
      this.deleteHandle();
    } else {
      this.isComplainDialogVisible = true;
      console.log('投诉');
    }
  }
  // 删除动态
  deleteHandle() {
    this.$confirm('你确定删除该动态吗？相关的评论也会被删除', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      customClass: 'circle-confirm-dialog',
    }).then(async () => {
      if (!this.dynamicData) return;
      await deleteCircleDynamic(this.dynamicData.id);
      this.$emit('deleteHandle');
      this.getDynamicDetail();
    });
  }

  // 回校友圈首页
  goCircleHome() {
    this.$router.replace({
      name: 'circle-index',
    });
  }

  updateCommentNum(num) {
    if (!this.dynamicData) return;
    this.$set(this.dynamicData, 'commentNum', num);
  }
}
