
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
// @ts-ignore
import MErrataOverFocus from '../../../../assets/images/book-errata/m-errata-over-focus.png';
// @ts-ignore
import MErrataOverBlur from '../../../../assets/images/book-errata/m-errata-over-blur.png';

@Component({
  components: {},
})
export default class ProgressCircle extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data: any;
  @Prop({ type: Boolean, default: false }) isActive: any; // 选择状态

  get circleValue() {
    const { data } = this;
    return (data.userReadNum / data.errataNum) * 100 < 100 ? 0 : 100;
  }

  get circleColor() {
    return this.isActive ? '#FFFFFF' : '#4B8FFF';
  }

  get overIcon() {
    return this.isActive ? MErrataOverFocus : MErrataOverBlur;
    // return this.isActive ? 'https://app-cdn.btclass.cn/turtle/m-errata-over-focus.png' : 'https://app-cdn.btclass.cn/turtle/m-errata-over-blur.png';
  }
}
