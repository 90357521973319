


































































import { Vue, Component } from 'vue-property-decorator';
import ChooseCircleDialog from './choose-circle-dialog.vue';
import UploadImgs from '../upload-img/index.vue';
import DgTinyEditor from './dg-tiny-editor.vue';
import { getMoreCircleList, createCircleDynamic } from '@/api/circle';
import { CircleItem } from '@/typings';

@Component({
  components: {
    DgTinyEditor,
    ChooseCircleDialog,
    UploadImgs,
  },
})
export default class EditLongText extends Vue {
  formData: any = {
    title: '',
    content: '',
    pictures: [],
    circleIds: [],
  };
  circleList: CircleItem[] = [];
  circleMap: any = {};
  isLoading = false;
  isChooseCircleDialogVisible: boolean = false;
  editorInit: boolean = false; // 编辑器初始化状态
  isCreating: boolean = false;
  textLength: number = 0;
  maxTextLength: number = 10000;

  mounted() {
    this.getMoreCircleListHandle();
  }

  get moreThan() {
    return Number(this.textLength) > this.maxTextLength;
  }

  // 校验标题和正文是否符合提交要求
  get isCanPush() {
    const { content, title } = this.formData;
    const titleError = (title.trim() && title.trim().length < 5) || !(title.trim() && title.trim().length); // 标题存在且少于5个字，不允许提交 || 标题不存在
    return !!(content.trim() && !titleError);
  }

  // 获取所有圈子列表
  async getMoreCircleListHandle() {
    try {
      this.isLoading = true;
      const { rows } = await getMoreCircleList({ page: 0, limit: 100 });
      this.circleList = rows;
      this.circleList.forEach(item => {
        this.circleMap[item.id] = item;
      });
    } finally {
      this.isLoading = false;
    }
  }

  // 创建长文
  async creatHandle() {
    try {
      if (this.isCreating) return;
      await (this.$refs.formRef as any).validate();
      if (!this.isCanPush) {
        this.$message.warning('请填写正确的标题和正文');
        return;
      }
      this.isCreating = true;
      const params: any = {
        dynamicType: 'longArticle',
        ...this.formData,
      };
      if (!this.formData.circleIds.length) delete params['circleIds'];
      if (!this.formData.pictures.length) delete params['pictures'];
      const { id } = await createCircleDynamic(params);
      this.$message.success('发布成功');
      this.$emit('createSuccess', id);
    } catch (e) {
      console.log('发布长文失败');
    } finally {
      this.isCreating = false;
    }
  }

  circleIdsChange(ids) {
    this.formData.circleIds = ids;
  }

  deleteCircle(index) {
    this.formData.circleIds.splice(index, 1);
  }

  // 选择发布圈子
  addCircleHandle() {
    this.isChooseCircleDialogVisible = true;
  }

  // 取消事件
  cancleHandle() {
    const { content, pictures, circleIds } = this.formData;
    if (content || pictures.length || circleIds.length) {
      this.$confirm('确定返回么?返回后当前页面内容将不会被保存', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'circle-confirm-dialog',
      }).then(async () => {
        window.close();
      });
    } else {
      this.$router.replace({
        name: 'circle-index',
      });
    }
  }
}
