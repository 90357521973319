














































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import CommonDialog from '../common-dialog.vue';
import { CircleItem } from '@/typings';

@Component({
  components: {
    CommonDialog,
  },
})
export default class ComName extends Vue {
  @PropSync('visible', { type: Boolean }) visibleSync!: boolean;
  @Prop({ type: Array, default: () => [] }) circleIds!: number[];
  @Prop() circleList!: CircleItem[];
  chooseCircleIds: number[] = [];

  afeterCircleList: any[] = [];

  openDialogHandle() {
    console.log('circleIds', this.circleIds);
    this.chooseCircleIds = [...this.circleIds];
    // 校友圈排序

    if (this.circleIds && this.circleIds.length > 0) {
      this.afeterCircleList = [];
      const activeList: any[] = [];
      this.circleList.forEach(item => {
        if (this.circleIds.includes(item.id)) {
          activeList.push(item);
        } else {
          this.afeterCircleList.push(item);
        }
      });
      this.afeterCircleList = [...activeList].concat(this.afeterCircleList);
    } else {
      this.afeterCircleList = [...this.circleList];
    }
  }

  choseHadnle(id) {
    const index = this.chooseCircleIds.findIndex(item => item == id);
    if (index !== -1) {
      this.chooseCircleIds.splice(index, 1);
    } else {
      if (this.chooseCircleIds.length >= 3) {
        this.$message.warning('最多只能添加3个圈子');
        return;
      }
      this.chooseCircleIds.push(id);
    }
  }

  async backHandle() {
    this.visibleSync = false;
    this.chooseCircleIds = [];
  }

  async sureHandle() {
    this.$emit('change', this.chooseCircleIds);
    this.visibleSync = false;
  }

  // 去校友圈详情页面
  goCircleDetail(circleId) {
    const { href } = this.$router.resolve({
      name: 'circle-detail',
      query: {
        circleId,
      },
    });
    window.open(href);
  }
}
