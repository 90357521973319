<template>
  <div class="home-list-model">
    <div class="course-list">
      <div :class="'row ' + (isEnter ? 'fade' : '')">
        <lazy-component @show="handler">
          <template v-if="data">
            <template v-for="(course, index) in data">
              <course-list :course="course" :key="index"></course-list>
            </template>
          </template>
        </lazy-component>
        <template v-if="!isLoad">
          <template v-for="(course, index) in data">
            <course-list :course="course" :is-load="false" :key="index"></course-list>
          </template>
        </template>
      </div>
    </div>
    <div class="bt-center" v-if="id !== 0 && Array.isArray(data) && count > 4 && !isGet">
      <el-button class="bt-more" type="primary" :loading="loading" @click="more"> <span class="text">查看更多</span> <img src="../../../../../assets/images/home/icon-more.png" alt /> </el-button>
    </div>
  </div>
</template>

<script>
import { getCategoryCourses, getCategoryRecommendCourses } from '@/api/home';
import CourseList from './CourseList';

export default {
  props: {
    active: Number,
    id: Number,
    categoryId: Number,
  },
  data() {
    return {
      data: 4,
      cache: null,
      isGet: false,
      loading: false,
      isEnter: false,
      isLoad: false,
      count: 0,
    };
  },
  components: {
    CourseList,
  },
  methods: {
    handler() {
      this.init();
      this.isLoad = true;
    },
    init(id = this.id) {
      if (this.id !== this.active) {
        return;
      }
      if (Array.isArray(this.cache)) {
        return;
      }

      if (id >= -1) {
        switch (id) {
          case 0:
            this.getRecommendCourse(this.categoryId, 0, 4);
            break;
          case -1:
            this.getRecommendCourse(this.categoryId, 0, 4);
            break;
          default:
            this.getCourse(id, 0, 4);
            break;
        }
      }
    },
    getRecommendCourse(categoryId, start = 0, limit = 20) {
      getCategoryRecommendCourses(categoryId, start, limit).then(e => {
        this.data = e.rows;
        this.cache = e.rows;
        this.count = e.count;
      });
    },
    getCourse(categoryId, start, limit) {
      getCategoryCourses(categoryId, start, limit).then(e => {
        this.data = e.rows;
        this.count = e.count;
        this.cache = e.rows;
      });
    },
    more() {
      this.loading = true;

      let categoryId = this.id === -1 ? this.categoryId : this.id;
      getCategoryCourses(categoryId)
        .then(e => {
          this.data = e.rows;
          this.cache = e.rows;
          this.isGet = true;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggle() {
      this.isEnter = true;
      setTimeout(() => {
        this.isEnter = false;
      }, 300);
    },
  },
  watch: {
    active(val) {
      if (this.id === val) {
        this.toggle();

        if (this.cache) {
          setTimeout(() => {
            this.data = this.cache;
          }, 300);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bt-center {
  .bt-more {
    border: 1px solid #4b8fff;
    border-radius: 2px;
    margin-top: 1px !important;
  }
}
</style>
