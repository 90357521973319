

















import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import CommonDialog from '../common-dialog.vue';
import CommentItemCom from './comment-item.vue';
import { getCommentReplyList } from '@/api/circle';

@Component({
  name: 'CommentReplayDialog',
  components: {
    CommentItemCom,
    CommonDialog,
  },
})
export default class CommentReplayDialog extends Vue {
  @PropSync('visible') visibleSync!: boolean;
  @Prop({ type: Object, default: {} }) commentData;
  @Prop({ type: Boolean, default: false }) isVialot;

  data: any = {};
  isLoading: boolean = false;

  created() {
    this.data = Object.assign({}, this.commentData);
  }

  mounted() {
    this.getCommentReplays();
  }

  // 获取当前评论以及评论回复
  async getCommentReplays() {
    try {
      this.isLoading = true;
      const { rows } = await getCommentReplyList(this.commentData.id, {
        page: 0,
        limit: 100,
      });
      this.$set(this.data, 'topReplys', rows);
      this.$set(this.data, 'replyNum', rows.length);
      console.log('ths.data', this.data);
    } finally {
      this.isLoading = false;
    }
  }

  // 刷新操作
  refreshHandle() {
    this.getCommentReplays();
  }

  closeHandle() {
    this.data.topReplys = this.data.topReplys.slice(0, 3);
    this.$emit('dataChange', this.data);
    this.visibleSync = false;
  }
}
