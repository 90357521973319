import BtLink from '@bt/web-link';
import { getBrowser } from '@/utils/helper';
const ua = navigator.userAgent;
const isMobile = getBrowser(ua).mobile;
const isiPad = getBrowser(ua).iPad;
const isHuawei = getBrowser(ua).huawei;
const isWx = getBrowser(ua).wx;
const JsBridge = window.JsBridge;
import Vue from 'vue';

window.webLink = Vue.prototype.$webLink = new BtLink({
  projectName: 'phoenix',
  env: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  $window: window,
  $jsBridge: JsBridge,
  isMobile: 0,
});

const isNormalCourseDetail = name => ['course-home', 'course-lesson-list', 'course-material', 'course-info'].includes(name);
export function rePlaceLink(name, data) {
  // app内，无论啥链接都不作处理

  // if (!JsBridge.hasBridge && isMobile && !isiPad) {
  if (JsBridge.hasBridge) {
    if (name === 'course-learn') {
      name = 'normalCourseLesson';
      data = {
        courseId: data.id,
        lessonId: data.lessonId,
      };
    } else if (isNormalCourseDetail(name)) {
      name = 'courseDetail';
      data = {
        courseId: data.id,
      };
    } else if (name === 'custome_page') {
      return window.webLink.getLinkByPlatform('customPage', data, 'mobile');
    } else {
      return false;
    }
    return {
      name,
      data,
    };
  } else if (isiPad) {
    return false;
  } else if (isMobile || (isHuawei && isWx)) {
    // 如果在手机端且不是ipad
    if (isNormalCourseDetail(name)) {
      // 普通课课程详情页
      name = 'courseBuyingDetail';
      data.courseId = data.id;
      delete data.id;
    } else if (name === 'course-explore') {
      name = 'normalCourseList';
    } else if (name === 'open-course-explore') {
      name = 'openCourseList';
    } else if (name === 'open-course') {
      name = 'openCourseDetail';
      data = {
        courseId: data.id,
      };
    } else if (name === 'login') {
      let gotoUrl = data.goto ? `?gotoUrl=${encodeURIComponent(window.location.origin + data.goto)}` : '';
      return `${process.env.VUE_APP_WHALE_URL}login${gotoUrl}`;
    } else if (name === 'home') {
      name = 'homePage';
      data = {
        categoryId: 0,
      };
    } else if (name === 'setting_profile' || name === 'setting_realname_authentication') {
      name = 'settingMyInfo';
    } else if (name === 'my_passwd') {
      name = 'setPassword';
    } else if (name === 'my_mobile') {
      name = 'bindMobile';
    } else if (name === 'payment') {
      return `${process.env.VUE_APP_WHALE_URL}course/pay?orderSn=${data.sn}&courseId=${data.courseId}`;
    } else if (name === 'my_orders') {
      name = 'orderIndex';
    } else if (name === 'my_coin') {
      name = 'myBalance';
    } else if (name === 'my_coupon') {
      name = 'couponList';
    } else if (name === 'my_invitecode') {
      name = 'userInvite';
    } else if (name === 'my_bind_alipay') {
      name = 'bindAlipay';
    } else if (name === 'my_coin_money_pay') {
      // (name === 'my_coin_card_pay') {name = 'cardRecharge'; } //去除学习卡充值
      name = 'moneyRecharge';
    } else if (name === 'setting_edit_mobile') {
      name = 'bindMobile';
    } else if (name === 'setting_realname_authentication') {
      name = 'setRealName';
    } else if (name === 'my_coin_withdraw') {
      name = 'cashOut';
    } else if (name === 'course-learn') {
      return `${process.env.VUE_APP_WHALE_URL}course/${data.id}/learn?lessonId=${data.lessonId}`;
    } else if (name === 'open-course-show') {
      return `${process.env.VUE_APP_WHALE_URL}open/course/${data.id}/learn?lessonId=${data.lessonId}`;
    } else if (name === 'course-buying-guide' || name === 'course-buying-guide-old') {
      name = 'courseGuideTpl';
    } else if (name === 'coupon_receive') {
      name = 'couponReceive';
    } else if (name === 'course_feedback') {
      name = 'courseFeedback';
    } else if (name === 'course_service') {
      name = 'courseService';
    } else if (name === 'about_us_page') {
      name = 'aboutUs';
    } else if (name === 'learning-center') {
      name = 'studyTask';
    } else if (name === 'my-course-learning') {
      name = 'myCourse';
    } else if (name === 'addresss') {
      name = 'setAddresss';
    } else if (name === '8th') {
      name = 'activity8';
    } else if (name === '10th') {
      name = 'activity10';
    } else if (name === 'download-page') {
      name = 'downloadApp';
    } else if (name === 'dynamic-detail') {
      name = 'dynamicDetails';
    } else if (name === 'circle-index' && data.type == 'single') {
      name = 'channelHomepage';
    } else if (name === 'circle-detail') {
      name = 'circleHomepage';
    } else if (['new-user-about', 'new-user-teach-course', 'new-user-dynamic', 'new-user-ask', 'new-user-focus', 'new-user-fans'].includes(name)) {
      name = 'userHomepage';
    } else if (name === 'book-errata-list') {
      name = 'bookErrataList'; // 教材修订-教材列表页
    } else if (name === 'book-errata-details') {
      name = 'bookErrataBookDetails'; // 教材修订-按页码查看的修订页
    } else if (name === 'book-errata-my') {
      name = 'bookErrataHandle'; // 教材修订-已处理/待处理反馈列表页
    } else if (name === 'help-center') {
      name = data.id ? 'helpCenterDetail' : 'helpCenter';
    } else if(name === 'activity-act'){
      name = 'actPage'
    } else if(name === 'raffle_activity_index'){
      name = 'raffleActivity'
    } else {
      return false;
    }
    // @ts-ignore
    return window.webLink.getLinkByPlatform(name, data, 'mobile');
  }
  return false;
}
