import { request } from '@/utils/axios';

// 分页获取课程评价列表
// ：todo userid
export function getReviewsByPage(params) {
  return request.get('/v2/course-reviews/page', params, { requestBase: 'course' });
}

// 条件获取课程评价列表
export function getReviewsByCondition(id) {
  return request.get('/v2/course-reviews', { courseId: id }, { requestBase: 'course' });
}

// 新建评价
export function createReview(params) {
  return request.post('/v2/course-reviews', params, { requestBase: 'course' });
}

// 回复评价
export function replyReview(postId, content) {
  return request.post(`/v2/course-reviews/${postId}/posts`, { content }, { requestBase:'course' });
}

// 删除评价或回复
export function deleteReview(postId, data) {
  return request.delete(`/v2/course-reviews/${postId}`, data, { requestBase: 'course' } );
}
