






























































































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import ChooseBook from './choose-book.vue';
import UploadImgs from './upload-imgs.vue';
import { createBookErrataFeedback } from '@/api/book-errata';

@Component({
  components: {
    ChooseBook,
    UploadImgs,
  },
})
export default class AskDrawer extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible!: any;

  modal: boolean = true; // 是否需要遮罩层
  direction: string = 'rtl'; // Drawer 打开的方向
  closeOnPressEscape: boolean = false; // 是否可以通过按下 ESC 关闭 Drawer
  destroyOnClose: boolean = true; // 控制是否在关闭 Drawer 之后将子元素全部销毁
  showClose: boolean = false; // 是否显示关闭按钮
  size: any = '812px';
  withHeader: boolean = false; // 控制是否显示 header 栏
  wrapperClosable: boolean = false; // 点击遮罩层是否可以关闭 Drawer

  chooseVisible: boolean = false; // 选择教材弹窗

  // 添加修订数据
  errataInfo: any = {
    bookInfo: {},
    page: '',
    content: '',
    pictures: [],
  };
  errorName: any = [];

  // 默认教材图不显示阴影
  get showShadow() {
    return this.errataInfo.bookInfo.picture !== 'https://tmp.btclass.cn/yunyin/%E6%95%99%E6%9D%90%E5%B0%81%E9%9D%A2%E4%BA%AE%E8%89%B2.png';
  }

  onInput() {
    this.errataInfo.page = this.errataInfo.page.replace(/[^\d]/g, '');
  }

  @Watch('errataInfo', { deep: true })
  watchErrataInfo() {
    this.errorName = [];
  }

  //  清除所有数据
  clearAll() {
    this.errataInfo = {
      bookInfo: {},
      page: '',
      content: '',
      pictures: [],
    };
  }

  // 关闭
  closeFun() {
    // 没有填写过任何内容，直接关闭
    const { bookInfo, page, content, pictures } = this.errataInfo;
    if (!bookInfo.id && !page && !content && !pictures.length) {
      this.clearAll();
      this.syncVisible = false;
      return;
    }
    // 有填过内容，二次提醒
    this.$confirm('确定取消吗？取消后当前内容将不会被保存', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      customClass: 'add-errata-confirm',
    }).then(async () => {
      this.clearAll();
      this.syncVisible = false;
    });
  }

  // 提交
  submitLoading:boolean = false;
  async submit() {
    if (this.submitLoading) return;
    try {
      this.submitLoading = true;
      const { bookInfo, page, content, pictures } = this.errataInfo;
      if (!bookInfo.id) {
        this.errorName.push('bookInfo');
      }
      if (!page) {
        this.errorName.push('page');
      }
      if (content.length < 5) {
        this.errorName.push('content');
      }
      // 有校验失败的，不继续提交
      if (this.errorName.length) return;

      // 校验页码范围1～9999
      if (Number(page) < 1 || Number(page) > 9999) {
        return this.$message.warning('页码范围1～9999');
      }

      const params: any = {
        bookId: bookInfo.id,
        page,
        content,
        pictures,
      };
      console.log('提交', params);
      await createBookErrataFeedback(params);
      this.$message.success('提交成功');
      this.syncVisible = false;
      this.$emit('addSuccess');
      this.clearAll();
    } catch (e) {
      console.log('新增修订失败', e);
    } finally {
      setTimeout(() => {
        this.submitLoading = false;
      }, 500);
    }
  }

  // 选择教材弹窗
  openBookDialog() {
    this.chooseVisible = true;
  }

  // 选择教材
  chooseBookFun(bookInfo) {
    console.log('选择教材', bookInfo);
    this.errataInfo.bookInfo = bookInfo;
  }
}
