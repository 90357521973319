












import { Vue, Component } from 'vue-property-decorator';
import { getChannelDetail, getCircleChannelList } from '@/api/circle';
import { ChannelItem } from '@/typings';

@Component
export default class SideCom extends Vue {
  channelList: ChannelItem[] = []; // 频道列表
  isLoadingChannels: boolean = false; // 频道是否加载中
  isSingleChannel: boolean = false; // 是否是单独频道页

  activeChannel: ChannelItem | null = null;

  async mounted() {
    try {
      this.isLoadingChannels = true;
      const { channelId, type } = this.$route.query;
      // 根据type判断是不是单独的频道页面
      if (type && type === 'single' && channelId) {
        this.isSingleChannel = true;
      }
      // 单独频道页
      if (this.isSingleChannel) {
        try {
          const data = await getChannelDetail(+channelId);
          this.channelList = [data];
          this.activeChannel = data;
        } catch (e) {
          // @ts-ignore
          if (e.code == 2025) {
            this.$emit('errorPage');
          }
        }
      } else {
        const { rows } = await getCircleChannelList();
        this.channelList = rows;

        if (!channelId) {
          this.activeChannel = this.channelList[0];
          this.$router.replace({
            query: {
              channelId: this.activeChannel.id + '',
            },
          });
        } else {
          const active = this.channelList.find(item => item.id == +channelId);
          console.log('active', active);
          if (active) {
            this.activeChannel = active as any;
          }
        }
      }
      this.$emit('change', this.activeChannel, this.isSingleChannel, false);
    } finally {
      this.isLoadingChannels = false;
    }
  }

  chooseChannel(channel) {
    if (!this.activeChannel || channel.id !== this.activeChannel.id) {
      this.activeChannel = channel;
      this.$emit('change', channel, this.isSingleChannel, true);
    }
  }

  // 返回圈子主页
  goMore() {
    const { href } = this.$router.resolve({ name: 'circle-index' });
    window.open(href);
  }
}
