<template>
  <div class="home-recommend">
    <div class="home-course-model course-area fff" v-if="data">
      <div class="pc-container">
        <div class="text-line head">
          <h5 class="anniversary-model-title">
            <span>推荐课程</span>
            <!-- <a v-if="$root.isAnniversary&&$root.anniversaryLinkImg" :href="$root.anniversaryLink" target="_blank" class="invite-anniversary">
              <img :src="$root.anniversaryLinkImg" v-default-img-alt="'七周年'" />
            </a> -->
            <OperatPlaceCom :subscene="0" title="推荐课程"></OperatPlaceCom>
          </h5>
          <a href="/course/explore" class="open">查看更多</a>
        </div>
        <div class="course-list" v-if="hasMounted">
          <div class="row">
            <button type="button" :class="'arrow-left ' + (left ? 'ccc' : '')" @click="pre"></button>
            <el-carousel :interval="6000" arrow="always" indicator-position="none" ref="car" :loop="true" :autoplay="true" @change="change">
              <el-carousel-item v-for="(courses, i) in data" :key="i" class="pt10">
                <template v-for="(course, j) in courses">
                  <course-list :course="course" :key="j"></course-list>
                </template>
              </el-carousel-item>
            </el-carousel>
            <button type="button" :class="'arrow-right ' + (right ? 'ccc' : '')" @click="next"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CourseList from './components/CourseList';
import OperatPlaceCom from '@/pc/views/home/components/OperatPlace.vue';

export default {
  data() {
    return {
      division: 4,
      left: true,
      right: false,
      opt: {
        query: {
          recommended: 1,
          status: 'published',
          start: 0,
          limit: 8,
          order: 'recommendedSeq,DESC|recommendedTime,DESC|id,DESC',
        },
        division: 4,
      },
      hasMounted: false,
    };
  },
  components: {
    CourseList,
    OperatPlaceCom,
  },
  computed: {
    ...mapState('home', {
      data: state => state.recommendCourses,
    }),
  },
  mounted() {
    this.hasMounted = true;
    this.getRecommendCourses(this.opt);
  },
  methods: {
    ...mapActions('home', ['getRecommendCourses']),
    change(index) {
      this.left = index === 0;
      this.right = index === this.data.length - 1;
    },
    pre() {
      if (!this.left) {
        this.$refs.car.prev();
      }
    },
    next() {
      if (!this.right) {
        this.$refs.car.next();
      }
    },
  },
};
</script>
