import Vue from 'vue';
import Router from 'vue-router';
import { beforeEachRouterHook, afterEachRouterHook } from './routerHooks';

import learnCenter from './learn-center';
import tiku from './tiku';
import home from './home';
import user from './user';
import login from './login';
import course from './course';
import group from './group';
import location from './location';
import payment from './payment';
import notification from './notification';
import settings from './settings';
import download from './download';
import myCoin from './my-coin';
import sitemap from './sitemap';
import aboutus from './aboutus';
import exportRecords from './export-records';
import Activity from './activity';
import fetchSetting from './fetch-setting';
import tools from './tools';
import ask from './ask';
import circle from './circle';
import search from './search';
import page from './page';
import bookErrata from './book-errata';
import helpCenter from './help-center';
import { getEnv, getPreTitle } from '@/utils/env';
import { getBrowser } from '@/utils/helper';

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Router.prototype.replace = function replace(location) {
  console.log('location', location);
  return originalReplace.call(this, location).catch(err => err);
};

Vue.use(Router);
let routes = [].concat(
  home,
  user,
  group,
  course,
  tiku,
  learnCenter,
  payment,
  notification,
  settings,
  download,
  sitemap,
  login,
  myCoin,
  aboutus,
  exportRecords,
  Activity,
  tools,
  page,
  [
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ '../views/404/Index'),
    },
  ],
  location,
  fetchSetting,
  ask,
  circle,
  search,
  bookErrata,
  helpCenter,
);
import { rePlaceLink } from '@/utils/replace-web-link';

export function createRouter() {
  let router = new Router({
    mode: process.env.VUE_APP_ROUTER_MODE,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
    },
    routes,
  });
  let hasEnv = false;
  let routerBeforeEach = async (to, from, next) => {
    if (!hasEnv) {
      hasEnv = true;
      const { isCalledCallback } = await getEnv(() => {
        delete to.query.env;
        router.replace({ name: to.name, query: to.query, params: to.params });
      });
      if (isCalledCallback) return;
    }
    if (to.path === '/') {
      let viewportMeta = document.getElementsByTagName('meta[name=viewport]');
      viewportMeta.content = 'width=1300,maximum-scale=1,viewport-fit=cover';
    }
    let targetLink = rePlaceLink(to.name, Object.assign({}, to.params, to.query));
    if (targetLink) {
      if (to.name === 'custome_page' && window.JsBridge.hasBridge) {
        // 在ios端存在链接跳转后，上一个路由还在，导致没法返回
        const isAndriod = getBrowser(navigator.userAgent).android; // 安卓
        if (isAndriod) {
          await window.location.replace(targetLink);
        } else {
          // 增加以下逻辑防止空白页面出现
          history.replaceState(null, '', targetLink);
          setTimeout(() => {
            history.back();
          });
          //单独这么处理完页面上层会存在一个空的页面，不过可以正常返回（配置页面已经改为了移动端链接，此处仅做兜底兼容处理）
          await window.location.replace(`sierra://webview?url=${targetLink}`);
        }
        next(false);
      } else if (window.JsBridge.hasBridge) {
        window.webLink.go(targetLink.name, targetLink.data, { forcePlatform: 'sierra' });
        setTimeout(() => {
          window.JsBridge.callHandler('exitWebview');
        }, 3000);
        next(false);
      } else {
        await window.location.replace(targetLink);
        next(false);
      }
    }
    if (to.meta && to.meta.title) {
      document.title = getPreTitle(`BT教育-${to.meta.title}`);
    }

    beforeEachRouterHook(to, from, next);
  };
  router.beforeEach(routerBeforeEach);
  router.afterEach(afterEachRouterHook);

  return router;
}
