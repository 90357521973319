


















import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

@Component({})
export default class DialogCode extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) dialogVisible: any;
  @Prop({
    type: Object,
    default: () => {
      return {
        title: '',
        subTitle: '',
        qrUrl: '',
        tip: '',
      };
    },
  })
  options: any;

  // options: any = {
  //   titlUrl: 'https://app-cdn.btclass.cn/new-prefix/turtle/FgxkHS1O3fMUYxkSZ8Io_Vbo1NxX.png',
  //   title: '标题标题',
  //   subTitle: '啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦',
  //   qrUrl: 'https://app-cdn.btclass.cn/new-prefix/turtle/FqkNgf10KtRICTo1w4SqOVk7GY6Q.png',
  //   tip: '使用微信扫码',
  // };
}
