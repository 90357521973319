





















import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import ToolbarImage from './toolbar-image.vue';
import load from '@/pc/components/Tinymce/dynamicLoadScript.ts';
import { getQiniuUpToken } from '@/api/token-v2';
import * as qiniu from 'qiniu-js';

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://lib.baomitu.com/tinymce/5.10.5/tinymce.min.js';
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce@4.9.8/tinymce.min.js';
const tinymceCDN = 'https://cdn.btclass.cn/tinymce/tinymce.min.js';

@Component({
  components: {
    ToolbarImage,
  },
})
export default class DGEditorAll extends Vue {
  @PropSync('form', { type: Object, default: '' }) formSync?: any;
  @PropSync('value', { type: String, default: '' }) syncValue?: string;
  @PropSync('textLength', { type: Number, default: 0 }) textLengthSync?: number;
  @PropSync('initStatus', { type: Boolean, default: false }) initSync?: boolean;

  @Prop({ type: String, default: '' }) className?: string;

  imageVisible: boolean = false; // 插入图片弹窗开关
  // 编辑器语言
  languageTypeList: any = {
    en: 'en',
    zh: 'zh_CN',
    es: 'es_MX',
    ja: 'ja',
  };
  // 七牛
  qiniu: any = {
    uploadUrl: '',
    key: '',
    config: { token: '', folderName: '' },
  };

  created() {
    this.setUploadConfig();
  }
  mounted() {
    this.init();
  }
  // 离开销毁编辑器
  destroyed() {
    this.destroyTinymce();
  }

  // 加载上传图片配置
  async setUploadConfig() {
    //@ts-ignore
    const tokenRes = await getQiniuUpToken('/circle/dynamicPc');
    console.log('tokenRes', tokenRes);
    if (tokenRes && tokenRes.token) {
      const uploadUrl = await qiniu.getUploadUrl({}, tokenRes.token);
      this.qiniu.uploadUrl = uploadUrl;
      this.qiniu.config = { token: tokenRes.token };
    }
  }

  // 初始化加载编辑器
  init() {
    // dynamic load tinymce from cdn
    load(tinymceCDN, err => {
      if (err) {
        this.$message.error(err.message);
        return;
      }
      this.initTinymce();
      // @ts-ignore
      window.tinymce.activeEditor.getBody().setAttribute('data-mce-placeholder', 'This is NEW placeholder text');
    });
  }

  /* eslint-disable */
  // 初始化编辑器
  initTinymce() {
    console.log('初始化编辑器');
    const _this = this;
    // @ts-ignore
    window.tinymce.init({
      selector: `#DGEditor`,
      // @ts-ignore
      placeholder: '请输入正文（必填~）',
      placeholder_attrs: {
        style: {
          color: 'red',
          fontStyle: 'italic',
        },
      },
      language: this.languageTypeList['zh'],
      branding: false, // 隐藏富文本编辑器组件的商标消息” Powered by TinyMCE”
      elementpath: false, //禁用编辑器底部的状态栏
      statusbar: false, // 隐藏编辑器底部的状态栏
      menubar: false, // 隐藏文件栏
      paste_webkit_styles: 'all',
      plugins: 'textcolor link wordcount paste table,placeholder',
      // @ts-ignore
      min_height: 350, // 编辑区域的最小高度
      fontsize_formats: '12px 13px 14px 15px 16px 18px 24px 36px', //字体大小配置
      toolbar: ['fontsizeselect bold underline strikethrough forecolor alignleft aligncenter alignright alignjustify qiNiuImage link'],
      paste_postprocess: async function (plugin, args) {
        // args.node可以获取到粘贴过来的所有dom节点，直接可以用操作dom的方式取修改它
        // 注意此函数不需要return返回值，直接修改即可

        // 获取粘贴图片dom,对样式做下前置处理
        let allImgs = args.node.querySelectorAll('img'); //图片dom列表
        allImgs.forEach(dom => {
          dom.style.maxWidth = '100%';
          dom.style.height = 'auto';
        });
        console.log('===========paste_postprocess', allImgs);
      },
      init_instance_callback: editor => {
        console.log('编辑器初始化', _this.formSync.content);
        // @ts-ignore
        editor.setContent(_this.formSync.content);
        _this.watchTextLimit();
        editor.on('NodeChange Change KeyUp SetContent', () => {
          // 第一轮方案
          // const tableStyle = '<style>bt-table-style: {} td,th,table {border: 1px solid #363636;} table {border-collapse: collapse;border-spacing: 0; max-width: 100%;}  table p {margin:2px 0}</style>';
          // let content = editor.getContent();
          // const hasTableStyle = content.indexOf('bt-table-style') !== -1; // 是否存在表格样式
          // const hasTableDom = content.indexOf('</table>') !== -1; // 是否存在表格

          // console.log('_this.formSync.content', _this.formSync.content, hasTableDom, hasTableStyle);

          // // 如果存在表格且没有默认样式，补充
          // if (hasTableDom && !hasTableStyle) {
          //   content = tableStyle + content;
          // }
          // _this.formSync.content = content;

          _this.formSync.content = editor.getContent();
          // console.log('editor.getContent()', editor.getContent())
        });
        editor.on('paste', e => {
          setTimeout(async () => {
            const doc = editor.getDoc();
            console.log('监测到有内容粘贴，开始处理');
            // 处理图片粘贴
            const allImgItems = doc.getElementsByTagName('img');
            if (allImgItems && allImgItems.length) {
              console.log('检测到图片列表', allImgItems);
              editor.setProgressState(true, 0); // 开始loading
              let actionArr: any = []; // 图片处理数组
              // @ts-ignore
              for (let dom of allImgItems) {
                actionArr.push(_this.uploadThirdUrlImg(dom, _this));
              }
              Promise.all(actionArr)
                .then(cb => {
                  editor.setProgressState(false, 0); // 结束loading
                  console.log('图片上传结束', cb);
                })
                .catch(err => {
                  editor.setProgressState(false, 0); // 结束loading
                  console.log('图片上传失败', err);
                });

              // // 选取第一张作为封面
              // if (!this.formSync.pictures[0]) {
              //   console.log('选取黏贴的第一张图片作为封面图', allImgItems[0].src);
              //   !this.formSync.pictures.push(allImgItems[0].src);
              // }
            }
            // console.log('editor.getDoc()', editor.getDoc())
          });
        });
      },
      // @ts-ignore
      content_css_cors: true,
      content_style:
        'p { margin: 0;font-weight: 400;color: #595959;line-height: 25px; font-weight:400 } .mce-item-table td,th,table {border: 1px solid #BBB;} .mce-item-table {border-collapse: collapse;border-spacing: 0}  table p {margin:2px 0}',
      setup(editor) {
        editor.addButton('qiNiuImage', {
          icon: 'image',
          tooltip: '插入图片',
          onclick: () => {
            _this.imageVisible = true;
          },
        });
      },
    });
    this.initSync = true;
  }
  /* eslint-enable */

  // 销毁编辑器
  destroyTinymce() {
    // @ts-ignore
    const tinymce = window.tinymce.get('DGEditor');
    if (tinymce) {
      tinymce.destroy();
    }
  }

  // 图片上传七牛成功
  imageSuccessCBK(arr) {
    console.log('arr', arr);
    arr.forEach(v => {
      // if (index === 0 && !this.formSync.pictures[0]) {
      //   // 封面未上传，设置为封面图
      //   this.formSync.pictures.push(v.url);
      // }
      // @ts-ignore
      window.tinymce.get('DGEditor').insertContent(`<img class="wscnph" style="max-width: 100%" src="${v.url}" >`);
    });
  }

  // 监听文本，获取字数
  @Watch('formSync.content')
  watchTextLimit() {
    const editor = window.tinymce.get('DGEditor');
    this.textLengthSync = editor.getContent({ format: 'text' }).replace(/\s*/g, '').length; // 过滤掉空格
    console.log('获取字数', this.textLengthSync);
  }
  // 图片链接转成base64
  getBase64ByURL(imgUrl) {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;
      var xhr = new XMLHttpRequest();
      xhr.open('get', imgUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (this.status == 200) {
          var blob = this.response;
          let oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            // @ts-ignore
            resolve({ blob, base64: e.target.result });
          };
          oFileReader.readAsDataURL(blob);
        }
      };
      xhr.onerror = function () {
        reject();
      };
      xhr.send();
    });
  }
  // 获取图片宽高
  getImageSize(url) {
    return new Promise(resolve => {
      var img = document.createElement('img');
      img.src = url;
      img.onload = () => {
        // 为什么要写 onload  是因为要等他加载完之后才能获取到图片宽高
        resolve({ width: img.naturalWidth, height: img.naturalHeight }); //  2064,4608
      };
    });
  }
  // 将第三方图片转成bt cdn链接,并替换富文本内容
  async uploadThirdUrlImg(dom, _that) {
    return new Promise(async (resolve, reject) => {
      console.log('1、检测到图片地址，尝试上传到七牛云', dom.src);
      try {
        const { blob }: any = await this.getBase64ByURL(dom.src);
        const { width, height }: any = await this.getImageSize(dom.src);
        const query = `?w=${width}&h=${height}`;
        // const key = `circle/dynamic/${new Date().getTime()}--${this.randomString(16)}.jpg`;
        const key = undefined;
        const observable = qiniu.upload(blob, key, this.qiniu.config.token, {}, {});
        observable.subscribe({
          next(res) {
            console.log(res);
          },
          error(err) {
            resolve(0);
            console.error('subscribe失败', err);
          },
          complete(qiniuRes: any) {
            console.count();
            dom.setAttribute('src', `${qiniuRes.url}${query}`);
            console.log('2、图片上传成功', `${qiniuRes.url}${query}`);
            resolve(1);
          },
        });
      } catch {
        // 防盗链图片会上传失败，做下处理
        const newUrl = `https://wx.btclass.cn/rf?originUrl=${encodeURIComponent(dom.src)}`;
        dom.setAttribute('src', newUrl);
        console.log('3、图片上传失败', dom.src, newUrl);
        resolve(0);
      } finally {
        _that.formSync.content = window.tinymce.get('DGEditor').getContent();
      }
    });
  }
  // 生成随机字符串
  randomString(length) {
    var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) {
      result += str[Math.floor(Math.random() * str.length)];
    }
    return result;
  }
}
