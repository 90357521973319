import { request } from '@/utils/axios';

// 获取教材分类和科目树
export function getBookCategoryTree(params: any) {
  return request.get('/v2/book/category-tree', params, { requestBase: 'newBook' });
}

// 书籍列表（会产生history记录）
export function getBookErrataListHistory(params) {
  return request.get(`/v2/book/errata-list`, params, {
    requestBase: 'newBook',
  });
}

// 书籍列表
export function getBookErrataList(params) {
  return request.get(`/v2/book`, params, {
    requestBase: 'newBook',
  });
}

// 获取教材修订页码列表
export function getBookErrataPages(params) {
  return request.get(`/v2/book/errata/page`, params, {
    requestBase: 'newBook',
  });
}

// 获取用户端教材修订列表(修订列表——按页码)
export function getOneBookErratas({ bookId, ...params }) {
  return request.get(`/v2/book/${bookId}/errata`, params, {
    requestBase: 'newBook',
  });
}

// 获取教材章节目录修订列表(修订列表——按目录)
export function getBookChapterErrata({ bookId, ...params }) {
  return request.get(`/v2/book/${bookId}/chapter/errata`, params, {
    requestBase: 'newBook',
  });
}

// 用户端获取书籍信息
export function showForUser({ bookId, ...params }) {
  return request.get(`/v2/book/${bookId}/for-user`, params, {
    requestBase: 'newBook',
  });
}

// 我的修订反馈列表（已处理、未处理）
export function myBookErrataFeedbacks({ ...params }) {
  return request.get(`/v2/book/errata/feedback/mine`, params, {
    requestBase: 'newBook',
  });
}

// 获取教材修订详情
export function showBookErrata({ errataId, ...params }) {
  return request.get(`/v2/book/errata/${errataId}/user`, params, {
    requestBase: 'newBook',
  });
}

// 新增教材修订反馈
export function createBookErrataFeedback({ ...params }) {
  return request.post(`/v2/book/errata/feedback`, params, {
    requestBase: 'newBook',
  });
}

// 标记教材修订已读
export function updateBookErrataRead({errataId, ...params}) {
  return request.post(`/v2/book/errata/${errataId}/read`, params, {
    requestBase: "newBook",
  });
}
