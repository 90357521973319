import Vue from 'vue';
import lStorage from '@/utils/localStorage';
import { collectionBarClick } from '@/api/my';

const notify = (notifications, config) => {
  let isShowNotifications = lStorage.get('isShowNotifications') || [];
  notifications.every((notification, index) => {
    if (!isShowNotifications.includes(notification._id)) {
      isShowNotifications.push(notification._id);
      const params = Object.assign({ offset: 80, customClass: 'bt-notification' }, config);
      params.title = notification.show.title || '你有一个通知';
      params.message = notification.show.content || '';
      params.onClick = () => {
        try {
          collectionBarClick({ notificationId: notification.id + '', timestamp: Date.now() + '' });
        } catch (err) {
          if (err.hideNormalFail) {
            err.hideNormalFail();
          }
        }
        window.open('/notification/show/' + notification.who, '_blank');
      };
      setTimeout(() => {
        Vue.prototype.$notify(params);
      }, index * 500);
      return false;
    } else {
      return true;
    }
  });
  if (isShowNotifications.length > 50) {
    isShowNotifications = isShowNotifications.slice(-50);
  }
  lStorage.set('isShowNotifications', isShowNotifications);
};

Vue.use({
  install: Vue => {
    Vue.prototype.$showNotification = notify;
  },
});
