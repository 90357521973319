



























import { Vue, Component, PropSync } from 'vue-property-decorator';
import { mapActions } from 'vuex';

@Component({
  methods: {
    ...mapActions('info', ['getQiniuToken', 'getQiniuUrl']),
  },
})
export default class modulesUploadImgs extends Vue {
  @PropSync('images', { type: Array, default: () => [] }) syncImages: any;
  getQiniuToken!: () => Promise<any>;
  getQiniuUrl!: () => Promise<any>;

  qiniuUrl: any = '';
  imgList: any = [];
  prefix:string = '/doubt/image';

  qiniuToken = {
    token: '',
  };

  created() {
    this.getToken();
  }

  // 获取上传token
  getToken() {
    const { prefix } = this;
    // @ts-ignore
    this.getQiniuToken(prefix).then(async data => {
      // @ts-ignore
      this.qiniuUrl = await this.getQiniuUrl(prefix);
      this.qiniuToken.token = data;
    });
  }
  storgeFile: any = [];
  // 上传文件之前的钩子
  beforeUpload(file) {
    this.storgeFile.push(file);
    const { syncImages, storgeFile } = this;
    if (syncImages.length + storgeFile.length > 3) {
      this.$message.warning('最多只能上传三张图片');
      return false;
    }
    if (!/.(jpg|jpeg|png)$/.test(file.type)) {
      this.$message.error('图片格式不正确!');
      return false;
    }
    return true;
  }

  // 文件上传成功时的钩子
  onUploadSuccess(data) {
    const { url } = data;
    if (!/(.*)\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(url)) {
      this.storgeFile = [];
      return;
    }
    this.syncImages.push(url);
    this.storgeFile = [];
  }

  onUploadError() {
    this.$message.error('当前网络状况不佳，请稍后再试~');
    this.storgeFile = [];
  }

  // 删除图片
  handleRemove(removeUrl) {
    this.syncImages = this.syncImages.filter(url => {
      return url !== removeUrl;
    });
  }
}
