




























































































import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import MainLoading from '@/pc/components/loading/main-loading.vue';
import ProgressCircle from './progress-circle.vue';
import { trackEvent } from '@/utils/helper';

import { getBookChapterErrata, getBookErrataPages } from '@/api/book-errata';
// @ts-ignore
import MErrataDown from '../../../../assets/images/book-errata/m-errata-down.png';
// @ts-ignore
import MErrataUp from '../../../../assets/images/book-errata/m-errata-up.png';

@Component({
  components: {
    MainLoading,
    ProgressCircle,
  },
})
export default class TabsChapter extends Vue {
  @Prop({ type: Number, default: 0 }) bookId!: any;
  @PropSync('chapterPageList', { type: Array, default: [] }) chapterPageListSync: any; // 章节对应的页码列表
  @PropSync('switchIndex', { type: Number, default: -1 }) switchIndexSync: any; // 当前选中的章节id
  @PropSync('activeChapterId', { type: Number, default: 0 }) activeChapterIdSync: any; // 章节对应的页码列表
  @PropSync('activeChildPage', { type: Number, default: 0 }) activeChildPageSync: any; // 当前选中的章节页码
  @PropSync('errataLoading', { type: Boolean, default: true }) errataLoadingSync: any; // 修订加载loading

  isReadStatus(data) {
    return data.errataNum === data.userReadNum;
  }

  isLoading: boolean = true;
  opacityLoading: boolean = false;
  chapterList: any = []; // 章节列表
  chapterIdMap: any = {};
  chapterMap: any = {};

  // 切换tab时，开始执行该逻辑
  async created() {
    // 获取教材的章节列表
    await this._getBookChapterErrata();
  }

  // 获取所有章节
  async _getBookChapterErrata() {
    try {
      const { dictory } = await getBookChapterErrata({
        bookId: this.bookId,
      });
      this.chapterList = dictory || [];
      this.sortChapterList(this.chapterList);
    } catch (e) {
      console.log('获取章节失败', e);
      // 教材被关闭
      if ([1041].includes(e.code)) {
        e.hideNormalFail && e.hideNormalFail();
        this.$emit('setErrorInfo', e);
      }
    } finally {
      // sync同步有延迟，用$nextTick包起来
      this.$nextTick(async () => {
        console.log('获取所有章节', this.chapterList);

        // 没有章节，那就清空数据，显示兜底页
        if (!this.chapterList.length) {
          this.$emit('showNoResult', 2);
        } else {
          // 有章节，遍历缓存章节id对应的下标和内容
          this.chapterList.forEach((parent: any, index) => {
            this.chapterIdMap[parent.id] = index;
            this.chapterMap[parent.id] = parent;
            parent.isShowRead = false;
            if ((parent.childs || []).length) {
              const { isReadList, unReadList } = this.initChapterChilds(parent);
              parent.isReadList = isReadList;
              parent.unReadList = unReadList;
              // parent.childs.forEach((child: any) => {
              //   this.chapterIdMap[child.id] = index;
              //   this.chapterMap[child.id] = child;
              // });
            }
          });
          // 优先级： 路由 > 本地缓存 > 默认章节列表第一个
          const { chapterId: sChapterId } = this.getStorage();
          // const chapterId = Number(this.$route.query.chapterId || sChapterId || this.chapterList[0].id);
          const chapterId = Number(this.orderSortChapterList[0].id);
          // 拿到选择章节的信息
          const activeCard = this.chapterMap[chapterId] || this.chapterMap[this.orderSortChapterList[0].id];
          // 拿到选择章节对应的父章节下标
          const sSwitchIndex = this.chapterIdMap[chapterId];
          // 缓存中展开的父章节下标
          const { switchIndex: storageIndex } = this.getStorage();
          // 拿到需要展开的父章节下标，优先级： 路由 > 本地缓存 > 默认第一个
          // const switchIndex = Number(storageIndex || this.$route.query.switchIndex || sSwitchIndex || 0);
          // const switchIndex = Number(sSwitchIndex || 0);
          const switchIndex = 0;
          // 展开父章节
          this.changeSwitchIndex(switchIndex);
          // 选择对应章节
          await this.activeChapter(activeCard, false);
        }
        this.isLoading = false;
        this.opacityLoading = false;
      });
    }
  }
  // 初始化章节排序
  initChapterChilds(chapter) {
    let isReadList: any = [];
    let unReadList: any = [];
    (chapter.childs || []).map((child, index) => {
      this.chapterIdMap[child.id] = index;
      this.chapterMap[child.id] = child;
      if (this.isReadStatus(child)) {
        isReadList.push(child);
      } else {
        unReadList.push(child);
      }
    });
    return {
      isReadList,
      unReadList,
    };
    // return [...unReadList, ...(chapter.isShowRead ? isReadList : []), ...(isReadList.length ? [{ id: 0 }] : [])];
  }
  // 根据收起展开状态显示章节列表
  getSortChapterList(chapter) {
    return [...chapter.unReadList, ...(chapter.isShowRead ? chapter.isReadList : []), ...(chapter.isReadList.length ? [{ id: 0 }] : [])];
  }

  // 收起展开已读节点
  showChildIsReadList(chapter) {
    if (chapter.isShowRead) {
      trackEvent(`bt_10913`, { description: '章节目录页二级章节的【收起】按钮', bookId: this.bookId, chapterId: chapter.id });
    } else {
      trackEvent(`bt_10912`, { description: '章节目录页二级章节的【查看已读节点】按钮', bookId: this.bookId, chapterId: chapter.id });
    }
    chapter.isShowRead = !chapter.isShowRead;
    this.$forceUpdate();
  }

  // 已读数改变，在不改变源数据结构的情况下，更新章节信息
  async updateBookChapterErrata() {
    // 半透明遮罩
    this.opacityLoading = true;
    try {
      const { dictory } = await getBookChapterErrata({
        bookId: this.bookId,
      });
      (dictory || []).map(parent => {
        const parentIndex = this.chapterIdMap[parent.id];
        if (this.chapterList[parentIndex]) {
          this.chapterList[parentIndex].errataNum = parent.errataNum;
          this.chapterList[parentIndex].userReadNum = parent.userReadNum;
          if ((parent.childs || []).length) {
            parent.childs.forEach((child: any) => {
              const childIndex = this.chapterIdMap[child.id];
              if (this.chapterList[parentIndex].childs[childIndex]) {
                this.chapterList[parentIndex].childs[childIndex].errataNum = child.errataNum;
                this.chapterList[parentIndex].childs[childIndex].userReadNum = child.userReadNum;
              }
            });
          }
        }
      });
      this.$forceUpdate();
    } catch (e) {
    } finally {
      this.opacityLoading = false;
    }
  }

  // 章节按已读未读归类
  get orderSortChapterList() {
    return [...this.unReadChapterList, ...(this.showIsReadList || !this.unReadChapterList.length ? this.isReadChapterList : [])];
  }

  isReadChapterList: any = []; // 已读章节列表
  unReadChapterList: any = []; // 未读章节列表
  showIsReadList: boolean = false; // 显示已读列表
  showIsReadIcon: any = {
    // true: 'https://app-cdn.btclass.cn/turtle/m-errata-up.png',
    // false: 'https://app-cdn.btclass.cn/turtle/m-errata-down.png',
    true: MErrataUp,
    false: MErrataDown,
  };
  sortChapterList(chapterList) {
    if (!chapterList) return [];
    this.isReadChapterList = [];
    this.unReadChapterList = [];
    this.chapterList.map((chapter, index) => {
      // 该章节下所有修订全部已读, 要绑定原型链
      if (this.isReadStatus(chapter)) {
        this.isReadChapterList.push(this.chapterList[index]);
      } else {
        this.unReadChapterList.push(this.chapterList[index]);
      }
    });
  }
  // 展开收起已读章节
  changeShowIsRead() {
    if (this.showIsReadList) {
      trackEvent(`bt_10911`, { description: '一级章节的【收起】按钮', bookId: this.bookId });
    } else {
      trackEvent(`bt_10910`, { description: '一级章节的【查看已读章节】按钮', bookId: this.bookId });
    }
    this.showIsReadList = !this.showIsReadList;
  }

  // 展开章节，要同步到路由和缓存
  changeSwitchIndex(newIndex) {
    console.log('this.switchIndexSync', this.switchIndexSync, newIndex, this.switchIndexSync !== newIndex);
    this.switchIndexSync = this.switchIndexSync !== newIndex ? newIndex : -1;
    // 更新一下缓存
    this.$emit('updateChapterStorage', {
      key: 'ChapterDetailsPage',
      keyData: {
        switchIndex: this.switchIndexSync !== newIndex ? newIndex : -1,
      },
    });
    // 更新一下路由
    this.$nextTick(() => {
      this.$router.replace({
        query: {
          ...this.$route.query,
          switchIndex: this.switchIndexSync + '',
        },
      });
    });
  }

  // 选择章节, 获取章节下的所有页码，并获取对应修订
  originPagesMap: any = {};
  async activeChapter(card, needClear = true) {
    // 不响应查看已读节点/收起的点击
    if (!card.id) return;
    // 不响应重复请求
    if (this.activeChapterIdSync === card.id) return;
    // 回到顶部查看
    (document.documentElement || document.body).scrollIntoView({ block: 'start', behavior: 'smooth' });
    // 开启loading
    this.errataLoadingSync = true;
    // 同步选择的章节id
    this.activeChapterIdSync = card.id;
    // 除了首次访问，其他情况下都要重置一下页码信息
    if (needClear) {
      const { pageNum, ...query } = this.$route.query; // 清除路由的pageNum
      this.activeChildPageSync = 0; // 重置当前选择的章节页码
      this.$router.replace({ query });
    }

    // 获取数据
    try {
      const { pages } = await getBookErrataPages({
        bookId: this.bookId,
        chapterId: card.id,
        unread: 1,
      });
      this.chapterPageListSync = pages || [];
      pages.map((page, index) => {
        this.originPagesMap[page.page] = index;
      });
    } catch (e) {
      console.log('获取页码失败', e);
    } finally {
      // sync同步有延迟，用$nextTick包起来
      this.$nextTick(() => {
        console.log('获取章节下的所有页码', this.chapterPageListSync);
        // 没有页码，那就显示兜底页
        if (!this.chapterPageListSync.length) {
          this.$emit('showNoResult', 3);
          // 更新一下缓存
          this.$emit('updateChapterStorage', {
            key: 'ChapterDetailsPage',
            keyData: {
              chapterId: this.activeChapterIdSync,
              [this.activeChapterIdSync]: { pageNum: 0 },
            },
          });
          // 更新一下路由链接:要固定携带上类型type和当前页码pageNum、展开的章节下标switchIndex和选择的章节chapterId
          this.$router.replace({
            query: {
              type: 'chapter',
              pageNum: '0',
              switchIndex: this.switchIndexSync,
              chapterId: this.activeChapterIdSync,
            },
          });
        } else {
          // 有页码，会自动加载章节页码模块，请求数据，详见tabs-chapter-page.vue
        }
      });
    }
  }

  // 当前页码的修订和页码下的修订列表数量不一致时，在不改变源数据结构的情况下，更新页码信息
  async updateBookErrataPages({ chapterId }) {
    try {
      const { pages } = await getBookErrataPages({
        bookId: this.bookId,
        chapterId,
        unread: 1,
      });
      pages.map(page => {
        const originIndex = this.originPagesMap[page.page];
        if (originIndex >= 0) {
          this.$set(this.chapterPageListSync, originIndex, page);
        }
      });
      console.log('页码', pages);
    } catch (e) {
      console.log('获取页码失败', e);
    }
  }

  // 获取缓存 按页码查看：bookDetailsPage、 按章节查看： ChapterDetailsPage
  getStorage() {
    const bookErrata = JSON.parse(localStorage.getItem('book-errata') || `{}`);
    const bookInfo = bookErrata[this.bookId] || {};
    return bookInfo['ChapterDetailsPage'] || {};
  }
}
