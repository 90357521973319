import { request } from '@/utils/axios';
import { SmsCodePostData, VerifySmsCodePostData } from '@/utils/sms-code-helper';

/**
 * @method sendSmsCode
 * @description 获取手机验证码,登录部分包含验证手机号是否已注册过
 * @param params {mobile:'string'}
 */
export function sendSmsCode(data: SmsCodePostData, checkExist: boolean = false) {
  if (checkExist) {
    return request.get('/user/login-smscode', { mobile: data.mobile }, { requestBase: 'mojave', noAccess: true });
  } else {
    // return request.throughApi('ms-user.user.sendSmsCode', data);
    return request.post('/v2/users/smscode/send', { mobile: data.mobile }, { requestBase: 'user', noAccess: true });
  }
}

export function verifySmsCode(data: VerifySmsCodePostData): Promise<boolean> {
  return request.post('/v2/users/binding-mobile', data, { requestBase: 'user' });
  // return request.throughApi('ms-user.user.bindingUserMobile', data);
}

export function sendSmsCodeById() {
  return request.throughApi('ms-user.user.sendSmsCodeById', {}, { isAuth: true, authKey: 'id' });
}
