<template>
  <div id="app">
    <layout :has-qiyu="!!(showQiyu && hasMounted)" :routerName="routerName"></layout>
    <LoginDialog></LoginDialog>
    <ResetPasswordDialog></ResetPasswordDialog>
    <Qiyu v-if="showQiyu && hasMounted"></Qiyu>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/pc/views/Layout';
import LoginDialog from '@/pc/views/login/loginDialog';
import { mapMutations } from 'vuex';
import Qiyu from '@/pc/components/qiyu/index';
import ResetPasswordDialog from '@/pc/views/login/resetPasswordDialog';
import { getActiveLinks } from '@/api/base';
import systemLocalStorage from '@/utils/localStorage';
import { removeAllLocalstorageOfKey } from '@/utils/helper';
// @ts-ignore
import $ from 'jquery';
import { postLogData } from '@/api/mojave-v2';

export default {
  name: 'App',
  components: {
    ResetPasswordDialog,
    Qiyu,
    Layout,
    LoginDialog,
  },
  data() {
    return {
      loop: 0,
      notificationTimer: 0,
      showQiyu: false, // 是否显示七鱼挂件
      hasMounted: false,
      routerName: '',
      showQiyuRoute: [
        'home', // 首页
        'course-explore', // 分类
        'open-course', // 公开课详情
        'course-home', // 普通课详情
        'course-lesson-list', // 普通课详情课时列表tab
        'course-testpaper', //  普通课详情题库tab
        'course-reviews', // 普通课详情评价tab
        'course-material', // 普通课详情课程资料tab
        'course-notes', // 普通课详情听课笔记tab
        'course-info', // 普通课详情概览tab
        'download-page',
        'group-home',
        'group-detail',
        'group-thread-create',
        'group-thread-detail',
        'group-thread-update',
        'group-thread-search',
        '8th', // 2023 8周年活动页面
        '10th', // 2024 10周年活动页面
        'ask-index', // 问答首页
        'ask-create', // 问答创建
        'ask-my-ask', // 问答我的提问
        'ask-my-favorite', // 问答我的收藏
        'search',
        'search_index',
        // 'ask-search', // 问答搜索
        // 'ask-details', // 问答详情
        'circle-index', //圈子主页
        'more-circle', // 更多圈子页面
        'circle-detail', // 圈子详情页
        'dynamic-detail', // 动态详情页
        'new-user-about',
        'new-user-teach-course',
        'new-user-dynamic',
        'new-user-ask',
        'new-user-focus',
        'new-user-fans',
        'help-center'
        // 'dynamic-update', // 动态编辑页
      ], // 显示七鱼挂件的路由
      listenImageError: []
    };
  },
  computed: {
    ...mapGetters('my', ['myDropMenu']),
    ...mapGetters(['offsetTime']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.routerName = to.name;
        if (this.showQiyuRoute.includes(to.name)) {
          this.showQiyu = true;
        } else {
          this.showQiyu = false;
        }
      },
    }
  },
  async created() {
    // 全局监听替换抛错图片
    window.addEventListener('error', event => {
      // 过滤js error
      let target = event.target || event.srcElement;
      if(target instanceof HTMLImageElement) {
        // console.log("图片加载异常", target);

        // 收集图片抛错链接
        let errSrc = target.getAttribute('src') || '';
        if (errSrc && errSrc !== 'undefined' && errSrc.indexOf('btclass') !== -1) {
          this.listenImageError.push(errSrc);
          // 如果没替换过，替换成服务端转发链接
          if (errSrc.indexOf('irf?originUrl') === -1) {
            target.setAttribute('src', `${process.env.VUE_APP_HUNTER_MSG_URL}irf?originUrl=${encodeURIComponent(errSrc)}`);
          }
        }
        // 第一个图片抛错开始后，5秒后上报总数
        if (this.listenImageError.length === 1) {
          const randomID = new Date().getTime();
          setTimeout(() => {
            postLogData({
              data: {
                type: 'phoenixDownImgError',
                mark: 'PC官网加载图片失败5秒内数量',
                randomID,
                listenImageError: this.listenImageError,
                length: this.listenImageError.length
              }
            })
          }, 5000);
        }
      };
    }, true);
    this.getSettings();
    if (this.$cookie.get('is_phoenix_login') === 'true' && localStorage.getItem('refreshToken')) {
      // 中间层存了登录态
      this.$store.commit('my/SET_LOGIN', true);
      this.getMyInfo({})
        .then(data => {
          if (data.roles !== '|ROLE_USER|') {
            this.getMyPermissions();
          }
          this.getMyVip();
          if (!this.myDropMenu || !this.myDropMenu.length) {
            this.getMyDropMenu();
          }
          this.$nextTick(() => {
            setTimeout(() => {
              this.loopUnreadNotification();
            }, 1000);
          });
          Vue.prototype.$sensors = {
            track: (id, data) => {
              console.log('埋点上传已经关闭', id, data);
            },
            quick: key => {
              console.log('路由切换埋点取消', key);
            },
          };
          if (data.isAutoUpSc) {
            localStorage.setItem('isAutoUpSc', '1');
            // 引入神策埋点
            import('@/utils/shence').then(sensors => {
              Vue.prototype.$sensors = sensors['default'];
              // 神策埋点注入用户id字段标识
              this.$sensors.login(data.id);
            });
          } else if (data.isAutoUpSc === 0) {
            localStorage.removeItem('isAutoUpSc');
            removeAllLocalstorageOfKey('sawebjssdk');
          }
        })
        .catch(err => {
          if (err.code === 401 || err.code === 410) {
            this.$store.commit('my/LOGOUT');
            this.$store.commit('entry/LOGOUT');
          } else {
            console.error(err);
          }
        });
    } else {
      this.$store.commit('my/SET_LOGIN', false);
    }
    this.SET_LOGIN_DIALOG_VISIBLE(false); // 初始化为false
    this.SET_RESET_PASSWORD_DIALOG_VISIBLE(false); // 初始化为false
    this.hasMounted = true;
  },
  methods: {
    ...mapActions('my', ['getMyInfo', 'getMyDropMenu', 'getMyVip', 'getMyPermissions', 'getUnreadNotifications']),
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE', 'SET_RESET_PASSWORD_DIALOG_VISIBLE']),
    loopUnreadNotification() {
      this.notificationTimer = setTimeout(() => {
        this.notify();
        this.loop += 2;
        this.loopUnreadNotification();
      }, 60000 * this.loop);
    },
    notify() {
      return this.getUnreadNotifications().then(notifications => {
        this.$showNotification(notifications, { offset: 80 });
        return notifications;
      });
    },
    setSetting(data, nowDate) {
      this.$root.isAnniversary = data.startTime < nowDate && data.endTime > nowDate;
      this.$root.anniversaryLink = data.link;
      this.$root.anniversaryImg = data.imgUrl;
      this.$root.anniversaryLinkImg = data.linkImgUrl;
      this.$root.topIconUrl = data.topIconUrl;
      this.$root.logoUrl = data.logoUrl;
    },
    // 获取周年庆配置，缓存在本地，5分钟查询一次
    async getSettings() {
      try {
        const nowDate = new Date().getTime() + this.offsetTime; // 服务器时间
        const newOverdueTime = nowDate + 5 * 60 * 1000;
        const anniversarySetting = systemLocalStorage.get('anniversaryBaseSetting5'); // 获取本地周年庆配置
        const effective = (anniversarySetting && anniversarySetting.expiry > nowDate) || false; // 缓存还在有效期内
        // 周年配置缓存已过期或不存在
        if (!effective) {
          const { rows: settings } = await getActiveLinks({ name: 'pc_page_base_setting_new' });
          if (settings.length) {
            systemLocalStorage.set('anniversaryBaseSetting5', { ...settings[0], expiry: newOverdueTime });
            const settingData = JSON.parse(settings[0].value);
            if (settingData.startTime < nowDate && settingData.endTime > nowDate) {
              this.setSetting(settingData, nowDate);
            }
          }
        } else {
          const settingData = JSON.parse(anniversarySetting.value);
          this.setSetting(settingData, nowDate);
        }
      } catch (e) {
        console.log('获取周年庆配置失败', e);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.notificationTimer);
  },
};
</script>

<style lang="scss">
.el-popup-parent--hidden {
  padding-right: 0 !important;
  overflow: visible;
}
.v-modal {
  opacity: unset !important;
  background: rgba(0, 0, 0, 0.5) !important;
  transition: unset;
}
.fun-custom-el-confirm {
  width: 500px !important;
  padding-bottom: 24px !important;
  .el-message-box__header {
    padding: 16px 21px 0px 32px;
    .el-message-box__title {
      font-weight: 500;
      color: #333333;
    }
  }
  .el-message-box__content {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    padding: 31px 54px 32px 34px;
    .el-message-box__message {
      padding-right: 0;
    }
    .el-icon-warning {
      top: 12px;
      color: #faad14;
      font-size: 21px !important;
    }
  }
  .el-message-box__btns {
    .el-button {
      padding: 9px 18px;
      &.el-button--default {
        float: right;
        margin-left: 10px;
      }
      &.el-button--primary {
        border-color: #4b8fff !important;
        background-color: #4b8fff !important;
      }
    }
  }
}
</style>
