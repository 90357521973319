









































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import askCreateTips from '../components/modules/ask-create-tips.vue';
import CategorySelect from '../components/modules/category-select.vue';
import ChooseQuestion from '../components/choose-question.vue';
import ChooseCoupon from '../components/choose-coupon.vue';
import UploadImgs from '../components/modules/upload-imgs.vue';
import FacingCard from '@/pc/views/my-coin/facing/_component/facing-card.vue';
import AskAccount from '../components/modules/ask-account.vue';
import AskDisabledCreate from '../components/ask-disabled-create.vue';
import MainLoading from '../components/modules/main-loading.vue';

import { addDoubt, getQuizQuestionInfo, getRequireTicketsByUser, getPermission } from '@/api/ask';

@Component({
  components: {
    askCreateTips,
    CategorySelect,
    ChooseCoupon,
    ChooseQuestion,
    UploadImgs,
    FacingCard,
    AskAccount,
    AskDisabledCreate,
    MainLoading
  },
})
export default class AskCreate extends Vue {
  @Prop({ type: Number, default: 0 }) categoryId: any;
  @Prop({ type: Number, default: 0 }) questionId: any;
  @Prop({ type: String, default: '' }) from: any;
  @Prop({ type: Boolean, default: false }) isFixed: any;
  categorysMap: any = {}; // 分类字典
  acceptDoubtId: number = 0; // 未进行采纳的问答Id

  awardType: any = 0; // 1 必答券 ｜ 2 B豆
  createForm: any = {
    categoryId: '', // 问答分类id
    content: '', // 提问内容，最支持10-200个字符
    questionId: undefined, // 题目id
    images: [], // 图片列表
    reward: undefined, // 悬赏B豆
    requireId: undefined, // 必达券id
  };

  factingList: any = []; // 必答券列表
  chooseVisible: boolean = false; // 更换必答券弹窗
  disabledVisibled: boolean = false; // 禁止提问

  questionList: any = [];
  questionVisible: boolean = false; // 更换题目弹窗

  bDouOption: any = {
    minPrice: 2,
    rewardChoices: [2, 4, 8],
  }; // B豆可选项
  bDouBalance: any = 0;
  isLoading: boolean = false;
  initLoading: boolean = true;
  showType2: boolean = true;

  isFixedCase2: boolean = true;
  get fixedStatus () {
    return this.isFixed && this.isFixedCase2;
  }

  /*
    从问答首页ask-index、我的提问页ask-my-doubt、我的收藏页ask-my-favorite、问答详情页ask-details发起的提问，默认选中上一级页面的问答分类，支持可切换
    从题目（试卷、报告页）发起的提问，问答项目和关联题目自动获取当前对应试卷的分类和当前题目，且不可修改
    从问答详情页进入的关联题目页发起的提问，问答项目自动获取当前问答详情页的分类，关联题目自动获取当前题目，且都不可修改
    从考点进入的题目发起的提问，问答项目和关联题目自动获取当前考点标签分类 和当前题目，且不可修改
  */
  async created() {
    this.setInit();
    this.setABC();
    this.createForm.categoryId = this.categoryId || '';

    // 有分类则去拿必答券和B豆信息，和同步题目信息
    if (this.createForm.categoryId) {
      this.createForm.questionId = this.questionId || '';
      await this.getFacingList();
    } else {
      this.isFixedCase2 = false;
    }

    // 获取题目详情
    if (this.questionId) {
      // 获取题目数据
      const qInfo = await getQuizQuestionInfo({
        questionId: this.questionId,
      });
      this.questionList = [{ question: qInfo }];
      console.log('this.questionId', qInfo);
    }
    await this.permissionInfo();
    this.initLoading = false;
  }

  setInit() {
    // 布局初始化
    this.$bus.$emit('setlayout', { openFlex: false, mainWidth: '1080px' });
  }

  // 设置面包屑导航
  setABC () {
    this.$bus.$emit('setAskBreadCrumb', {
      show: true,
      data: [{ name: 'BT教育' }, { name: '问答主页', to: { path: `/ask` } }, { name: '去提问' }],
    });
  }

  // 校验用户提问权限
  async permissionInfo() {
    try {
      const { isPermission, doubtId } = await getPermission({});
      if (!isPermission) {
        this.disabledVisibled = true;
      }
      this.acceptDoubtId = doubtId;
      console.log('校验权限', isPermission, doubtId);
    } catch (e) {
      console.log('获取权限失败', e);
    }
  }

  // 获取可用的必答券,只拉第一张，等打开弹窗再去弹窗拉全部
  async getFacingList() {
    try {
      const { rows } = await getRequireTicketsByUser({
        categoryId: this.createForm.categoryId,
        page: 0,
        limit: 1,
      });
      // 有必答券，则默认选中第一张,否则选中B豆悬赏
      if (rows && rows[0]) {
        this.factingList = rows || [];
        this.createForm.requireId = rows[0].id;
        this.awardType = 1;
      } else {
        this.awardType = 2;
      }
      console.log('可用的必答券', rows);
    } catch (e) {
      console.log('获取可用的必答券', e);
    }
  }

  // 所属分类-同步一下分类数据，弹窗打开时需要用到
  setCategorys(data) {
    this.categorysMap = data;
  }

  // 所属分类-修改，重置一下数据
  async changeCategoryId() {
    this.createForm.questionId = ''; // 清除题目id
    this.questionList = []; // 清除题目数据
    this.awardType = 2; // 悬赏方式恢复成B豆悬赏
    this.createForm.reward = undefined; // 清除B豆悬赏数量
    this.createForm.requireId = undefined; // 清除必答券选中
    this.factingList = []; // 清除必答券列表
    this.showType2 = false;
    setTimeout(() => {
      this.showType2 = true;
    })
    await this.getFacingList(); // 重新获取下必答券数据
  }

  // 题目-打开更换题目弹窗
  openChooseQuestion() {
    this.questionVisible = true;
  }

  // 题目-选择题目
  chooseQuestion(info) {
    this.questionList = [info];
    this.createForm.questionId = info.question.id;
    console.log('更换题目', info);
  }

  // 必答券-打开更换必答券弹窗
  openChooseFacing() {
    this.chooseVisible = true;
  }

  // 必答券-选择必答券
  chooseFacing(cardItem) {
    this.factingList = [cardItem];
    this.createForm.requireId = cardItem.id;
    console.log('更换必答券', cardItem);
  }

  // 提交数据，新增一个问答提问
  async createNew() {
    const { categoryId, content, reward, questionId, requireId, images } = this.createForm;
    if (!categoryId) {
      return this.$message.warning('请先选择问题分类');
    }
    if (!content || content.length < 10) {
      return this.$message.warning('至少输入10个字');
    }
    if (this.awardType === 1 && !requireId) {
      return this.$message.warning('请选择其他悬赏方式');
    }
    // 根据选择状态获取最终悬赏数量，这么做是为了实现UI上的交互
    if (this.awardType === 2) {
      if (!reward && reward !== 0) {
        return this.$message.warning('请输入B豆悬赏金额');
      }
      if (reward < this.bDouOption.minPrice) {
        return this.$message.warning(`输入金额不能低于${this.bDouOption.minPrice}B豆`);
      }
      if (reward > this.bDouBalance) {
        return this.$message.warning('您的余额不足，请先去充值');
      }
    }
    if (this.isLoading) return;
    try {
      this.isLoading = true;
      let params: any = {
        categoryId,
        questionId: questionId || undefined,
        content,
        images,
        requireId: this.awardType === 2 ? undefined : requireId,
        reward: this.awardType === 1 ? undefined : reward,
      };
      console.log('新增问答提问', params);
      const askInfo = await addDoubt(params);
      this.$message.success('发布成功');
      window.scrollTo(0, 0);
      this.$router.replace({ name: 'ask-details', params: { doubtId: askInfo.id } });
    } catch (e) {
      console.log('新增问答提问失败', e);
    } finally {
      this.isLoading = false;
    }
  }
}
