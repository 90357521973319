










import { Vue, Component, Prop } from 'vue-property-decorator';
import DynamicItemCom from './dynamic-item.vue';
import { DynamicItem } from '@/typings';
import { throttle } from '@/utils/helper';
import LoadingIcon from '@/pc/views/ask/components/modules/loading-icon.vue';

@Component({
  components: {
    DynamicItemCom,
    LoadingIcon,
  },
})
export default class DynamicList extends Vue {
  @Prop({ type: Array, default: () => [] }) dynamicList!: DynamicItem[];
  @Prop({ type: String }) listKey;
  @Prop({ type: Boolean }) isLoading;
  @Prop({ type: Object }) pagination;
  @Prop({ type: Number, default: 564 }) outWidth;
  scrollHandleThrottled: any = null;

  get bottomTxt() {
    return this.pagination.isLoadAll ? '没有更多啦~' : this.isLoading ? '更多内容加载中...' : '';
  }

  mounted() {
    this.scrollHandleThrottled = throttle(this.scrollHandle, 50);
    window.addEventListener('scroll', this.scrollHandleThrottled);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandleThrottled);
  }

  scrollHandle() {
    // 没点击加载全部或者已经加载完了，不在继续loading后续数据
    if (this.isLoading || this.pagination.isLoadAll) return;
    // console.log('scrollHandle', ($(window) as any).scrollTop());
    if (window.innerHeight + ($(window) as any).scrollTop() + 1500 >= document.documentElement.scrollHeight) {
      // 判断到底部了，出发加载
      console.log('到底部了，加载数据');
      this.$emit('scollGet');
    }
  }

  focuseChange(userId, isFocused) {
    this.dynamicList.forEach(item => {
      if (item.userId == userId) {
        item['isFocused'] = isFocused;
      }
    });
    this.$forceUpdate();
  }

  deleteHandle(data) {
    const delIndex = this.dynamicList.findIndex(item => item.id == data.id);
    if (delIndex != -1) {
      this.dynamicList.splice(delIndex, 1);
    }
    this.$emit('deleteHandle', data);
  }
}
