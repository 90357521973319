// const DownloadApp = () => import(/* webpackChunkName: "download" */ '@/pc/views/download/DownloadApp');
import DownloadApp from '@/pc/views/download/DownloadApp';

export default {
  path: '/download-page',
  name: 'download-page',
  component: DownloadApp,
  meta: {
    hideFooter: false,
  },
};
