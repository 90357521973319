














































import { Vue, Component, Prop } from 'vue-property-decorator';
import OpenCourseModel from '@/pc/views/home/components/components/OpenCourseModel.vue';
import NormalCourseItem from '@/pc/views/home/components/components/CourseList.vue';
import Sticky from './sticky.vue';
import { getCourseListForUser } from '@/api/course';

@Component({
  components: {
    OpenCourseModel,
    NormalCourseItem,
    Sticky,
  },
})
export default class Anniversary extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data;
  @Prop({ type: Number, default: 80 }) headHeight;
  @Prop({ type: String, default: '' }) activeBlock;

  courseMap: any = {};
  curCategoryId: number = 0;

  mounted() {
    this.getCourse();
    this.curCategoryId = this.data['defaultCategoryId'];
  }
  seeMore() {
    // this.$router.push({ path: '/' });
    window.open(`/course/explore?categoryId=${this.curCategoryId}`);
  }

  tabClick({ categoryId }) {
    this.curCategoryId = categoryId;
  }

  sensorsHandle(courseId) {
    try {
      this.$sensors.track('bt_10619', {
        courseId,
        categoryId: this.curCategoryId,
      });
    } catch (e) {
      console.log('cpa23年新课点击');
    }
  }

  async getCourse() {
    try {
      console.log('low-price-course', this.data);
      const allCourseIds: any[] = this.data.list
        .map(item => [...(item.courseIds || []), ...(item.tikuCourseIds || []), ...(item.materlCourseIds || [])])
        .reduce((result, cur) => [...result, ...cur], []);
      console.log('allCourseIds', allCourseIds);
      let { rows } = await getCourseListForUser({ids:[...new Set(allCourseIds)].join(','), needPriceDetail: 1});
      rows.forEach(item => {
        if (!this.courseMap[item.id]) {
          this.courseMap[+item.id] = item;
        }
      });
      console.log('---------------低价课获取成功----------------');
      this.$forceUpdate();
    } catch (e) {
      console.log('获取双十一好课失败', e);
    }
  }
}
