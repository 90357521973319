














































import { Vue, Component, Prop } from 'vue-property-decorator';
import UserAvator from '../user-com/user-avator.vue';
import { mapGetters, mapMutations } from 'vuex';
import { getUserHomePage, focusUsre, cancleFocusUser } from '@/api/circle';
import { CircleUserInfo } from '@/typings';

@Component({
  components: {
    UserAvator,
  },
  computed: {
    ...mapGetters('my', ['me', 'isLogin']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
  filters: {
    maxNum(val) {
      return val > 9999 ? '9999+' : val;
    },
  },
})
export default class AboutUserItem extends Vue {
  @Prop({ type: Number }) userId!: number;

  userInfo: CircleUserInfo | null = null;
  isLoading: boolean = false;
  isChanging: boolean = false;

  me: any;
  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  get isMe() {
    if (!this.me || !this.me.id) return false;
    return this.me.id === this.userId;
  }

  // @Watch('me', { deep: true, immediate: true })

  mounted() {
    this.getUserInfo();
  }

  async getUserInfo() {
    if (!this.userId) return;
    try {
      this.isLoading = true;
      this.userInfo = await getUserHomePage(this.userId);
    } finally {
      this.isLoading = false;
    }
  }

  // 关注用户
  async focusHandle() {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      if (this.isChanging || !this.userInfo) return;
      if (this.isMe) {
        this.$message.warning('你不能关注自己');
        return;
      }
      this.isChanging = true;
      const { userId, isFocused } = this.userInfo;
      const params = {
        focusType: 'user',
        targetId: userId,
      };
      if (!isFocused) {
        await focusUsre(params);
        this.$set(this.userInfo, 'isFocused', 1);
      } else {
        this.$confirm('确定取消关注吗？', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'circle-confirm-dialog',
        }).then(async () => {
          if (!this.userInfo) return;
          await cancleFocusUser(params);
          this.$set(this.userInfo, 'isFocused', 0);
          this.$message.success('操作成功');
        });
      }
    } finally {
      this.isChanging = false;
    }
  }

  // 跳转用户详情页面
  goUserInfoPage() {
    if (!this.userId) return;
    window.open(`/new-user/${this.userId}`);
    console.log('跳转个人页面');
  }
}
