




























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import * as SettingService from '@/api/settings';
import SendSmsCodeBtn from '@/pc/components/ui/send-sms-code-btn/SendSmsCodeBtn.vue';
// eslint-disable-next-line no-unused-vars
import { VeeValidateObserver } from '@/typings/vee-validate';
import { TextFieldWithValidation } from '@/utils/vee-validate/validate-fields';
// eslint-disable-next-line no-unused-vars
import { MeModel } from '@/typings';
type OpType = 'setting_set_init_passwd' | 'setting_set_new_passwd' | 'setting_refound_passwd';

@Component({
  components: {
    TextFieldWithValidation,
    ValidationProvider,
    ValidationObserver,
    SendSmsCodeBtn,
  },
  computed: {
    ...mapState('my', ['me']),
  },
  methods: {
    ...mapMutations('my', ['SET_RESET_PASSWORD_DIALOG_VISIBLE']),
  },
  filters: {
    mobileFormat: (val: string) => {
      return `${val.substring(0, 3)}-${val.substring(3, 7)}-${val.substring(7)}`;
    },
  },
})
export default class PasswdForm extends Vue {
  private SET_RESET_PASSWORD_DIALOG_VISIBLE?: any;

  @Prop({ type: String, default: 'setting_set_init_passwd' }) opType!: OpType;
  resetPasswdDialogVisible: boolean = false;
  me!: MeModel;
  get isLogin() {
    return this.$login();
  }

  get mobile() {
    return this.me.verifiedMobile || this.form.mobile;
  }

  form = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    smscode: '',
    mobile: '',
  };
  submitLoading = false;

  async onSubmit() {
    const valid = await ((this.$refs.observer as any) as VeeValidateObserver).validate();
    if (!valid) {
      return;
    }

    const mp = {
      // eslint-disable-next-line camelcase
      setting_set_init_passwd: async () => {
        await SettingService.setPasswordFirst(this.form.newPassword);
        this.$message.success('密码设置成功!!!');
      },
      // eslint-disable-next-line camelcase
      setting_set_new_passwd: async () => {
        if (this.form.oldPassword === this.form.newPassword) {
          this.$message.error('新密码不能与旧密码相同');
          return Promise.reject('新密码不能与旧密码相同');
        }
        await SettingService.updateUserPassword(this.form.oldPassword, this.form.newPassword);
        this.$message.success('密码修改成功!!!');
      },
      // eslint-disable-next-line camelcase
      setting_refound_passwd: async () => {
        let params: any = {smscode: this.form.smscode, mobile: this.mobile, newPassword: this.form.newPassword, needVerify: 1};
        await SettingService.resetUserPassword(params);
        this.$message.success('密码重置成功!!!');
      },
    };
    try {
      if (this.submitLoading) return;
      this.submitLoading = true;
      await mp[this.opType]();
      // this.$router.push({ name: 'my_passwd' });
      location.href = '/logout';
    } catch (e) {
      // if (e.code === 1011) {
      this.$message.error(e.msg);
      // }

      // if (e.code === 1022) {
      // this.$message.error(e.msg);
      // }
    } finally {
      this.submitLoading = false;
    }
  }

  resetPassword() {
    if (this.mobile) {
      this.SET_RESET_PASSWORD_DIALOG_VISIBLE(true);
    } else {
      this.$message.error('请先绑定手机号');
      setTimeout(() => {
        this.$router.push({ name: 'setting_edit_mobile' });
      }, 1000);
    }
  }
}
