<template>
  <div class="home-recommend">
    <div class="course-area" v-if="data || !hasMounted">
      <div class="pc-container">
        <div class="text-line head">
          <h5 class="anniversary-model-title">
            <span>免费课程</span>
            <!-- <a v-if="$root.isAnniversary&&$root.anniversaryLinkImg" :href="$root.anniversaryLink" target="_blank" class="invite-anniversary">
              <img :src="$root.anniversaryLinkImg" v-default-img-alt="'七周年'" />
            </a> -->
            <OperatPlaceCom :subscene="0" title="免费课程"></OperatPlaceCom>
          </h5>
        </div>
        <div class="course-list" v-if="hasMounted">
          <div class="row">
            <button type="button" :class="'arrow-left ' + (left ? 'ccc' : '')" @click="pre"></button>
            <el-carousel :interval="6000" arrow="always" indicator-position="none" ref="car" :loop="true" :autoplay="true" @change="change">
              <el-carousel-item v-for="(courses, i) in data" :key="i" class="pt10">
                <template v-for="(course, j) in courses">
                  <course-list :course="course" :is-load="course.loaded" :key="j"></course-list>
                </template>
              </el-carousel-item>
            </el-carousel>
            <button type="button" :class="'arrow-right ' + (right ? 'ccc' : '')" @click="next"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseList from './components/CourseList';
import OperatPlaceCom from '@/pc/views/home/components/OperatPlace.vue';
import { findFreeCourses } from '@/api/home';
import { getCourseListByIds, getCourseListForUser } from '@/api/course';

export default {
  data() {
    return {
      division: 4,
      data: null,
      courses: [],
      left: true,
      right: false,
      hasMounted: false,
    };
  },
  components: {
    CourseList,
    OperatPlaceCom,
  },
  mounted() {
    this.hasMounted = true;
    this.init();
  },
  methods: {
    init() {
      findFreeCourses().then(e => {
        let rows = e.rows;
        this.courses = rows;
        this.data = this.setList(rows);
      });
    },
    setList(data) {
      let division = this.division;
      let result = [];
      for (let i = 0, len = data.length; i < len; i += division) {
        const list = data.slice(i, i + division);
        result.push(list);
      }

      return result;
    },
    change(index) {
      this.left = index === 0;
      this.right = index === this.data.length - 1;
      let tmpArr = this.data.slice(index, index + 2);
      let courses = [];
      tmpArr.forEach(tmp => {
        courses = courses.concat(tmp);
      });
      this.getCourseDetailByCourses(courses);
    },
    pre() {
      if (!this.left) {
        this.$refs.car.prev();
      }
    },
    next() {
      if (!this.right) {
        this.$refs.car.next();
      }
    },
    getCourseDetailByCourses(courses) {
      let loadingCourseIds = [];
      for (let course of courses) {
        if (!course.loaded && !course.isLoading) {
          // 未加载 & 非loading状态
          loadingCourseIds.push(course.id);
          course.isLoading = true;
        }
      }
      if (loadingCourseIds.length) {
        getCourseListForUser({ ids: loadingCourseIds.join(','), limit: loadingCourseIds.length, needPriceDetail: 1 }).then(res => {
          let courseDetails = res.rows;
          for (let courseDetail of courseDetails) {
            for (let course of courses) {
              if (parseInt(course.id) === courseDetail.id) {
                Object.assign(course, courseDetail, {
                  isLoading: false,
                  loaded: true,
                });
              }
            }
          }
        });
      }
    },
  },
};
</script>
