const user = {
  namespaced: true,
  state: () => ({
    cards: {}
  }),
  getters: {
    cards: state => state.cards
  },
  mutations: {
    SET_CARDS: (state, { userId, card }) => {
      state.cards[userId] = card;
    }
  },
  actions: {
    setCard({ commit }, { userId, card }) {
      commit('SET_CARDS', {
        userId,
        card
      });
    }
  }
};

export default user;
