import { request } from '@/utils/axios';
import { onceTokenParams } from '@/typings';

// 刷新token
export function refreshUserToken(refreshToken: string) {
  return request.get('/v2/user/token/refresh', { refreshToken }, { requestBase: 'token', noAccess: true });
}

// 获取七牛token
export function getQiniuUpToken(prefix: string) {
  return request.get('/v2/qiniu/up-token', { prefix }, { requestBase: 'token' });
}

// 根据用户onceToken换去登陆态
export function loginByOnceToken(params: onceTokenParams) {
  return request.post('/v2/once-token/login', params, { requestBase: 'token', noAccess: true });
}
