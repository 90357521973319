import { getLinkHtml, getCourseItemsByCourseId, getCourseSubjectsByCourseId, getCourse } from '@/api/course';

const course = {
  namespaced: true,
  state: () => ({
    course: {},
    aboutHtml: '',
    subjects: [],
    courseLoaded: false,
    // actives: [],
    items: [],
    chapterIds: [],
    normalDataLoaded: false
  }),
  getters: {
    courseLoaded: state => state.courseLoaded,
    subjects: state => state.subjects,
    aboutHtml: state => state.aboutHtml,
    course: state => state.course,
    // actives: state => state.actives,
    items: state => state.items,
    chapterIds: state => state.chapterIds,
    normalDataLoaded: state => state.normalDataLoaded,
  },
  mutations: {
    SET_COURSE_DETAIL: (state, data) => {
      state.course = data;
      state.courseLoaded = Object.keys(data).length > 0;
    },
    SET_ABOUTHTML: (state, data) => {
      state.aboutHtml = data;
    },
    SET_SUBJECTS: (state, data) => {
      state.subjects = data;
    },
    // SET_ACTIVES: (state, { id, isToggle }) => {
    //   if (state.actives.includes(id)) {
    //     if (isToggle) {
    //       state.actives = state.actives.filter(e => e !== id)
    //     }
    //   } else {
    //     state.actives.push(id)
    //   }
    // },
    SET_NORMAL_ITEMS: (state, data) => {
      state.items = data.data;
      state.normalDataLoaded = true;
    },
    SET_CHAPTERIDS: (state, data) => {
      state.chapterIds.push(data);
    },
  },
  actions: {
    async getCourseDetail({ commit, state }, courseId) {
      if (courseId != state.course.id) {
        commit('SET_COURSE_DETAIL', {});
      }
      let data = await getCourse(courseId);
      if (data && data.aboutLink) {
        let aboutHtml = await getLinkHtml(data.aboutLink);
        commit('SET_ABOUTHTML', aboutHtml);
      } else {
        commit('SET_ABOUTHTML', '');
      }
      if (data.subjectType != 0) {
        let subjects = await getCourseSubjectsByCourseId(data.id, 4);
        // if (subjects && subjects.length > 0) {
        //   for (let e of subjects) {
        //     let items = await getCourseItemsByCourseId(e.id)
        //     e.items = items;
        //   }
        // }
        commit('SET_SUBJECTS', subjects);
      } else {
        commit('SET_SUBJECTS', []);
      }
      commit('SET_COURSE_DETAIL', data);
      return data;
    },
    async getNormalItems({ commit }, subjectId) {
      let data = await getCourseItemsByCourseId(subjectId);
      data.map(d => {
        d.type === 'chapter' ? commit('SET_CHAPTERIDS', d.id) : null;
      });
      commit('SET_NORMAL_ITEMS', { data });
      return data;
    }
  },
};

export default course;
