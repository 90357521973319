<template>
  <div class="home-friend-link">
    <div class="pc-container">
      <div class="title">BT教育自媒体</div>
      <div class="swiper-ctx" v-swiper="SwiperOption" ref="mySwiper">
        <div class="swiper-wrapper" @mousemove="mousemove" @mouseout="mouseout">
          <div :class="['swiper-slide']" v-for="(page, index) in splitLinks" :key="index">
            <div :class="['swiper-slide-child']" v-for="item in page" :key="item.id">
              <a :href="item.url" :target="item.isNewWin ? '_blank' : ''" rel="nofollow">
                <img :src="item.icon" height="46" width="46" v-default-img-alt="item.name" />
                <div class="name">{{ item.name }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination-friend"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FriendLink',
  data() {
    return {
      SwiperOption: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        speed: 800,
        grabCursor: true,
        spaceBetween: 22,
        initialSlide: 0,
        direction: 'horizontal',
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination-friend',
          clickable: true,
        }
      },
      splitLinks: []
    };
  },
  serverPrefetch() {
    return this.getFriendLink()
  },
  watch: {
    links: {
      immediate: true,
      deep: true,
      handler(data) {
        this.initList(data);
      },
    },
  },
  mounted() {
    if (!this.links) {
      this.getFriendLink();
    }
  },
  computed: {
    ...mapGetters(['links']),
  },
  methods: {
    ...mapActions(['getFriendLink']),
    mousemove() {
      this.$refs.mySwiper.swiper.autoplay.stop();
    },
    mouseout() {
      this.$refs.mySwiper.swiper.autoplay.start();
    },
    initList(links) {
      const result = [];
      const array = JSON.parse(JSON.stringify(links || []));
      for (let i = 0; i < array.length; i += 8) {
        result.push(array.slice(i, i + 8));
      }
      this.splitLinks = result;
    }
  },
};
</script>

<style scoped lang="scss">
.pc-container {
  padding-bottom: 2.148438rem;

  .title {
    margin-bottom: .78125rem;
  }
}

.swiper-ctx {
  padding: .390625rem 0;
}

.swiper-wrapper {
  .swiper-slide {
    display: flex;

    .swiper-slide-child {
      flex-shrink: 0;
      margin: 0 .205078rem;
      border-radius: .214844rem;
      background: #FFFFFF;
      width: 2.753906rem;
      height: 2.578125rem;
      transition: transform 0.3s ease;
      /* 添加过渡效果 */

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .46875rem .234375rem 0;

        img {
          width: .898438rem;
          height: .898438rem;
        }

        .name {
          padding-top: .234375rem;
          color: #919191;
          font-size: .273438rem;
          line-height: .390625rem;
          text-align: center;
        }
      }

      &:hover {
        .name {
          color: #4b8fff;
        }

        transform: translateY(-0.15625rem);
        box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
        /* 鼠标悬浮时向上移动10px */
      }
    }
  }
}

.swiper-pagination-friend {
  width: 25.390625rem;
  text-align: center;
  margin-top: .78125rem;

  ::v-deep {
    .swiper-pagination-bullet {
      width: .78125rem;
      height: .15625rem;
      margin: 0 .078125rem;
      border-radius: .078125rem;
      color: #CCCCCC;
    }

    .swiper-pagination-bullet-active {
      color: #4B8FFF;
    }
  }
}
</style>
