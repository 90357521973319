










import { Vue, Component, Watch } from 'vue-property-decorator';
import BreadCrumb from './components/modules/breadcrumb.vue';
import AskDrawer from '@/pc/views/ask/components/ask-drawer.vue';
import NotesDrawer from '@/pc/views/notes/components/notes-drawer.vue';

@Component({
  components: {
    BreadCrumb,
    AskDrawer,
    NotesDrawer,
  },
})
export default class AskMain extends Vue {
  askDrawervisible: boolean = false;
  askOption: any = {};

  notesDrawerVisible: boolean = false;
  notesOption: any = {};

  breadcrumb: any = {
    // 面包屑数据
    show: false,
    data: [],
  };
  mainWidth: any = '1080px';
  openFlex: boolean = false;

  created() {
    this.init();
    this.$bus.$on('setAskBreadCrumb', this.setBreadCrumb);
    this.$bus.$on('setlayout', this.setlayout);
    this.$bus.$on('openAskDrawer', this.openAskDrawer);
    this.$bus.$on('openNotesDrawer', this.openNotesDrawer);
  }

  beforeDestroy() {
    this.$bus.$off('setAskBreadCrumb', this.setBreadCrumb);
    this.$bus.$off('setlayout', this.setlayout);
    this.$bus.$off('openAskDrawer', this.openAskDrawer);
    this.$bus.$off('openNotesDrawer', this.openNotesDrawer);
  }

  setBreadCrumb(obj) {
    console.log('设置面包屑', obj);
    this.breadcrumb = obj;
  }

  setlayout({ openFlex, mainWidth }) {
    this.openFlex = openFlex || false;
    this.mainWidth = mainWidth || '1080px';
  }

  // 打开问答弹窗
  openAskDrawer(option) {
    this.askDrawervisible = true;
    this.askOption = option;
    console.log('打开问答弹窗', option);
  }

  // 打开笔记弹窗
  openNotesDrawer(option) {
    this.notesDrawerVisible = true;
    this.notesOption = option;
    console.log('打开笔记弹窗', option);
  }

  init() {
    this.setBreadCrumb({ show: false, data: [{ name: 'BT教育' }] });
  }

  @Watch('$route.name')
  change(val) {
    this.init();
  }
}
