const User = () => import(/* webpackChunkName: "user" */ '@/pc/views/user/course/index.vue');
const CoursePage = () => import(/* webpackChunkName: "user" */ '@/pc/views/user/course/course-page');

const NewUserIndex = () => import(/* webpackChunkName: "user" */ '@/pc/views/new-user/index.vue');
const NewUserAbout = () => import(/* webpackChunkName: "user" */ '@/pc/views/new-user/about.vue');
const NewTeacherCourse = () => import(/* webpackChunkName: "user" */ '@/pc/views/new-user/teach-course.vue');
const NewUserDaynamic = () => import(/* webpackChunkName: "user" */ '@/pc/views/new-user/dynamic.vue');
const NewUserAsk = () => import(/* webpackChunkName: "user" */ '@/pc/views/new-user/ask.vue');
const NewUserFocus = () => import(/* webpackChunkName: "user" */ '@/pc/views/new-user/focus.vue');
const NewUserFans = () => import(/* webpackChunkName: "user" */ '@/pc/views/new-user/fans.vue');

const MobileWx = () => import(/* webpackChunkName: "user" */ '@/pc/views/login/mobile-wx-login.vue');

export default [
  {
    path: '/login/mobile-wx',
    component: MobileWx,
  },
  {
    path: '/user/:userId',
    component: User,
    props: router => ({
      userId: Number(router.params.userId),
    }),
    children: [
      {
        path: '',
        name: 'user-course',
        redirect: '/user/:userId/teach',
      },
      {
        path: 'about',
        name: 'user-about',
        component: CoursePage,
      },
      {
        path: 'learn',
        name: 'user-learn',
        component: CoursePage,
      },
      {
        path: 'overdue',
        name: 'user-overdue',
        component: CoursePage,
      },
      {
        path: 'teach',
        name: 'user-teach',
        component: CoursePage,
      },
    ],
  },
  {
    path: '/new-user/:userId',
    component: NewUserIndex,
    meta: { hideFooter: true },
    props: router => ({
      userId: Number(router.params.userId),
    }),
    children: [
      {
        path: '',
        name: 'new-user-about',
        component: NewUserAbout,
        meta: { hideFooter: true },
      },
      {
        path: 'teach/course',
        name: 'new-user-teach-course',
        component: NewTeacherCourse,
        meta: { hideFooter: true },
      },
      {
        path: 'dynamic',
        name: 'new-user-dynamic',
        component: NewUserDaynamic,
        meta: { hideFooter: true },
      },
      {
        path: 'ask',
        name: 'new-user-ask',
        component: NewUserAsk,
        meta: { hideFooter: true },
      },
      {
        path: 'focus',
        name: 'new-user-focus',
        component: NewUserFocus,
        meta: { hideFooter: true },
      },
      {
        path: 'fans',
        name: 'new-user-fans',
        component: NewUserFans,
        meta: { hideFooter: true },
      },
    ],
  },
];
