














import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { countShare } from '@/api/circle';
import { DynamicItem } from '@/typings';
import QRCode from 'qrcode';

@Component
export default class SharePopover extends Vue {
  @Prop({ type: Object }) dynamicData!: DynamicItem;
  @Prop({ type: String, default: 'bottom' }) placement;

  qrcodeUrl: string = ''; // 移动端页面二维码

  get shareText() {
    const { dynamicType, userName, title, plainContent } = this.dynamicData;
    return dynamicType === 'longArticle' ? `${title.substr(0, 50)}-${userName}发布的动态-BT教育 ` : `${plainContent.substr(0, 50)}-${userName}发布的动态-BT教育 `;
  }

  get shareUrl() {
    const target = `/circle/dynamic/detail?dynamicId=${this.dynamicData.id}`;
    return process.env.NODE_ENV !== 'production' ? `https://dev.btclass.net${target}` : `https://www.btclass.cn${target}`;
  }

  @Watch('dynamicData', { immediate: true, deep: true })
  async watchDetail() {
    if (!this.dynamicData || !this.dynamicData.id) return;
    this.qrcodeUrl = await QRCode.toDataURL(`${process.env.VUE_APP_WHALE_URL}discuss-group/share/${this.dynamicData.id}/detail`);
  }

  // 复制模板内容
  copyTemplate() {
    this.copyToClipboard(this.shareText + this.shareUrl); // 需要复制的文本内容
    this.$message.success('复制链接成功');
  }
  // 点击复制到剪贴板函数
  copyToClipboard(content) {
    //window.clipboardData的作用是在页面上将需要的东西复制到剪贴板上，
    //提供了对于预定义的剪贴板格式的访问，以便在编辑操作中使用。
    // @ts-ignore
    if (window.clipboardData) {
      /*
          window.clipboardData有三个方法:
        （1）clearData(sDataFormat) 删除剪贴板中指定格式的数据。sDataFormat:"text","url"
        （2）getData(sDataFormat) 从剪贴板获取指定格式的数据。 sDataFormat:"text","url"
        （3）setData(sDataFormat, sData) 给剪贴板赋予指定格式的数据。返回 true 表示操作成功。
          */
      // @ts-ignore
      window.clipboardData.setData('text', content);
    } else {
      (function (content) {
        //oncopy 事件在用户拷贝元素上的内容时触发。
        document.oncopy = function (e: any) {
          e.clipboardData.setData('text', content);
          e.preventDefault(); //取消事件的默认动作
          document.oncopy = null;
        };
      })(content);
      //execCommand方法是执行一个对当前文档/当前选择/给出范围的命令。
      //'Copy':将当前选中区复制到剪贴板。
      document.execCommand('Copy');
    }
  }
  // 微博分享
  wbShare() {
    countShare(this.dynamicData.id);
    var query: any = {};
    query.url = this.shareUrl;
    query.title = this.shareText;
    const url = 'http://service.weibo.com/share/share.php?' + this.buildUrlQuery(query);
    window.open(url);
  }

  buildUrlQuery(query) {
    let queryItems: any = [];
    for (var q in query) {
      queryItems.push(q + '=' + encodeURIComponent(query[q] || ''));
    }
    return queryItems.join('&');
  }
}
