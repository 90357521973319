















import { Vue, Component } from 'vue-property-decorator';
import { getHotDyanmicList } from '@/api/circle';
import { throttle } from '@/utils/helper';

@Component({
  filters: {
    maxNum(val) {
      if (!val) return 0;
      return val > 999 ? '999+' : val;
    },
  },
})
export default class HotStoried extends Vue {
  iconImgs = [
    'https://tmp.btclass.cn/yunyin/o_1hh6qc8oimr3grs1d911q80of8i.png',
    'https://tmp.btclass.cn/yunyin/o_1hh6qc8oi17d1mcb1j261812o18e.png',
    'https://tmp.btclass.cn/yunyin/o_1hh6qc8oi1lcpvgk6q11rqogglf.png',
    'https://tmp.btclass.cn/yunyin/o_1hh6qc8oilsg11djvsj3tr7ash.png',
    'https://tmp.btclass.cn/yunyin/o_1hh6qc8oi7flucr5coavh1cktg.png',
    'https://app-cdn.btclass.cn/new-prefix/turtle/FncaYvaoQ5S-_PzLWQ-Uukw636aB.png',
    'https://app-cdn.btclass.cn/new-prefix/turtle/FlXeFboOXv4nZtMdNm3fSnXTuKhz.png',
    'https://app-cdn.btclass.cn/new-prefix/turtle/Fln2EZPcz68xBvPXd7Na58NBS0vD.png',
  ];
  list: any[] = [];
  targetOffset: number = 800;
  scrollHandleThrottled: any = null;
  isFixedSide: boolean = false;

  async mounted() {
    try {
      this.list = (await getHotDyanmicList()) || [];
      if (!this.list || !this.list.length) return;
      await this.$nextTick();
      setTimeout(() => {
        const domHead = document.querySelector('.bt-header');
        this.targetOffset = (this.$refs.hotStoried as any).offsetTop + (domHead && (domHead as any).offsetHeight) || 80;
        this.scrollHandleThrottled = throttle(this.scrollHandle, 50);
        window.addEventListener('scroll', this.scrollHandleThrottled);
      }, 500);
    } catch (e) {
      console.log('获取热门动态失败');
    }
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandleThrottled);
  }

  // 跳转至动态详情页
  async goDynamicDetail({ id }) {
    const { href } = this.$router.resolve({
      name: 'dynamic-detail',
      query: {
        dynamicId: id,
      },
    });
    window.open(href);
  }

  scrollHandle() {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollBottom = scrollHeight - scrollTop - window.innerHeight;
    if (scrollBottom >= 350) {
      this.isFixedSide = scrollTop > this.targetOffset;
    } else {
      this.isFixedSide = false;
    }
  }
}
