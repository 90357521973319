



























































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import { getQuestionDoubts } from '@/api/ask';
import AskItem from '@/pc/views/ask/components/modules/ask-item.vue';
import MainLoading from './modules/main-loading.vue';

@Component({
  components: {
    AskItem,
    MainLoading,
  },
})
export default class AskDrawer extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible!: any;
  @Prop({ type: Object }) askOption: any;

  modal: boolean = true; // 是否需要遮罩层
  direction: string = 'rtl'; // Drawer 打开的方向
  closeOnPressEscape: boolean = false; // 是否可以通过按下 ESC 关闭 Drawer
  destroyOnClose: boolean = true; // 控制是否在关闭 Drawer 之后将子元素全部销毁
  showClose: boolean = false; // 是否显示关闭按钮
  size: any = '812px';
  withHeader: boolean = false; // 控制是否显示 header 栏
  wrapperClosable: boolean = true; // 点击遮罩层是否可以关闭 Drawer

  isLoading: boolean = true;

  askList: any = []; // 列表数据
  pagination: any = {
    // 分页器数据
    total: 0,
    page: 1,
    limit: 5,
  };

  @Watch('syncVisible', { immediate: true })
  async watchVisible(visible) {
    // 弹窗打开时，重置数据
    if (visible) {
      this.pagination = {
        total: 0,
        page: 1,
        limit: 5,
      };
      this.askList = [];
      await this.updateList();
    }
  }

  // 刷新/加载列表数据
  async updateList() {
    try {
      this.isLoading = true;
      const { count, rows } = await getQuestionDoubts({
        questionId: this.askOption.questionId,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        needCount: 1,
      });
      this.askList = rows;
      this.pagination.total = count;
      console.log('刷新列表数据', { count, rows });
    } catch (e) {
      console.log('弹窗列表数据加载失败', e);
    } finally {
      this.isLoading = false;
    }
  }

  // 切换页数
  async handleCurrentChange(val) {
    this.pagination.page = val;
    await this.updateList();
    console.log('切换页数', val, this.pagination.page);
  }

  // 关闭弹窗
  closeDrawer() {
    this.syncVisible = false;
  }

  // 去提问,需带上试卷分类和题目数据，自动选中
  goAskCreate() {
    // 判断题目是否被删除
    if (this.askOption.isDel) {
      this.$message.warning('该题目已被删除，无法发起提问 ');
      return;
    }
    console.log('去提问');
    // @ts-ignore
    const link = this.$router.resolve({
      name: 'ask-create',
      query: {
        categoryId: this.askOption.categoryId,
        questionId: this.askOption.questionId,
        from: this.$route.name,
        isFixed: '1',
      },
    });
    window.open(link.href, '_blank');
  }
}
