export const siteInfo = [
  {
    name: '快速导航',
    isTop: true,
    data: [
      {
        name: '新手指南',
        path: '/group/1/thread/1248',
        isLink: true,
      },
      {
        name: '圈子交流',
        path: '/group',
        isLink: true,
      },
      {
        name: '选课中心',
        path: '/course/explore?categoryId=50&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: 'APP下载',
        path: '/download-page',
        isLink: true,
      },
    ],
  },
  {
    name: '会计学院',
    path: '/course/explore?categoryId=50&page=1&orderType=studentNum',
    data: [
      {
        name: '[注册会计师选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=3&page=1&orderType=studentNum',
        data: [
          [
            {
              name: '六神班',
              path: '/course/explore?categoryId=22&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '2022年CPA六神班',
              path: '/course/11197',
            },
          ],
          [
            {
              name: 'BT小班',
              path: '/course/explore?categoryId=7&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '《战略》BT小班',
              path: '/course/11432',
            },
            {
              name: '《税法》BT小班',
              path: '/course/11431',
            },
            {
              name: '《经济法》BT小班',
              path: '/course/11430',
            },
            {
              name: '《财管》BT小班',
              path: '/course/11429',
            },
            {
              name: '《审计》BT小班',
              path: '/course/11428',
            },
            {
              name: '《会计》BT小班',
              path: '/course/11427',
            },
          ],
          [
            {
              name: '私教班',
              path: '/course/explore?categoryId=339&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '《审计》私教小班',
              path: '/course/11436',
            },
            {
              name: '《会计》私教小班',
              path: '/course/11433',
            },
            {
              name: '《战略》私教小班',
              path: '/course/11448',
            },
            {
              name: '《税法》私教小班',
              path: '/course/11445',
            },
            {
              name: '《经济法》私教小班',
              path: '/course/11442',
            },
            {
              name: '《财管》私教小班',
              path: '/course/11439',
            },
          ],
          [
            {
              name: '综合班',
              path: '/course/explore?categoryId=27&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=337&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[中级会计师选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=133&page=1&orderType=studentNum',
        data: [
          [
            {
              name: '全程班',
              path: '/course/explore?categoryId=144&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=172&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '联报班',
              path: '/course/11625',
              isLink: true,
            },
          ],
          [
            {
              name: 'BT小班《财务管理》',
              path: '/course/11640',
            },
            {
              name: 'BT小班《经济法》',
              path: '/course/11639',
            },
            {
              name: 'BT小班《中级会计实务》',
              path: '/course/11638',
            },
          ],
          [
            {
              name: '私教班',
              path: '/course/explore?categoryId=405&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '《会计实务》私教小班',
              path: '/course/11635',
            },
            {
              name: '《财务管理》私教小班',
              path: '/course/11632',
            },
            {
              name: '《经济法》私教小班',
              path: '/course/11629',
            },
            {
              name: '三科联报保过班',
              path: '/course/5133',
            },
          ],
        ],
      },
      {
        name: '[初级会计职称选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=171&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '课程',
              path: '/course/explore?categoryId=174&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=175&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[税务师选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=1&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: 'BT小班',
              path: '/course/explore?categoryId=245&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: 'BT小班-涉税服务相关法律',
              path: '/course/11522',
            },
            {
              name: 'BT小班-涉税服务实务',
              path: '/course/12521',
            },
            {
              name: 'BT小班-财务与会计',
              path: '/course/12520',
            },
            {
              name: 'BT小班-税法二',
              path: '/course/12519',
            },
            {
              name: 'BT小班-税法一',
              path: '/course/12518',
            },
          ],
          [
            {
              name: '五神班',
              path: '/course/explore?categoryId=23&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '冲刺班',
              path: '/course/explore?categoryId=390&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=351&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[资产评估师选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=303&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '课程',
              path: '/course/explore?categoryId=307&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=353&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[ACCA选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=362&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '全程班',
              path: '/course/explore?categoryId=363&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=364&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
    ],
  },
  {
    name: '法律学院',
    path: '/course/explore?categoryId=209&page=1&orderType=recommendedSeq',
    data: [
      {
        name: '[法考选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=30&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '全程班',
              path: '/course/explore?categoryId=186&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '客观题',
              path: '/course/explore?categoryId=166&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '主观题',
              path: '/course/explore?categoryId=187&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=188&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[法硕选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=287&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '全程班',
              path: '/course/explore?categoryId=288&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=289&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
    ],
  },
  {
    name: '金融学院',
    path: '/course/explore?categoryId=207&page=1&orderType=recommendedSeq',
    data: [
      {
        name: '[CFA选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=19&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: 'CFAⅠ级',
              path: '/course/explore?categoryId=397&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: 'CFAⅡ级',
              path: '/course/explore?categoryId=398&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: 'CFAⅢ级',
              path: '/course/explore?categoryId=399&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=400&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[保荐代表人选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=208&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '全程班',
              path: '/course/explore?categoryId=341&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '通关班',
              path: '/course/explore?categoryId=342&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '无忧班',
              path: '/course/explore?categoryId=343&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '保过班',
              path: '/course/explore?categoryId=344&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '精选',
              path: '/course/explore?categoryId=345&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
    ],
  },
  {
    name: '才鲸学院',
    path: '/course/explore?categoryId=155&page=1&orderType=recommendedSeq',
    data: [
      {
        name: '[会计职业线选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=206&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '小白营',
              path: '/course/explore?categoryId=317&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: 'P2初级',
              path: '/course/explore?categoryId=318&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: 'P3中级',
              path: '/course/explore?categoryId=319&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[审计职业线选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=156&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '小白营',
              path: '/course/explore?categoryId=320&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: 'P2初级',
              path: '/course/explore?categoryId=321&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '才鲸Career',
              path: '/course/explore?categoryId=322&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[春田读书会选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=278&page=1&orderType=recommendedSeq',
      },
    ],
  },
  {
    name: '考研学院',
    path: '/course/explore?categoryId=142&page=1&orderType=recommendedSeq',
    data: [
      {
        name: '[MPAcc选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=189&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '初试班',
              path: '/course/explore?categoryId=193&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '复试班',
              path: '/course/explore?categoryId=194&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[考研公开课选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=143&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '考研英语',
              path: '/course/explore?categoryId=313&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '考研数学',
              path: '/course/explore?categoryId=314&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '考研政治',
              path: '/course/explore?categoryId=315&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '百场讲座',
              path: '/course/explore?categoryId=316&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
      {
        name: '[MBA选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=324&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '初试班',
              path: '/course/explore?categoryId=325&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '复试班',
              path: '/course/explore?categoryId=326&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
    ],
  },
  {
    name: '艾财学堂',
    path: '/course/explore?categoryId=51&page=1&orderType=recommendedSeq',
    data: [
      {
        name: '[理财课程选课中心]',
        isTitle: true,
        path: '/course/explore?categoryId=306&page=1&orderType=recommendedSeq',
        data: [
          [
            {
              name: '小白营',
              path: '/course/explore?categoryId=310&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '初级',
              path: '/course/explore?categoryId=311&page=1&orderType=recommendedSeq',
              isLink: true,
            },
            {
              name: '中级',
              path: '/course/explore?categoryId=356&page=1&orderType=recommendedSeq',
              isLink: true,
            },
          ],
        ],
      },
    ],
  },
  {
    name: 'BT教育教材',
    isBottom: true,
    path: '/course/explore?categoryId=50&page=1&orderType=recommendedSeq',
    data: [
      {
        name: '注册会计师考试教材',
        path: '/course/explore?categoryId=222&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '中级会计师考试教材',
        path: '/course/explore?categoryId=268&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '初级会计职称考试教材',
        path: '/course/explore?categoryId=223&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '税务师考试教材',
        path: '/course/explore?categoryId=267&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '法考教材',
        path: '/course/explore?categoryId=219&page=1&orderType=recommendedSeq',
        isLink: true,
      },
    ],
  },
  {
    name: 'BT教育在线题库',
    isBottom: true,
    path: '/course/explore?categoryId=50&page=1&orderType=recommendedSeq',
    data: [
      {
        name: '注册会计师考试题库',
        path: '/course/explore?categoryId=222&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '中级会计师考试题库',
        path: '/course/explore?categoryId=173&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '初级会计职称考试题库',
        path: '/course/explore?categoryId=176&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '税务师考试题库',
        path: '/course/explore?categoryId=267&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: '资产评估师考试题库',
        path: '/course/explore?categoryId=304&page=1&orderType=recommendedSeq',
        isLink: true,
      },
      {
        name: 'ACCA考试题库',
        path: '/course/explore?categoryId=367&page=1&orderType=recommendedSeq',
        isLink: true,
      },
    ],
  },
  {
    name: '圈子论坛',
    isBottom: true,
    path: '/group',
    data: [
      {
        name: '注册会计师考试交流区',
        path: '/group/1',
        isLink: true,
      },
      {
        name: '税务师考试交流区',
        path: '/group/47',
        isLink: true,
      },
      {
        name: '会计职称考试交流区',
        path: '/group/33',
        isLink: true,
      },
      {
        name: '会计实操交流社区',
        path: '/group/49',
        isLink: true,
      },
      {
        name: '法考交流区',
        path: '/group/40',
        isLink: true,
      },
      {
        name: '考研交流区',
        path: '/group/48',
        isLink: true,
      },
      {
        name: 'CFA考试交流区',
        path: '/group/24',
        isLink: true,
      },
      {
        name: '保荐代表人考试交流区',
        path: '/group/50',
        isLink: true,
      },
      {
        name: '资产评估师考试交流区',
        path: '/group/59',
        isLink: true,
      },
    ],
  },
  {
    name: 'BT教育名师',
    path: '/teacher',
    isBottom: true,
    data: [
      {
        name: '李彬',
        path: '/user/2294/about',
        isLink: true,
      },
      {
        name: '向艳',
        path: '/user/25208/about',
        isLink: true,
      },
      {
        name: 'Jack',
        path: '/user/796563/about',
        isLink: true,
      },
    ],
  },
];
