export const getTimes = () => {
  // 获取当前日期
  const currentDate1 = new Date();
  const currentDate2 = new Date();
  // 设置时间为23:59:59
  currentDate1.setHours(23, 59, 59, 0);
  // 获取当天23:59:59的时间戳
  const todayEndTimestamp = currentDate1.getTime();
  // 获取一年前的日期
  const oneYearAgoDate = new Date(currentDate2.getFullYear() - 1, currentDate2.getMonth(), currentDate2.getDate());
  // 获取一年前的0点的时间戳
  const oneYearAgoStartTimestamp = new Date(oneYearAgoDate.getFullYear(), oneYearAgoDate.getMonth(), oneYearAgoDate.getDate()).getTime();
  return {
    oneYearAgoStartTimestamp,
    todayEndTimestamp
  }
}