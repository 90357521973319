const SearchIndex = () => import(/* webpackChunkName: "search" */ '@/pc/views/search/index.vue');
const Search = () => import(/* webpackChunkName: "search" */ '@/pc/views/search/search.vue');

export default [
  {
    path: '/search/index',
    name: 'search_index',
    component: SearchIndex,
    meta: { isPC: true, needLogin: true },
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: { isPC: true, needLogin: true },
    // props: (router) => ({
    //   searchText: router.query.searchText || '',
    //   type: router.query.type || 'course',
    // }),
  },
];
