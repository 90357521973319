


























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import UploadImgs from './modules/upload-imgs.vue';

import { addNewDiscussion } from '@/api/ask';
@Component({
  components: {
    UploadImgs,
  },
})
export default class modulesCreateDiscussion extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Number, default: 0 }) explanationId: any;
  @Prop({ type: String, default: '' }) type: any; // 追问/追答类型: answer-追答，ask-追问

  form: any = {
    content: '',
    images: [],
  };

  get status() {
    return this.submitloading;
  }

  // 添加追问追答
  submitloading: boolean = false;
  async pushDiscussion() {
    if (this.submitloading) return;
    if (this.form.content.length < 5) {
      this.$message.warning('请输入5-200字问题描述');
      return;
    }
    this.submitloading = true;
    try {
      await addNewDiscussion({
        explanationId: this.explanationId,
        type: this.type,
        content: this.form.content,
        images: this.form.images,
      });
      this.$message.success('发布成功');
      this.$emit('updateFun');
      this.closeDialog();
    } catch (e) {
      console.log('添加追问追答失败', e);
    } finally {
      setTimeout(() => {
        this.submitloading = false;
      }, 500);
    }
  }

  // 关闭弹窗
  closeDialog() {
    this.syncVisible = false;
  }
}
