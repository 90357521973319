










import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
@Component({})
export default class TabsErrataList extends Vue {
  @Prop({ type: Array, default: [] }) errataList: any;
  @PropSync('errataId', { type: Number, default: 0 }) errataIdSync: any;

  // 选择某个修订
  activeErrata(errataId) {
    this.errataIdSync = errataId;
    console.log('选择某个修订', errataId);
  }
  // @PropSync({type:})
}
