import { getUserTeachers } from '@/api/user';

const teacher = {
  namespaced: true,
  state: () => ({
    pagination: {
      total: 0,
      limit: 20,
      page: 1,
    },
    teacherList: [],
    isLoading: false,
  }),
  getters: {
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_TEACHER_LIST(state, payload) {
      state.teacherList = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = {
        ...state.pagination,
        ...payload,
      };
    },
  },
  actions: {
    async getTeacherList({ state, commit }, alias) {
      commit('SET_LOADING', true);
      const { rows, count } = await getUserTeachers({
        page: state.pagination.page - 1,
        limit: state.pagination.limit,
        needCount: 1,
      }).finally(() => commit('SET_LOADING', false));
      commit('SET_TEACHER_LIST', rows);
      commit('SET_PAGINATION', { total: count });
    },
  },
};

export default teacher;