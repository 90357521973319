<template>
  <div class="qiyu-container-wrap">
    <div @click="showChat" class="qiyu-item qiyu-container" v-show="isSdkReady" :class="unreadMsgNum > 0 ? 'active' : ''">
      <img src="https://tmp.btclass.cn/yunyin/menu-service.png" class="service-icon" v-default-img-alt="'在线咨询'" />
      <span class="unread-num" v-if="unreadMsgNum > 0">{{ unreadMsgNum }}</span>
      <div class="dialog-des active-des">咨询</div>
    </div>
    <!-- 下载APP -->
    <a href="/download-page" class="qiyu-item" @mouseenter="enterEvent('app')" target="_black" @mouseleave="enterType = ''">
      <div class="hover-ctx" v-show="enterType === 'app'">
        App下载
      </div>
      <img src="https://tmp.btclass.cn/yunyin/menu-app.png" class="account-icon" v-show="enterType !== 'app'" />
      <div class="qr-code-wrap" v-show="enterType === 'app'">
        <img src="https://tmp.btclass.cn/yunyin/bt-app-code.png" class="qr-code-img" v-default-img-alt="'APP下载'" />
        <div class="account-des">
          <div class="m-b-4">扫码下载BT教育App</div>
          <div>带你高效过关</div>
        </div>
      </div>
    </a>
    <!-- 小程序 -->
    <div class="account_wrap qiyu-item" @mouseenter="enterEvent('applet')" @mouseleave="levaveEvent()">
      <div class="hover-ctx" v-show="enterType === 'applet'">
        小程序
      </div>
      <img src="https://tmp.btclass.cn/yunyin/menu-applet.png" class="account-icon" v-show="enterType !== 'applet'" />
      <div class="qr-code-wrap" v-show="enterType === 'applet'">
        <img src="https://tmp.btclass.cn/yunyin/bt-applet-code.png" class="qr-code-img" v-default-img-alt="'公众号'" />
        <div class="account-des">
          <div class="m-b-4">扫码BT教育题库小程序</div>
          <div>每日Ai练精准学</div>
        </div>
      </div>
    </div>
    <!-- 微信公众号 -->
    <div class="account_wrap qiyu-item" @mouseenter="enterEvent('account')" @mouseleave="levaveEvent()">
      <div class="hover-ctx" v-show="enterType === 'account'">
        公众号
      </div>
      <img src="https://tmp.btclass.cn/yunyin/menu-weixin.png" class="account-icon" v-show="enterType !== 'account'" />
      <div class="qr-code-wrap" v-show="enterType === 'account'">
        <img src="../../../assets/images/qiyu/qrcode.png" class="qr-code-img" v-default-img-alt="'公众号'" />
        <div class="account-des">
          <div>扫码关注BT教育公众号</div>
          <div>获取考试最新动态</div>
        </div>
      </div>
    </div>
    <!-- 置顶 -->
    <div @click="toTop(600)" v-if="showTop" @mouseenter="enterEvent('back-top')" class="qiyu-item animation-back-top" @mouseleave="enterType = ''">
      <div class="hover-ctx" v-show="enterType === 'back-top'">
        返回顶部
      </div>
      <img src="https://tmp.btclass.cn/yunyin/menu-backtop.png" class="account-icon" v-show="enterType !== 'back-top'" />
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  name: 'Qiyu',
  data() {
    return {
      isSdkReady: false, // 是否加载成功
      unreadMsgNum: 0,
      showQrCode: false,
      enterType: '',
      timer: null,
      showTop: false,
      qrcodeUrl: '',
    };
  },
  created() {
    if (SSR_ENV !== 'server') {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
      setInterval(() => {
        let res = window.ysf('getUnreadMsg');
        if (res && res.total > 0) {
          this.unreadMsgNum = res.total;
        } else {
          this.unreadMsgNum = 0;
        }
      }, 3000);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    async enterEvent(val) {
      if (val === 'app') {
        this.qrcodeUrl = await QRCode.toDataURL(`${process.env.VUE_APP_WHALE_URL}download-app`);
      }
      clearTimeout(this.timer);
      this.enterType = val;
    },
    levaveEvent() {
      this.timer = setTimeout(() => {
        this.enterType = '';
      }, 500);
    },
    onScroll() {
      let height = window.innerHeight;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.showTop = scrollTop >= height - 100;
    },
    showChat() {
      if (this.isSdkReady) {
        window.ysf('open');
        this.unreadMsgNum = 0;
      }
    },
    toShowCode() {
      this.showQrCode = true;
    },
    toTop(i) {
      document.documentElement.scrollTop -= i;
      document.body.scrollTop -= i;
      if (document.documentElement.scrollTop > 0 || document.body.scrollTop > 0) {
        var timer = setTimeout(() => this.toTop(i), 16);
      } else {
        clearTimeout(timer);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('./qiyu.debug.css');
// 不要删除下面的css,如果在下面新加了css就可以删除了 因为@import在最后一行会导致页面后端渲染失败
div {
  display: block;
}
</style>
