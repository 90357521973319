const Group = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/Group');
const GroupHome = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/home/Home');
const Detail = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/detail/Detail');
const GroupDetail = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/detail/GroupDetail');
const GroupCreate = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/create/GroupCreate');
const GroupSearch = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/search/Search');
const GroupDetailThreadContainer = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/detail/components/DetailThreadContainer');
const GroupCreateThread = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/detail/components/DetailCreateThread');
const GroupUpdateThread = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/detail/components/DetailUpdateThread');
const GroupDetailSearch = () => import(/* webpackChunkName: "group" */ '@/pc/views/group/detail/components/DetailSearch');

export default [
  {
    path: '/group',
    component: Group,
    children: [
      {
        path: '',
        name: 'group-home',
        component: GroupHome
      },
      {
        path: '/group/add',
        name: 'group-add',
        component: GroupCreate
      },
      {
        path: '/group/search_group',
        name: 'group-search',
        component: GroupSearch
      },
      {
        path: '/group/:id',
        component: Detail,
        props: router => ({
          groupId: Number(router.params.id)
        }),
        children: [
          {
            path: '',
            name: 'group-detail',
            component: GroupDetail
          },
          {
            path: 'thread/create',
            name: 'group-thread-create',
            component: GroupCreateThread
          },
          {
            path: 'thread/:threadId',
            name: 'group-thread-detail',
            component: GroupDetailThreadContainer
          },
          {
            path: 'thread/:threadId/update',
            name: 'group-thread-update',
            component: GroupUpdateThread
          },
          {
            path: 'search',
            name: 'group-thread-search',
            component: GroupDetailSearch
          }
        ]
      }
    ]
  }
];
