import Vue from 'vue';
import App from './App.vue';
import '@/utils/vconsole';
import '@/utils/jsBridge.js';

import { sync } from 'vuex-router-sync';
import { createRouter } from './router';
import { createStore } from '@/store';
import '@/plugins/index';
import '@/directives/global';
import '@/filters/global';

import '@/utils/vee-validate/vee-validate';
import '@/utils/number';

import '@/assets/styles/bootstrap-blue-light.css';
import '@/assets/styles/es-icon.css';
import '@/assets/styles/element.css';
import '@/assets/styles/course.css';
import '@/assets/styles/main.css';
import '@/assets/styles/common.css';
import '@/assets/styles/validate.scss';
import '@/assets/styles/bt-style.scss';
// svg icon
import '@/icons';
import $ from 'jquery';

window.jQuery = window.$ = $;

const isDebug = process.env.NODE_ENV !== 'production';
Vue.config.devtools = isDebug;
Vue.config.productionTip = isDebug;

import sensors from '@/utils/shence';
localStorage.setItem('isAutoUpSc', '1');
Vue.prototype.$sensors = sensors;

// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app');
export function createApp() {
  const router = createRouter();
  const store = createStore();
  Vue.prototype._$router = router;
  Vue.prototype.$appLoginLoading = false;
  // sync the router with the vuex store.
  // this registers `store.state.route`
  sync(store, router);
  const app = new Vue({
    router,
    store,
    data() {
      return {
        isAutoPlay: false,
        isAnniversary: false,
        anniversaryLink: '', // 小banner图标跳转地址
        anniversaryImg: '', // 课程左上角角标
        anniversaryLinkImg: '', // 标题后banner
        topIconUrl: '', //头部的周年庆banner图标
      };
    },
    render: h => h(App),
  });
  return { app, router, store };
}

// @ts-ignore
if (VITE_APP) {
  // 说明是 vite 环境
  const { app, router } = createApp();
  router.onReady(() => {
    app.$mount('#app');
  });
}
