












import { Vue, Component, Prop } from 'vue-property-decorator';
import BindMobile from '@/pc/components/bind-mobile/index.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import LiveLessonItem from './live-lesson-item.vue';
import { getPageData } from '@/api/activity';
import { getLessonByLessonIdOrCourseId } from '@/api/course';

@Component({
  components: {
    swiper,
    swiperSlide,
    LiveLessonItem,
    BindMobile,
  },
})
export default class LiveCom extends Vue {
  @Prop() pageId!: number;

  editLesson: any = null;
  isBindDialogVisible: boolean = false;
  dataIsReady: boolean = false;
  lessons: any[] = [];
  lessonParams = {};
  itemLessons: any[] = [];
  scrollIndex: number = 0;

  // 视频swiper配置
  videoListSwiperOption = {
    loop: false,
    direction: 'vertical',
    slidesPerView: 4.2,
    slidesPerGroup: 1,
    noSwiping: true,
    navigation: {
      nextEl: '.turn-btn.next-btn',
      prevEl: '.turn-btn.prev-btn',
    },
  };

  mounted() {
    this.getLessonData();
  }

  get isVideoScroll() {
    return this.lessons.length > 4;
  }

  async getLessonData() {
    try {
      console.log('获取页面数据');
      this.dataIsReady = false;
      // 首先获取页面配置
      const { items } = await getPageData(this.pageId);
      const types = ['LiveListFirst', 'OpenCourseLesson', 'LiveListSecond'];
      // 对配置数据进行过滤兼容处理
      this.itemLessons = items
        .filter(item => types.includes(item.code) && item.content && item.content.courseLessons && Array.isArray(item.content.courseLessons))
        .reduce((prev, cur) => prev.concat(cur.content.courseLessons), []);
      if (this.itemLessons.length) {
        const courseIdOrLessonIdAndOrders = this.itemLessons.map((item, index) => ({
          courseId: item.courseId,
          lessonId: item.id,
          orderNumber: index,
        }));
        this.lessonParams = {
          courseIdOrLessonIdAndOrders: courseIdOrLessonIdAndOrders,
        };
        await this.getLessonDetailList();
      }
    } catch (e) {
      console.log('获取直播失败');
    } finally {
      this.dataIsReady = true;
    }
  }

  async getLessonDetailList() {
    const lessons = (await getLessonByLessonIdOrCourseId(this.lessonParams)).map(item => Object.assign(item.course, { orderNumber: item.orderNumber, originData: this.itemLessons[item.orderNumber] }));
    this.lessons = lessons;
  }

  // 绑定手机号结束后自动调用预约逻辑
  closeHandle() {
    if (!this.editLesson) return;
    (this.$refs[`refLesson${this.editLesson.id}`] as any)[0].sendReserveRequest();
  }
}
