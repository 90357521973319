<template>
  <div class="home-bt-student-comment bt-model" v-if="hasMounted">
    <div class="bt-head"><span class="bt-title">学员评价</span></div>
    <div class="bt-body">
      <lazy-component @show="handler">
        <template v-if="data">
          <div class="list" v-for="(item, index) in data" :key="index">
            <div class="left"><user-preview :user="item.user" :showImgCase="showImgCase" class="info" model="img"></user-preview></div>
            <div class="right">
              <div class="title">
                <span class="text"> <user-preview :user="item.user" class="info" model="name"></user-preview> </span>
                <span class="star" :rating="item.rating"> <user-rating :num="item.rating"></user-rating> </span> <span class="time">{{ item.createdTime | smartTime }}</span>
              </div>
              <span class="content">{{ item.content | sub_text(20) }}</span>
            </div>
          </div>
        </template>
      </lazy-component>
    </div>
  </div>
</template>

<script>
import UserRating from './components/UserRating';
import { getReviewsByPage } from '@/api/course_review';

export default {
  components: {
    UserRating,
    UserPreview: () => import('@/pc/components/ui/user/UserPreview'),
  },
  data() {
    return {
      data: null,
      showImgCase: 'avatar',
      hasMounted: false,
    };
  },
  mounted() {
    this.hasMounted = true;
  },
  methods: {
    handler() {
      getReviewsByPage({ page: 0, limit: 3 }).then(e => (this.data = e.reviews));
    },
  },
};
</script>
