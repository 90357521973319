

































import { Vue, Component } from 'vue-property-decorator';
import { removeHtmlTagsAndNewlines } from '@/utils/helper';
import { mapActions, mapMutations, mapState } from 'vuex';

@Component({
  methods: {
    ...mapActions('teacher', ['getTeacherList']),
    ...mapMutations('teacher', ['SET_PAGINATION']),
  },
  computed: {
    ...mapState('teacher', ['pagination', 'isLoading', 'teacherList'])
  },
  filters: {
    transAbout(val) {
      if (!val) return '暂无简介';
      return removeHtmlTagsAndNewlines(val);
    },
  },
})
export default class MoreTeacher extends Vue {
  getTeacherList!: any;
  SET_PAGINATION!: any;
  pagination!: any;
  isLoading!: boolean;
  teacherList!: any;
  
  mounted() {
    if (!this.teacherList.length) {
      this.getTeacherList();
    }
  }

  serverPrefetch() {
    return this.getTeacherList();
  }

  handleCurrentChange(page) {
    this.SET_PAGINATION({ page });
    this.getTeacherList();
  }
}
