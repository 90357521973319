import { Module } from 'vuex';
import NP from 'number-precision';

import { OrderModel, CouponModel } from '@/typings';
import * as PaymentService from '@/api/payment';
import { CourseModel } from '@/typings/common/course';

type State = {
  order: OrderModel;
  course: CourseModel;
  coupon: CouponModel;
  redEnvelope: CouponModel;
  usedBcoin: number;
};

export default {
  namespaced: true,
  state: () => ({
    order: {} as OrderModel,
    course: {} as CourseModel,
    coupon: {} as CouponModel,
    redEnvelope: {} as CouponModel,
    usedBcoin: 0,
  }),
  getters: {
    // 优惠券后价格，只拿优惠券去计算
    afterCouponToPayAmount: state => {
      const {
        order: { amount = 0, coinAmount = 0, couponDiscount = 0, redEnvelopeDiscount = 0, discountPrice = 0, vipDiscountAmount = 0, bargainAmount = 0 } = {},
        coupon: { rate = 0, type, batchInfo },
      } = state;
      let finalRatePrice = rate;
      let price: number = 0;
      let nowNeedPay = 0;
      // 可叠加的时候，需支付金额需要减去会员卡优惠，否自不用
      if (batchInfo && !batchInfo.usedWithVipDiscount) {
        if (type === 'discount') {
          nowNeedPay = NP.minus(discountPrice, bargainAmount); // 剩余需要支付的部分 = discountPrice(去掉活动折扣后的价格) - bargainAmount砍价优惠
          finalRatePrice = NP.round(nowNeedPay - (nowNeedPay * rate) / 10, 2); // 应该为 ，然后再乘以优惠券的折扣
        }
        price = NP.round(NP.minus(NP.plus(amount, coinAmount, couponDiscount, redEnvelopeDiscount, vipDiscountAmount), finalRatePrice), 2);
      } else {
        if (type === 'discount') {
          nowNeedPay = NP.minus(discountPrice, vipDiscountAmount + bargainAmount); // 剩余需要支付的部分 = discountPrice(去掉活动折扣后的价格) - vipDiscountAmount会员优惠价格 - bargainAmount砍价优惠
          finalRatePrice = NP.round(nowNeedPay - (nowNeedPay * rate) / 10, 2); // 需要支付的价格 ，然后再乘以优惠券的折扣
        }
        price = NP.round(NP.minus(NP.plus(amount, coinAmount, couponDiscount, redEnvelopeDiscount), finalRatePrice), 2);
      }
      console.log(`vuex-getter-afterCouponToPayAmount${rate ? '优惠券后':''}价格`, price);
      return price > 0 ? price : 0;
    },
    // 最终支付价格 HB 红包
    finalToPayAmount: state => {
      const {
        order: { amount = 0, coinAmount = 0, couponDiscount = 0, redEnvelopeDiscount = 0, discountPrice = 0, vipDiscountAmount = 0, bargainAmount = 0 } = {},
        coupon: { rate = 0, type, batchInfo },
        redEnvelope: { rate: HBRate = 0, type: HBType },
        usedBcoin,
      } = state;
      let finalRatePrice = rate;
      let finalRatePriceHB = HBRate; // 红包无门槛或满减的金额
      let price: number = 0;
      let nowNeedPay = 0;
      //可叠加的时候，需支付金额需要减去会员卡优惠，否自不用
      if (batchInfo && !batchInfo.usedWithVipDiscount) {
        if (type === 'discount') {
          nowNeedPay = NP.minus(discountPrice, bargainAmount); // 剩余需要支付的部分 = discountPrice(去掉活动折扣后的价格) - bargainAmount砍价优惠
          finalRatePrice = NP.round(nowNeedPay - (nowNeedPay * rate) / 10, 2); // 应该为 ，然后再乘以优惠券的折扣
        }
        price = NP.round(NP.minus(NP.plus(amount, coinAmount, couponDiscount, redEnvelopeDiscount, vipDiscountAmount), finalRatePrice, finalRatePriceHB, usedBcoin), 2);
      } else {
        if (type === 'discount') {
          nowNeedPay = NP.minus(discountPrice, vipDiscountAmount + bargainAmount); // 剩余需要支付的部分 = discountPrice(去掉活动折扣后的价格) - vipDiscountAmount会员优惠价格 - bargainAmount砍价优惠
          finalRatePrice = NP.round(nowNeedPay - (nowNeedPay * rate) / 10, 2); // 需要支付的价格 ，然后再乘以优惠券的折扣
        }
        price = NP.round(NP.minus(NP.plus(amount, coinAmount, couponDiscount, redEnvelopeDiscount), finalRatePrice, finalRatePriceHB, usedBcoin), 2);
      }
      console.log('vuex-getter-finalToPayAmount', {type, rate, HBType, HBRate});
      return price > 0 ? price : 0;
    },
  },
  mutations: {
    SET_ORDER(state, order: OrderModel) {
      state.order = order;
    },
    SET_COURSE(state, course: CourseModel) {
      state.course = course;
    },
    SET_USED_BCOIN(state, coin: number) {
      state.usedBcoin = coin;
    },
    SET_COUPON(state, coupon: CouponModel) {
      state.coupon = coupon;
    },
    // 同步选中的红包
    SET_REDENVELOPE(state, redEnvelope: CouponModel) {
      state.redEnvelope = redEnvelope;
    },
  },
  actions: {
    // 获取订单详情
    async getOrder({ commit, dispatch }, sn: string) {
      const order = await PaymentService.getOrder(sn);
      commit('SET_ORDER', order);
      dispatch('getCourse');
      return order;
    },
    // 获取课程详情
    async getCourse({ state, commit }) {
      const course = await PaymentService.getCourseById(state.order.targetId);
      commit('SET_COURSE', course);
    },
  },
} as Module<State, any>;
