




































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { getActMineTask, getActMinePrizeReco, getActMineFreeNum } from '@/api/raffle';
import { trackEvent } from '@/utils/helper';
import { getRemainingSum } from '@/api/my_coin';
import dayjs from 'dayjs';

import TipsDialog from './dialog-tips.vue';
import AddressDialog from './dialog-address.vue';
import Turntable from './turntable.vue';

@Component({
  components: {
    Turntable,
    TipsDialog,
    AddressDialog,
  },
  filters: {
    formatTime(val) {
      if (!val) return '-';
      return dayjs(Math.floor(val * 1000)).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class RaffleModule extends Vue {
  @Prop({ type: Object, default: () => ({}) }) activityInfo; // 活动详情
  @Prop({ type: Object, default: () => ({}) }) raffleSetting; // 全局配置
  @Prop({ type: Number, default: 0 }) activityId: any;
  @Prop({ type: String, default: '' }) mobileCodeUrl: any;
  @Prop({ type: String, default: '' }) statusForUser: any; // 未开始/BEFORE_STARTED, 进行中/IN_PROGRESS, 已结束/AFTER_ENDED;
  @Prop({ type: Object, default: () => ({}) }) TopicItem: any;

  prizeList: any[] = []; // 奖品列表
  taskList: any = []; // 任务列表
  remainingFreeNum: number = 0; // 免费抽奖次数
  myCoinNum: number = 0; // 我的B豆
  myLotteryRecords: any[] = []; // 中奖记录
  curMenu: string = 'task';
  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;
  ruleTipsDialog: boolean = false;
  isAddressDialogVisible: boolean = false;
  curRecord: any = {};

  get showNotice() {
    return this.raffleStatus === 'RAFFLE_IN_PROGRESS' && this.randomNoticeList && this.randomNoticeList.length;
  }

  // 规则文案
  get choujiangRules() {
    const actInfo = (this.activityInfo && this.activityInfo.actInfo) || {};
    return actInfo.rule || '';
  }

  // 获取中奖记录
  async getRecords() {
    const { rows } = await getActMinePrizeReco({ actId: this.activityId, limit: 0 });
    this.myLotteryRecords = rows;
    this.$forceUpdate();
  }

  // 活动进行状态
  get raffleStatus() {
    const actInfo = (this.activityInfo && this.activityInfo.actInfo) || {};
    // 活动未开始
    if (actInfo.statusForUser === 'BEFORE_STARTED') {
      return 'RAFFLE_NO_START';
    }
    // 活动进行中
    if (actInfo.statusForUser === 'IN_PROGRESS') {
      return 'RAFFLE_IN_PROGRESS';
    }
    // 活动已关闭/已结束
    if (actInfo.status === 'CLOSED' || actInfo.statusForUser === 'AFTER_ENDED') {
      return 'RAFFLE_END';
    }
    // 活动未发布
    return 'RAFFLE_ERROR';
  }

  async created() {
    // 获取奖品列表
    const resPrizes = this.activityInfo.prizeInfo.prizes;
    [0, 1, 2, 7, 3, 6, 5, 4].map((key: number) => {
      this.prizeList.push(resPrizes[key]);
    });
    console.log('=== 奖品列表 ===', this.prizeList);

    // 更新任务列表
    this.initTaskList();

    // 如果已经登陆，获取用户信息，如B豆、奖品列表等
    if (this.isLogin) {
      this.updateInfo();
    }

    // 获取获奖信息跑马灯数据
    this.getRandomNotice();
  }

  // 更新任务列表
  initTaskList() {
    if (!this.activityInfo) return;
    const { taskMap } = this.activityInfo.chanceInfo;
    let taskList: any = [];
    // 报分活动开启
    if (taskMap.REPORT_SCORE.status === 'enabled') {
      taskMap.REPORT_SCORE.bindSettings.forEach(item => {
        taskList.push({
          key: `REPORT_SCORE`,
          options: item,
          icon: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fg-Cc_DX3q-qrLXLkBSLVYdYSRyi.png',
          title: `${item.title}`,
          subTitle: `赠送${item.freeNum}次抽奖机会`,
          status: false,
        });
      });
    }
    // 登陆
    if (taskMap.LOGIN_ACT.status === 'enabled') {
      taskList.push({
        key: 'LOGIN_ACT',
        options: taskMap.LOGIN_ACT,
        icon: 'https://tmp.btclass.cn/yunyin/o_1he2cm4snpvg13jto6ec1ubqqi.png',
        title: '每天在活动主页登录',
        subTitle: `每天赠送${taskMap.LOGIN_ACT.freeNum}次抽奖机会`,
        status: false,
      });
    }
    // 购课任务
    if (taskMap.BUY_COURSE.status === 'enabled') {
      taskList.push({
        key: 'BUY_COURSE',
        options: taskMap.BUY_COURSE,
        icon: 'https://tmp.btclass.cn/yunyin/o_1he2cm4snkob1sc8109hnt2u9dh.png',
        title: `每单购课满￥${taskMap.BUY_COURSE.minPrice}`,
        subTitle: `每单赠送${taskMap.BUY_COURSE.freeNum}次抽奖机会`,
        status: false,
        sensorsId: 10687,
      });
    }
    // 推荐任务
    if (taskMap.RCMD_V2.status === 'enabled') {
      taskList.push({
        key: 'RCMD_V2',
        options: taskMap.RCMD_V2,
        icon: 'https://tmp.btclass.cn/yunyin/o_1he2cm4sn1uv517fa1cnjdu71l2kg.png',
        title: '首次推荐好友购课成功',
        subTitle: `赠送${taskMap.RCMD_V2.freeNum}次抽奖机会`,
        status: false,
        sensorsId: 10688,
      });
    }
    // 听课任务
    if (taskMap.LEARN.status === 'enabled') {
      taskList.push({
        key: 'LEARN',
        options: taskMap.LEARN,
        icon: 'https://tmp.btclass.cn/yunyin/o_1he2cm4sn1j0f5un1nplg6f1pj7f.png',
        title: `每天听课超过${taskMap.LEARN.minDuration}分钟`,
        subTitle: `每天赠送${taskMap.LEARN.freeNum}次抽奖机会`,
        status: false,
        sensorsId: 10689,
      });
    }
    // 交卷任务
    if (taskMap.FINISH_TEST.status === 'enabled') {
      taskList.push({
        key: 'FINISH_TEST',
        options: taskMap.FINISH_TEST,
        icon: 'https://tmp.btclass.cn/yunyin/o_1he2cm4sn1djq11fi6ol1aptio9e.png',
        title: `每天完成一次交卷`,
        subTitle: `每天赠送${taskMap.FINISH_TEST.freeNum}次抽奖机会`,
        status: false,
        sensorsId: 10690,
      });
    }
    // 分享任务任务
    if (taskMap.SHARE && taskMap.SHARE.status === 'enabled') {
      taskList.push({
        key: 'SHARE',
        options: taskMap.SHARE,
        icon: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fj49BiMDWts7o8e2TllF5kUBUFg2.png',
        title: '每天通过任务分享活动',
        subTitle: '每天赠送1次抽奖机会',
        status: false,
        sensorsId: 10690,
      });
    }
    this.taskList = taskList;
  }

  // 获取抽奖次数任务点击
  taskDo(task) {
    // 未登陆
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    // 报分, 打开二维码
    if (task.key === 'REPORT_SCORE') {
      this.$emit('dialog', {
        title: '扫码报分',
        subtitle: '报分有奖，查看全国排行榜',
        codeUrl: task.options.pcTaskQrcode,
        codeTips: '微信扫码即可报分',
      });
    }
    // 登陆不用

    // 购课满多少，单独活动跳自定义链接，专题活动跳锚点
    if (task.key === 'BUY_COURSE') {
      if (this.activityInfo.actInfo.promotionChannel === 'SELF') {
        window.open(task.options.link);
      } else {
        this.toPosition(this.TopicItem?.content?.taskBuyCourseScrollToName);
      }
    }

    // 推荐好友，跳转到推荐有奖
    if (task.key === 'RCMD_V2') {
      this.$emit('dialog', {
        title: '推荐好友购课成功',
        subtitle: '推荐好友购课成功得抽奖机会',
        codeUrl: 'https://tmp.btclass.cn/yunyin/o_1he51e8nss37gp01gl6jp51q88a.png',
        codeTips: '使用微信扫码',
      });
    }

    // 听课
    if (task.key === 'LEARN') {
      window.open('/my/courses/learning');
    }

    // 交卷
    if (task.key === 'FINISH_TEST') {
      window.open('/my/quiz/index');
    }

    // 分享
    if (task.key === 'SHARE') {
      this.$emit('dialog', {
        title: '分享到微信',
        subtitle: '当前渠道暂不支持打开此链接，请使用微信扫码在手机上查看',
        codeUrl: this.mobileCodeUrl,
        codeTips: '使用微信扫码',
      });
    }
  }

  // 跳转锚点
  toPosition(key) {
    this.$emit('scrollTo', key);
  }

  // 更新用户相关信息，抽中奖要获取更新b豆以及我的奖品列表
  async updateInfo() {
    try {
      // 用户完成任务情况
      const statusInfo = await getActMineTask(this.activityId, {});
      this.taskList.forEach(item => {
        if (item.key === 'REPORT_SCORE') {
          item.status = statusInfo.REPORT_SCORE.reportActMap[item.options.reportActId];
        } else {
          item.status = statusInfo[item.key].completed;
        }
      });
      console.log('我的任务完成情况', statusInfo, this.taskList);

      // 更新其他信息
      const [{ rows }, { remainingFreeNum }, { cash }] = await Promise.all([getActMinePrizeReco({ actId: this.activityId, limit: 0 }), getActMineFreeNum(this.activityId, {}), getRemainingSum()]);
      this.remainingFreeNum = remainingFreeNum || 0;
      this.myCoinNum = cash || 0;
      this.myLotteryRecords = rows;
      console.log(' === 中奖记录 === ', rows);
    } catch (e) {
      console.log('出错了', e);
    }
  }

  // 切换Tab
  changeMenu(menu) {
    this.curMenu = menu;
    trackEvent(`bt_${menu === 'task' ? '10683' : '10684'}`, {
      description: menu === 'task' ? '做任务得抽奖机会' : '我的奖品',
    });
  }
  menuTypeChange({ type }) {
    this.curMenu = type;
  }

  // 去登录
  loginHandle() {
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
    }
  }

  // 增加编辑地址
  editAddress({ id, data }) {
    console.log('id', id, 'data', data);
    this.curRecord['recordId'] = id;
    this.curRecord['type'] = data['addressInfo'] ? 'edit' : 'add';
    this.curRecord['addressInfo'] = null;
    if (data['addressInfo']) {
      this.curRecord['addressInfo'] = data['addressInfo'];
    }
    this.$forceUpdate();
    this.isAddressDialogVisible = true;
  }

  // 去我的B豆页面
  seeMyCoin() {
    window.open('/my/coin');
  }

  // 联系客服
  openQiyu() {
    // @ts-ignore
    window.ysf('open');
  }

  // 跳转至优惠券详情页
  goCouponDetial({ couponBatchInfo }) {
    const { href } = this.$router.resolve({
      name: 'coupon_receive',
      query: {
        batchId: couponBatchInfo.id,
        promoteType: couponBatchInfo.promoteType,
      },
      params: {
        token: couponBatchInfo.token,
      },
    });
    window.open(href);
  }

  // 查看规则
  seeRule() {
    trackEvent('bt_10691', {
      description: '抽奖详情规则查看',
    });
    this.ruleTipsDialog = true;
  }

  // 生成随机播报数据
  randomNoticeList: any = [];
  // 姓氏
  surnames: any = [];
  // 名字
  nameText: any = [];
  // 时间
  timeText: any = [];
  // 文案
  giftText: any = [];

  initSetting() {
    this.surnames = this.raffleSetting.broadcast.surnames;
    this.nameText = this.raffleSetting.broadcast.name;
    this.timeText = this.raffleSetting.broadcast.time;
    let newGiftText: any = [];
    this.prizeList.map(item => {
      if (item.rewardType && item.rewardType !== 'none') {
        newGiftText.push(`抽中 ${item.title}`);
      }
    });
    this.giftText = newGiftText;
  }

  getRandomNotice() {
    this.initSetting();
    this.randomNoticeList = [];
    Array.from({ length: 50 }).forEach(() => {
      let surnames = this.surnames[Math.floor(Math.random() * this.surnames.length)] || '陈';
      let nameText = this.nameText[Math.floor(Math.random() * this.nameText.length)] || '*';
      let giftText = this.giftText[Math.floor(Math.random() * this.giftText.length)] || '抽中20B豆';
      let timeText = this.timeText[Math.floor(Math.random() * this.timeText.length)] || '今天';
      this.randomNoticeList.push('恭喜' + surnames + nameText + ' ' + timeText + giftText);
    });
  }

  // 引导跳转移动端页面
  goH5Page() {
    this.$emit('dialog', {
      title: '活动已结束',
      subtitle: '抽奖活动已结束，扫码查看中奖记录',
      codeUrl: this.mobileCodeUrl,
      codeTips: '使用微信扫码',
    });
  }
}
