<template>
  <div class="home-bt-teacher course-area fff">
    <div class="pc-container teachers">
      <div class="text-line head">
        <h5 class="anniversary-model-title">
          <span>BT教育名师</span>
          <!-- <a v-if="$root.isAnniversary&&$root.anniversaryLinkImg" :href="$root.anniversaryLink" target="_blank" class="invite-anniversary">
            <img :src="$root.anniversaryLinkImg" v-default-img-alt="'七周年'" />
          </a> -->
          <OperatPlaceCom :subscene="0" title="BT教育名师"></OperatPlaceCom>
        </h5>
        <a href="/teacher" class="open">查看更多</a>
      </div>
      <!-- 客户端渲染需要懒加载 -->
      <template v-if="hasMounted">
        <template v-if="storeTeachers">
          <lazy-component @show="handler">
            <div v-for="(item, index) in storeTeachers" :key="index" :class="'link-teacher ' + (index === 0 ? 'first' : '')" @mouseenter="mouseenter(index)" @mouseleave="mouseleave">
              <div class="top-info">
                <div class="head-img"><img :src="item.largeAvatar" v-default-img:avatar v-default-img-alt="item.title" /></div>
                <div class="name">{{ item | name | sub_text(10) }}</div>
                <div class="title">{{ item.title }}</div>
              </div>
              <transition name="fade" mode="out-in" appear>
                <div class="hover-menu" v-show="active === index">
                  <div class="name">{{ item | name }}</div>
                  <div class="info">{{ ((item && item.signature) || '') | sub_text(100) }}</div>
                  <a class="open" :href="`/user/${item.id}`"> 查看全部 <img src="../../../../assets/images/home/link-teacher-open.png" alt /> </a>
                </div>
              </transition>
            </div>
          </lazy-component>
        </template>
        <template v-if="!storeTeachers">
          <div v-for="(item, index) in 5" :key="index" :class="'link-teacher ' + (index === 0 ? 'first' : '')"></div>
        </template>
      </template>
      <!-- 服务端渲染不需要懒加载 -->
      <template v-else>
        <div v-for="(item, index) in storeTeachers" :key="index" :class="'link-teacher ' + (index === 0 ? 'first' : '')">
          <div class="top-info">
            <div class="head-img"><img :src="item.largeAvatar" v-default-img:avatar /></div>
            <div class="name">{{ item | name }}</div>
            <div class="title">{{ item.title }}</div>
          </div>
          <div class="hover-menu">
            <div class="name">{{ item | name }}</div>
            <div class="info">{{ item.signature }}</div>
            <a class="open" :href="`/user/${item.id}`"> 查看全部 <img src="../../../../assets/images/home/link-teacher-open.png" alt /> </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OperatPlaceCom from '@/pc/views/home/components/OperatPlace.vue';
export default {
  data() {
    return {
      active: null,
      hasMounted: false,
    };
  },
  components: {
    OperatPlaceCom,
  },
  filters: {
    name(obj) {
      return obj.nickname || obj.wxname || obj.studentid || '';
    },
  },
  async serverPrefetch() {
    return this.$store.dispatch('home/getTeachers');
  },
  computed: {
    ...mapState('home', {
      storeTeachers: state => state.teachers,
    }),
  },
  mounted() {
    console.log('this.storeTeachers', this.storeTeachers);
    if (!this.storeTeachers.length) {
      this.$store.dispatch('home/getTeachers');
    }
    this.hasMounted = true;
  },
  methods: {
    handler() {
      this.$store.dispatch('home/getTeachers');
    },
    mouseenter(index) {
      this.active = index;
    },
    mouseleave() {
      this.active = null;
    },
  },
};
</script>
