import Vue from 'vue';
import dayjs from 'dayjs';

Vue.filter('timeFormat', (timestamp, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!timestamp) {
    return '';
  }
  timestamp = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp;
  return dayjs(timestamp).format(format);
});

Vue.filter('smartTimeFormat_1', timestamp => {
  timestamp = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp;
  const now = Date.now();
  if (now - timestamp < 24 * 60 * 60 * 1000) {
    return dayjs(timestamp).format('HH:mm');
  } else {
    const isNowYear = dayjs().isSame(timestamp, 'year');
    return isNowYear ? dayjs(timestamp).format('MM-DD') : dayjs(timestamp).format('YYYY-MM-DD');
  }
});

Vue.filter('duration', time => {
  let minutes = parseInt(time / 60);
  let seconds = parseInt(time % 60);
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  return minutes + ':' + seconds;
});

/**
 * 将秒转换成 h:mm:ss
 */
Vue.filter('formatTimePlayer', seconds => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = Math.floor(seconds % 60);

  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (remainingSeconds < 10) {
    remainingSeconds = '0' + remainingSeconds;
  }
  return `${hours}:${minutes}:${remainingSeconds}`;
});

Vue.filter('durationUnit', time => {
  let minutes = parseInt(time / 60);
  let seconds = parseInt(time % 60);
  return `${+minutes ? `${minutes}分` : ''}${+seconds ? `${seconds}秒` : ''}`;
});

Vue.filter('remainTime', time => {
  let curTime = parseInt(Date.now() / 1000);
  let remain = time - curTime;
  if (remain <= 0) {
    return '0分钟';
  } else if (remain < 3600) {
    return parseInt(remain / 60) + '分钟';
  } else if (remain < 86400) {
    return parseInt(remain / 3600) + '小时';
  } else {
    return parseInt(remain / 86400) + '天';
  }
});

Vue.filter('smartTime', time => {
  let curTime = Math.round(Date.now() / 1000);
  let remain = curTime - time;
  if (remain < 0) {
    return '未来';
  } else if (remain === 0) {
    return '刚刚';
  } else if (remain < 60) {
    return remain + '秒前';
  } else if (remain < 3600) {
    return Math.floor(remain / 60) + '分钟前';
  } else if (remain < 86400) {
    return Math.floor(remain / 3600) + '小时前';
  } else if (remain < 2592000) {
    return Math.floor(remain / 86400) + '天前';
  } else if (remain < 31536000) {
    return dayjs.unix(time).format('MM-DD');
  } else {
    return dayjs.unix(time).format('YYYY-MM-DD');
  }
});

Vue.filter('filterStudentName', user => {
  if (!user) {
    return '';
  }
  return user.nickname || user.wxname || user.studentid;
});

Vue.filter('sub_text', (string, len) => {
  return string.length > len ? string.substr(0, len) + '...' : string;
});

Vue.filter('differ', (times, measurement = 'minutes') => {
  let startTime = times[0];
  let endTime = times[1];
  let start = startTime.toString().length === 10 ? startTime * 1000 : startTime;
  let end = endTime.toString().length === 10 ? endTime * 1000 : endTime;
  return dayjs(end).diff(dayjs(start), measurement);
});

Vue.filter('fileSize', fileSize => {
  const unitExps = {
    B: 0,
    KB: 1,
    MB: 2,
    GB: 3,
  };
  let currentValue, currentUnit;
  for (const [unit, exp] of Object.entries(unitExps)) {
    const divisor = Math.pow(1000, exp);
    currentUnit = unit;
    currentValue = fileSize / divisor;
    if (currentValue < 1000) {
      break;
    }
  }
  return currentValue.toFixed(1) + currentUnit;
});

/**
 * 获取无后缀的文件名
 */
Vue.filter('fileName', fullName => {
  return fullName.replace(/\.[a-z]+$/, '');
});

Vue.filter('sizeTostr', size => {
  var data = '';
  if (size < 0.1 * 1024) {
    //如果小于0.1KB转化成B
    data = size.toFixed(2) + 'B';
  } else if (size < 0.1 * 1024 * 1024) {
    //如果小于0.1MB转化成KB
    data = (size / 1024).toFixed(2) + 'K';
  } else if (size < 0.1 * 1024 * 1024 * 1024) {
    //如果小于0.1GB转化成MB
    data = (size / (1024 * 1024)).toFixed(2) + 'M';
  } else {
    //其他转化成GB
    data = (size / (1024 * 1024 * 1024)).toFixed(2) + 'G';
  }
  var sizestr = data + '';
  var len = sizestr.indexOf('.');
  var dec = sizestr.substr(len + 1, 2);
  if (dec == '00') {
    //当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }
  return sizestr;
});

/**
 * 将秒转换成 00:05:06
 */
Vue.filter('formatSencod', seconds => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (remainingSeconds < 10) {
    remainingSeconds = '0' + remainingSeconds;
  }
  return `${hours}:${minutes}:${remainingSeconds}`;
});

const transWebp = val => {
  let isSupportWebp = false;
    // @ts-ignore
    if (SSR_ENV === 'client') {
      try {
        isSupportWebp = !![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
      } catch (e) {
        console.log(e);
      }
    }
  const prefix = 'https://app-cdn.btclass.cn';
  // suffixes 后端压缩裁剪后添加的图片后缀
  const suffixes = ['-c_l', '-c_m', '-c_s'];
  if (val && val.indexOf(prefix) !== -1 && isSupportWebp && suffixes.every(suffix => val.lastIndexOf(suffix) === -1)) {
    return val + '-webp';
  }
  return val;
}

Vue.filter('transWebp', transWebp);