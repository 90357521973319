


















































import { Vue, Component } from 'vue-property-decorator';
import { siteInfo } from './siteinfo';
import BreadCrumb from '@/pc/components/ui/breadcrumb/BreadCrumb.vue';
@Component({
  components: {
    BreadCrumb,
  },
})
export default class Sitemap extends Vue {
  breadcrumb: any = {
    show: true,
    data: [
      {
        name: '首页',
        path: '/',
      },
      {
        name: '网站地图',
        path: '/sitemap',
      },
    ],
  };
  get siteInfo() {
    return siteInfo;
  }
}
