


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapMutations } from 'vuex';
import { getCouponsReceiveStatus, bulkReceiveCoupons } from '@/api/activity';
import { trackEvent } from '@/utils/helper';

@Component({
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class CouponList extends Vue {
  @Prop() couponIds!: number[];
  @Prop() bgUrl!: string;
  isLoading: boolean = false;
  isReceive: boolean = false;
  coupons: any[] = [];
  isDialogVisible: boolean = false;

  async created() {
    await this.getCoupons();
    if (this.$login() && localStorage.getItem('autoGetCouponsNew') && !this.isAllReceive) {
      console.log('进入自动领券逻辑');
      this.receiveCoupons();
    }
  }

  // 判断是否全部领取了
  get isAllReceive() {
    return this.coupons.every(item => item.showType === 'usable');
  }

  async getCoupons() {
    try {
      this.isLoading = true;
      this.coupons = await getCouponsReceiveStatus(this.couponIds.join(','));
      const receivableCoupons = this.coupons.filter(item => item.showType === 'receivable').sort((a, b) => b.rate - a.rate);
      const notReceivableCoupons = this.coupons.filter(item => item.showType !== 'receivable').sort((a, b) => b.rate - a.rate);
      this.coupons = receivableCoupons.concat(notReceivableCoupons);
    } catch (e) {
      console.log('周年活动获取优惠券列表失败', e);
    } finally {
      this.isLoading = false;
    }
  }
  // 批量领取优惠券
  async batchGetCoupons() {
    trackEvent('bt_10662', {
      description: this.isAllReceive ? '查看我的券包' : '一键领取优惠券',
    });
    // 如果都已领取，则跳转至我的券包
    if (this.isAllReceive) {
      // this.$router.push({ name: 'my_coupon' });
      window.open('/my/coupon');
      return;
    }
    try {
      // 判断是否登录,未登录打开配置弹窗
      if (!this.$login()) {
        // @ts-ignore
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        localStorage.setItem('autoGetCouponsNew', 'true');
        return;
      }
      await this.receiveCoupons();
      // this.$message.success('领券成功，券已发送至您的券包~');
    } catch (e) {
      console.log('批量领取优惠券失败', e);
    }
  }
  async receiveCoupons() {
    try {
      this.isReceive = true;
      const batchs = this.coupons.map(item => ({
        id: item.id,
        token: item.token,
      }));

      await bulkReceiveCoupons(batchs);
      this.isDialogVisible = true;
      // @ts-ignore
      // this.$alert('', '领券成功，券已发送至您的券包~', {
      //   confirmButtonText: '我知道了',
      //   confirmButtonClass: 'coupon-confirm-btn',
      //   showClose: false,
      //   center: true,
      // });
      this.getCoupons();
    } catch (e) {
      console.log('批量领取优惠券失败', e);
    } finally {
      localStorage.removeItem('autoGetCouponsNew');
      this.isReceive = false;
    }
  }
}
