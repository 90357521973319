<template>
  <div class="activity-carousel">
    <div v-swiper="swiperOption">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(poster, index) in posters" :key="index"
          :style="'background:' + poster.background">
          <div style="height: 100%">
            <img class="img-responsive" :data-item="func_str(poster)" v-default-img-alt="poster.imgAlt || '轮播图' + index"
              :data-src="poster.src" :src="poster.src" />
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import { trackEvent } from '@/utils/helper';

let vm;
export default {
  data() {
    let userAgent = navigator.userAgent;
    let isIE = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') === -1; // ie & !ie11
    return {
      swiperOption: {
        loop: true,
        width: window.innerWidth,
        grabCursor: true,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        effect: isIE ? 'slide' : 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          init() {
            if (window.innerWidth <= 1200) {
              this.params.width = 1200;
            } else {
              this.params.width = window.innerWidth;
            }

            this.update();
          },
          resize() {
            if (window.innerWidth <= 1200) {
              this.params.width = 1200;
            } else {
              this.params.width = window.innerWidth;
            }

            this.update();
          },
          slideChange: () => {
            this.setImgSrc();
          },
          click: e => {
            let item = JSON.parse(e.target.getAttribute('data-item')); //转换为对象
            console.log('点击', item);
            vm.goHandle(item || {});
          },
        },
      },
    };
  },
  props: {
    posters: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    //将当前的item对象转换为字符串
    func_str(item) {
      return JSON.stringify(item);
    },
    setImgSrc() {
      // 根据轮播图位置加载图片
      let activeIndex = this.swiper.activeIndex;
      let slideEl = this.swiper.slides[activeIndex];
      if (!slideEl) return
      let bannerImgEl = slideEl.getElementsByClassName('img-responsive')[0];
      let src = bannerImgEl.getAttribute('src');
      if (!src) {
        bannerImgEl.setAttribute('src', bannerImgEl.getAttribute('data-src'));
      }
    },

    goHandle({ href, qrDialog, eventId, description, scrollTo }) {
      trackEvent(`bt_${eventId}`, { description });
      console.log('href', href, 'qrDialog', qrDialog);
      if (href) {
        window.open(href);
      } else if (qrDialog) {
        this.$emit('dialog', qrDialog);
      } else if (scrollTo) {
        console.log('锚点');
        this.$emit('scrollTo', scrollTo);
      }
    },
  },
  created() {
    vm = this;
  },
  async mounted() {
    this.setImgSrc();
  },
};
</script>
