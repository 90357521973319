import { getMyInfo, getMyDropMenu, getMyVip, getMyPermissions, getUnreadNotifications, readNotifications, getMyToken, getAvailableVip } from '@/api/my';

import Cache from '@/utils/cache';
import Vue from 'vue';
const cache = new Cache('session');

const my = {
  namespaced: true,
  state: () => ({
    loginDialogVisible: false,
    resetPasswordDialogVisible: true,
    me: {
      id: 0,
    },
    myDropMenu: [],
    myPermissions: [],
    myVip: {},
    isLogin: undefined,
    onceToken: '',
    unreadNotifications: [],
    isDefaultSort: true,
    unreadExportPrompt: false, // [(我的学习 or 我的教学)/试卷导出]导航右上角小红点
  }),
  getters: {
    me: state => state.me,
    myDropMenu: state => state.myDropMenu,
    myPermissions: state => state.myPermissions,
    myVip: state => state.myVip,
    isLogin: state => state.isLogin,
    unreadNotifications: state => state.unreadNotifications,
    isDefaultSort: state => state.isDefaultSort,
    unreadExportPrompt: state => state.unreadExportPrompt,
  },
  mutations: {
    SET_LOGIN_DIALOG_VISIBLE: (state, data) => {
      state.loginDialogVisible = data;
    },
    SET_RESET_PASSWORD_DIALOG_VISIBLE: (state, data) => {
      state.resetPasswordDialogVisible = data;
    },
    SET_ME: (state, data) => {
      state.me = data;

      if (data.id && window.ysf) {
        window.ysf('config', {
          uid: data.id.toString(),
          name: data.nickname,
          mobile: data.verifiedMobile,
          data: JSON.stringify([
            { key: 'real_name', value: data.truename || data.nickname || data.wxname },
            { key: 'mobile_phone', value: data.verifiedMobile },
            { index: 0, key: 'studentid', label: '学号', value: data.studentid },
          ]),
        });
      }
    },
    SET_MY_DROP_MENU: (state, data) => {
      state.myDropMenu = data;
    },
    SET_MY_PERMISSIONS: (state, data) => {
      state.myPermissions = data;
    },
    SET_MY_VIP: (state, data) => {
      state.myVip = data;
    },
    SET_LOGIN: (state, status) => {
      state.isLogin = status;
    },
    SET_ONCE_TOKEN: (state, token) => {
      state.onceToken = token;
    },
    LOGOUT: state => {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiresTime');
      Vue.cookie.delete('is_phoenix_login');
      cache.clearByKeys(['getMyDropMenu', 'vuex']);
      state.isLogin = false;
      state.me = { id: 0 };
      state.myDropMenu = [];
      state.myPermissions = [];
      state.myVip = {};
    },
    SET_UNREAD_NOTIFICATIONS: (state, data) => {
      state.unreadNotifications = data;
    },
    SET_DEFAULT_SORT: (state, status) => {
      state.isDefaultSort = status;
    },
    SET_UNREAD_EXPORT_PROMPT: (state, data) => {
      state.unreadExportPrompt = data;
    },
  },
  actions: {
    getMyInfo({ commit }) {
      return getMyInfo().then(data => {
        commit('SET_ME', data);
        return data;
      });
    },
    getMyPermissions({ commit }) {
      return getMyPermissions().then(data => {
        commit('SET_MY_PERMISSIONS', data);
        return data;
      });
    },
    async getMyDropMenu({ commit }) {
      let data = await cache.query('getMyDropMenu', getMyDropMenu);
      commit('SET_MY_DROP_MENU', data);
      return data;
    },
    getMyVip({ commit }) {
      return getMyVip().then(data => {
        commit('SET_MY_VIP', data);
        Vue.cookie.set('levelId', (data && data.levelId) || 0);
        return data;
      });
    },
    getAvailableVip({ commit }, courseId) {
      return getAvailableVip(courseId).then((data) => {
        commit('SET_MY_VIP', data);
        Vue.cookie.set('levelId', (data && data.levelId) || 0);
        return data;
      });
    },
    getOnceToken({ state, commit }) {
      if (state.onceToken) {
        getMyToken().then(data => {
          commit('SET_ONCE_TOKEN', data.token);
        });
        return Promise.resolve(state.onceToken);
      } else {
        return getMyToken().then(data => {
          getMyToken().then(data => {
            commit('SET_ONCE_TOKEN', data.token);
          });
          return data.token;
        });
      }
    },
    getUnreadNotifications({ commit }) {
      return getUnreadNotifications().then(data => {
        commit('SET_UNREAD_NOTIFICATIONS', data);
        return data;
      });
    },
    readNotifications({ dispatch }, params) {
      return readNotifications(params).then(() => {
        return dispatch('getUnreadNotifications').then(data => {
          return data;
        });
      });
    },
  },
};

export default my;
