import { findPromoteTeachers } from '@/api/user';
import { getCategoryTree, findCourses, getAdConfigList } from '@/api/home';

// // 法硕：287 考研公共课：143 硕博院校直招：621 同等学力：615 暂时通过白名单写死id的方式去掉这几个分类【2024-5-13】课程分类增加开启关闭功能，这里逻辑做废弃处理
// export const whiteListIds = [287, 143, 621, 615];
const home = {
  namespaced: true,
  state: () => ({
    teachers: [],
    categorys: [],
    courseCategorys: [],
    recommendCourses: [],
    adConfig: [],
  }),
  getters: {
    teachers: state => state.teachers,
    recommendCourses: state => state.recommendCourses,
    categorys: state => state.categorys,
    courseCategorys: state => state.courseCategorys,
    adConfig: state => state.adConfig,
  },
  mutations: {
    SET_TEACHERS: (state, data) => {
      state.teachers = data;
    },
    SET_CATEGORY_TREE: (state, data) => {
      state.categorys = data;
    },
    SET_COURSE_CATEGORYS: (state, data) => {
      state.courseCategorys = data;
    },
    SET_RECOMMEND_COURSES: (state, data) => {
      state.recommendCourses = data;
    },
    SET_AD_CONFIG: (state, data) => {
      state.adConfig = data;
    },
  },
  actions: {
    async getTeachers({ commit }) {
      let data = await findPromoteTeachers(0, 5);
      commit('SET_TEACHERS', data);
      return data;
    },
    async getCategoryTree({ commit }) {
      let data = await getCategoryTree({ code: 'course', levels: '1,2,3', showStatus: 1 });
      const lists = [];
      data = data.filter(parent => {
        // if (whiteListIds.includes(parent.id)) {
        //   return false;
        // }
        if (parent.children && parent.children.length) {
          parent.children = parent.children.filter(child => {
            // if (whiteListIds.includes(child.id)) {
            //   return false;
            // }
            child.parentCode = parent.code;
            child.parentName = parent.name;
            child.parentId = parent.id;
            lists.push(child);
            return true;
          });
        }
        return true;
      });
      commit('SET_CATEGORY_TREE', data);
      commit('SET_COURSE_CATEGORYS', lists);
      return data;
    },
    async getRecommendCourses({ commit }, { query, division }) {
      let data = await findCourses({ ...query, getNew: 1});
      let { rows } = data;
      let result = [];
      if (rows && rows.length > 0) {
        for (let i = 0, len = rows.length; i < len; i += division) {
          const list = rows.slice(i, i + division);
          result.push(list);
        }
      }
      commit('SET_RECOMMEND_COURSES', result);
      return result;
    },
    async getAdConfig({ commit }) {
      try {
        let { rows } = await getAdConfigList();
        commit('SET_AD_CONFIG', rows);
        return rows;
      } catch (e) {
        console.log('获取运营位信息失败');
      }
    },
  },
};

export default home;
