






























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getQuizQuestionInfo } from '@/api/ask';
import { questionTypes, subjectiveTypes } from '@/pc/views/tiku/common/filter';

@Component({
  components: {
    // Problem
    Problem: () => import('@/pc/views/tiku/testpaper/components/testpaper-model/components/questions-senior/problem.vue'),
  },
})
export default class AskDetailsQuestions extends Vue {
  @Prop({ type: Number, default: 0 }) doubtId: any; // 问答id
  @Prop({ type: Number, default: 0 }) questionId: any; // 题目id
  @Prop({ type: Number, default: 0 }) categoryId: any; // 分类id
  @Prop({ type: Boolean, default: false }) autoOpen: any; // 自动打开弹窗
  isLoading: boolean = true;

  questionTypes: any = questionTypes;
  subjectiveTypes: any = subjectiveTypes;
  itemScore: number = 0;
  question: any = {};
  useSource: string = 'practice';
  detail: any = {};
  syncAnswers: any = [];
  get isDel() {
    const { isDel, parent } = this.question;
    return isDel || (parent && parent.isDel);
  }

  async created() {
    try {
      this.isLoading = true;
      // 初始化布局
      this.setInit();
      // 初始化题目数据
      await this.pageInit();
    } catch (e) {
      console.log('关联题目页，初始化失败', e);
    } finally {
      this.isLoading = false;
      if (this.autoOpen) {
        this.openAskDrawer();
      }
    }
  }

  setInit() {
    const data = [{ name: 'BT教育' }, { name: '问答主页', to: { path: `/ask?categoryId=${this.categoryId}`} }];
    if (this.doubtId) {
      data.push({ name: '问答详情页', to: { path: `/ask/${this.doubtId}/details` } });
    }
    data.push({ name: '关联题目页' });
    // 设置面包屑导航
    this.$bus.$emit('setAskBreadCrumb', {
      show: true,
      data,
    });
    // 布局初始化
    this.$bus.$emit('setlayout', { openFlex: true, mainWidth: 'calc(100% - 40px )' });
  }

  // 页面初始化
  async pageInit() {
    // 获取题目数据
    const info = await getQuizQuestionInfo({
      questionId: this.questionId,
    });
    const question = info || {};
    this.question = question;
    // 构造一下数据，兼容组件
    this.syncAnswers = [
      {
        questionId: question.id,
        itemId: 10086,
        choice: [], // 用户的答案
        text: '',
        images: [],
        tableArr: [],
      },
    ];
    this.detail = {
      id: 10086,
      seq: 1,
      question: {
        id: question.id,
        isDel: question.isDel || 0,
        isFavorite: 0,
        answer: question.answer,
      },
      result: {
        answer: {},
        status: '',
        itemId: 10086,
      },
      isRemove: false,
    };
    console.log('===当前题目数据===', question);
  }

  @Watch('syncAnswers', { immediate: false, deep: true })
  onAnswersChange() {
    // 题目未初始化完成不响应更新
    if (this.isLoading) return;

    // 题目初始化已完成
    const { type } = this.question;
    switch (type) {
      case 'single_choice': // 单选题 直接显示答案和解析 确定对错
        this.parsing();
        break;
      case 'determine': // 判断题
        this.parsing();
        break;
      default:
        break;
    }
  }

  hasAnswer(v) {
    return v && ((v.choice && v.choice.length) || (v.images && v.images.length) || (v.tableArr && v.tableArr.length) || v.text);
  }

  // 查看解析
  async parsing() {
    // 题目已作废
    if (this.isDel) {
      return this.$message.warning('该题已作废');
    }
    // 题目已答或者允许跳过未答
    if (this.hasAnswer(this.syncAnswers[0]) || this.subjectiveTypes.includes(this.question.type)) {
      const userAnswer = this.syncAnswers[0];
      let isRight = false;
      const { type, answer } = this.question;
      /* eslint-disable */
      switch (type) {
        case 'single_choice': // 单选题 直接显示答案和解析 确定对错
        case 'choice': // 多选题
        case 'uncertain_choice': // 不定项选择题
        case 'determine': // 判断题
          const userChoice = userAnswer && userAnswer.choice;
          isRight = userChoice && JSON.stringify(userChoice) === JSON.stringify(answer);
          const status = isRight ? 'right' : 'wrong';
          this.detail.result = { status };
          this.detail.isRight = isRight;
          break;
        case 'fill': // 填空题
        case 'essay': // 问答题
        case 'material': // 材料题
          isRight = true;
          this.detail.result = {
            status: userAnswer.text ? 'none' : 'noAnswer',
          };
          this.detail.isRight = isRight;
          break;
        default:
      }
      /* eslint-enable */
    } else {
      // @ts-ignore
      this.$message.closeAll();
      // @ts-ignore
      this.$message.warning('请先选择答案');
    }
  }

  // 打开问答抽屉
  openAskDrawer() {
    this.$bus.$emit('openAskDrawer', {
      categoryId: this.categoryId || this.question.tikuCategoryId,
      questionId: this.questionId,
    });
  }

  // 打开笔记抽屉
  openNotesDrawer() {
    this.$bus.$emit('openNotesDrawer', {
      categoryId: this.categoryId || this.question.tikuCategoryId,
      questionId: this.questionId,
      isDel: this.question.isDel,
    });
  }
}
