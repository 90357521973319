




















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PreviewImgs from './preview-imgs.vue';
import SharePopover from './share-popover.vue';
import ComplainDialog from './complain-dailog.vue';
import DynamicComment from './comment/index.vue';
import UserAvator from './user-com/user-avator.vue';
// @ts-ignore
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { likeCircleDynamic, cancelLikeCircleDynamic, focusUsre, cancleFocusUser, deleteCircleDynamic } from '@/api/circle';
import { DynamicItem } from '@/typings';
import QRCode from 'qrcode';
import { mapGetters, mapMutations } from 'vuex';
import TextEllipsisCom from './text-ellipsis.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    PreviewImgs,
    DynamicComment,
    SharePopover,
    ComplainDialog,
    TextEllipsisCom,
    ElImageViewer,
    UserAvator,
  },
  filters: {
    maxNum(val) {
      if (!val) return '';
      return val > 99 ? '99+' : val;
    },
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
    ...mapGetters(['offsetTime']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class DynamicItemCom extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data!: DynamicItem;
  @Prop({ type: String }) listKey!: string;
  @Prop({ type: Boolean, default: true }) isNeedOperateBtns; // 是否展示底部的分享评论等按钮
  @Prop({ type: Boolean, default: false }) isDyanmicDetail;
  @Prop({ type: Number, default: 733 }) outWidth;

  isChanging: boolean = false;
  isShowComment: boolean = false;
  isComplainDialogVisible: boolean = false;

  me: any;
  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;
  offsetTime: any;
  preImgs: string[] = [];
  isPreviewImg: boolean = false;

  get isVialot() {
    return this.data.checkStatus === 'abnormal';
  }

  get isMe() {
    if (!this.me || !this.me.id) return false;
    return this.me.id === this.data.userId;
  }

  async mounted() {
    // 展示底部的点赞按钮的情况，都不属于动态详情页，所以这里不走更新逻辑
    if (this.isNeedOperateBtns) return;
    // 这里主要用来判断v-html内容中的图片是否加载完毕更新评论的高度
    await this.$nextTick();
    if (this.data.dynamicType === 'longArticle') {
      const images = (this.$refs.htmlContentRef as any).querySelectorAll('img');
      let loadedCount = 0;
      images.forEach(img => {
        img.addEventListener('load', () => {
          loadedCount++;
          if (loadedCount === images.length) {
            this.$emit('updateCommenOffset');
          }
        });
        img.addEventListener('error', () => {
          loadedCount++;
          if (loadedCount === images.length) {
            this.$emit('updateCommenOffset');
          }
        });
      });
    }
  }

  @Watch('data', { deep: true, immediate: true })
  async dataChange() {
    if (this.data.links && this.data.links.length) {
      for (let i in this.data.links) {
        this.data.links[i].qrUrl = await QRCode.toDataURL(this.data.links[i].url);
      }
      this.$forceUpdate();
    }
  }

  get roleList() {
    return this.data.roles.filter(item => item.code !== 'ROLE_OFFICIAL');
  }

  // 点赞功能
  async likeHandle(isLiked, dynamicId) {
    // 点赞前判断是否登录
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    if (this.isChanging) return;
    try {
      this.isChanging = true;
      if (!isLiked) {
        await likeCircleDynamic(dynamicId);
        this.data['likeNum'] += 1;
        this.data['isLiked'] = 1;
      } else {
        await cancelLikeCircleDynamic(dynamicId);
        this.data['likeNum'] -= 1;
        this.data['isLiked'] = 0;
      }
    } finally {
      this.isChanging = false;
    }
  }

  // 关注事件
  async focusHandle() {
    // 关注前判断是否登录
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    if (this.isMe) {
      this.$message.warning('不能关注自己');
      return;
    }
    if (this.isChanging) return;
    try {
      this.isChanging = true;
      const { userId, isFocused } = this.data;
      const params = {
        focusType: 'user',
        targetId: userId,
      };
      if (!isFocused) {
        await focusUsre(params);
        this.$set(this.data, 'isFocused', 1);
        this.$emit('focuseChange', this.data.userId, 1);
      } else {
        this.$confirm('确定取消关注吗？', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'circle-confirm-dialog',
        }).then(async () => {
          await cancleFocusUser(params);
          this.$set(this.data, 'isFocused', 0);
          this.$emit('focuseChange', this.data.userId, 0);
          this.$message.success('操作成功');
        });
      }
    } finally {
      this.isChanging = false;
    }
  }

  // 删除动态
  deleteHandle() {
    this.$confirm('你确定删除该动态吗？相关的评论也会被删除', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      customClass: 'circle-confirm-dialog',
    }).then(async () => {
      await deleteCircleDynamic(this.data.id);
      this.$emit('deleteHandle', this.data);
      this.$message.success('动态已删除');
    });
  }

  // 删除或投诉事件
  commandHandle(type) {
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    if (type == 'delete') {
      this.deleteHandle();
    } else {
      this.isComplainDialogVisible = true;
      console.log('投诉');
    }
  }

  // 跳转动态详情页
  goDynamicDetail() {
    if (this.isDyanmicDetail) return;
    const { id, seq } = this.data;
    const query: any = {
      dynamicId: id + '',
      seq: seq + '',
      listKey: this.listKey,
    };
    if (!seq) delete query['seq'];
    if (!this.listKey) delete query['listKey'];
    const { href } = this.$router.resolve({
      name: 'dynamic-detail',
      query,
    });
    window.open(href);
  }

  // 跳转用户详情页面
  goUserInfoPage(userId) {
    window.open(`/new-user/${userId}`);
  }

  // 跳转校友圈详情页
  goCircleDetail(circleId) {
    const { href } = this.$router.resolve({ name: 'circle-detail', query: { circleId } });
    window.open(href);
  }

  formatTimestamp(timestamp) {
    if (!timestamp) return '-';
    const now = new Date();
    const date = new Date(timestamp);
    // 计算时间差（单位：毫秒）
    const timeDiff = now.getTime() - this.offsetTime - date.getTime();
    const yestoryStart = dayjs(now.getTime() - this.offsetTime)
      .subtract(1, 'day')
      .startOf('day')
      .valueOf();
    const yestoryEnd = dayjs(now.getTime() - this.offsetTime)
      .subtract(1, 'day')
      .endOf('day')
      .valueOf();

    // 计算时间差（单位：秒、分钟、小时）
    const secondsDiff = Math.floor(timeDiff / 1000);
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    if (timestamp > yestoryStart && timestamp < yestoryEnd) {
      return '昨天';
    } else if (secondsDiff < 60) {
      return `${secondsDiff}秒前`;
    } else if (minutesDiff < 60) {
      return `${minutesDiff}分钟前`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff}小时前`;
    } else if (timeDiff < 1000 * 60 * 60 * 24 * 90) {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }

  closePreview() {
    this.isPreviewImg = false;
    this.preImgs = [];
  }

  showImage(e) {
    if (e.target.tagName == 'IMG') {
      this.preImgs.push(e.target.src);
      this.isPreviewImg = true;
    }
  }

  // 更新评论数
  updateCommentNum(num) {
    this.$set(this.data, 'commentNum', num);
  }
}
