














import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { addDoubtReward } from '@/api/ask';
import AskAccount from './modules/ask-account.vue';

@Component({
  components: {
    AskAccount,
  },
})
export default class ChooseCoupon extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Number, default: 0 }) doubtId: any;

  bDouOption: any = {
    minPrice: 2,
    rewardChoices: [2, 4, 8],
  }; // B豆可选项
  bDouBalance: any = 0;

  reward: any = 0;

  // 追加悬赏
  submitLoading: boolean = false;
  async submit() {
    if (this.submitLoading) return;
    this.submitLoading = true;
    try {
      if (!this.reward && this.reward !== 0) {
        this.$message.warning('请输入B豆悬赏金额');
      } else if (this.reward <= 0) {
        this.$message.warning('请输入正确的B豆悬赏金额');
      } else if (this.reward > this.bDouBalance) {
        this.$message.warning('您当前的B豆余额不足 ，快去充值吧');
      } else {
        await addDoubtReward({
          doubtId: this.doubtId,
          reward: this.reward,
        });
        this.$message.success('追加悬赏成功');
        this.$emit('updateFun');
        this.closeDialog();
      }
    } catch (e) {
      console.log('追加悬赏失败', e);
    } finally {
      setTimeout(() => {
        this.submitLoading = false;
      }, 500);
    }
  }

  // 关闭弹窗
  closeDialog() {
    this.syncVisible = false;
  }
}
