



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

type MenuItem = {
  code: string;
  name: string;
};
@Component
export default class SideBar extends Vue {
  @Prop() scrollTo: any;
  @Prop() active!: string;
  @Prop() list!: MenuItem[];
  @Prop({ type: String, default: '' }) imgIcon;

  show: boolean = false;

  get menus() {
    return [...this.list];
  }

  @Watch('show')
  showChangeHandle(val) {
    this.$bus.$emit('homeMenuStatus', val);
  }

  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
  }
  onScroll() {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    this.show = scrollTop >= 100;
    let codes: any[] = [];
    this.menus.forEach(item => {
      codes.push(item.code);
    });
    if (codes.includes(this.active)) {
      (document.getElementById(this.active) as any).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }
}
