<template>
  <div class="home-bt-latest-info bt-model">
    <div class="bt-head"><span class="bt-title">最新资讯</span> <a href="/article" class="bt-open">查看更多</a></div>
    <div class="bt-body" v-if="hasMounted">
      <lazy-component @show="handler">
        <template v-if="data">
          <div class="top">
            <div class="left">
              <a :href="`/article/${data[0].id}`"> <img :src="data[0].originalThumb" v-default-img:information v-default-img-alt="data[0].title"/> </a>
            </div>
            <div class="right">
              <div class="title">
                <a :href="`/article/${data[0].id}`">{{ data[0].title | sub_text(22) }}</a>
              </div>
              <div class="content" v-if="data[0].body && isWebKit">{{ data[0].body }}</div>
              <div class="content" v-if="data[0].body && !isWebKit">{{ data[0].body | sub_text(50) }}</div>
              <div class="time">{{ data[0].publishedTime | timeFormat('YYYY-MM-DD') }}</div>
            </div>
          </div>
          <div class="bottom">
            <template v-for="(item, index) in data">
              <div class="list" :key="index" v-if="index > 0">
                <div class="title">
                  <a :href="`/article/${item.id}`">{{ item.title | sub_text(25) }}</a>
                </div>
                <div class="time">{{ item.publishedTime | timeFormat('YYYY-MM-DD') }}</div>
              </div>
            </template>
          </div>
        </template>
      </lazy-component>
    </div>
  </div>
</template>

<script>
import { findHomeArticles } from '@/api/home';

export default {
  data() {
    return {
      data: null,
      info: '',
      isWebKit: true,
      hasMounted: false,
    };
  },
  mounted() {
    this.hasMounted = true;
    this.check();
  },
  methods: {
    async handler() {
      try {
        const data = await findHomeArticles();
        this.data = data;
      } catch (e) {
        console.log('获取最新咨询失败', e);
      }
    },
    check() {
      if (!(navigator.userAgent.indexOf('Chrome') > -1 || navigator.userAgent.indexOf('Safari') > -1)) {
        this.isWebKit = false;
      }
    },
  },
};
</script>
