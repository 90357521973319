

























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import MainLoading from '@/pc/components/loading/main-loading.vue';
import MenuShell from '../components/menu-shell.vue';
import TabsPage from '../components/tabs-page.vue';
import TabsChapter from '../components/tabs-chapter.vue';
import TabsChapterPage from '../components/tabs-chapter-page.vue';
// @ts-ignore
import MErrataUnRead from '../../../../assets/images/book-errata/m-errata-un-read.png';
// @ts-ignore
import MErrataIsRead from '../../../../assets/images/book-errata/m-errata-is-read.png';

import { trackEvent } from '@/utils/helper';

import { showForUser, getOneBookErratas, updateBookErrataRead } from '@/api/book-errata';
Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {
    MenuShell,
    TabsPage,
    TabsChapter,
    TabsChapterPage,
    MainLoading,
  },
})
export default class BookErrataDetail extends Vue {
  @Prop({ type: Number, default: 0 }) bookId!: any;

  // readIcon: any = ['https://app-cdn.btclass.cn/turtle/m-errata-un-read.png', 'https://app-cdn.btclass.cn/turtle/m-errata-is-read.png'];
  readIcon: any = [MErrataUnRead, MErrataIsRead];

  async beforeRouteEnter(to: any, from: any, next: any) {
    console.log('from', from.name);
    next(vm => {
      vm.routerFromName = from.name;
    });
  }
  routerFromName: string = '';

  initLoading: boolean = true; // 全局loading

  bookTitle: string = ''; // 教材标题
  tabName: any = ''; // 选择的模块名称
  activeMenu: any = ''; // 选择的模块名称

  // 按页码查看
  pagesList: any = []; // 页码列表
  activePage: number = 0; // 当前选中的页码

  // 按章节查看
  chapterPageList: any = []; // 章节的页码列表
  switchIndex: any = -1; // 当前展开的章节下标
  activeChapterId: any = 0; // 当前选中的章节id
  activeChildPage: number = 0; // 当前选中的章节页码

  // 右边显示区域
  errataInfo: any = {}; // 修订数据
  errataLoading: boolean = true; // 修订列表加载状态
  noResultIcon: string = '';
  noResultText: string = '';

  // 报错信息
  errorInfo: any = {};

  // 显示章节页码模块：章节有页码数据存在
  get showChapterPage() {
    return this.chapterPageList.length;
  }

  // 按页码查看-页码下标：根据当前选择的页码，找到对应的下标
  get activePageIndex() {
    const findIndex = this.pagesList.findIndex((page: any) => {
      return this.activePage === page.page;
    });
    return findIndex > -1 ? findIndex : 0;
  }

  // 按章节查看-页码下标：根据当前选择的页码，找到对应的下标
  get activeChildPageIndex() {
    const findIndex = this.chapterPageList.findIndex((page: any) => {
      return this.activeChildPage === page.page;
    });
    return findIndex > -1 ? findIndex : 0;
  }

  isReadStatus(data) {
    return data.userReadNum;
  }

  /* 
    首先先加载书籍数据，
    · 如果书籍被关闭了或者被设置为不在修订列表显示，则不继续往下执行逻辑
    · 如果书籍修订可被查看，则加载数据
  */
  async created() {
    // 回到顶部查看
    (document.documentElement || document.body).scrollIntoView({ block: 'start', behavior: 'smooth' });
    try {
      const bookInfo = await showForUser({ bookId: this.bookId });
      this.bookTitle = bookInfo.title;
      console.log('获取书籍信息', bookInfo);
      // 教材在修订列表不展示
      if (!bookInfo.showInErrata) {
        this.setErrorInfo({
          code: 1041,
          msg: '教材修订正在更新中，敬请期待哦~',
        });
        return;
      }
      // 优先级：路由的tab > 默认选择【按页码查看】
      this.tabName = this.$route.query.type || 'chapter';
      this.activeMenu = this.tabName;
    } catch (e) {
      // 教材被关闭
      if ([1041].includes(e.code)) {
        e.hideNormalFail && e.hideNormalFail();
        this.setErrorInfo(e);
      }
      console.log('获取书籍信息出错', e);
    }
  }

  // 切换查看模式时，要先重置一下数据，避免路由携带的参数产生影响
  changeTabType(tab) {
    // 不响应重复点击
    if (this.activeMenu === tab.name) return;

    // 重置页码
    this.pagesList = [];
    this.activePage = 0;

    // 重置章节
    this.activeChapterId = 0;
    this.chapterPageList = [];
    this.switchIndex = -1;
    this.activeChildPage = 0;

    // 重置修订数据
    this.errataInfo = {}; // 修订数据
    this.errataLoading = true; // 修订列表加载状态

    // 更新路由
    this.$router.replace({ query: { type: tab.name } });

    // 切换tab
    this.activeMenu = tab.name;
  }

  // 获取页码对应的修订列表，仅提供给【按页码查看】
  async getPageErrata({ pageNum, init }) {
    // 加载中不重复请求
    if (this.errataLoading && !init) return;
    // 选择的页码是同一个，不重复请求
    if (pageNum === this.activePage) return;

    // 执行请求
    return new Promise(async resolve => {
      // 同步页码，并且重置其他数据
      this.activePage = pageNum;
      this.activeChildPage = 0;
      // 更新一下路由链接:要固定携带上类型type和当前页码pageNum
      this.$router.replace({ query: { type: 'pageNum', pageNum } });
      // 回到顶部查看
      (document.documentElement || document.body).scrollIntoView({ block: 'start', behavior: 'smooth' });

      // 开始获取数据
      try {
        this.errataLoading = true;
        const info = await getOneBookErratas({
          bookId: this.bookId,
          pageNum,
          unread: 1,
        });
        this.errataInfo = info;
        this.updateStorage('bookDetailsPage', { pageNum });
        console.log('获取页码对应的修订列表', this.bookId, pageNum, info);
      } catch (e) {
        console.log('获取页码对应的修订列表失败', e);
        // 教材被关闭
        if ([1041].includes(e.code)) {
          e.hideNormalFail && e.hideNormalFail();
          this.setErrorInfo(e);
        }
      } finally {
        setTimeout(() => {
          // 特殊场景：页码加载出来后在后台关闭数据，这个时候显示兜底逻辑
          if (!(this.errataInfo.rows && this.errataInfo.rows.length)) {
            this.showNoResult(4);
          }
          this.errataLoading = false;
          this.initLoading = false;
          resolve(true);
        }, 300);
      }
    });
  }

  // 获取章节页码对应的修订列表，仅提供给【按章节查看】
  async getChapterPageErrata({ pageNum, errataNum, init }: { pageNum: any; errataNum: any; init: any }) {
    // 加载中不重复请求
    if (this.errataLoading && !init) return;
    // 选择的页码是同一个，不重复请求
    if (pageNum === this.activeChildPage) return;

    // 执行请求
    return new Promise(async resolve => {
      // 同步页码，并且重置其他数据
      this.activePage = 0;
      this.activeChildPage = pageNum;
      // 更新一下路由链接:要固定携带上类型type和当前页码pageNum、展开的章节下标switchIndex和选择的章节chapterId
      this.$router.replace({
        query: {
          type: 'chapter',
          pageNum,
          switchIndex: this.switchIndex,
          chapterId: this.activeChapterId,
        },
      });
      // 回到顶部查看
      (document.documentElement || document.body).scrollIntoView({ block: 'start', behavior: 'smooth' });

      // 开始获取数据
      try {
        this.errataLoading = true;
        const info = await getOneBookErratas({
          bookId: this.bookId,
          pageNum,
          chapterId: this.activeChapterId,
          unread: 1,
        });
        this.errataInfo = info;
        // 如果加载的勘误列表数量和页码的勘误数量对不上，可能是后台更新了，刷新一下页码列表数据
        if (info.rows.length !== errataNum) {
          this.$refs['tabsChapterRef'] && (this.$refs['tabsChapterRef'] as any).updateBookErrataPages({ chapterId: this.activeChapterId });
        }
        this.updateStorage('ChapterDetailsPage', { chapterId: this.activeChapterId, [this.activeChapterId]: { pageNum } });
        console.log('获取章节页码对应的修订列表', this.bookId, pageNum, this.switchIndex, this.activeChapterId, info);
      } catch (e) {
        console.log('获取章节页码对应的修订列表失败', e);
        // 教材被关闭
        if ([1041].includes(e.code)) {
          e.hideNormalFail && e.hideNormalFail();
          this.setErrorInfo(e);
        }
      } finally {
        setTimeout(() => {
          // 特殊场景：页码加载出来后在后台关闭数据，这个时候显示兜底逻辑
          if (!(this.errataInfo.rows && this.errataInfo.rows.length)) {
            this.showNoResult(4);
          }
          this.errataLoading = false;
          this.initLoading = false;
          resolve(true);
        }, 300);
      }
    });
  }

  // 更新本地缓存
  updateChapterStorage({ key, keyData }) {
    this.updateStorage(key, keyData);
  }

  // 更新缓存 按页码查看：bookDetailsPage、 按章节查看： ChapterDetailsPage
  updateStorage(key, keyData) {
    let bookErrata = JSON.parse(localStorage.getItem('book-errata') || `{}`);
    let bookInfo = bookErrata[this.bookId] || {};
    bookInfo[key] = { ...(bookInfo[key] || {}), ...keyData };
    bookErrata[this.bookId] = bookInfo;
    localStorage.setItem('book-errata', JSON.stringify(bookErrata));
  }

  // 上一页
  cutPrePage() {
    // 修订还在获取中，防止重复请求
    if (this.errataLoading) return;

    const { activeMenu } = this;
    let page: any = {};
    // 【按页码查看】
    if (activeMenu === 'pageNum') {
      page = this.pagesList.filter((_, index) => {
        return index === this.activePageIndex - 1;
      })[0];
      this.getPageErrata({ pageNum: page.page, init: false });
    }
    // 【按章节查看】
    if (activeMenu === 'chapter') {
      page = this.chapterPageList.filter((_, index) => {
        return index === this.activeChildPageIndex - 1;
      })[0];
      this.getChapterPageErrata({ pageNum: page.page, errataNum: page.errataNum, init: false });
    }
  }

  // 下一页
  cutNextPage() {
    // 修订还在获取中，防止重复请求
    if (this.errataLoading) return;

    const { activeMenu } = this;
    let page: any = {};
    // 【按页码查看】
    if (activeMenu === 'pageNum') {
      page = this.pagesList.filter((_, index) => {
        return index === this.activePageIndex + 1;
      })[0];
      this.getPageErrata({ pageNum: page.page, init: false });
    }

    // 【按章节查看】
    if (activeMenu === 'chapter') {
      page = this.chapterPageList.filter((_, index) => {
        return index === this.activeChildPageIndex + 1;
      })[0];
      this.getChapterPageErrata({ pageNum: page.page, errataNum: page.errataNum, init: false });
    }
  }

  // 没有修订，显示的兜底数据
  showNoResult(key = 1) {
    const iconMap: any = {
      1: require('@/assets/images/book-errata/no-result-2.png'),
      2: require('@/assets/images/book-errata/no-result-2.png'),
      3: require('@/assets/images/book-errata/no-result-2.png'),
      4: require('@/assets/images/book-errata/no-result-2.png'),
    };
    const textMao: any = {
      1: '该教材暂无修订',
      2: '该教材暂无修订',
      3: '该章节暂无修订，请切换其他章节查看',
      4: '该页码暂无修订，请切换其他页码查看',
    };
    this.noResultIcon = iconMap[key];
    this.noResultText = textMao[key];
    this.errataInfo = {};
    this.errataLoading = false;
    this.initLoading = false;
  }

  // 同步报错信息
  setErrorInfo(info) {
    console.log('同步报错信息', info);
    this.errorInfo = info;
    this.initLoading = false;
  }

  // 返回上一页
  goBack() {
    if (this.routerFromName !== 'book-errata-list') {
      this.$router.replace({ name: 'book-errata-list' });
      return;
    }
    this.$router.go(-1);
  }

  showReadTips: boolean = false;
  // 下次不再提醒
  readTipsNext: number = 1;
  changeTipsNext() {
    this.readTipsNext = this.readTipsNext ? 0 : 1;
  }
  // 标记为已读
  submitRead: any = () => {};
  // 我再看看
  cancelSubmit: any = () => {};
  // 修改阅读状态
  readStatusLoading: boolean = false;
  // 正在已读状态
  readLoadingIds: any = [];
  changeReadLoadingIds(errataId) {
    console.log('changeReadLoadingIds-errataId', errataId);
    if (this.readLoadingIds.includes(errataId)) {
      this.readLoadingIds = this.readLoadingIds.filter(id => {
        return id !== errataId;
      });
    } else {
      this.readLoadingIds.push(errataId);
    }
  }
  async changeReadStatus(cardInfo, isAuth = true, site) {
    // if (this.readStatusLoading) return;
    // 正在已读或者已经已读的，不响应重复点击
    if (this.readLoadingIds.includes(cardInfo.id)) return;
    if (cardInfo.userReadNum) return;
    // 需要弹出提示
    if (isAuth && localStorage.getItem('errataReadTips') !== '1') {
      this.showReadTips = true;
      this.submitRead = async () => {
        await this.changeReadStatus(cardInfo, false, site);
        this.showReadTips = false;
        // 弹窗关闭有肉眼上的延迟
        setTimeout(() => {
          this.readTipsNext = 1;
        }, 300);
      };
      this.cancelSubmit = async () => {
        // 下次是否弹出提醒
        localStorage.setItem('errataReadTips', this.readTipsNext + '');
        this.showReadTips = false;
        // this.readStatusLoading = false;
        // 弹窗关闭有肉眼上的延迟
        setTimeout(() => {
          this.readTipsNext = 1;
        }, 300);
      };
      return;
    }
    // this.readStatusLoading = true;
    this.changeReadLoadingIds(cardInfo.id);
    console.log('标记为已读', isAuth, this.readTipsNext);
    if (site === 'top') {
      trackEvent(`bt_10914`, { description: '修订卡片右上角【未读】勾选框', bookId: this.bookId, page: cardInfo.page, errataId: cardInfo.id });
    } else {
      trackEvent(`bt_10915`, { description: '修订卡片底部【点我标记成已读】勾选框', bookId: this.bookId, page: cardInfo.page, errataId: cardInfo.id });
    }
    try {
      // 下次是否弹出提醒
      localStorage.setItem('errataReadTips', this.readTipsNext + '');
      // 置为已读
      await updateBookErrataRead({ errataId: cardInfo.id });
      // 本地置为已读，不刷新修订列表
      cardInfo.userReadNum = 1;
      // 本地置为已读，不刷新修订页码列表 TODO
      this.chapterPageList[this.activeChildPageIndex].userReadNum++;
      // 刷新章节列表
      this.$refs['tabsChapterRef'] && (this.$refs['tabsChapterRef'] as any).updateBookChapterErrata();
      console.log('修改阅读状态成功');
    } catch (e) {
      console.log('修改阅读状态失败', e);
    } finally {
      // this.readStatusLoading = false;
      this.changeReadLoadingIds(cardInfo.id);
    }
  }
}
