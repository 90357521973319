const TikuExam = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/exam.vue');
const TestpaperShow = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/show/index-senior.vue');
const TestpaperReview = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/review/index.vue');
const TestpaperResult = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/result/index.vue');
const TestpaperReviewResult = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/review-result/index.vue');
const TestpaperDo = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/do/index.vue');
const TestpaperRedo = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/redo/index.vue');
const TestpaperMistakes = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/mistakes/index-senior.vue');
const TestpaperEasyMistake = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/easy-mistake/index-senior.vue');
const TestpaperPractice = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/practice/index-senior.vue');
const TestpaperQuestionPreview = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/question-preview/index-senior.vue');
const MockExam = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/mock-exam/index.vue');
const MockExamEntry = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/mock-exam/pages/entry.vue');
const MockExamLogin = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/mock-exam/pages/login.vue');
const MockExamRule = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/mock-exam/pages/rule.vue');
const MockExamResult = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/mock-exam/pages/result.vue');
const MockExamPaper = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/mock-exam/pages/paper.vue');
const RelevantQuestions = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/testpaper/relevant-questions/index.vue');
// 云考场/全真模拟
const CloundExam = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/index.vue');
const AdmissionTicket = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/admission-ticket.vue');
const StudentMessage = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/student-message.vue');
const ExamRules = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/exam-rules.vue');
const OperateExplain = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/operate-explain.vue');
const SpecialTips = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/special-tips.vue');
const TopicDirectory = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/topic-directory.vue');
const ExamEnd = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/exam-end.vue');
const DoExercise = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/do-exercise.vue');
// 税务师云考场/全真模拟
const CctaaCloundExam = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/index.vue');
const CctaaLoginBefore = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/login-before.vue');
const CctaaAdmissionTicket = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/admission-ticket.vue');
const CctaaStudentMessage = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/student-message.vue');
const CctaaExamRules = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/exam-rules.vue');
const CctaaOperateExplain = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/operate-explain.vue');
const CctaaSpecialTips = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/special-tips.vue');
const CctaaTopicDirectory = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/topic-directory.vue');
const CctaaExamEnd = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/exam-end.vue');
const CctaaDoExercise = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cctaa-exam/do-exercise.vue');
// 云考场成绩报告单
const CoreReports = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/cloud-exam/score-reports/index.vue');
// 初级会计/全真模拟
const AccountingExam = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/accounting-exam/index.vue');
const AccountingExamLogin = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/accounting-exam/pages/login.vue');
const AccountingExamBefore = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/accounting-exam/pages/before.vue');
const AccountingExamDo = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/accounting-exam/pages/do.vue');
const AccountingExamEnd = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/accounting-exam/pages/end.vue');
// 中级会计/全真模拟
const ZjAccountingExam = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/zj-accounting-exam/index.vue');
const ZjAccountingExamLogin = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/zj-accounting-exam/pages/login.vue');
const ZjAccountingExamBefore = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/zj-accounting-exam/pages/before.vue');
const ZjAccountingExamDo = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/zj-accounting-exam/pages/do.vue');
const ZjAccountingExamEnd = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/zj-accounting-exam/pages/end.vue');
// 高级会计/全真模拟
const GjAccountingExam = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/gj-accounting-exam/index.vue');
const GjAccountingExamLogin = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/gj-accounting-exam/pages/login.vue');
const GjAccountingExamBefore = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/gj-accounting-exam/pages/before.vue');
const GjAccountingExamDo = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/gj-accounting-exam/pages/do.vue');
const GjAccountingExamEnd = () => import(/* webpackChunkName: "tiku" */ '@/pc/views/tiku/gj-accounting-exam/pages/end.vue');

export default [
  {
    path: '',
    component: TikuExam,
    name: 'tiku-exam',
    meta: { isPC: true },
    children: [
      // 开始做题
      {
        path: '/test/:id/do',
        name: 'tiku-test-do',
        component: TestpaperDo,
        meta: {
          isPC: true,
          needLogin: true,
        },
        props: router => ({
          testId: Number(router.params.id),
        }),
      },

      // 重新做题
      {
        path: '/test/:id/redo',
        name: 'tiku-test-redo',
        component: TestpaperRedo,
        meta: {
          isPC: true,
          needLogin: true,
        },
        props: router => ({
          testId: Number(router.params.id),
        }),
      },

      // 继续做题
      {
        path: '/test/:id/show',
        name: 'tiku-test-show',
        component: TestpaperShow,
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          hideHeader: true,
        },
        props: router => ({
          testpaperResultId: Number(router.params.id),
        }),
      },

      // 自评
      {
        path: '/test/:id/review',
        name: 'tiku-test-review',
        component: TestpaperReview,
        meta: {
          isPC: true,
          needLogin: true,
        },
        props: router => ({
          testpaperResultId: Number(router.params.id),
        }),
      },

      // 答题报告
      {
        path: '/test/:id/result',
        name: 'tiku-test-result',
        component: TestpaperResult,
        meta: {
          isPC: true,
          needLogin: true,
        },
        props: router => ({
          testpaperResultId: Number(router.params.id),
        }),
      },

      // 错题巩固
      {
        path: '/test/:id/mistakes',
        name: 'tiku-test-mistakes',
        component: TestpaperMistakes,
        meta: {
          isPC: true,
          needLogin: true,
          hideHeader: true,
          hideFooter: true,
        },
        props: router => ({
          testId: Number(router.params.id),
        }),
      },

      // 易错题
      {
        path: '/test/review/:reviewId',
        name: 'tiku-easy-mistake',
        component: TestpaperEasyMistake,
        meta: {
          isPC: true,
          needLogin: true,
          hideHeader: true,
          hideFooter: true,
        },
        props: router => ({
          reviewId: Number(router.params.reviewId),
        }),
      },

      // 开始背题
      {
        path: '/test/:id/practice',
        name: 'tiku-test-practice',
        component: TestpaperPractice,
        meta: {
          isPC: true,
          needLogin: true,
          hideHeader: true,
          hideFooter: true,
        },
        props: router => ({
          testId: Number(router.params.id),
        }),
      },

      // 题目预览-收藏
      {
        path: '/test/category/:categoryId/question/:questionId/preview',
        name: 'tiku-question-favorite',
        component: TestpaperQuestionPreview,
        meta: {
          isPC: true,
          needLogin: true,
          hideHeader: true,
          hideFooter: true,
        },
        props: router => ({
          testId: Number(router.params.testId),
          categoryId: Number(router.params.categoryId),
          questionId: Number(router.params.questionId),
        }),
      },

      // 相关试题
      {
        path: '/topic/:topicId/relevant/questions',
        name: 'tiku-relevant-questions',
        component: RelevantQuestions,
        meta: {
          isPC: true,
          needLogin: true,
        },
        props: router => ({
          topicId: Number(router.params.topicId),
        }),
      },
    ],
  },
  {
    path: '/test/:id/review_result',
    name: 'tiku-test-review-result',
    component: TestpaperReviewResult,
    meta: {
      isPC: true,
      needLogin: true,
    },
    props: router => ({
      firstTestId: Number(router.query.testId || Number(router.params.id) || ''),
      targetUserId: Number(router.query.targetUserId || ''),
    }),
  },
  {
    path: '/mock-exam/:testId',
    component: MockExam,
    name: 'mock-exam',
    props: router => ({
      testId: Number(router.params.testId),
    }),
    children: [
      {
        path: '/mock-exam/:testId/entry',
        name: 'mock-exam-entry',
        component: MockExamEntry,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: '/mock-exam/:testId/login',
        name: 'mock-exam-login',
        component: MockExamLogin,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: '/mock-exam/:testId/rule',
        name: 'mock-exam-rule',
        component: MockExamRule,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: '/mock-exam/:testId/result',
        name: 'mock-exam-result',
        component: MockExamResult,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: '/mock-exam/:testId/paper',
        name: 'mock-exam-paper',
        component: MockExamPaper,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
    ],
  },
  {
    // cpa云考场系统
    path: '/cloud-exam/:testpaperId',
    component: CloundExam,
    name: 'cloud-exam',
    meta: {
      isPC: true,
      hideFooter: true,
      hideHeader: true,
      needLogin: true,
    },
    props: router => ({
      resultId: Number(router.params.resultId),
      testpaperId: Number(router.params.testpaperId),
    }),
    children: [
      {
        // 准考证号
        path: 'admission-ticket',
        component: AdmissionTicket,
        name: 'admission-ticket',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 考生信息
        path: 'student-message',
        component: StudentMessage,
        name: 'student-message',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 考场守则
        path: 'exam-rules',
        component: ExamRules,
        name: 'exam-rules',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 操作说明
        path: 'operate-explain',
        component: OperateExplain,
        name: 'operate-explain',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 特别提示
        path: ':resultId/special-tips',
        component: SpecialTips,
        name: 'special-tips',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 题目目录
        path: ':resultId/topic-directory',
        component: TopicDirectory,
        name: 'topic-directory',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 做题页面
        path: ':resultId/do-exercise',
        component: DoExercise,
        name: 'do-exercise',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 考试结束
        path: 'exam-end',
        component: ExamEnd,
        name: 'exam-end',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
    ],
  },
  {
    // 税务师云考场系统
    path: '/cctaa-exam/:testpaperId',
    component: CctaaCloundExam,
    name: 'cctaa-exam',
    meta: {
      isPC: true,
      hideFooter: true,
      hideHeader: true,
      needLogin: true,
    },
    props: router => ({
      resultId: Number(router.params.resultId),
      testpaperId: Number(router.params.testpaperId),
    }),
    children: [
      {
        // 登陆前的注意事项
        path: 'login-before',
        component: CctaaLoginBefore,
        name: 'cctaa-login-before',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 准考证号
        path: 'admission-ticket',
        component: CctaaAdmissionTicket,
        name: 'cctaa-admission-ticket',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 考生信息
        path: 'student-message',
        component: CctaaStudentMessage,
        name: 'cctaa-student-message',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 考场守则
        path: 'exam-rules',
        component: CctaaExamRules,
        name: 'cctaa-exam-rules',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 操作说明
        path: 'operate-explain',
        component: CctaaOperateExplain,
        name: 'cctaa-operate-explain',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 特别提示
        path: ':resultId/special-tips',
        component: CctaaSpecialTips,
        name: 'cctaa-special-tips',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 题目目录
        path: ':resultId/topic-directory',
        component: CctaaTopicDirectory,
        name: 'cctaa-topic-directory',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 做题页面
        path: ':resultId/do-exercise',
        component: CctaaDoExercise,
        name: 'cctaa-do-exercise',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        // 考试结束
        path: 'exam-end',
        component: CctaaExamEnd,
        name: 'cctaa-exam-end',
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
    ],
  },
  {
    // 云考场答题报告
    path: '/score-reports/:testpaperId/:resultId',
    component: CoreReports,
    name: 'score-reports',
    meta: {
      isPC: true,
      needLogin: true,
    },
    props: router => ({
      testpaperId: Number(router.params.testpaperId),
      resultId: Number(router.params.resultId),
    }),
  },
  {
    // 初级会计全真模拟
    path: '/accounting-exam/:testpaperId',
    component: AccountingExam,
    name: 'accounting-exam',
    meta: {
      isPC: true,
      hideFooter: true,
      hideHeader: true,
      needLogin: true,
    },
    props: router => ({
      resultId: Number(router.params.resultId),
      testpaperId: Number(router.params.testpaperId),
    }),
    children: [
      {
        path: 'login',
        name: 'accounting-exam-login',
        component: AccountingExamLogin,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: 'before',
        name: 'accounting-exam-before',
        component: AccountingExamBefore,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: ':resultId/do',
        name: 'accounting-exam-do',
        component: AccountingExamDo,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: ':resultId/end',
        name: 'accounting-exam-end',
        component: AccountingExamEnd,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
    ]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  },
  {
    // 中级会计全真模拟
    path: '/zj-accounting-exam/:testpaperId',
    component: ZjAccountingExam,
    name: 'zj-accounting-exam',
    meta: {
      isPC: true,
      hideFooter: true,
      hideHeader: true,
      needLogin: true,
    },
    props: router => ({
      resultId: Number(router.params.resultId),
      testpaperId: Number(router.params.testpaperId),
    }),
    children: [
      {
        path: 'login',
        name: 'zj-accounting-exam-login',
        component: ZjAccountingExamLogin,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: 'before',
        name: 'zj-accounting-exam-before',
        component: ZjAccountingExamBefore,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: ':resultId/do',
        name: 'zj-accounting-exam-do',
        component: ZjAccountingExamDo,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: ':resultId/end',
        name: 'zj-accounting-exam-end',
        component: ZjAccountingExamEnd,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
    ]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  },
  {
    // 高级会计全真模拟
    path: '/gj-accounting-exam/:testpaperId',
    component: GjAccountingExam,
    name: 'gj-accounting-exam',
    meta: {
      isPC: true,
      hideFooter: true,
      hideHeader: true,
      needLogin: true,
    },
    props: router => ({
      resultId: Number(router.params.resultId),
      testpaperId: Number(router.params.testpaperId),
    }),
    children: [
      {
        path: 'login',
        name: 'gj-accounting-exam-login',
        component: GjAccountingExamLogin,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: 'before',
        name: 'gj-accounting-exam-before',
        component: GjAccountingExamBefore,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: ':resultId/do',
        name: 'gj-accounting-exam-do',
        component: GjAccountingExamDo,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
      {
        path: ':resultId/end',
        name: 'gj-accounting-exam-end',
        component: GjAccountingExamEnd,
        meta: {
          isPC: true,
          hideFooter: true,
          hideHeader: true,
          needLogin: true,
        },
      },
    ]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  }
];
