














import { Vue, Component, Prop } from 'vue-property-decorator';
import { getPageData } from '@/api/activity';
import LiveTabContent from './components/live-tab-content.vue';

@Component({
  components: {
    LiveTabContent,
  },
})
export default class LiveTabList extends Vue {
  @Prop() data!: any;

  tabList: any = [];
  tabContentLesson: any = [];
  activeTabIndex: number = 0;

  async mounted() {
    await this.getLessonData();
  }

  async getLessonData() {
    // 首先获取页面配置
    const { pageId } = this.data;
    const { items } = await getPageData(pageId);
    // 获取直播列表1组件内容
    this.tabList = [];
    this.tabContentLesson = [];
    items.forEach((item, index) => {
      if (['LiveListFirst'].includes(item.code)) {
        this.tabList.push(item);
      }
      return ['LiveListFirst'].includes(item.code);
    });
  }
}
