





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class modulesToAsk extends Vue {
  @Prop({ type: Number, default: 0 }) categoryId: any;
  searchText: any = '';

  // 去搜索
  toSearch() {
    if (!this.searchText || !this.searchText.trim()) {
      this.$message.warning('请输入搜索内容');
      return;
    }
    // @ts-ignore
    const link = this.$router.resolve({ name: 'search', query: { searchText: encodeURIComponent(this.searchText), type: 'doubt' } });
    window.open(link.href, '_blank');
    console.log('去提问', this.searchText);
  }

  // 去提问
  toCreate() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-create', query: { categoryId: this.categoryId, form: this.$route.name } });
    window.open(link.href, '_blank');
    console.log('去提问', this.searchText);
  }
}
