

































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import CommonDialog from './common-dialog.vue';
import UploadImg from './upload-img/index.vue';
import { getComplaintTagList, addComplaint, getUserComplaintTagList, addUserComplaint } from '@/api/circle';

@Component({
  components: {
    UploadImg,
    CommonDialog,
  },
})
export default class ComplainDialog extends Vue {
  @PropSync('visible') visibleSync;
  @Prop({ type: String, default: 'dynamic' }) complaintType; // dynamic-动态、comment-评论、reply-评论回复   用户投诉类型   user-用户、groupChat-群聊、privateMessage-私信、tutor-课程班班
  @Prop({ type: Number }) targetId;

  complainTagList: any[] = [];
  isLoading: boolean = false;
  isSubmitting: boolean = false;
  complainForm: any = {
    complaintTagId: '',
    des: '',
    pictures: [],
  };

  get isUserComplain() {
    return !['dynamic', 'comment', 'reply'].includes(this.complaintType);
  }

  rules = {
    complaintTagId: [
      {
        required: true,
      },
    ],
  };

  async mounted() {
    try {
      this.isLoading = true;
      if (this.isUserComplain) {
        this.complainTagList = await getUserComplaintTagList();
      } else {
        this.complainTagList = await getComplaintTagList();
      }
    } catch (e) {
      console.log('获取投诉列表失败', e);
    } finally {
      this.isLoading = false;
    }
  }

  // 添加投诉
  async addComplainHandle() {
    if (this.isSubmitting) return;
    try {
      this.isSubmitting = true;
      const { des, complaintTagId, pictures } = this.complainForm;
      if (!complaintTagId) {
        this.$message.warning('请选择投诉类型');
        return;
      }
      const params: any = {
        complaintType: this.complaintType,
        targetId: this.targetId,
        complaintTagId: complaintTagId,
      };
      if (this.isUserComplain) {
        if (des) params['des'] = des;
        if (pictures && pictures.length) params['pictures'] = pictures;
        await addUserComplaint(params);
      } else {
        await addComplaint(params as any);
      }
      this.$message.success('投诉成功');
      this.visibleSync = false;
    } finally {
      this.isSubmitting = false;
    }
  }
  closeHandle() {
    this.visibleSync = false;
  }
}
