import customPage from '@/pc/views/custom-page/index.vue'

export default [
  {
    path: '/custom-page/:alias',
    name: 'custome_page',
    component: customPage,
    props: route => ({
      alias: route.params.alias,
    }),
  },
  {
    path: '/raffle',
    name: 'raffle_activity_main',
    component: () => import('@/pc/views/raffle/index.vue'),
    children: [
      {
        path: ':activityId',
        name: 'raffle_activity_index',
        component: () => import(/* webpackChunkName: "raffle_activity" */ '@/pc/views/raffle/activity.vue'),
        meta: {},
        props: route => ({
          activityId: Number(route.params.activityId),
        }),
      },
    ],
  },
];
