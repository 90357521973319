



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { getDynamicRecommendList } from '@/api/circle';

@Component
export default class RecommendDynamic extends Vue {
  @Prop({ type: Number }) dynamicId!: number;

  recommendDynamicList: any[] = [];
  defaultRecommandCircleId: number = 0;
  isLoading: boolean = false;

  async mounted() {
    try {
      this.isLoading = true;
      const { rows, recommandCircleId } = await getDynamicRecommendList(this.dynamicId);
      this.recommendDynamicList = rows;
      this.defaultRecommandCircleId = recommandCircleId;
    } finally {
      this.isLoading = false;
    }
  }

  async goMoreCircle() {
    const { href } = this.$router.resolve({
      name: 'circle-detail',
      query: {
        circleId: this.defaultRecommandCircleId + '',
      },
    });
    window.open(href);
  }

  goDynamicDetail(item) {
    const { seq, id, dynamicType } = item;
    console.log('seq', seq, 'dynamicType', dynamicType);
    const { href } = this.$router.resolve({
      name: 'dynamic-detail',
      query: {
        dynamicId: id + '',
      },
    });
    window.open(href);
  }
}
