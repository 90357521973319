












































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import SmsCodeHelper from '@/utils/sms-code-helper';
const smsCodeHelper = new SmsCodeHelper('<% %>秒后重试');

type FormValidator = {
  val: string;
  errorMessage: string;
  validator: (form?: any) => Promise<boolean>;
};

@Component({
  computed: {
    ...mapGetters('my', ['me']),
  },
  methods: {
    ...mapActions('my', ['getMyInfo']),
  },
})
export default class BindMobileModal extends Vue {
  @Prop({ type: String, default: '绑定手机号' }) title;
  @PropSync('visible', { type: Boolean, default: false }) visibleSync;

  isSendSmsCode: boolean = false;
  smsCodeBtnText: string = '获取验证码';
  verifiedMobile: string = '';
  skipMobile: boolean = false;

  get hasBindMobile() {
    return this.me.verifiedMobile;
  }

  form = {
    phoneNum: {
      val: '',
      errorMessage: '',
      async validator() {
        if (!this.val) {
          this.errorMessage = '手机号不能为空';
          return false;
        }
        if (!/^1\d{10}$/.test(this.val)) {
          this.errorMessage = '手机格式错误';
          return false;
        }
        return true;
      },
    },
    smsCode: {
      val: '',
      errorMessage: '',
      async validator(form: any) {
        if (!this.val) {
          this.errorMessage = '验证码不能为空';
          return false;
        }

        const valid = await SmsCodeHelper.verifySmsCode({ mobile: form.phoneNum.val, smscode: this.val });
        if (!valid) {
          this.errorMessage = '验证码输入错误';
          return false;
        }
        return true;
      },
    },
    async validator() {
      const form = this;
      const task: Promise<boolean>[] = [];
      Object.entries(form).forEach(async ([key, val]) => {
        if (key !== 'validator') {
          task.push((val as FormValidator).validator(form));
        }
      });

      const values = await Promise.all(task);

      return values.every(v => v);
    },
  };
  me: any;

  get checkPhone() {
    return !!this.form.phoneNum.val.match(/^\d{11}$/) && this.form.phoneNum.errorMessage === '';
  }
  clearError(field: FormValidator) {
    field.errorMessage = '';
  }

  mounted() {
    smsCodeHelper.onWaitSecondsChange = msg => {
      this.smsCodeBtnText = msg;
      this.isSendSmsCode = true;
    };
    smsCodeHelper.onStop = () => {
      this.smsCodeBtnText = '获取验证码';
      this.isSendSmsCode = false;
    };
  }

  isSubmitting: boolean = false;
  async submit() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    const valid = await this.form.validator().finally(() => (this.isSubmitting = false));
    if (!valid) {
      return;
    }
    this.$emit('dialogClose');
    //@ts-ignore
    this.getMyInfo({});
    this.visibleSync = false;
  }

  async sendSmsCode() {
    const mobileValid = await this.form.phoneNum.validator();
    if (!mobileValid) {
      return;
    }
    this.isSendSmsCode = true;
    await smsCodeHelper.sendSmsCode({ mobile: this.form.phoneNum.val });
  }

  //打开客服聊天框
  showChat() {
    (window as any).ysf('open');
  }
}
