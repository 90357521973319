const Notification = () => import(/* webpackChunkName: "notification" */ '@/pc/views/notification/Notification');

export default [
  {
    path: '/notification/show/:who?',
    name: 'notification',
    component: Notification,
    props: route => ({
      who: route.params.who || 'all',
      page: Number(route.query.page) || 1,
    }),
    meta: {
      needLogin: true
    },
  }
];
