import { getQueryVariable } from '@/utils/parseUrlParams';

export enum ENV {
    DEV = 'dev',
    TEST = 'test',
    PRE = 'pre',
    PROD = 'prod',
}
/**
 * 1. 通过域名决定默认环境
 * 2. 需要同步APP的环境
 * 3. 通过query参数可修改环境
 */
export const getEnv: (cb: () => void) => Promise<any> = async (cb) => {
    const host: string = location.host;
    const reg: RegExp = /^pre(.+)\.cn/;
    const queryEnv = getQueryVariable('env');
    const ENVS: string[] = Object.values(ENV);
    if (queryEnv && ENVS.includes(queryEnv)) {
        localStorage.setItem('env', queryEnv);
        cb();
        return {
            env: queryEnv,
            isCalledCallback: true,
        };
    }
    let env: string = '';
    if (host.indexOf('.net') !== -1) {
        // 测试环境
        env = ENV.TEST;
    } else if (reg.test(host)) {
        // 预发布环境
        env = ENV.PRE;
    } else if (host.indexOf('.cn') !== -1) {
        // 正式环境
        env = ENV.PROD;
    } else {
        // 本地dev环境
        env = ENV.DEV;
    }
    return new Promise((resolve) => {
        if (window.JsBridge && window.JsBridge.hasBridge) {
        window.JsBridge.callHandler("appUserAgent", null, (data) => {
            if (data) {
            let agentData = JSON.parse(data);
            const app_env = agentData && agentData.release;
            env = ENVS.includes(app_env) ? app_env : env;
            }
            localStorage.setItem('env', env);
            resolve({
                env,
                isCalledCallback: false,
            });
        });
        } else {
            localStorage.setItem('env', env);
            resolve({ env, isCalledCallback: false });
        }
    })
};

const getEnvTitle: (env: string) => (title: string) => string = function (env: string) {
    return function (title: string) {
        const localEnv = localStorage.getItem('env');
        const titleMap = {
            [ENV.PRE] : '预发布'
        };
        if (!titleMap[env]) return title;
        return `${localEnv === env ? `【${titleMap[env]}】` + '-' : ''}${title}`
    }
}
export const getPreTitle = getEnvTitle(ENV.PRE);