


















































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { getRewardChoices, getCashAccount, addRequireOrder } from '@/api/ask';
import NP from 'number-precision';

@Component({
  components: {},
})
export default class ChooseCoupon extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Object, default: () => {} }) requireServeceInfo: any;
  @Prop({ type: Number, default: 0 }) doubtId: any;

  bDouOption: any = {
    minPrice: 2,
    rewardChoices: [2, 4, 8],
  };
  bDouBalance: number = 0;
  isRecharge: boolean = false;

  // 用户实际支付
  get userPayNum() {
    const discount = NP.divide(Number(this.requireServeceInfo.discount), 10);
    return NP.round(NP.times(Number(this.requireServeceInfo.amount), discount), 1);
  }

  // 承诺回复时长
  get replyTimeText() {
    // if (this.requireServeceInfo.replyTime > 48) {
    //   return NP.divide(this.requireServeceInfo.replyTime, 24) + '天';
    // }
    return this.requireServeceInfo.replyTime + '小时';
  }

  // 折扣
  get discount() {
    return Number(this.requireServeceInfo.discount).toFixed(1);
  }

  async created() {
    await this.getBdouNum();
  }

  // 获取B豆相关配置
  cashLoading: boolean = false;
  async getBdouNum() {
    if (this.cashLoading) return;
    this.cashLoading = true;
    try {
      const optionInfo = await getRewardChoices({});
      this.bDouOption = optionInfo;
      console.log('B豆悬赏数量可选项', optionInfo);
      const balanceInfo = await getCashAccount({
        timestamp: new Date().getTime(),
      });
      this.bDouBalance = balanceInfo.cash || 0;
      console.log('B豆余额', balanceInfo);
      this.isRecharge = false;
    } catch (e) {
      console.log('获取B豆相关配置失败', e);
    } finally {
      setTimeout(() => {
        this.cashLoading = false;
      }, 300);
    }
  }

  // B豆-去充值
  toRecharge() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'my_coin_money_pay' });
    window.open(link.href, '_blank');
    this.isRecharge = true;
  }

  // 升级
  submitLoading: boolean = false;
  async submit() {
    if (this.submitLoading) return;
    this.submitLoading = true;
    try {
      if (this.userPayNum > this.bDouBalance) {
        this.$message.warning('您当前的B豆余额不足 ，快去充值吧');
      } else {
        await addRequireOrder({ doubtId: this.doubtId });
        setTimeout(() => {
          this.$emit('updateFun');
          this.$message.success('当前提问已成功升级必答服务');
          this.closeDialog();
        }, 500);
      }
    } catch (e) {
      console.log('接口请求失败', e);
    } finally {
      setTimeout(() => {
        this.submitLoading = false;
      }, 500);
    }
  }

  closeDialog() {
    this.syncVisible = false;
  }
}
