



































import { Vue, Component, Prop } from 'vue-property-decorator';
import OpenCourseModel from '@/pc/views/home/components/components/OpenCourseModel.vue';
import NormalCourseItem from '@/pc/views/home/components/components/CourseList.vue';
import Sticky from './sticky.vue';
import scrollWatch from '@/libs/vue-scrollwatch2';
import { getCourseListForUser } from '@/api/course';
import { trackEvent } from '@/utils/helper';

@Component({
  components: {
    OpenCourseModel,
    NormalCourseItem,
    Sticky,
  },
})
export default class Anniversary extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data;
  @Prop({ type: Number, default: 80 }) headHeight;
  @Prop({ type: String, default: '' }) activeBlock;

  courseMap: any = {};
  activeNameSync: string = '';

  get isChangeTab() {
    return this.data.vantType === 'swipeable' ? true : false;
  }

  get blockList() {
    if (this.isChangeTab) {
      return [].concat(this.data.blockList).filter((item: any) => item.title == this.activeNameSync);
    }
    return this.data.blockList;
  }

  async mounted() {
    // 默认选中第一个tab
    if (this.data.blockList && this.data.blockList.length) {
      this.activeNameSync = this.data.blockList[0]['title'];
    }
    await this.getCourse();
    await this.$nextTick();
    const stickyDom = this.$refs.stickyBtnsRef as HTMLElement;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          entry.target.classList.toggle('is-fixed', entry.intersectionRatio < 1);
        });
      },
      { threshold: [0, 1], rootMargin: `${-this.headHeight + 20}px 0px 0px 0px` }
    );
    observer.observe(stickyDom);
  }

  tabClick(name) {
    try {
      if (this.isChangeTab) {
        this.activeNameSync = name;
      } else {
        scrollWatch.scrollTo(name);
      }
      trackEvent('bt_10663', { description: '保价双十一-项目切换', projectName: name });
    } catch (error) {
      console.log(error);
    }
  }

  sensorsHandle(courseId) {
    trackEvent('bt_10664', {
      description: '保价双十一-查看课程详情',
      courseId,
      projectName: this.activeNameSync,
    });
  }

  async getCourse() {
    try {
      // 将所有课程配置 ids 获取下
      const allCourseIds: number[] = this.data.blockList
        .map(item => {
          let courseIds = item.courseIds || [];
          if (item.cBlocks && item.cBlocks.length) {
            item.cBlocks.forEach(cItem => {
              cItem.courseIds && courseIds.push(...cItem.courseIds);
            });
          }
          return courseIds;
        })
        .reduce((result, cur) => [...result, ...cur], []);
      let { rows } = await getCourseListForUser({ ids: [...new Set(allCourseIds)].join(','), needPriceDetail: 1 });
      rows.forEach(item => {
        if (!this.courseMap[item.id]) {
          this.courseMap[+item.id] = item;
        }
      });
      console.log('---------------双十一好课获取成功----------------');
      this.$forceUpdate();
    } catch (e) {
      console.log('获取双十一好课失败', e);
    }
  }

  scroll(node) {
    this.activeNameSync = node.name;
  }
}
