<template>
  <div class="lottie" :style="style" ref="lavContainer">
    <slot></slot>
  </div>
</template>

<script>
import lottie from 'lottie-web';
export default {
  name: 'Lottie',
  props: {
    options: {
      type: Object,
      required: true
    },
    height: Number,
    width: Number
  },
  data() {
    return {
      style: {
        width: this.width ? `${this.width}px` : '',
        height: this.height ? `${this.height}px` : ''
      },
      anim: null
    };
  },
  mounted() {
    this.loadAnimation();
  },
  methods: {
    loadAnimation() {
      this.anim = lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: 'svg',
        loop: this.options.loop !== false,
        autoplay: this.options.autoplay !== false,
        animationData: this.options.animationData,
        rendererSettings: this.options.rendererSettings
      });
      this.$emit('animCreated', this.anim);
    },
    registerAnimation() {
      this.$refs.lavContainer.innerHTML = '';
      this.anim = lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: 'svg',
        loop: this.options.loop !== false,
        autoplay: this.options.autoplay !== false,
        animationData: this.options.animationData,
        rendererSettings: this.options.rendererSettings
      });
      this.$emit('animRegister', this.anim);
    }
  },
  watch: {
    options() {
      this.registerAnimation();
    }
  }
};
</script>
<style scoped lang="scss">
.lottie {
  overflow: hidden;
  margin: 0 auto;
}
</style>
