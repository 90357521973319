


















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import DynamicList from './components/dynamic-list.vue';
import SearchBlock from './components/side-bar/search-block.vue';
import RecomandCircle from './components/side-bar/recommend-circle.vue';
import HotLesson from './components/side-bar/hot-lesson.vue';
import HotStoried from './components/side-bar/hot-storied.vue'; // 热门动态
import { getCircleDetail, getCircleDynamicList, joinCircle, quitCircle } from '@/api/circle';
import { CircleItem, DynamicItem } from '@/typings';
import { mapGetters, mapMutations } from 'vuex';

@Component({
  components: {
    DynamicList,
    SearchBlock,
    RecomandCircle,
    HotLesson,
    HotStoried,
  },
  computed: {
    ...mapGetters('my', ['isLogin']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class CircleDetailCom extends Vue {
  @Prop({ type: Number }) circleId!: number;

  pagination: any = {
    page: 0,
    limit: 10,
    total: 0,
    isLoadAll: false,
  };
  circleDetail: CircleItem | null = null;
  isFirstLoading: boolean = false;
  isLoading: boolean = false;
  listKey: string = '';
  dynamicList: DynamicItem[] = [];
  isJoing: boolean = false;
  circleIsClose: boolean = false;

  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  async mounted() {
    this.isFirstLoading = true;
    try {
      await this.getCircleDetailInfo();
      await this.getCircleDynamicListFun();
    } finally {
      this.isFirstLoading = false;
    }

    this.$emit('breadChange', {
      show: true,
      data: [
        { name: 'BT教育', path: '/' },
        { name: '校友圈主页', to: { path: '/circle' } },
        { name: '圈子详情页', path: this.$route.path },
      ],
    });
  }
  // 获取圈子详情
  async getCircleDetailInfo() {
    try {
      this.circleDetail = await getCircleDetail(this.circleId);
      this.$forceUpdate();
    } catch (e) {
      console.log('e', e);
      // @ts-ignore
      if (e.code == 2002) {
        this.circleIsClose = true;
      }
      throw new Error('error');
    }
  }

  scrollUpdate() {
    this.pagination.page += 1;
    this.getCircleDynamicListFun(true);
  }
  // 获取圈子动态列表
  async getCircleDynamicListFun(isScroll = false) {
    try {
      this.isLoading = true;
      const { page, limit } = this.pagination;
      const params = {
        page,
        limit,
        needCount: 1,
      };
      const { rows, listKey, count } = await getCircleDynamicList(this.circleId, params);
      this.listKey = listKey;
      if (isScroll) {
        this.dynamicList = this.dynamicList.concat(rows);
      } else {
        this.dynamicList = rows;
      }
      this.pagination.total = count;
      this.pagination.isLoadAll = (this.pagination.page + 1) * this.pagination.limit > count;
    } finally {
      this.isLoading = false;
    }
  }

  // 加入或退出圈子
  async joinCircleHandle({ id, isEntered }) {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      if (this.isJoing || !this.circleDetail) return;
      this.isJoing = true;
      if (isEntered) {
        this.$confirm('确定退出圈子吗？', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'circle-confirm-dialog',
        }).then(async () => {
          await quitCircle(id);
          if (this.circleDetail) {
            this.circleDetail['isEntered'] = 0;
          }
          this.getCircleDetailInfo();
          this.$message.success('操作成功');
        });
      } else {
        await joinCircle(id);
        this.circleDetail['isEntered'] = 1;
        this.getCircleDetailInfo();
      }
    } finally {
      this.isJoing = false;
    }
  }

  goCicleHome() {
    this.$router.replace({
      name: 'circle-index',
    });
  }
}
