import { request } from '@/utils/axios';

//最近话题列表
export function getRecentlyThread() {
  return request.get('/group/threads/recently', {}, { requestBase: 'phoenix' });
}

//小组话题列表
export function getGroupThread(data) {
  return request.get(`/group/${data.id}/threads`, data, { requestBase: 'phoenix' });
}

//创建话题
export function createThread(data) {
  return request.post(`/group/${data.groupId}/thread`, data, { requestBase: 'phoenix' });
}

//编辑话题
export function updateThread(data) {
  return request.post(`/group/${data.groupId}/thread/${data.threadId}`, data, { requestBase: 'phoenix' });
}

//话题详情
export function getThreadDetail(id) {
  return request.get(`/group/thread/${id}`, {}, { requestBase: 'phoenix' });
}

//话题回复列表
export function getThreadPosts(data) {
  return request.get(`/group/thread/${data.id}/posts`, { data }, { requestBase: 'phoenix' });
}

//发表回复
export function postThread(data) {
  return request.post(`/group/thread/${data.threadId}/post`, data, { requestBase: 'phoenix' });
}

//删除回复
export function deletePost(postId) {
  return request.delete(`/group/thread/post/${postId}`, {}, { requestBase: 'phoenix' });
}

//话题加精
export function setElite(id) {
  return request.post(`/group/thread/${id}/set-elite`, {}, { requestBase: 'phoenix' });
}

//话题取消加精
export function removeElite(id) {
  return request.post(`/group/thread/${id}/remove-elite`, {}, { requestBase: 'phoenix' });
}

//话题置顶
export function setStick(id) {
  return request.post(`/group/thread/${id}/set-stick`, {}, { requestBase: 'phoenix' });
}

//话题取消置顶
export function removeStick(id) {
  return request.post(`/group/thread/${id}/remove-stick`, {}, { requestBase: 'phoenix' });
}

//收藏话题
export function addThreadCollect(id) {
  return request.post(`/group/thread/${id}/collect`, {}, { requestBase: 'phoenix' });
}

//取消收藏话题
export function removeThreadCollect(id) {
  return request.delete(`/group/thread/${id}/collect`, {}, { requestBase: 'phoenix' });
}

//话题热门回复列表
export function getThreadHotPosts(data) {
  return request.get(`/group/thread/${data.id}/hot-posts`, data, { requestBase: 'phoenix' });
}

//话题回复子回复
export function getThreadChildPosts(data) {
  return request.get(`/group/thread/${data.threadId}/child-posts`, data, { requestBase: 'phoenix' });
}

// 圈子资料下载
export function downloadThread(params) {
  return request.throughMojaveApi({ url: `/attachment/${params.usedId}/download`, method: 'get', params });
}

// 导出圈子资料下载信息
export function exportThread(params) {
  return request.throughApi('data-analysis.im.exportGroupsMaterial', params);
}
