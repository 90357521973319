import bookErrataMain from '@/pc/views/book-errata/main.vue';
import bookErrataDetail from '@/pc/views/book-errata/pages/details.vue';
import bookErrataMy from '@/pc/views/book-errata/pages/my-errata.vue';

export default {
  path: '/book-errata',
  meta: { isPC: true },
  component: bookErrataMain,
  redirect: { name: 'book-errata-details' },
  children: [
    {
      path: 'book/:bookId',
      name: 'book-errata-details', // 教材修订-教材修订详情
      meta: { isPC: true, needLogin: true },
      props: router => ({
        bookId: Number(router.params.bookId),
        chapterId: Number(router.query.chapterId),
      }),
      component: bookErrataDetail,
    },
    {
      path: 'my',
      name: 'book-errata-my', // 教材修订-我的修订
      meta: { isPC: true, needLogin: true },
      props: router => ({
        bookId: Number(router.params.bookId),
        chapterId: Number(router.query.chapterId),
      }),
      component: bookErrataMy,
    },
  ],
};
