






















































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { trackEvent } from '@/utils/helper';
import NP from 'number-precision';
import { rouletteActGameLottery } from '@/api/raffle';

import TipsDialog from './dialog-tips.vue';
import BindMobile from './dialog-bind-mobile.vue';

@Component({
  components: {
    TipsDialog,
    BindMobile,
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class turntable extends Vue {
  @Prop({ type: Number, default: 0 }) activityId: any;
  @Prop({ type: Object, default: () => ({}) }) activityInfo; // 活动详情
  @Prop({ type: Array, default: () => [] }) prizeList;
  @Prop({ type: Number, default: 0 }) remainingFreeNum;
  @PropSync('myCoinNum', { type: Number, default: 0 }) myCoinNumSync;
  @Prop({ type: String, default: '' }) statusForUser: any; // 未开始/BEFORE_STARTED, 进行中/IN_PROGRESS, 已结束/AFTER_ENDED;

  isLogin: any;
  me: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  lotOption: any = {
    index: 0, // 当前转动到哪个位置，第一次起点位置0,对应页面item1位置
    times: 0, // 转动跑格子次数,
    cycle: 40, // 转动基本次数：即至少需要转动多少次再进入抽奖环节
    speed: 50, // 初始转动速度
    timer: null,
    timerPop: null, //抽奖之后停一下在弹出弹窗
    prizeIndex: 0, //抽中的奖励所在位置
    totalCount: 8, // 总共有多少奖
  };
  prizeLoading: boolean = false; // 是否正在获取抽奖数
  isDialogVisible: boolean = false; // 抽中奖品dialog
  isTipsDialogVisible: boolean = false; //提示奖品dialog
  isTipErrorDialogVisible: boolean = false; // 错误奖品dialog
  isBindDialogVisible: boolean = false; // 绑定手机号弹窗
  isPrizing: boolean = false; // 是否正在抽奖
  requestTimer: any = null; // 获取奖品定时函数
  requestIndex: any = {}; // 接口获取到的中奖index
  curLottery: any = {};
  noStartVisible: boolean = false;

  get isNone() {
    return this.curLottery.rewardType === 'none';
  }

  async getPrizeResult(isNeedCoin) {
    try {
      this.prizeLoading = true;
      let requestIndex = 0;
      let startTime = 0;
      if (this.requestTimer) {
        clearInterval(this.requestTimer);
        this.requestTimer = null;
      }
      this.requestTimer = setInterval(() => {
        startTime += 100;
        if (startTime >= 2000 && requestIndex) {
          console.log('赋值操作', requestIndex);
          this.lotOption.prizeIndex = requestIndex;
          this.lotOption.cycle = this.lotOption.times + 16;
          clearInterval(this.requestTimer);
          this.requestTimer = null;
          this.prizeLoading = false;
        }
      }, 100);
      const { prizeId } = await rouletteActGameLottery(this.activityId, { type: isNeedCoin ? 'COIN' : 'FREE' });

      this.curLottery = this.prizeList.find(item => item.id === prizeId);
      requestIndex = this.curLottery['seq'];
      console.log('requestIndex', prizeId);
    } catch (e) {
      if (e.hideNormalFail) {
        e.hideNormalFail();
      }
      this.prizeLoading = false;
      this.isPrizing = false;
      clearInterval(this.requestTimer);
      this.requestTimer = null;
      console.log('抽奖出错了，请重试', e);
      clearTimeout(this.lotOption.timer);
      this.lotOption.timer = null;
      if ([7007, 7008].includes(e.code)) {
        this.isTipErrorDialogVisible = true;
        return;
      } else {
        this.$message.warning(e.msg);
      }
      this.$emit('update');
    }
  }

  // 抽奖一次所需B豆
  get raffleCoin() {
    return (this.activityInfo && this.activityInfo.chanceInfo && this.activityInfo.chanceInfo.coin) || 5;
  }

  refreshHandle() {
    this.$emit('update');
    this.isTipErrorDialogVisible = false;
  }

  startHandle() {
    trackEvent('bt_10682', {
      description: '点击抽奖',
    });
    if (!this.isLogin) {
      // 判断是否登录，没登录的话先走登录流程
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    } else if (['BEFORE_STARTED', 'AFTER_ENDED'].includes(this.statusForUser)) {
      // 再判断状态
      if (this.statusForUser === 'AFTER_ENDED') {
        // 已结束
        this.$emit('goH5Page');
      } else {
        // 未开始
        this.noStartVisible = true;
        console.log('未开始');
      }
      return;
    } else if ((this.me.roles || '').includes('|ROLE_BT|')) {
      // 内部员工不参与抽奖
      this.$message.warning('内部员工不参与抽奖');
      return;
    } else if (!this.me.verifiedMobile) {
      // 手机号
      this.isBindDialogVisible = true;
      return;
    } else if (!this.remainingFreeNum) {
      // 免费抽奖
      this.isTipsDialogVisible = true;
      return;
    }
    this.rollStartHandle();
  }
  rollStartHandle(isNeedCoin = false) {
    if (isNeedCoin && this.myCoinNumSync >= 5) {
      this.myCoinNumSync = NP.minus(this.myCoinNumSync, this.raffleCoin);
    }
    this.isTipsDialogVisible = false;
    this.isPrizing = true;
    this.startRoll();
    this.getPrizeResult(isNeedCoin); // 获取中奖目标值
  }
  startRoll() {
    const { index, times, totalCount } = this.lotOption;
    this.lotOption.times = times + 1;
    this.lotOption.index = index + 1 > totalCount ? 1 : index + 1;
    this.rollHandle(); // 开始转动
  }

  rollHandle() {
    const { index, times, cycle, prizeIndex } = this.lotOption;
    if (!this.prizeLoading && times > cycle && prizeIndex === index) {
      clearTimeout(this.lotOption.timer); // 清除转动定时器
      this.lotOption.timer = null; // 重置定时器
      this.lotOption.speed = 50; // 重置转动速度
      this.lotOption.times = 0; // 重置已经转动的次数

      clearTimeout(this.lotOption.timerPop);
      this.lotOption.timerPop = setTimeout(() => {
        console.log('你中奖了');
        this.$emit('update');
        this.isPrizing = false;
        this.isDialogVisible = true;
      }, 500);
    } else {
      // 没在加载表示已经获取到了，该减速了
      if (!this.prizeLoading && this.lotOption.speed <= 500) {
        this.lotOption.speed += 15; // 拿到抽奖结果了，抽奖即将结束，放慢转动速度
      }
      this.lotOption.timer = setTimeout(this.startRoll, this.lotOption.speed); // 转动定时器
    }
  }

  dialogBtnHandle() {
    this.isDialogVisible = false;
    const { data, rewardType } = this.curLottery;
    if (rewardType === 'none') return;
    if (rewardType === 'coupon') {
      this.$emit('goCoupon', data.reward);
      // this.$emit('scroll');
    }
    // else if (data.reward.type === 'vip') {
    //   // @ts-ignore
    //   window.ysf('open');
    // }
    else {
      this.$emit('menuType', { type: 'prize' });
    }
  }

  // 去充值
  goRecharge() {
    window.open('/my/coin/pay_money');
    trackEvent('bt_10685', {
      description: '去充值',
    });
  }

  goTask() {
    this.isTipsDialogVisible = false;
    this.$emit('menuType', { type: 'task' });
  }
}
