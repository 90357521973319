window.JsBridge = {
  hasBridge: !!window.isSierra, // 这个值代表是否在BTapp中。 在安卓app中会马上置为true，ios中判断挂载了isSierra则马上置为true，没有isSierra则需要等JsBridge回调
  isInit: false,
  device: '',
  project: '',
  regHandlers: [],
  appVersion: '',
  isShowTitle: 0,
  init: function(callback) {
    if (this.device === 'ios') {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      const WVJBIframe = document.createElement('iframe');
      WVJBIframe.style.display = 'none';
      WVJBIframe.src = 'https://__bridge_loaded__';
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(function() {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    }
  },

  isExist: function() {
    const THIS = this;
    const u = navigator.userAgent;
    // @ts-ignore
    if (u.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
      THIS.device = 'wx';
    } else {
      // THIS.device = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 'ios' : 'android';
      THIS.device = /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ? 'ios' : 'android';
    }
    if (THIS.device === 'ios') {
      THIS.init(function(bridge) {
        bridge.callHandler('getRegisteredHandlers', '', function(resp) {
          THIS.isInit = true;
          THIS.hasBridge = true;
          THIS.regHandlers = resp;
        });
        bridge.callHandler('appUserAgent', null, (data) => {
          let agentData = JSON.parse(data);
          THIS.project = agentData && agentData.project && agentData.project.toLowerCase();
          THIS.appVersion = agentData && agentData.appVer;
        });
      });
    } else if (THIS.device === 'android') {
      if (window.AndroidJS) {
        THIS.isInit = true;
        THIS.hasBridge = true;
        THIS.regHandlers = Object.keys(window.AndroidJS);
        THIS.callHandler('appUserAgent', null, (data) => {
          let agentData = JSON.parse(data);
          THIS.project = agentData && agentData.project && agentData.project.toLowerCase();
          THIS.appVersion = agentData && agentData.appVer;
        });
      }
    }
  },

  registerHandler: function(name, fun) {
    const THIS = this;
    window[name] = fun;

    if (THIS.device === 'ios') {
      THIS.init((bridge) => {
        bridge.registerHandler(name, fun);
      });
    } else if (THIS.device === 'android') {
      // @ts-ignore
      window.JsBridge[name] = fun;
    }
  },

  callHandler: function(name, data, fun) {
    try {
      // console.log(this.device, name, data);
      const THIS = this;
      if (THIS.device === 'ios') {
        THIS.init((bridge) => {
          bridge.callHandler(name, data, (resp) => {
            // console.log('res', resp);
            fun && fun(resp);
          });
        });
      } else if (THIS.device === 'android') {
        let res = null;
        if (data !== null && data !== undefined) {
          let params = data;
          if (typeof data === 'object') {
            params = JSON.stringify(data);
          }
          res = window.AndroidJS && window.AndroidJS[name] && window.AndroidJS[name](params);
        } else {
          res = window.AndroidJS && window.AndroidJS[name] && window.AndroidJS[name]();
        }
        // console.log('res', res);
        if (fun) {
          fun(res);
        }
      }
    } catch (e) { console.error(e); }
  },
};

window.JsBridge.isExist();
