import { request } from '@/utils/axios';

//用户卡片 selfAjax
export function getUserCardInfo(id) {
  return request.get(`/user/${id}/card`, {}, { requestBase: 'phoenix' });
}

export function findPromoteTeachers(start = 0, limit = 5) {
  return request.get('/v2/users/promote-teachers', { start, limit }, { requestBase: 'user' });
}

// 根据id获取指定用户详情
export function getUserInfoById(userId) {
  return request.get(`/v2/users/${userId}/info`, {}, { requestBase: 'user' });
}

// 获取老师列表
export function getUserTeachers(params) {
  return request.get('/v2/user/teachers', params, { requestBase: 'user' });
}
