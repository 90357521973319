












































import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import * as SettingService from '@/api/settings';
import SendSmsCodeBtn from '@/pc/components/ui/send-sms-code-btn/SendSmsCodeBtn.vue';
// eslint-disable-next-line no-unused-vars
import { VeeValidateObserver } from '@/typings/vee-validate';
import { TextFieldWithValidation } from '@/utils/vee-validate/validate-fields';
// eslint-disable-next-line no-unused-vars
import { MeModel } from '@/typings';

@Component({
  components: {
    TextFieldWithValidation,
    ValidationProvider,
    ValidationObserver,
    SendSmsCodeBtn,
  },
  computed: {
    ...mapState('my', ['me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE', 'SET_RESET_PASSWORD_DIALOG_VISIBLE']),
  },
  filters: {
    mobileFormat: (val: string) => {
      return `${val.substring(0, 3)}-${val.substring(3, 7)}-${val.substring(7)}`;
    },
  },
})
export default class resetPassword extends Vue {
  me!: MeModel;
  SET_LOGIN_DIALOG_VISIBLE!: (boolean) => void;
  SET_RESET_PASSWORD_DIALOG_VISIBLE!: (boolean) => void;

  get mobile() {
    return this.me.verifiedMobile || this.form.mobile;
  }

  get isLogin() {
    return this.$login();
  }

  form = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    smscode: '',
    mobile: '',
  };

  async onSubmit() {
    const valid = await ((this.$refs.observer as any) as VeeValidateObserver).validate();
    if (!valid) {
      return;
    }
    try {
      let params: any = { smscode: this.form.smscode, password: this.form.newPassword, needVerify: 1 };
      if (this.isLogin) {
        await SettingService.resetUserPasswordLogin(params);
      } else {
        params.mobile = this.form.mobile;
        await SettingService.resetUserPassword(params);
      }

      this.SET_LOGIN_DIALOG_VISIBLE(true);
      setTimeout(() => {
        this.$message.success('密码重置成功');
        if (this.isLogin) {
          location.href = '/logout';
        }
      }, 300);
      // this.$router.push({ name: 'my_passwd' });
    } catch (e) {
      console.log('error---', e);
    }
  }

  gotoChangeMobile() {
    this.SET_RESET_PASSWORD_DIALOG_VISIBLE(false);
    this.$router.push({ name: 'setting_edit_mobile', params: { mobile: this.me.verifiedMobile, fromSetPasswd: '1' } });
  }
}
