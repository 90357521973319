import sessionStorage from './sessionStorage';
import localStorage from './localStorage';

export default class Cache {
  private storage?: any;
  public onWaitSecondsChange?: (msg: string) => void;
  public onStop?: (v: number) => void;

  /**
   * @param storageType 缓存类型 local/session
   */
  constructor(private storageType: string) {
    if (storageType === 'local') {
      this.storage = localStorage;
    } else if (storageType === 'session') {
      this.storage = sessionStorage;
    } else {
      console.error('缓存类型错误');
    }
  }

  public async query(key, fn) {
    if (!key) {return;}

    if (this.storage.has(key)) { // 缓存存在，返回内容
      return this.storage.get(key);
    }

    let data = await fn();
    if (data) {
      this.storage.set(key, data);
    }
    return data;
  }

  public flushall() {
    this.storage.clear();
  }

  public clearByKeys(keys) {
    for (let key of keys) {
      this.storage.del(key);
    }
  }
}
