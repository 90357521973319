import Vue from 'vue';

Vue.prototype.$globalClick = callback => {
  document.addEventListener(
    'click',
    e => {
      callback(e);
    },
    true
  );
};
