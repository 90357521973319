import { request } from '@/utils/axios';
// 需要登陆
export function getMyInfo(params = { attributes: 'approvalStatus,id,nickname,roles,smallAvatar,studentid,truename,verifiedMobile,wxname' }) {
  return request.get('/v2/users/info', params, { requestBase: 'user' });
}

export function getMyDropMenu() {
  return request.get('/drop-menu', {}, { requestBase: 'phoenix' });
}
// 获取我的 vip 信息
export function getMyVip() {
  return request.get('/v2/users/vip-info', {}, { requestBase: 'user' });
}

// 获取登录用户当前课程下可用的校友卡等级
export function getAvailableVip(courseId) {
  return request.get(`/v2/courses/${courseId}/users/mine/available-vip-level`, {}, { requestBase: 'course' });
}

export function getMyToken() {
  return request.get('/my/token', {}, { requestBase: 'phoenix' });
}

export function getMyPermissions() {
  return request.get('/my/permissions', {}, { requestBase: 'phoenix' });
}

// 获取我的未读消息
export function getUnreadNotifications() {
  return request.get('/v2/user/unread/notification', { who: 'all' }, { requestBase: 'dove', isAuth: true });
}

// 把消息设置为已读
export function readNotifications(params) {
  return request.patch('/v2/user/notice/read-status', { who: params }, { requestBase: 'dove' });
}

export function findNotifications(params) {
  let rParams = Object.assign({}, params);
  request.patch('/v2/user/notice/read-status', rParams, { requestBase: 'dove' });
  return request.get('/v2/user/notification', params, { requestBase: 'dove' });
}

// 通知列表点击数据收集
export function collectionListClick(params) {
  return request.post(`/v2/push/collection/list-click`, params, { requestBase: 'dove' });
  // return request.throughApi('ms-dove.v2.push.collectionListClick', params, { authKey: 'userId' });
}

// 通知栏点击数据收集
export function collectionBarClick(params) {
  return request.post(`/v2/push/collection/bar-click`, params, { requestBase: 'dove' });
  // return request.throughApi('ms-dove.v2.push.collectionBarClick', params, { authKey: 'userId' });
}

// 获取用户专属云考场的广告位配置
export function fetchCloudTestConfig(params) {
  return request.get('/exam/cloud-test/config', params, { requestBase: 'guser' });
}
