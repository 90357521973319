




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { getRecDoubts, getQuestionRecDoubts } from '@/api/ask';
import TransparentMode from './transparent-mode.vue';

@Component({
  components: {
    TransparentMode,
  },
})
export default class modulesRecommendAsk extends Vue {
  @Prop({ type: String, default: 'index' }) type: any;
  @Prop({ type: Number, default: 0 }) categoryId: any;
  @Prop({ type: Number, default: 0 }) questionId: any;
  @Prop({ type: Number, default: 0 }) doubtId: any;

  isLoading: boolean = false;
  list: any = [];
  count: number = 0;

  get watchAll() {
    return {
      type: this.type,
      categoryId: this.categoryId,
      questionId: this.questionId,
    };
  }

  @Watch('watchAll', { immediate: true, deep: true })
  async watchPage() {
    const case1 = this.type === 'index' && this.categoryId;
    const case2 = this.type === 'detail' && this.categoryId && this.doubtId;
    if (case1 || case2) {
      try {
        this.isLoading = true;
        const rows = await getRecDoubts({ categoryId: this.categoryId, doubtId: this.doubtId ? this.doubtId : undefined });
        this.list = rows.filter((item, index) => {
          return index < 5;
        });
        this.count = rows.length;
        console.log('推荐问答', rows);
      } catch (e) {
        this.list = [];
        console.log('推荐问答推荐失败', e);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.list = [];
    }
  }

  goAskDetail(item) {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-details', params: { doubtId: item.id } });
    window.open(link.href, '_blank');
  }

  goMore() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-index', query: { categoryId: this.categoryId } });
    window.open(link.href, '_blank');
  }
}
