<template>
  <div class="pc-home" v-cloak>
    <!-- 顶部banner -->
    <carousel></carousel>

    <!-- 六大学院 -->
    <category :data="categorys"></category>
    <!-- 排行榜 -->
    <grade-rank class="home-grade-rank-con" v-if="hasRank" :rankData="rankData"></grade-rank>
    <!-- 成绩展示模块 -->
    <!--    <grade-module></grade-module>-->
    <!-- 直播课版块 -->
    <live-com class="live-container"></live-com>
    <!-- 免费公开课 -->
    <course-open v-scrollWatch="{ name: 'mfgkk', offset: 150, callback: scroll }"></course-open>

    <!-- 新年免费课程 -->
    <course-free v-scrollWatch="{ name: 'free', offset: 150, callback: scroll }"></course-free>

    <!-- 推荐课程 -->
    <course-recommend v-scrollWatch="{ name: 'tjkc', offset: 150, callback: scroll }"></course-recommend>

    <!-- 分类课程 -->
    <template v-if="categorys && courseCategorys">
      <template v-for="(item, index) in courseCategorys">
        <course-model
          :key="index"
          :id="item.id"
          :code="item.parentCode"
          :serial="index"
          :course-categorys="courseCategorys"
          :categorys="categorys"
          v-scrollWatch="{ name: item.code, offset: 150, callback: scroll }"
        ></course-model>
      </template>
    </template>

    <!-- BT名师 -->
    <bt-teacher v-scrollWatch="{ name: 'btms', offset: 150, callback: scroll }"></bt-teacher>

    <!-- 社群 -->
    <bt-community v-scrollWatch="{ name: 'sq', offset: 150, callback: scroll }"></bt-community>

    <!-- Link -->
    <bt-friend-link></bt-friend-link>

    <!-- 侧边栏 -->
    <home-menu :scroll-to="scrollTo" :active="activeMenu" :course-categorys="courseCategorys" v-if="courseCategorys && hasMounted"></home-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import scrollWatch from '@/libs/vue-scrollwatch';
import GradeRank from '@/pc/components/grade-rank/index.vue';
import LiveCom from './components/live-com/index.vue';
import Carousel from './components/Carousel';
import Category from './components/Category';
import CourseOpen from './components/CourseOpen';
import CourseFree from './components/CourseFree';
import CourseRecommend from './components/CourseRecommend';
import CourseModel from './components/CourseModel';
import HomeMenu from './components/HomeMenu';
import BtTeacher from './components/BtTeacher';
import BtFriendLink from './components/FriendLink';
import BtCommunity from './components/BtCommunity';
// import GradeModule from './components/GradeModule';
// const options = require('../activity/anniversary/10th_mock_dev.json');
import { getActiveLinks } from '@/api/base';
// import './home.scss'

export default {
  name: 'Home',
  components: {
    GradeRank,
    // GradeModule,
    LiveCom,
    Carousel,
    Category,
    CourseOpen,
    CourseFree,
    CourseRecommend,
    CourseModel,
    HomeMenu,
    BtTeacher,
    BtCommunity,
    BtFriendLink,
  },
  data() {
    return {
      activeMenu: null,
      hasMounted: false,
      hasRank: false,
      rankData: {},
    };
  },
  async serverPrefetch() {
    return this.$store.dispatch('home/getCategoryTree');
  },

  // 先注释掉防止排行榜受周年庆影响
  created() {
    // this.getSetting();
  },

  computed: {
    ...mapState('home', {
      courseCategorys: state => state.courseCategorys,
      categorys: state => state.categorys,
    }),
  },
  mounted() {
    this.hasMounted = true;
    if (!this.categorys.length || !this.courseCategorys.length) {
      this.$store.dispatch('home/getCategoryTree');
    }
    window.scrollTo(0, 0);
  },
  methods: {
    // 隐藏排行榜
    // async getSetting() {
    //   try {
    //     // const localOptions = JSON.parse(JSON.stringify(options));
    //     // const visible = localOptions['visible'];
    //     // this.hasRank = visible;
    //     // this.rankData = localOptions['pc'].rankData;

    //     // 接口获取
    //     const { rows } = await getActiveLinks({ name: 'activity-10th-reserve' });
    //     const obj = JSON.parse(rows[0].value);
    //     const data = obj['pc'];
    //     const visible = obj['visible'];
    //     this.hasRank = visible;
    //     this.rankData = data.rankData;
    //   } catch (e) {
    //     console.log('获取setting配置失败');
    //   }
    // },
    scroll(node) {
      if (this.activeMenu != node.name) {
        this.activeMenu = node.name;
      }
    },
    scrollTo(name) {
      try {
        scrollWatch.scrollTo(name);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
// px2rem -u 51.2 home.css
@import url('./home.scss');
// @import url('./home.css');
// 不要删除下面的css,如果在下面新加了css就可以删除了 因为@import在最后一行会导致页面后端渲染失败
div {
  display: block;
}
</style>
<!-- <style>
html {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}
</style> -->
