






















































































import { Vue, Component } from 'vue-property-decorator';
import { getVersionInfoByAndroid } from '@/api/base';
import { getBrowser } from '@/utils/helper';
import ClipboardJS from 'clipboard';

@Component({
  components: {},
})
export default class DownloadApp extends Vue {
  isLoading: boolean = false;
  activeIndex: number = 1; // 活动tab的下标
  isWechat: boolean = false;
  announcements: any[] = [
    {
      id: 1,
      icon: 'https://app-cdn.btclass.cn/new-prefix/turtle/FhbN7d0JC0ShRoW5bO8VylGTxmni.png',
      activeIcon: 'https://app-cdn.btclass.cn/new-prefix/turtle/FvfK3iDXHb8Rp0kw9sQQJzH9xCkf.png',
      name: 'IOS客户端',
      type: 'ios',
      titleImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/FgmViHNjweVPvhbAUhYPoL_kNAMr.png',
      qrcode: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fv9kENVgvtZQ481RkShUg6BiOsct.png',
      rightConImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fsp3V1Iqw1UqKYShd7VvfPjPMa0B.png',
      downloadUrl: 'https://apps.apple.com/cn/app/id1207614274',
    },
    {
      id: 2,
      icon: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fs-p8QfZSAngUFOzuU_U7OkqvS2p.png',
      activeIcon: 'https://app-cdn.btclass.cn/new-prefix/turtle/FsuvE1HOkcnVni7JF5WmwhgT2PJy.png',
      name: '安卓客户端',
      type: 'android',
      titleImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fnsuh4vVq7Z_cvTgrtevbMbhTts6.png',
      qrcode: 'https://app-cdn.btclass.cn/new-prefix/turtle/FiGbVZQUiz4ASp9eoLjdf7mVrBvo.png',
      rightConImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fsp3V1Iqw1UqKYShd7VvfPjPMa0B.png',
      downloadUrl: 'https://turtle.btclass.cn/developer/bt-app-version/android?page=1&limit=10',
    },
    {
      id: 3,
      icon: 'https://app-cdn.btclass.cn/new-prefix/turtle/FpbcPtCLb-FwpP1k9suwyoFfnz4N.png',
      activeIcon: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fg7pPWWZO5TjW9qHYPR9tJkz30IR.png',
      name: 'iPad平板端',
      type: 'ipad',
      titleImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/FoET4kay2Z3KTR_tIVqkQoXv1Xpd.png',
      qrcode: 'https://app-cdn.btclass.cn/new-prefix/turtle/Frbb_vzq4z-PoLGv5UbalVPvVQd5.png',
      rightConImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fv-iKv0MWBhb0qFxkPgMsRkzj6CS.png',
      downloadUrl: 'https://apps.apple.com/cn/app/id1532195470',
    },
    {
      id: 4,
      icon: 'https://app-cdn.btclass.cn/new-prefix/turtle/FtJBbxy9DvMbabrRxNIt0KwYx65m.png',
      activeIcon: 'https://app-cdn.btclass.cn/new-prefix/turtle/FlbRf4JCMesweFY9JvY5QmzhMYjU.png',
      name: '微信小程序',
      type: 'applet',
      titleImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/FlDceCXqI3zsj3hoHj_sd8f6vgSJ.png',
      qrcode: 'https://app-cdn.btclass.cn/new-prefix/turtle/Fr6EijTksYGGl0sKwbvWYtOTb5TN.png',
      rightConImg: 'https://app-cdn.btclass.cn/new-prefix/turtle/FhsNcY1UmUu4IIQBIlPMRENz5kvm.png',
    },
  ];
  isDownloadDialogVisible: boolean = false;
  clipboard!: ClipboardJS;

  get hrefUrl() {
    return location.href;
  }

  get curData() {
    return this.announcements[this.activeIndex - 1] || {};
  }

  async created() {
    const { rows } = await getVersionInfoByAndroid({
      limit: 1,
      order: 'versionCode,DESC',
    });
    const { updateLink } = rows[0];
    this.announcements[1].downloadUrl = updateLink;
    this.isWechat = getBrowser(navigator.userAgent).wx;
    var clipboard = new ClipboardJS('#copy-button');
    clipboard.on('success', () => {
      this.$message.success('复制成功，请前往默认浏览器');
    });
    clipboard.on('error', function (e) {
      console.log('Error:', e.text);
    });
  }

  mouseenter(index) {
    this.activeIndex = index;
  }
  mouseleave() {}

  openUrl() {
    if (this.isWechat && this.curData && this.curData.type && ['ipad', 'ios'].includes(this.curData.type)) {
      this.isDownloadDialogVisible = true;
      return;
    } else {
      window.open(this.curData.downloadUrl);
    }
  }
}
