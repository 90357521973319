

































import { Vue, Component } from 'vue-property-decorator';
import { getMoreRecommendCircle, quitCircle, joinCircle } from '@/api/circle';
import { CircleItem } from '@/typings';
import { mapGetters, mapMutations } from 'vuex';

@Component({
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class RecomandCircle extends Vue {
  circleList: CircleItem[] = [];
  isJoing: boolean = false;

  me: any;
  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  async mounted() {
    try {
      this.circleList = (await getMoreRecommendCircle()) || [];
    } catch (e) {
      this.circleList = [];
    }
  }

  async joinCircleHandle(circleData) {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      const { id, isEntered } = circleData;
      if (this.isJoing) return;
      this.isJoing = true;
      if (isEntered) {
        this.$confirm('确定退出圈子吗？', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'circle-confirm-dialog',
        }).then(async () => {
          await quitCircle(id);
          circleData['isEntered'] = 0;
          this.$message.success('操作成功');
          this.$emit('refresh');
        });
      } else {
        await joinCircle(id);
        circleData['isEntered'] = 1;
      }
    } finally {
      this.isJoing = false;
      this.$emit('joinUpdate');
    }
    console.log('circleData', circleData);
  }

  // 跳转更多圈子
  goMoreCircle() {
    const { href } = this.$router.resolve({
      name: 'more-circle',
    });
    window.open(href);
  }

  goCircleDetail(circleId) {
    const { href } = this.$router.resolve({
      name: 'circle-detail',
      query: {
        circleId: circleId + '',
      },
    });
    window.open(href);
  }
}
