





















import { Vue, Component, Prop } from 'vue-property-decorator';
import { getCourseListForUser } from '@/api/course';
import { trackEvent } from '@/utils/helper';

@Component
export default class VipCom extends Vue {
  @Prop() courseIds!: number[];
  @Prop() url!: string;
  courses: any[] = [];

  getOriginPrice(course) {
    let buyingInfo = course.buyingInfo;
    if (buyingInfo && buyingInfo.originIntervalPrice[0]) {
      return buyingInfo.originIntervalPrice[0];
    }
    return course.originPrice;
  }

  async created() {
    let { rows } = await getCourseListForUser({ids: this.courseIds.join(','), needPriceDetail: 1});
    rows.sort((a, b) => {
      return this.courseIds.indexOf(a.id) - this.courseIds.indexOf(b.id);
    });
    this.courses = rows;
  }

  vipCourseClick({ id, title }) {
    trackEvent('bt_10676', {
      courseId: id,
      courseTitle: title,
    });
  }
}
