














































import { Vue, Component, Prop } from 'vue-property-decorator';
import AskClassFilter from '../components/modules/ask-class-filter.vue';
import ToAsk from '../components/modules/to-ask.vue';
import MyAskFavorite from '../components/modules/my-ask-favorite.vue';
import Operating from '../components/modules/operating.vue';
import RecommendAsk from '../components/modules/recommend-ask.vue';
import RecommendCourse from '../components/modules/recommend-course.vue';
import AskItem from '../components/modules/ask-item.vue';
import MainLoading from '../components/modules/main-loading.vue';
import LoadingIcon from '../components/modules/loading-icon.vue';

import { getCategoryTree, getDoubtListByPC, getCfgValids } from '@/api/ask';

@Component({
  components: {
    AskClassFilter,
    ToAsk,
    MyAskFavorite,
    Operating,
    RecommendAsk,
    RecommendCourse,
    AskItem,
    MainLoading,
    LoadingIcon,
  },
})
export default class AskIndex extends Vue {
  defaultOption: any = {
    categoryId: 0, // 默认
    createTimes: [], // 默认时间
  };

  projectId: number = 0; // 一级
  categoryId: number = 0; // 二级科目分类Id
  categoryTree: any = [];
  createTimes: any = [];

  initLoading: boolean = true; // 初始化loading
  isLoading: boolean = false; // 请求数据的loading
  pagination: any = {
    page: 1,
    limit: 10,
    total: 0,
  };
  askList: any = [];
  marginBottom: number = 60;

  cgfInfo: any = [];

  async created() {
    this.setInit();
    await this.getBtCategoryTree();
    await this.getList(true);
  }

  async mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    // 在组件销毁前移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  }

  // 监听滚动
  async handleScroll() {
    const element = document.documentElement;
    const scrollVal = element.scrollHeight - element.scrollTop;
    const bottomVal = element.clientHeight + 352 + this.marginBottom;
    const needLoading = this.askList.length < this.pagination.total;
    // 滚动到指定距离且未在加载数据且总数据还没加载完
    if (scrollVal <= bottomVal && !this.isLoading && needLoading) {
      this.pagination.page = this.pagination.page + 1;
      await this.getList();
      console.log('触底加载');
    }
  }

  setInit() {
    // 设置面包屑导航
    this.$bus.$emit('setAskBreadCrumb', {
      show: true,
      data: [{ name: 'BT教育' }, { name: '问答主页' }],
    });
    // 布局初始化
    this.$bus.$emit('setlayout', { openFlex: false, mainWidth: '1080px' });
  }

  async getBtCategoryTree() {
    let categoryIdArr: any = [];
    this.categoryTree = ((await getCategoryTree({})) || []).filter(item => {
      return item.doubtShow;
    });
    this.categoryTree.forEach((item: any) => {
      item.children = item.children.filter((child: any) => {
        if (child.doubtShow) {
          categoryIdArr.push(child.id);
        }
        return child.doubtShow;
      });
    });
    // 路由有值，优先用路由的
    const { categoryId } = this.$route.query;
    this.categoryId = Number(categoryId) || 0;
    this.defaultOption.categoryId = Number(categoryId) || 0;
    const noCategoryId = !categoryIdArr.includes(this.categoryId);
    // 没有默认区第一个分类的第一个
    if (!this.categoryId || noCategoryId) {
      this.defaultOption.categoryId = this.categoryTree[0].children[0].id;
      this.categoryId = this.categoryTree[0].children[0].id;
    }
    console.log('===获取问答分类树', this.categoryTree, this.defaultOption, this.categoryId);
  }

  // 筛选器修改
  async filterChange(data) {
    console.log('filterChange', data);
    this.categoryId = data.categoryId;
    this.projectId = data.projectId;
    // 重新获取列表数据
    if (data.update) {
      await this.getList(true);
    }
  }

  // 获取列表数据
  async getList(reset: boolean = false) {
    try {
      this.isLoading = true;
      // 重置
      if (reset) {
        this.initLoading = true;
        this.pagination.page = 1;
        this.askList = [];
      }
      const params: any = {
        categoryId: this.categoryId,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        needCount: 1,
      };
      this.$router.replace({ name: 'ask-index', query: { categoryId: this.categoryId + '' } });
      const [{ count, rows }, cgfInfo] = await Promise.all([
        getDoubtListByPC(params),
        getCfgValids({
          type: 9,
          categoryId: this.categoryId,
        }),
      ]);
      this.cgfInfo = cgfInfo;
      this.pagination.total = count;
      if (reset) {
        this.askList = rows;
      } else {
        // @ts-ignore
        this.askList = [...this.askList, ...rows];
      }
      console.log('===首页列表===', params, { count, rows });
      console.log('===运营位配置===', cgfInfo);
    } catch (e) {
      console.log('列表获取失败', e);
    } finally {
      this.isLoading = false;
      this.initLoading = false;
    }
  }
}
