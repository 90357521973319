
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { trackEvent } from '@/utils/helper';
import ActImgCom from './act-img-com.vue';

@Component({
  components: {
    ActImgCom,
  },
})
export default class ActCom extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data;
  swiper: any;
  get imgs() {
    return this.data.pictureList;
  }
  userAgent = navigator.userAgent;
  isIE = this.userAgent.indexOf('Trident') > -1 && this.userAgent.indexOf('rv:11.0') === -1; // ie & !ie11
  swiperOption = {
    loop: true,
    width: window.innerWidth,
    grabCursor: true,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    effect: this.isIE ? 'slide' : 'fade',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      init() {
        // @ts-ignore
        this.params.width = window.innerWidth;
        // @ts-ignore
        this.update();
      },
      resize() {
        // @ts-ignore
        this.params.width = window.innerWidth;
        // @ts-ignore
        this.update();
      },
      slideChange: () => {
        // this.setImgSrc();
      },
    },
  };

  // 根据轮播图位置加载图片
  // setImgSrc() {
  //   if (!this.swiper) return;
  //   let activeIndex = this.swiper.activeIndex;
  //   let slideEl = this.swiper.slides[activeIndex];
  //   let bannerImgEl = slideEl.getElementsByClassName('img-responsive')[0];
  //   let src = bannerImgEl.getAttribute('src');
  //   if (!src) {
  //     bannerImgEl.setAttribute('src', bannerImgEl.getAttribute('data-src'));
  //   }
  // }

  goHandle({ href, qrDialog, eventId, description }) {
    trackEvent(`bt_${eventId}`, { description });
    if (href) {
      window.open(href);
    } else if (qrDialog) {
      this.$emit('dialog', qrDialog);
    }
  }
  async mounted() {
    // this.setImgSrc();
  }
}
