
















































import { Vue, Component, Prop } from 'vue-property-decorator';
import AskItem from '../components/modules/ask-item.vue';

import { searchDoubtListByPC } from '@/api/ask';
// import { fetchDoubtSearch } from '@/api/search.ts';
import MainLoading from '../components/modules/main-loading.vue';
import Loading from '@/pc/views/search/components/loading.vue';

@Component({
  components: {
    AskItem,
    MainLoading,
    Loading,
  },
})
export default class AskSearch extends Vue {
  @Prop({ type: String, default: '' }) searchText: any;
  @Prop({ type: Number, default: 1 }) page: any;
  @Prop({ type: Number, default: 10 }) limit: any;
  @Prop({ type: String, default: '' }) from!: string;
  // 在PC搜索页面引用
  get fromSearch() {
    return this.from === 'search';
  }
  get htmlText() {
    return !this.fromSearch
      ? '抱歉，没有找到相关结果，您可尝试去提问'
      : `
        抱歉，暂无结果 <br/>
        可前往问答中心提问
        `;
  }
  isloading: boolean = false;
  searchForm: any = {
    content: '',
  };
  pagination: any = {
    // 分页器数据
    total: 0,
    page: 1,
    limit: 10,
  };
  activeTab: any = 'ask';
  resultList: any = [];

  async created() {
    const { searchText, page, limit } = this;
    this.searchForm.content = searchText;
    this.pagination.page = page;
    this.pagination.limit = limit;
    if (searchText) {
      await this.searchList(true);
    }
  }
  // 提供给pc搜索那边调用
  search() {
    this.searchList(true);
  }
  // 搜索列表
  async searchList(reset = false) {
    if (!this.searchForm.content) {
      this.$message.warning('请输入搜索内容');
      return;
    }
    this.isloading = true;
    if (reset) {
      this.pagination.page = 1;
    }
    const params: any = {
      content: !this.fromSearch ? this.searchForm.content : this.searchText, // 搜索内容
      page: this.pagination.page - 1, // 页数
      limit: this.pagination.limit, // 每页条数
      needCount: 1, // 是否需要总数量
    };
    if (!this.fromSearch) {
      this.$router.replace({
        name: 'ask-search',
        query: {
          searchText: encodeURIComponent(this.searchForm.content),
          page: this.pagination.page,
          limit: this.pagination.limit,
        },
      });
    }
    try {
      const { count, rows } = await searchDoubtListByPC(params);
      this.pagination.total = count;
      this.resultList = rows;
      console.log('搜索列表', { count, rows });
    } catch (e) {
      console.log('搜索列表失败', e);
    } finally {
      this.isloading = false;
    }
  }

  // 切换页数
  async handleCurrentChange(val) {
    this.pagination.page = val;
    await this.searchList();
    console.log('切换页数', val, this.pagination.page);
  }

  // 去提问
  goCreateAsk() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-create', query: { form: this.$route.name } });
    window.open(link.href, '_blank');
    console.log('去提问', this.searchText);
  }
}
