























import { Vue, Component, Prop } from 'vue-property-decorator';
import { getBrowser, jump } from '@/utils/helper';

@Component
export default class ImgCom extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data;

  get isWeixin() {
    return getBrowser(navigator.userAgent).wx;
  }

  // 跳转访问链接
  toLink(data) {
    console.log('点击触发跳转');
    if (!data.jumpUrlToWx || !data.jumpUrlToNotWx) return;
    console.log('jump-data', data);
    const result = jump(data);
    if (result && result.type === 'dialog') {
      this.$emit('dialog', result);
    }
    console.log('result', result);
  }

  //获取热区所占百分比
  getPersent(val, total) {
    if (!total) return val + 'px';
    const result = Math.round((val / total) * 10000) / 100.0;
    return result > 100 ? 100 : result + '%';
  }
}
