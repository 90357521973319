import { extend } from 'vee-validate';
import { required, max, confirmed, min } from 'vee-validate/dist/rules.js';
import zh from 'vee-validate/dist/locale/zh_CN.json';
import IDValidator from 'id-validator';
import GB2260 from 'id-validator/src/GB2260';


const Validator = new IDValidator(GB2260);

extend('required', {
  ...required,
  // @ts-ignore
  message: zh.messages.required
});


extend('min', {
  ...min,
  // @ts-ignore
  message: zh.messages.min
});

extend('max', {
  ...max,
  // @ts-ignore
  message: zh.messages.max
});

extend('ID', {
  validate: (value: string, params: any[] | Record<string, any>) => {
    return Validator.isValid(value);
  },
  message: '身份证格式错误',
});

extend('passwdConfirmed', {
  ...confirmed,
  message: '两次输入密码不一致',
});

extend('phone', {
  validate: (value: string, params: any[] | Record<string, any>) => {
    return /^1[3456789]\d{9}$/.test(value);
  },
  message: '手机号格式错误',
});

