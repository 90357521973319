




























































import { Vue, Component, Prop } from 'vue-property-decorator';
import scrollWatch from '@/libs/vue-scrollwatch';
import Shuffle from './components/shuffle.vue';
import SideBar from './components/sidebar.vue';
import ActCoupons from './components/act-coupons.vue';
import NormalCourses from './components/normal-course.vue';
import ActLiveCom from './components/act-live-com/index.vue';
import ActImgCom from './components/act-img-com.vue';
import RaffleModule from '@/pc/views/raffle/activity.vue';
import ActDialog from './components/act-dialog.vue';
import { getAnnouncementsByPage } from '@/api/course';
import { getPageData } from '@/api/activity';
import { jump } from '@/utils/helper';
import TipsDialog from './components/tips-dialog.vue';

@Component({
  components: {
    Shuffle,
    ActCoupons,
    ActImgCom,
    NormalCourses,
    ActLiveCom,
    RaffleModule,
    ActDialog,
    SideBar,
    TipsDialog,
  },
})
export default class ActCom extends Vue {
  @Prop({ type: Number, default: 350 }) pageId;
  headHeight: number = 80;
  $cookie: any;
  actDailogVisible: boolean = false;
  dialogData: any = {};
  activeBlock: string = '';

  pageData: any = {
    globalConfig: {},
    items: [],
  };

  dialogVisible: boolean = false;
  dialogOptions: any = {
    title: '',
    content: '',
  };

  openDialog(dialogOptions) {
    this.dialogVisible = true;
    this.dialogOptions = dialogOptions;
  }

  // 左侧悬浮导航栏
  get menus() {
    return this.pageData.items.filter(item => item.content.menuName).map(item => ({ code: item.id, name: item.content.menuName })) || [];
  }
  mounted() {
    this.init();
  }
  async getPageSetting() {
    const pageInfo = (await getPageData(this.pageId)) || {};
    document.title = pageInfo.title;
    console.log('===pageInfo===', pageInfo);
    const { items, globalConfig } = pageInfo;
    this.pageData.items = items || [];
    this.pageData.globalConfig = globalConfig || {};
  }
  async init() {
    this.getPageSetting();
    await this.$nextTick();
    setTimeout(async () => {
      // scrollToName 滚动到对应的模块
      let { scrollToName } = this.$route.query;
      if (scrollToName) {
        setTimeout(() => {
          this.scrollTo(scrollToName);
          this.$router.replace({ query: {} });
        }, 1000);
      }
      // @ts-ignore
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.headHeight = (entry.target as HTMLElement).offsetHeight || 80;
        }
      });
      const domHead = document.querySelector('.bt-header');
      // 对进入页面还没有头部dom的问题进行兼容处理
      if (!domHead) {
        let isRead = this.$cookie.get('close_announcements_alert');
        if (!isRead) {
          const { announcements } = await getAnnouncementsByPage({ targetType: 'global' });
          if (announcements && announcements.length > 0) {
            this.headHeight = 118;
          }
        }
      } else {
        resizeObserver.observe(domHead);
      }
    }, 300);
  }
  scrollTo(name) {
    console.log('===滚动对应模块===', name);
    try {
      scrollWatch.scrollTo(name);
    } catch (error) {
      console.log(error);
    }
  }

  // 打开链接
  goLink(link) {
    window.open(link);
  }

  // 打开客服
  showChat() {
    // @ts-ignore
    window.ysf('open');
  }

  scrollHandle(node) {
    this.activeBlock = node.name;
  }

  dialogHandle(dialogData) {
    this.dialogData = dialogData;
    this.actDailogVisible = true;
    console.log('触发弹窗', dialogData);
  }

  jumpHandle(data) {
    jump(data);
  }
}
