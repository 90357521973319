











import { Vue, Component } from 'vue-property-decorator';
import AddressParse from 'address-parse';

@Component({})
export default class AiAddress extends Vue {
  address = '';

  onAiClick() {
    const [result] = AddressParse.parse(this.address);
    this.$message.success('请检查拆分信息是否准确，如有遗漏请手动补充');
    this.$emit('ai', result);
  }
}
