









import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

import { getCategoryTree } from '@/api/ask';

@Component({})
export default class modulesCategorySeletc extends Vue {
  @PropSync('categoryId', { type: [Number, String], default: '' }) syncCategoryId: any;
  @Prop({ type: Boolean, default: false }) disabled: any;
  categoryTree: any = [];
  categorys: any = [];
  categorysMap: any = {};

  async created() {
    try {
      this.categoryTree = ((await getCategoryTree({})) || []).filter(item => {
        return item.doubtShow;
      });
      let categorys: any = [];
      let categorysMap: any = {};
      this.categoryTree.forEach(item => {
        (item.children || []).forEach(items => {
          categorys.push({
            ...items,
            longName: `${item.name} · ${items.name}`,
          });
          categorysMap[items.id] = items;
        });
      });
      this.categorys = categorys;
      this.categorysMap = categorysMap;
      this.$emit('setCategorys', categorysMap);
      console.log('===获取问答分类树===', this.categorys);
    } catch (e) {
      console.log('获取问答分类树失败', e);
    }
  }

  change() {
    console.log('1111');
    this.$emit('change', this.syncCategoryId);
  }
}
