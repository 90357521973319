import { RouteConfig } from 'vue-router';

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/index.vue'),
    children: [
      {
        path: '/my-teaching',
        name: 'my-teaching',
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          showHeaderShadow: true,
          isFlex: true,
          activeMenuName: 'my-teaching',
          title: '我的教学',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/teaching/index.vue'),
      },
      {
        path: '/learning-center',
        name: 'learning-center',
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          showHeaderShadow: true,
          isFlex: true,
          activeMenuName: 'my-task',
          title: '学习计划',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/task/index.vue'),
      },
      {
        path: '/my/quiz',
        name: 'tiku-home',
        redirect: '/my/quiz/index',
        meta: { title: '我的题库' },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/tiku/entry.vue'),
        children: [
          {
            path: '/my/quiz/index',
            name: 'tiku-index',
            meta: {
              isPC: true,
              needLogin: true,
              hideFooter: true,
              showHeaderShadow: true,
              isFlex: true,
              activeMenuName: 'my-tiku',
              title: '我的题库',
            },
            component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/tiku/index.vue'),
            props: router => ({
              categoryId: Number(router.query.categoryId),
            }),
          },
          {
            path: '/my/quiz/:categoryId/:type(homework|checkpoint|phaseTest|key|simulation|mistake|favorite|record|ai|cloudTest|topic)',
            name: 'tiku-type',
            component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/tiku/type.vue'),
            meta: {
              isPC: true,
              needLogin: true,
              hideFooter: true,
              showHeaderShadow: true,
              isFlex: true,
              activeMenuName: 'my-tiku',
              title: '我的题库',
            },
            props: router => ({
              categoryId: Number(router.params.categoryId),
              type: router.params.type,
            }),
          },
        ],
      },
      {
        path: '/my/courses',
        name: 'my-course',
        redirect: '/my/courses/learning',
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/courses/CourseModel.vue'),
        children: [
          {
            path: '/my/courses/learning',
            name: 'my-course-learning',
            meta: {
              isPC: true,
              needLogin: true,
              hideFooter: true,
              showHeaderShadow: true,
              isFlex: true,
              activeMenuName: 'my-course',
              title: '我的课程',
            },
            component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/courses-learning/index.vue'),
          },
          {
            path: '/my/courses/deadline',
            name: 'my-course-deadline',
            meta: {
              isPC: true,
              needLogin: true,
              hideFooter: true,
              showHeaderShadow: true,
              isFlex: true,
              activeMenuName: 'my-course',
              title: '我的课程',
            },
            component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/courses-deadline/index.vue'),
          },
          {
            path: '/my/courses/hidden',
            name: 'my-course-hidden',
            meta: {
              isPC: true,
              needLogin: true,
              hideFooter: true,
              showHeaderShadow: true,
              isFlex: true,
              activeMenuName: 'my-course',
              title: '我的课程',
            },
            component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/courses-hidden/index.vue'),
          },
          {
            path: '/my/courses/activation',
            name: 'my-course-activation',
            meta: {
              isPC: true,
              needLogin: true,
              hideFooter: true,
              showHeaderShadow: true,
              isFlex: true,
              title: '我的课程',
            },
            component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/courses-activation/index.vue'),
          },
          {
            path: '/my/courses/favorited',
            name: 'my-course-favorited',
            meta: {
              isPC: true,
              needLogin: true,
              hideFooter: true,
              showHeaderShadow: true,
              isFlex: true,
              activeMenuName: 'my-course',
              title: '我的课程',
            },
            component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/courses-favorited/index.vue'),
          },
        ],
      },
      {
        path: '/my/notebooks',
        name: 'my-notebooks',
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          showHeaderShadow: true,
          isFlex: true,
          activeMenuName: 'my-note',
          title: '我的笔记',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/notebooks/index-new.vue'),
      },
      {
        path: '/my/notebooks/:courseId',
        name: 'my-notebooks-detail',
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          showHeaderShadow: true,
          isFlex: true,
          activeMenuName: 'my-note',
          title: '上课笔记',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/notebooks-detail/index.vue'),
        props: router => ({
          courseId: Number(router.params.courseId),
        }),
      },
      {
        path: '/my/question-notebooks/:confusionId',
        name: 'my-question-notebooks-detail',
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          showHeaderShadow: true,
          isFlex: true,
          activeMenuName: 'my-note',
          title: '做题笔记',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/question-notebooks-detail/index.vue'),
        props: router => ({
          confusionId: Number(router.params.confusionId),
        }),
      },
      {
        path: '/learning-record',
        name: 'learning-record',
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          showHeaderShadow: true,
          isFlex: true,
          activeMenuName: 'my-learning-record',
          title: '听课记录',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/learn_center/learning-record/index.vue'),
      },
      {
        path: '/book-errata/list', // 教材修订-列表页
        name: 'book-errata-list',
        meta: {
          isPC: true,
          needLogin: true,
          hideFooter: true,
          isFlex: true,
          activeMenuName: 'book-correction',
          title: '教材修订',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/book-errata/list.vue'),
      },
    ],
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/task-homework/HomeWorkCommon.vue'),
    meta: { isPC: true },
    children: [
      {
        path: '/task/homework/:homeworkId',
        name: 'task-homework',
        meta: {
          isPC: true,
          needLogin: true,
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/task-homework/index.vue'),
        props: router => ({
          homeworkId: Number(router.params.homeworkId),
          type: router.query.type || '',
          taskId: Number(router.query.taskId || 0),
        }),
      },
      {
        path: '/task/homework/:homeworkId/review_detail',
        name: 'task-homework-review-detail',
        meta: {
          isPC: true,
          needLogin: true,
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/task-homework/ReviewDetail.vue'),
        props: router => ({
          homeworkId: Number(router.params.homeworkId),
          type: router.query.type || '',
          taskId: Number(router.query.taskId || 0),
          targetUserId: Number(router.query.targetUserId || ''),
        }),
      },
      {
        path: '/task/homework/:homeworkId/complete',
        name: 'task-homework-complete',
        meta: {
          title: '作业任务',
        },
        component: () => import(/* webpackChunkName: "learn_center" */ '@/pc/views/task-homework/complete.vue'),
        props: router => ({
          homeworkId: Number(router.params.homeworkId),
          type: router.query.type || '',
          taskId: Number(router.query.taskId || 0),
        }),
      },
    ],
  },
] as RouteConfig[];
