import { request } from '@/utils/axios';

import { CourseProtocol, SaveFeedbackPostData } from '@/pc/typings';

type DownloadKeyQuery = {
  originCourseId: number;
  courseIds: string;
  materialIds: string;
  packageName: string;
};

type MaterialDownloadInfo = {
  downloadUrl: string;
  hash: string;
  key: string;
  persistentId: string;
};

// 获取HTML
export function getLinkHtml(url: string) {
  return request.get<string>(url, {}, { withCredentials: false });
}

// 课程详情，这个不能替换是因为里头加了favoriteInfo
export function getCourse(id) {
  return request.get(`/course/${id}`, {}, { requestBase: 'phoenix' });
}

// 课程详情V2
export function getCourseForUser(id, params = {}) {
  return request.get(`/v2/course/${id}/for-user`, { ...params, needFavorite: 1 }, { requestBase: 'course' });
}

export function getCourseTeachers(courseId) {
  return request.get(`/v2/courses/${courseId}/teachers`, {}, { requestBase: 'course' });
}
// :todo 是否登录
// 根据课程ids获取课程列表
export function getCourseListByIds(ids) {
  return request.get('/v2/courses', { ids: ids.join(','), limit: ids.length }, { requestBase: 'course' });
}
// ：todo 是否登录
// 获取课程列表
export function getCourseList(params) {
  params.ns = 'bt';
  return request.get('/v2/courses', params, { requestBase: 'course' });
}

// 获取课程列表（new）
export function getCourseListForUser(params) {
  params.ns = 'bt';
  return request.get('v2/course/for-user', params, { requestBase: 'course' });
}

/* 课程课时学习 */

// 获取课时详情
export function getLesson(lessonId, params = {}) {
  return request.throughMojaveApi({
    url: `/lessons/${lessonId}?from=PC`,
    params,
    method: 'get',
  });
}

// 获取直播播放地址
export function getCcLiveUri(lessonId) {
  return request.get(`/course/lesson/${lessonId}/cc-live-uri`, {}, { requestBase: 'phoenix' });
}

// 最近学员
export function getRecentMembers(id, role = 'student') {
  return request.get(`/v2/courses/${id}/recent-members`, { role }, { requestBase: 'course' });
}

// 学员动态
export function getStudentStatus(page, limit, courseId) {
  const params: any = {
    page,
    limit,
  };
  if (courseId) {
    params.courseId = courseId;
  }
  return request.get('/course/users/list/status', params, { requestBase: 'phoenix' });
}

// 根据课程id获取item列表
export function getCourseItemsByCourseId(id, params = {}) {
  return request
    .throughMojaveApi({
      url: `/courses/${id}/lessons`,
      params,
    })
    .then(data => {
      return data.lessons;
    });
}

// 获取课程专题
export function getCourseSubjectsByCourseId(id, topLessonNum) {
  return request.get(`/v2/courses/${id}/subjects`, { status: 'published', topLessonNum, needTeacherName: 1, needPaperStatus: 1 }, { requestBase: 'course' });
}

//  确认课程
export function confirmCombineCourse(id, subjectIds) {
  return request.post('/v2/course-member/confirm-combine', { courseId: id, subjectIds }, { requestBase: 'course' });
}

// 收藏课程
export function addFavorite(courseId) {
  return request.post('/v2/course-favorites', { courseId }, { requestBase: 'course' });
}

// 取消收藏课程
export function cancelFavorite(id) {
  return request.delete(`/v2/course-favorites/${id}`, {}, { requestBase: 'course' });
}
// 获取用户收藏的列表
export function getUserFavoriteCourseList(params) {
  return request.get('/v2/users/courses/favorite', params, { requestBase: 'course' });
}

// 获取标签路径 | 分类详情
export function getCategoryPath(id) {
  return request.get(`/v2/categorys/${id}/path`, {}, { requestBase: 'course' });
}

// 新增公告
export function addAnnouncement(params) {
  return request.post('/v2/announcements', params, { requestBase: 'course' });
}

// 获取公告分页列表
export function getAnnouncementsByPage(data) {
  return request.get('/v2/announcements/page', data, { requestBase: 'course' });
}

// 编辑公告
export function editAnnouncement(id, data) {
  return request.post(`/v2/announcements/${id}`, data, { requestBase: 'course' });
}

// 删除公告
export function deleteAnnouncement(id) {
  return request.delete(`/v2/announcements/${id}`, {}, { requestBase: 'course' });
}

/**
 * 获取课程下的保过协议
 * @param id 课程Id
 */
export async function getInsuranceProtocolInfo(id): Promise<CourseProtocol> {
  return request.get(`/v2/course/${id}/insurance-protocol/info`, {}, { requestBase: 'course' });
}

/**
 * 新增用户协议
 */
export function addUserCourseInsuranceProtocol(params, protocolId) {
  return request.post(`/v2/course/insurance-protocol/${protocolId}/user`, params, { requestBase: 'course' });
}

/**
 * 保存课时评价
 */
export function addFeedBack(data: SaveFeedbackPostData) {
  return request.post('/v2/feedbacks/new', data, { requestBase: 'course' });
}

// 获取用户课时评价
export function getUserLessonFeedback(lessonId) {
  return request.get(`/v2/feedback/users/lesson/${lessonId}`, { version: 5 }, { requestBase: 'course' });
}

// 获取课时反馈标签列表
export function getLessonFeedbackTagList() {
  return request.get('/v2/feedback/tags/new', {}, { requestBase: 'course' });
}

/**
 * 获取购课引导页信息
 */
export function getBuyingGuideInfo(tplId: number = 0, courseId: number = 0) {
  const params: any = {};
  if (tplId) {
    params.tplId = tplId;
  }
  if (courseId) {
    params.courseId = courseId;
  }
  return request.throughMojaveApi({
    url: '/guide-template-page',
    params,
  });
}

/**
 * 用户批量分期选择
 */
export function bindAssistantPhases(data) {
  return request.throughMojaveApi({
    url: '/course-assistant/user/bind-phases',
    method: 'post',
    data,
  });
}

/**
 * 课程分类接口(分类树)
 * @param levels
 * 获取一级维度分类传值: 1
 * 获取二级维度分类传值: 1,2
 * 获取三级维度分类传值: 1,2,3
 * showStatus 仅获取对用户可见的
 */
export function getCourseCategory(levels: string = '', showStatus = 1) {
  return request.get('/v2/category-tree', { code: 'course', levels, showStatus }, { requestBase: 'course' });
}
//获取分类列表
export function getCategorys(params) {
  return request.get('/v2/categorys', params, { requestBase: 'course' });
}

export function getMaterials(courseId: number) {
  return request.get(`/course/${courseId}/materials`, { status: 'published' }, { requestBase: 'phoenix' });
}
// 判断课程是否有资料
export function getCourseHasCourseMaterial(courseId: number) {
  return request.get(`/v2/course-materials/${courseId}/has-course-material`, {}, { requestBase: 'course' });
}

export function getCourseMaterialsTimeline(params) {
  return request.get(`/course/${params.courseId}/timeline/materials`, params, { requestBase: 'phoenix' });
}

export function getSubMaterials(courseId: number, materialType: string, materialId?: number) {
  return request.get(`/course/${courseId}/sub-materials`, { materialId, materialType }, { requestBase: 'phoenix' });
}

// 获取资料批量下载key
export function getDownloadKey(qeury: DownloadKeyQuery = {} as DownloadKeyQuery) {
  return request.post<{ downloadFileKey: string }>('/v2/course-material/batch-download/key', qeury, { requestBase: 'course' });
}
// 批量下载资料
export function getDownloadInfo(downloadFileKey: string) {
  return request.get<MaterialDownloadInfo>('/v2/course-material/batch-download', { downloadFileKey }, { requestBase: 'course' });
}
// 获取打包完的状态
export function getPackageStatus(downloadFileKey: string) {
  return request.get<{ hasfinished: boolean }>('/v2/course-material/package-status', { inputKey: downloadFileKey }, { requestBase: 'course' });
}
// 获取资料下载地址
export function getDownloadUrl(params) {
  return request.get('/v2/course-material/download-url', params, { requestBase: 'course' });
}

// 获取自己课程列表
export function getMyCourses(params) {
  return request.get('/v2/users/course-list', params, { requestBase: 'course' });
}
// 获取其他用户的课程列表
export function getUserCourses(params) {
  return request.get('/v2/users/course-list/other', params, { requestBase: 'course' });
}

export function getTargetUserCourses(params) {
  return request.get('/v2/targetUsers/courses/other', params, { requestBase: 'course' });
}

// 获取用户再教课程列表
export function getTargetTeachingCourses(params) {
  return request.get('/v2/users/teaching-courses', params, { requestBase: 'course' });
}

// 我的教学
export function getCurrentUserCourses(params) {
  return request.get('/v2/targetUsers/courses', params, { requestBase: 'course' });
}
// 通用课程搜索 :todo 需要支持未登录
export function getCoursesSearch(params) {
  params.ns = 'bt';
  return request.get('/v2/course/search', params, { requestBase: 'course' });
}
// todo:需要支持未登录
export function getArticles(itemCategoryId, params) {
  return request.get('/v2/articles', Object.assign(params, { itemCategoryId }), { requestBase: 'course' });
}

//todo: 需要支持未登录
export function getTargetCouponBatch(targetId, params) {
  // return request.get(`/v2/coupons/target/${targetId}`, params, { requestBase: 'course' });
  // return request.get(`/v2/coupons/target/${targetId}`, params, { requestBase: 'course' });
  return request.get(`/v2/coupon-batch/courses/${targetId}/useable`, params, { requestBase: 'course' });
}
// 通过唯一token获取优惠券
export function receiveCouponByUniqToken(token) {
  return request.post(`/v2/coupons/${token}/users`, {}, { requestBase: 'course' });
}

//检查听课前是否需要签署协议绑定手机号...
export function checkCoursePreTask(courseId) {
  return request.get('/v2/course-member/check-pre-task', { courseId }, { requestBase: 'course' });
}

export function checkCoursePreTaskNew(courseId) {
  return request.post(`/v2/course-member/courses/${courseId}/users/mine/pre-task`, {}, { requestBase: 'course' });
}

//绑定手机号可跳过页面调取接口
export function markUserMobileBind(courseId) {
  return request.post('/v2/course-member/mobilebind-mark', { courseId }, { requestBase: 'course' });
}

// 优惠券批次id换token
export function getUniqTokenByBatchId(batchId: number) {
  return request.get(`/v2/coupons/${batchId}/token`, {}, { requestBase: 'course' });
}

// 根据分类id获取用户默认头像
export function getCategoryUserAvatar(id) {
  return request.get(`/v2/categorys/${id}/user-avatar`, {}, { requestBase: 'course' });
}

//获取一个课程下的资料
export function getLessonMaterials(params) {
  return request.get('/v2/lesson/materials', params, { requestBase: 'course' });
}

// 获取课程资料，通过搜索方式
export function getMaterialsByTitle(params) {
  return request.get('/v2/course-materials/search/list', params, { requestBase: 'course' });
}

// 手动激活课程
export function activateGiftCourse(params) {
  return request.post('/v2/activateGift/active/course', params, { requestBase: 'course' });
}

// 获取已经发送的弹窗
export function getLivePopup(params) {
  return request.get('/v2/active-live-popups', params, { requestBase: 'course' });
}

// 置顶课程
export function topUserCourse(courseId, params) {
  return request.post(`/v2/courses/${courseId}/top`, params, { requestBase: 'course' });
}

// 根据课程以及课时id获取课时详情
export function getLessonByLessonIdOrCourseId(params) {
  return request.post('/v2/lessons-by-lessonids-or-courseids', params, { requestBase: 'course' });
}

// 公开课预约
export function reserveOpenCourseLesson(id) {
  return request.post(`/v2/open-course-lessons/${id}/reserve`, {}, { requestBase: 'course' });
}

// 公开课预约成功后送B豆
export function giveAnniversaryCoin(action) {
  return request.post(`/v2/gift-coin/8-year-anniversary/${action}`, {}, { requestBase: 'order' });
}

// 取消公开课预约
export function cancelReserveCourseLesson(id) {
  return request.post(`/v2/open-course-lessons/${id}/cancel-reserve`, {}, { requestBase: 'course' });
}

// 获取落地页配置项数据
export function getReportActScoreConfig(actId) {
  return request.get(`/v2/report-act-score-config/${actId}`, {}, { requestBase: 'marketing' });
}

// 获取某个落地页详情
export function getReportActScoreDetail(params) {
  return request.get('/v2/report-act', params, { requestBase: 'marketing' });
}

// 获取课时视频打点
export function getLessonMarkPoints(lessonId) {
  return request.get(`/v2/mark-point/${lessonId}/list`, {}, { requestBase: 'course' });
}

// 获取课时字幕下载url
export function getLessonSubtitleDownloadUrl(lessonId) {
  return request.get(`/v2/subtitle/${lessonId}/download-url`, {}, { requestBase: 'course' });
}

// 获取公开课课程推荐列表
export function getOpenCourseRecommend(params) {
  return request.get('/open-course/recommend', params, { requestBase: 'guser' });
}

// 获取公开课班班信息
export function getBanban(params) {
  return request.get('/v2/guide/banban', params, { requestBase: 'course' });
}

//获取学员是否有补课任务安排
export function getIfHasMissedTasks(params) {
  return request.get('/v2/user/if-has-missed-tasks', params, { requestBase: 'task' });
}

// 课程通用配置
export function getBtSettingByName(name) {
  return request.get(`/bt-settings/${name}`, {}, { requestBase: 'guser' }); // v2
}

// 获取学习计划日历接口
export function getOverviewCalendar(params) {
  return request.get('/v2/study-plan-v2/user/overview-calendar', params, { requestBase: 'task' });
}

// 获取今日直播
export function getTodayLive(userId: number) {
  return request.get(`/v2/courses/today-live-courses/users/${userId}`, {}, { requestBase: 'course' });
}

// 获取用户当日计划列表
export function getTodayPlan(params) {
  return request.get('/v2/study-plan-v2/today-plan', params, { requestBase: 'task' });
}

// 获取用户补课任务
export function getMissedTasks(params) {
  return request.get('/v2/study-plan-v2/today-plan/missed', params, { requestBase: 'task' });
}
