












import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UserTagVip extends Vue {
  @Prop({ type: String }) vipIconUrl?: string;
  @Prop({ type: Array }) roles?: any[];
  @Prop({ type: Boolean, default: false }) isNeedHotIcon;

  get roleList() {
    return (this.roles && this.roles.filter(item => item.code !== 'ROLE_OFFICIAL')) || [];
  }
}
