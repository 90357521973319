



















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
type ActDialogData = {
  qrUrl: string;
  sTitle: string;
  subTitle: string;
  tip: string;
  title: string;
};
@Component
export default class ActDialog extends Vue {
  @Prop({ type: Object, default: () => ({}) }) dialogData!: ActDialogData;
  @PropSync('visible', { type: Boolean, default: false }) visibleSync;
  data = this.dialogData || {};

  openDialog(dialogData) {
    this.data = dialogData;
    this.visibleSync = true;
  }
}
