import { request } from '@/utils/axios';
import { RemainingPostData, CouponModel, PageResponse } from '@/typings';

/**
 * @method getRemainingSum
 * @description 获取账户余额|获取用户B豆余额
 * @param userId (用户id)
 */
export const getRemainingSum = () => {
  return request.get(`/v2/user/cash-account`, {}, { requestBase: 'order' });
  // return request.throughApi<RemainingPostData>('ms-order.cashAccount.getCashAccount', {}, { isAuth: true });
};

/**
 * @method cardRecharge
 * @description 学习卡使用调用接口
 * @param params
 */
export const cardRecharge = params => {
  return request.throughApi('ms-order.cashOrder.cardRecharge', params, { isAuth: true });
};
/**
 * @method createCashOrder
 * @description 充值b豆生成订单接口
 * @param params
 */
export const createCashOrder = params => {
  return request.post(`/v2/cash-orders`, params, { requestBase: 'order' });
  // return request.throughApi('ms-order.cashOrder.createCashOrder', params, { isAuth: true });
};

/**
 * @method getCashFlow
 * @description 获取用户的b豆流水记录
 */
export const getCashFlow = params => {
  return request.get(`/v2/users/cash-flow`, params, { requestBase: 'order' });
  // return request.throughApi('ms-order.cashAccount.getCashFlow', params, { isAuth: true });
};
/**
 * @method summaryCashFlow
 * @description 获取b豆总的收入和支出
 */
export const summaryCashFlow = params => {
  return request.get(`/v2/users/cash-flow-total`, params, { requestBase: 'order' });
  // return request.throughApi('ms-order.cashAccount.summaryCashFlow', params, { isAuth: true });
};

/**
 * @method beanInflation
 * @description B豆膨胀
 * @param params
 */
export const beanInflation = params => {
  return request.throughApi('ms-order.cashAccount.beanInflation', params, { isAuth: true });
};

/**
 * @method applyCashWithdraw
 * @description 申请B豆提现接口
 * @param params
 */
export const applyCashWithdraw = params => {
  return request.post(`/v2/users/cash-withdraw`, params, { requestBase: 'order' });
  // return request.throughApi('ms-order.cashAccount.applyCashWithdraw', params, { isAuth: true });
};

/**
 * @method getCashWithdraw
 * @description 获取b豆提现的状态
 */
export const getCashWithdraw = () => {
  return request.get(`/v2/users/cash-withdraw`, {}, { requestBase: 'order' });
  // return request.throughApi('ms-order.cashAccount.getCashWithdraw', {}, { isAuth: true });
};

/**
 * @method getMyRank
 * @description 获取我的邀请排名
 */
export const getMyRank = () => {
  return request.throughMojaveApi({ url: '/account/invites/rank', method: 'get' });
};
/**
 * @method getAccountInvites
 * @description 获取我的推荐列表以及推荐获取奖励
 */
export const getAccountInvites = () => {
  return request.throughMojaveApi({ url: '/account/invites', method: 'get' });
  // return request.throughApi('mojave.get_account_invites', {}, { isAuth: true });
};
/**
 * @method getInviteRankRecords
 * @description 获取推荐排行版
 */
export const getInviteRankRecords = () => {
  return request.throughApi('ms-distribution.accountCenter.getInviteRankRecords', {}, { isAuth: true });
};
/**
 * @method checkInviteCode
 * @description 检查邀请码状态
 */
export const checkInviteCode = () => {
  return request.throughApi('ms-order.invite.checkInviteCode', {}, { isAuth: true });
};
/**
 * @method writeInviteCode
 * @param params
 * @description 填写邀请码校验
 */
export const writeInviteCode = params => {
  return request.throughApi('ms-order.invite.writeInviteCode', params, { isAuth: true });
};
/**
 * @method getOrders
 * @description 获取订单列表
 * @param params
 */
export const getOrders = params => {
  return request.get(`/v2/orders/user`, params, { requestBase: 'order' });
  // return request.throughApi('ms-order.order.getOrders', params, { isAuth: true });
};
/**
 * @method getOrderDetail
 * @description 获取订单某一项的详情值
 * @param params
 */
export const getOrderDetail = params => {
  return request.get(`/v2/orders/${params.orderSn}`, params, { requestBase: 'order' });
  // return request.throughApi('ms-order.order.getOrderBySn', params, { isAuth: true });
};
/**
 * @method cancelOrder
 * @description 取消订单参数是 sn => orderSn
 * @param params
 */
export const cancelOrder = params => {
  const isManualCancel = [undefined, null].includes(params.isManualCancel) ? 1: params.isManualCancel
  return request.delete(`/v2/orders/${params.orderSn}?isManualCancel=${isManualCancel}`, {}, { requestBase: 'order' });
  // return request.throughApi('ms-order.order.cancelOrder', params, { isAuth: true });
};

/**
 * @method getUserInvitedStatus
 * @description 获取用户是否被邀请状态
 */
export const getUserInvitedStatus = () => {
  return request.throughApi('ms-user.user.getUserInvitedStatus', {}, { isAuth: true });
};

/**
 * @method getUseableCoupons
 * @description 获取可用的优惠券列表
 * @param params
 */
export const getUseableCoupons = params => {
  return request.get<PageResponse<CouponModel>>('/v2/coupon/useable-list', params, { requestBase: 'course' });
};

/**
 * @method getDisableCoupons
 * @description 获取不可用优惠卷
 * @param params
 */
export const getDisableCoupons = params => {
  return request.get('/v2/coupon/disable-list', params, { requestBase: 'course' });
};
/**
 * @method getCouponBatchs
 * @description 根据batchId 获取该优惠券的详细信息
 * @param params {ids:'123'}
 */
export const getCouponBatchs = params => {
  return request.get('/v2/coupon-batch', params, { requestBase: 'course' });
};
/**
 * @method receiveCoupon
 * @description 用户获取优惠券
 * @param couponBatchId 优惠券批次ID
 * @param token 优惠券token
 */
export function receiveCoupon(couponBatchId, token = '') {
  const params = { couponBatchId, token };
  if (!token) {
    delete params.token;
  }
  return request.get('/v2/coupons/for-user', params, { requestBase: 'course' });
}
/**
 * @method getVerifiedMobile
 * @description 获取用户的手机号
 */
export const getVerifiedMobile = () => {
  return request.throughApi('ms-user.user.getUserSensitiveInfo', {}, { isAuth: true });
};

/**
 * @method getUserAndProfilesById
 * @description 获取支付绑定的用户信息
 * @param params
 */
export const getUserAndProfilesById = () => {
  return request.get(`/v2/users/profiles`, {}, { requestBase: 'user' });
  // return request.throughApi('ms-user.user.getUserAndProfilesById', {}, { isAuth: true, authKey: 'id' });
};

/**
 * @method certifyUserAlipayInfo
 * @description 绑定支付宝验证接口
 * @param params {alipayAccount?:'string',alipayName:string,mobile:string,smscode:string}
 */
export const certifyUserAlipayInfo = params => {
  return request.throughApi('ms-user.user.certifyUserAlipayInfo', params, { isAuth: true });
};

// 获取推荐人状态
export const fetchRecommendUserState = params => {
  return request.throughApi('ms-marketing.recommendUser.getState', params, { isAuth: true });
};

// 获取用户端推荐人数据
export const fetchRecommendUserData = params => {
  return request.throughApi('ms-marketing.recommendUser.showForUser', params, { isAuth: true });
};

// 用户端获取推荐记录列表
export const fetchRecommendUserRecro = params => {
  return request.throughApi('ms-marketing.recommendReco.indexForUser', params, { isAuth: true, authKey: 'recommenderId' });
};

// 用户端获取推荐人返利排行榜
export const fetchRecommendUserRank = params => {
  return request.throughApi('ms-marketing.recommendUser.getRankingList', params, { isAuth: true });
};

// 获取现金充值的订单信息
export const getRechargeOrder = (sn: string) => {
  return request.get(`/v2/cash-orders/${sn}`, {sn}, { requestBase: 'order' });
  // return request.throughApi('ms-order.cashOrder.getCashOrder', { sn });
};

// 获取推荐记录
export const listForReferrer = params => {
  return request.get(`/v2/recommend-v2/referral/list-for-referrer`, params, { requestBase: 'marketing' }); 
  // return request.throughApi('ms-marketing.v2.recommendV2.referral.listForReferrer', params, { isAuth: true });
};

// 获取推荐页面推荐人信息
export const getRecommendPageInfo = () => {
  return request.get(`/v2/recommend-v2/referrer/recommend-page-info`, {}, { requestBase: 'marketing' });
  // return request.throughApi('ms-marketing.v2.recommendV2.referrer.getRecommendPageInfo', { isAuth: true });
};

// 获取推荐人排行榜
export const getRankingList = () => {
  return request.get(`/v2/recommend-v2/referrer/ranking-list`, {}, { requestBase: 'marketing' });
  // return request.throughApi('ms-marketing.v2.recommendV2.referrer.getRankingList', { isAuth: true });
};

/**
 * @method getCurrentWithdrawMinAmount
 * @description 获取当前最低可提现金额
 * @param params
 */
 export const getCurrentWithdrawMinAmount = params => {
  return request.get('/order/withdraw/min-amount', params, { requestBase: 'guser' });
};