import { request } from '@/utils/axios';

// 获取分类树
export function getCategoryTree(params) {
  return request.get('/v2/category-tree', params, { requestBase: 'course' });
}

// 分页获取分类下的课程列表
export function getCategoryCourses(categoryId, start, limit) {
  return request.get(`/course/category/${categoryId}/courses`, { start, limit, getNew: 1 }, { requestBase: 'phoenix' });
}

// 获取分类下的推荐课程列表
export function getCategoryRecommendCourses(categoryId, start, limit) {
  return request.get(`/course/category/${categoryId}/recommend-courses`, { start, limit, getNew: 1 }, { requestBase: 'phoenix' });
}

// 分页获取课程列表
export function findCourses(data) {
  return request.get('/course/list/courses', data, { requestBase: 'phoenix' });
}

// 获取免费课程列表
export function findFreeCourses() {
  return request.get('/course/list/free/courses', { getNew: 1 }, { requestBase: 'phoenix' });
}

// 获取首页最新资讯列表
export function findHomeArticles() {
  return request.get('/article/home-articles', {}, { requestBase: 'phoenix' });
}

// 分页获取课程评价列表
export function findReviews(data) {
  return request.get('/v2/course-reviews/page', data, { requestBase: 'course' });
}

//获取直播列表
export function getChoicenessPageLiveItems() {
  return request.get('/v2/page/choiceness-page-live-item', {}, { requestBase: 'course' });
}

// 根据别名获取自定义页面数据
export function getCustomPage(alias) {
  return request.get('/v2/custom-page/page', { alias }, { requestBase: 'user' });
}

// 获取展示中的运营位配置列表
export function getAdConfigList() {
  return request.get('/v2/ad-config/show-list', { scene: 'pcIndexMoudule' }, { requestBase: 'marketing' });
}
