<template>
  <el-dialog
    title="重置密码"
    center
    v-if="resetPasswordDialogVisible"
    custom-class="reset-password-dialog"
    :visible.sync="isVisible"
    :append-to-body="true"
  >
    <reset-password></reset-password>
  </el-dialog>
</template>

<script>
  import LoginContainer from './components/loginContainer';
  import { mapState, mapMutations } from 'vuex';
  import SetPasswd from '../settings/edit-passwd/set-passwd';
  import ResetPassword from './components/resetPassword';

  export default {
    name: 'ResetPasswordDialog',
    components: {
      ResetPassword,
      SetPasswd,
      LoginContainer
    },
    data() {
      return {
        isVisible: this.resetPasswordDialogVisible,
      };
    },
    computed: {
      ...mapState('my', ['resetPasswordDialogVisible']),
    },
    methods: {
      ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE', 'SET_RESET_PASSWORD_DIALOG_VISIBLE']),
      onLoginSuccess() {
        window.location.reload();
      }
    },
    watch: {
      isVisible: function (val) {
        if (val) {
          setTimeout(() => {
            this.SET_LOGIN_DIALOG_VISIBLE(false); // 关闭登录弹窗
          });
        }
        if (val !== this.resetPasswordDialogVisible) {
          this.SET_RESET_PASSWORD_DIALOG_VISIBLE(val);
        }
      },
      resetPasswordDialogVisible: {
        handler: function (val) {
          if (this.isVisible !== val) {
            this.isVisible = val;
          }
        },
        immediate: true
      },
    },
  };
</script>

<style scoped lang="scss">
  ::v-deep
  .reset-password-dialog {
    /*padding: 20px;*/
    /*.el-dialog__header {*/
    /*  display: none;*/
    /*}*/
    width: 400px;
    @media (max-width: 500px) {
      width: 310px;
    }
    .el-dialog__body {
      padding: 16px 56px 10px;
      @media (max-width: 500px) {
        padding: 16px 16px 10px;
      }
    }
  }
</style>
