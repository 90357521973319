




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { getRecCourseByCategoryId } from '@/api/ask';
import TransparentMode from './transparent-mode.vue';

@Component({
  components: {
    TransparentMode,
  },
})
export default class modulesRecommendCourse extends Vue {
  @Prop({ type: Number, default: 0 }) parentCategoryId: any;
  @Prop({ type: Number, default: 0 }) categoryId: any;

  isLoading: boolean = false;
  list: any = [];
  count: number = 0;
  projectId: number = 0;

  @Watch('parentCategoryId', { immediate: true })
  async watchPage(val) {
    if (val) {
      try {
        this.isLoading = true;
        const { projectId, rows } = await getRecCourseByCategoryId({ parentCategoryId: this.parentCategoryId });
        this.list = rows.filter((item, index) => {
          return index < 4;
        });
        this.count = rows.length;
        this.projectId = projectId;
        console.log('推荐课程', this.list);
      } catch (e) {
        this.list = [];
        this.count = 0;
        console.log('推荐课程获取失败', e);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.list = [];
      this.count = 0;
    }
  }

  // 更多
  getMore() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'course-explore', query: { categoryId: this.projectId } });
    window.open(link.href, '_blank');
    console.log('更多', this.categoryId);
  }

  // 课程详情页
  goCourseDetail(item) {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'course-home', params: { id: item.id } });
    window.open(link.href, '_blank');
    console.log('课程详情页', item.id);
  }
}
