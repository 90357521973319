



























































import { Vue, Component } from 'vue-property-decorator';
import LeftChannel from './components/chanel-list.vue';
import Carousel from './components/carousel.vue';
import CircleCardCom from './components/circle-card-item.vue';
import ChoseCircleStartDialog from './components/chose-circle-start-dialog.vue';
import SearchBlock from './components/side-bar/search-block.vue';
import HotLessons from './components/side-bar/hot-lesson.vue'; // 直播公开课
import HotStoried from './components/side-bar/hot-storied.vue'; // 热门动态
import DynamicList from './components/dynamic-list.vue';
import { mapGetters } from 'vuex';
import { getChannelDynamicList, getMoreCircleList, getUserMine, getChannelDetail } from '@/api/circle';
import { CircleItem, ChannelItem, DynamicItem } from '@/typings';

@Component({
  computed: {
    ...mapGetters('my', ['isLogin']),
  },
  components: {
    LeftChannel,
    Carousel,
    CircleCardCom,
    ChoseCircleStartDialog,
    SearchBlock,
    HotLessons,
    HotStoried,
    DynamicList,
  },
})
export default class ComName extends Vue {
  // 面包屑数据
  breadcrumb: any = {
    show: false,
    data: [],
  };
  pagination: any = {
    page: 0,
    limit: 10,
    total: 0,
    isLoadAll: false,
  };
  isLogin: any; // 是否登录
  activeChannel: ChannelItem | null = null; // 当前选中频道
  isLoadingDynamic: boolean = false; // 是否正在加载圈子
  isFirstLoading: boolean = false; // 首次加载圈子增加页面loading
  dynamicList: DynamicItem[] | null = null; // 动态详情
  recommadCircleList: CircleItem[] = []; // 推荐圈子列表
  isEnteredAlumniCircle: 0 | 1 = 1;
  isShowStartEnterCircleDialog: boolean = false;
  listKey: string = 'channel'; //列表关键词, model: 'type:data', 用于详情页获取上下篇动态。type: channel-频道动态列表、circle-圈子动态列表、user-用户动态列表
  isErrorPage: boolean = false;

  get serviceBit() {
    const { serviceBit } = this.activeChannel || {};
    return serviceBit
      ? serviceBit
          .filter(item => item.display)
          .map(item => ({
            ...item,
            href: item.url,
            src: item.picture,
          }))
      : [];
  }

  async created() {
    this.isFirstLoading = true;
    // 冷启动页加载逻辑
    if (this.isLogin) {
      const { isEnteredAlumniCircle } = await getUserMine();
      this.isEnteredAlumniCircle = isEnteredAlumniCircle;
      // 判断如果没有进过圈子页面，则加载冷启动页
      if (!this.isEnteredAlumniCircle) {
        this.isShowStartEnterCircleDialog = true;
      }
    }
  }

  // 只有固定频道获取推荐圈子列表
  async getRecommendCircleList() {
    const { rows } = await getMoreCircleList({ page: 0, limit: 4 });
    this.recommadCircleList = rows;
  }

  // 连续加载两次
  async scrollUpdate() {
    this.pagination.page += 1;
    await this.getChannelDynamicListFun(true);
    this.pagination.page += 1;
    this.getChannelDynamicListFun(true);
  }

  // 获取频道下的动态列表
  async getChannelDynamicListFun(isScroll = false) {
    try {
      if (!isScroll) {
        console.log('走入scroll');
        this.isFirstLoading = true;
      }
      this.isLoadingDynamic = true;
      if (!this.activeChannel) return;
      const { rows, listKey, count } = await getChannelDynamicList(this.activeChannel.id, {
        page: this.pagination.page,
        limit: this.pagination.limit,
        needCount: 1,
      });
      if (isScroll && this.dynamicList) {
        this.dynamicList = this.dynamicList.concat(rows);
      } else {
        this.dynamicList = rows;
      }

      this.listKey = listKey;
      this.pagination.total = count;
      this.pagination.isLoadAll = (this.pagination.page + 1) * this.pagination.limit > count;
      // console.log('circleContent', this.dynamicList);
    } finally {
      this.isFirstLoading = false;
      this.isLoadingDynamic = false;
    }
  }

  // 切换频道,或者首次加载频道
  async channelIdChange(data: ChannelItem, isSingleChannel = false, isSwitch = false) {
    this.pagination.page = 0;
    this.activeChannel = data;
    const query = {
      channelId: data.id + '',
      pageId: data.webInfo && data.webInfo.pageId + '',
    };
    if (isSingleChannel) {
      query['type'] = 'single';
    }
    this.$router.replace({ query });
    window.scrollTo(0, 0);

    // 单频道
    if (isSingleChannel) {
      this.$emit('breadChange', {
        show: true,
        data: [
          { name: 'BT教育', path: '/' },
          { name: '校友圈主页', path: 'circleHome' },
          { name: '频道页', path: '/circle' },
        ],
      });
    } else {
      //校友圈主页
      this.$emit('breadChange', {
        show: true,
        data: [
          { name: 'BT教育', path: '/' },
          { name: '校友圈主页', path: '/circle' },
        ],
      });
    }

    console.log('activeChannel', this.activeChannel);
    // 固定频道加载推荐的校友圈
    if (this.activeChannel.channelType === 'fixed') {
      this.getRecommendCircleList();
    } else {
      this.recommadCircleList = [];
    }
    // 表示通过侧边tab栏切换，需要重新拉取渠道详情，更新轮播图数据
    if (isSwitch) {
      const targetData = await getChannelDetail(data.id);
      if (this.activeChannel.id === targetData.id) {
        this.activeChannel = Object.assign({}, targetData);
      }
    }
    this.dynamicList = [];
    this.getChannelDynamicListFun();
  }

  // 关注事件，循环更新当前关注者的状态
  focuseChangeHandle(userId, isFocused) {
    if (!this.dynamicList) return;
    this.dynamicList.forEach(item => {
      if (item.userId === userId) {
        item['isFocused'] = isFocused;
      }
    });
    this.$forceUpdate();
  }

  // 回校友圈首页
  goCircleHome() {
    location.href = '/circle';
  }

  // 前往更多圈子
  goMoreCicle() {
    const { href } = this.$router.resolve({ name: 'more-circle' });
    window.open(href);
  }

  errorPage() {
    this.$emit('breadChange', {
      show: true,
      data: [
        { name: 'BT教育', path: '/' },
        { name: '校友圈主页', path: 'circleHome' },
        { name: '频道页', path: '/circle' },
      ],
    });
    this.isErrorPage = true;
  }
}
