<!--移动端注册页面-->
<template>
  <div class="mobile-register">
    <p>未注册的用户可以在<span class="strong">电脑端网页扫码</span>或者<span class="strong">下载APP</span>，用微信登录即可</p>
    <img src="@/assets/images/whale-icon/2.png" >
    <a href="http://a.app.qq.com/o/simple.jsp?pkgname=cn.btclass.sierra" target="_blank" class="download-btn">下载APP</a>
  </div>
</template>

<script>
  export default {
    name: 'MobileRegister',
  };
</script>

<style scoped lang="scss">
  .mobile-register {
    padding: 8px 43px;

    p {
      font-size: 14px;
      color: #5A5A5A;
      text-align: left;
      line-height: 20px;

      .strong {
        color: #363636;
        font-weight: bold;
      }
    }

    img {
      margin: 26px auto 0;
      width: 80px;
      display: block;
    }

    .download-btn {
      display: inline-block;
      font-size: 14px;
      color: #FFFFFF;
      background: #01A0EA;
      height: 38px;
      line-height: 38px;
      padding: 0 28px;
      border-radius: 4px;
      margin: 16px 0 20px;
    }
  }
</style>
