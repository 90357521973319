
























import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { getDoubtFlowTips } from '@/api/ask';

@Component({})
export default class modulesAskItemReward extends Vue {
  @PropSync('visible', { type: Boolean, default: true }) syncVisible: any;
  @Prop({ type: Number, default: 0 }) doubtId: any;

  rewardInfo: any = {};
  isLoading: boolean = false;

  iconsList: any = {
    0: 'https://tmp.btclass.cn/yunyin/o_1h9hi2oqi1i0uqsnh011oa4qoag.png',
    1: 'https://tmp.btclass.cn/yunyin/o_1h9hha9061dvr1s1flri181m11hef.png',
    2: 'https://tmp.btclass.cn/yunyin/o_1h9hha90642n1gv3ser10ek362e.png',
    3: 'https://tmp.btclass.cn/yunyin/o_1h9hha9061340de6m2ncohhscd.png',
    4: 'https://tmp.btclass.cn/yunyin/o_1h9hha906e6714891ob911snqk3g.png',
    5: 'https://tmp.btclass.cn/yunyin/o_1h9hha906e6714891ob911snqk3g.png',
  };

  @Watch('doubtId', { immediate: true })
  async watchDoubtId(id) {
    try {
      this.isLoading = true;
      if (id) {
        /* 
          type: 0-默认(异常情况)， 1-已采纳，2-平分悬赏，3-悬赏退回，4-等待采纳回答
        */
        const rewardInfo = await getDoubtFlowTips({ doubtId: id });
        this.rewardInfo = rewardInfo || {};
        console.log('悬赏详情', rewardInfo);
      }
    } catch (e) {
      console.log('获取悬赏详情失败', e);
    } finally {
      this.isLoading = false;
    }
  }

  // 关闭弹窗
  closeDialog() {
    this.syncVisible = false;
  }
}
