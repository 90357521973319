<template>
  <div class="login-container" :class="$isMobile ? 'mobile-view' : ''">
    <template v-if="!$isMobile">
      <template v-if="loginWay === 'wechat'">
        <img @click="changeLoginWay('verifyCode')" class="tip-icon" alt="账号登录" src="@/assets/images/login/tip-icon-qrcode-login.png" />
        <img @click="changeLoginWay('verifyCode')" class="corner-icon" alt="账号登录" src="@/assets/images/login/corner-icon-qrcode-login.png" />
      </template>
      <template v-else>
        <img @click="changeLoginWay('wechat')" class="tip-icon" alt="扫码登录" src="@/assets/images/login/tip-icon-form-login.png" />
        <img @click="changeLoginWay('wechat')" class="corner-icon" alt="扫码登录" src="@/assets/images/login/corner-icon-form-login.png" />
      </template>
    </template>
    <template v-else>
      <template v-if="loginWay === 'mobileRegister'">
        <img @click="changeLoginWay('verifyCode')" class="tip-icon" alt="登录" src="@/assets/images/login/tip-icon-mobile-register.png" />
        <img @click="changeLoginWay('verifyCode')" class="corner-icon" alt="登录" src="@/assets/images/login/corner-icon-mobile-register.png" />
      </template>
      <template v-else>
        <img @click="changeLoginWay('mobileRegister')" class="tip-icon" alt="注册" src="@/assets/images/login/tip-icon-mobile-login.png" />
        <img @click="changeLoginWay('mobileRegister')" class="corner-icon" alt="注册" src="@/assets/images/login/corner-icon-mobile-login.png" />
      </template>
    </template>

    <h1>
      <template v-if="loginWay === 'wechat'">微信扫码登录</template>
      <template v-else-if="loginWay === 'mobileRegister'">账号注册</template>
      <template v-else>账号登录</template>
    </h1>

    <div class="login-way" v-if="['verifyCode', 'passwd'].includes(loginWay)">
      <div class="custom-btn-wrap" @click="changeLoginWay('verifyCode')">
        <span class="custom-btn" :class="loginWay === 'verifyCode' ? 'active' : ''">短信登录</span>
      </div>
      <div class="custom-btn-wrap" @click="changeLoginWay('passwd')">
        <span class="custom-btn" :class="loginWay === 'passwd' ? 'active' : ''">密码登录</span>
      </div>
    </div>

    <PasswordLoginForm @loginSuccess="onLoginSuccess" v-show="loginWay === 'passwd'"></PasswordLoginForm>
    <VerifyCodeLoginForm @loginSuccess="onLoginSuccess" v-show="loginWay === 'verifyCode'"></VerifyCodeLoginForm>
    <WechatLoginForm @loginSuccess="onLoginSuccess" v-if="!$isMobile" v-show="loginWay === 'wechat'"></WechatLoginForm>
    <mobile-register v-if="$isMobile" v-show="loginWay === 'mobileRegister'"></mobile-register>
  </div>
</template>

<script>
import PasswordLoginForm from './passwordLoginForm';
import VerifyCodeLoginForm from './verifyCodeLoginForm';
import WechatLoginForm from './wechatLoginForm';
import MobileRegister from './mobileRegister';

export default {
  name: 'LoginContainer',
  components: {
    PasswordLoginForm,
    VerifyCodeLoginForm,
    WechatLoginForm,
    MobileRegister,
  },
  data() {
    return {
      loginWay: this.$isMobile ? 'verifyCode' : 'wechat',
    };
  },
  methods: {
    changeLoginWay(way) {
      this.loginWay = way;
    },
    onLoginSuccess() {
      this.$emit('onLoginSuccess');
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  border-radius: 4px;
  background: #ffffff;
  width: 375px;
  min-height: 300px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;

  &.mobile-view {
    width: 310px;
    height: 330px;
  }

  h1 {
    font-size: 20px;
    margin: 38px 0 16px;
    height: 28px;
    line-height: 28px;
    color: #363636;
  }
  .tip-icon {
    width: 79px;
    position: absolute;
    right: 42px;
    top: 11px;
    cursor: pointer;
  }
  .corner-icon {
    width: 55px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .login-way {
    margin-top: 28px;
    font-size: 14px;
    color: #5a5a5a;
    overflow: hidden;
    display: flex;
    .custom-btn-wrap {
      width: 50%;
      cursor: pointer;
    }
    .custom-btn {
      height: 30px;
      font-weight: 500;
      line-height: 20px;
      padding-bottom: 8px;
      display: inline-block;
      &.active {
        border-bottom: 2px solid #4b8fff;
      }
    }
  }
}
</style>
