
const LawTree = () => import(/* webpackChunkName: "user" */ '@/pc/views/law-tree/index.vue');

export default [
  {
    path: '/tools/law-tree',
    name: 'law-tree',
    component: LawTree,
    meta: {
      isPC: true,
      needLogin: false,
      hideFooter: true,
      hideHeader: true,
    },
  },
];
