


































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import OpenCourseModel from '@/pc/views/home/components/components/OpenCourseModel.vue';
import NormalCourseItem from '@/pc/views/home/components/components/CourseList.vue';
import Sticky from './sticky.vue';
import scrollWatch from '@/libs/vue-scrollwatch2';
import ProgressCom from './progress.vue';
import CountDown from './count-down.vue';
import { getCourseListForUser } from '@/api/course';
import { trackEvent } from '@/utils/helper';

@Component({
  components: {
    OpenCourseModel,
    NormalCourseItem,
    Sticky,
    CountDown,
    ProgressCom,
  },
})
export default class Anniversary extends Vue {
  @PropSync('activeName') activeNameSync!: 'string';
  @Prop({ type: Object, default: () => ({}) }) data;
  @Prop({ type: Number, default: 80 }) headHeight;
  @Prop({ type: String, default: '' }) activeBlock;

  courseMap: any = {};

  get isTimeout() {
    return this.data.deadline && Date.now() > this.data.deadline;
  }

  mounted() {
    this.getCourse();
  }
  seeMore() {
    // this.$router.push({ path: '/' });
    window.open('/course/explore');
    trackEvent('bt_10665', {
      description: '保价双十一-查看更多',
      projectName: this.activeNameSync,
    });
  }

  tabClick(name) {
    try {
      scrollWatch.scrollTo(name);
      trackEvent('bt_10663', { description: '保价双十一-项目切换', projectName: name });
    } catch (error) {
      console.log(error);
    }
  }

  sensorsHandle(courseId) {
    trackEvent('bt_10664', {
      description: '保价双十一-查看课程详情',
      courseId,
      projectName: this.activeNameSync,
    });
  }

  async getCourse() {
    try {
      const allCourseIds: any[] = this.data.list.map(item => item.courseIds).reduce((result, cur) => [...result, ...cur], []);
      let { rows } = await getCourseListForUser({ ids: [...new Set(allCourseIds)].join(','), needPriceDetail: 1 });
      rows.forEach(item => {
        if (!this.courseMap[item.id]) {
          this.courseMap[+item.id] = item;
        }
      });
      console.log('---------------双十一好课获取成功----------------');
      this.$forceUpdate();
    } catch (e) {
      console.log('获取双十一好课失败', e);
    }
  }

  scroll(node) {
    this.activeNameSync = node.name;
  }
}
