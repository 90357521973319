import { getMenu, getHomeTopBanner, getFriendLinks, getActiveLinks } from '../api/base';
import Cache from '@/utils/cache';

const cache = new Cache('session');
const state = () => ({
  menu: [],
  posters: null,
  links: null,
  activeLinks: {},
  offsetTime: 0, // 和服务器时间的差值，差值2秒内，都算0
});

const getters = {
  menu: state => state.menu,
  posters: state => state.posters,
  links: state => state.links,
  activeLinks: state => state.activeLinks,
  offsetTime: state => state.offsetTime,
};

const mutations = {
  SET_MENU: (state, data) => {
    state.menu = data;
  },
  SET_TOPBANNER: (state, data) => {
    state.posters = data;
  },
  SET_FRIENDLINK: (state, data) => {
    state.links = data;
  },
  SET_ACTIVE_LINKS: (state, data) => {
    state.activeLinks = data;
  },
  SET_SERVE_TIME(state, val) {
    let offsetTime = new Date().getTime() - Number(val); // 本地时间和服务器时间相差毫秒数
    if (Math.abs(offsetTime) < 2000) {
      // 2s内都不算时间有差值
      state.offsetTime = 0;
    } else {
      state.offsetTime = offsetTime;
    }
  },
};

const actions = {
  async getMenu({ commit }) {
    let data = await cache.query('getMenu', getMenu);
    commit('SET_MENU', data);
    return data;
  },
  async getHomeTopBanner({ commit }) {
    let data = await cache.query('getHomeTopBanner', getHomeTopBanner);
    data.forEach((item, index) => {
      if (index === 0) {
        // 首张轮播默认加载
        item['realSrc'] = item.src;
        return;
      }
      item['realSrc'] = '';
    });
    commit('SET_TOPBANNER', data);
    return data;
  },
  async getFriendLink({ commit }) {
    let res = await cache.query('getFriendLinks', getFriendLinks);
    let data = res.filter(item => item.isOpen);
    commit('SET_FRIENDLINK', data);
    return data;
  },
  async getActiveLinks({ commit }, params) {
    let { rows: settings } = await getActiveLinks(params);
    let data = {
      friendlyLinks: [],
      links: [],
    };
    if (settings.length) {
      let setting = settings[0];
      if (setting.value) {
        let value = JSON.parse(setting.value);
        data = value;
        data.friendlyLinks =
          value.friendlyLinks.filter(el => {
            return el.status === 'opened';
          }) || [];
        data.links = value.links || [];
      }
    }
    commit('SET_ACTIVE_LINKS', data);
    return data;
  },
};
export { state, getters, mutations, actions };
