import SiteMap from '@/pc/views/sitemap/index';

export default {
  path: '/sitemap',
  name: 'sitemap',
  component: SiteMap,
  meta: {
    hideFooter: true,
  },
};
