import Vue from 'vue';

// import store from "@/store/index";
const permission = {
  install(Vue, options) {
    Vue.prototype.$permission = value => {
      const roles = Vue.prototype.$store.getters['my/me'].roles;
      const myPermission = Vue.prototype.$store.getters['my/myPermissions'];
      const isLogin = Vue.prototype.$store.getters['my/isLogin'];

      if (!isLogin) {
        return false;
      }

      if (roles && roles.includes('SUPER_ADMIN')) {return true;}

      // 拿到了权限
      if (myPermission.indexOf(value) !== -1) {
        return true;
      }

      // 没有获取到权限 false
      return false;
    };
  }
};

Vue.use(permission);
