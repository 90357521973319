import { request } from '@/utils/axios';

import { CreateOrderResponse, CreateOrderParams, OrderModel, CouponModel, CheckInviteCodeResponse, UpdateOrderParams, UpdateOrderResponse, LeftCrashModel, GuideTempleteModel } from '@/typings';
import { PageResponse, PayConfigQuery, UpdateUserParams } from '@/typings/common';
import { CourseModel } from '@/typings/common/course';

// 创建一个订单
export const createOrder = (params: CreateOrderParams) => {
  if (!params.buyFrom) {
    params.buyFrom = 'pc'; // 官网购课buyFrom默认传pc
  }
  return request.post(`/v2/orders/user`, params, { requestBase: 'order' });
  // return request.throughApi<CreateOrderResponse>('ms-order.order.createOrder', params);
};

export const getOrder = (orderSn: string) => {
  return request.get(`/v2/orders/${orderSn}`, { orderSn }, { requestBase: 'order' });
  // return request.throughApi<OrderModel>('ms-order.order.getOrderBySn', { orderSn });
};

export const getUserInvitedStatus = () => {
  return request.throughApi<{ isInvited: boolean }>('ms-user.user.getUserInvitedStatus');
};

export const checkInviteCode = (inviteCode: string) => {
  return request.throughApi<CheckInviteCodeResponse>('ms-order.invite.checkInviteCode', { inviteCode });
};

export const writeInviteCode = (inviteCode: string) => {
  return request.throughApi('ms-order.invite.writeInviteCode', { inviteCode });
};

// export const rechargeByCard = (password: string) => {
//   return request.throughApi<{coin: number}>('ms-order.cashOrder.cardRecharge', { password });
// }

export const getCourseById = (id: number) => {
  return request.get<CourseModel>(`/v2/courses/${id}`, {}, { requestBase: 'course' });
};

// 获取用户B豆余额
export const getCashAccount = () => {
  return request.get(`/v2/user/cash-account`, {}, { requestBase: 'order' });
  // return request.throughApi<LeftCrashModel>('ms-order.cashAccount.getCashAccount');
};

// 系统内支付，提交优惠码或B豆等
export const updateOrder = (params: UpdateOrderParams) => {
  return request.post(`/v2/orders/${params.orderSn}/pay`, params, { requestBase: 'order' });
  // return request.throughApi<UpdateOrderResponse>('ms-order.order.updOrder', params);
};

// 获取第三方支付配置信息
export const getPayConfig = (params: PayConfigQuery) => {
  return request.get(`/v2/orders/${params.orderSn}/payConfig`, params, { requestBase: 'order' });
  // return request.throughApi('ms-order.order.getPayConfig', params);
};

export const applyHaierFq = (params: any) => {
  return request.throughApi('ms-order.order.applyHaierFq', params);
};

export const getGuideTemplates = (courseId: number) => {
  return request.get<PageResponse<GuideTempleteModel>>('/v2/guide/templates', {
    limit: 1,
    status: 'published',
    courseId,
  }, {requestBase:'course'});
};
