




























import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import UpdateNotesDialog from './update-dialog.vue';
import BindMobile from '@/pc/components/bind-mobile/type-2.vue';
import { getCheckMobileBindConfig } from '@/api/settings';

@Component({
  components: {
    UpdateNotesDialog,
    BindMobile,
  },
  computed: {
    ...mapGetters('my', ['me']),
  },
})
export default class BindMobileToUpdateDialog extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Object }) notesOption: any;
  @Prop({ type: String, default: '' }) type: any;
  @Prop({ type: Object, default: () => {} }) notesInfo: any;

  bindConfig: any = {
    questionNoteStatus: 'none', // 做题笔记
    courseNoteStatus: 'none', // 上课笔记
  };

  // 需要绑定手机号
  get needMobile() {
    const { type, bindConfig } = this;
    return type === 'questionNote' ? bindConfig.questionNoteStatus !== 'none' : bindConfig.courseNoteStatus !== 'none';
  }

  // 允许跳过绑定手机号
  get canJump() {
    const { type, bindConfig } = this;
    return type === 'questionNote' ? bindConfig.questionNoteStatus === 'optional' : bindConfig.courseNoteStatus === 'optional';
  }

  // 新增/编辑笔记
  updateVisible: boolean = false; // 弹窗开关
  // 笔记编辑成功时，关闭大弹窗且刷新列表
  updateList() {
    this.syncVisible = false;
    this.$emit('updateList');
  }

  /* 绑定手机号 */
  me: any;
  bindMobileVisible: boolean = false; // 绑定手机开关
  // 绑定成功或者跳过
  async bindMobileSuccess(mobile) {
    this.addNotesFun(mobile);
  }
  // 单纯点击关闭绑定手机号弹窗，此时不接着往下走流程了，要把大弹窗也关闭了
  closeDialog() {
    this.syncVisible = false;
  }

  // 初始化的时候，先加载校验手机号绑定的配置，根据配置来决定要不要绑定手机号
  async created() {
    this.bindConfig = await getCheckMobileBindConfig();
    console.log('=== 获取校验手机绑定配置 ===', this.bindConfig);
  }

  // 当大弹窗打开时，尝试唤起笔记编辑弹窗
  @Watch('syncVisible', { deep: true, immediate: true })
  watchVisible(status) {
    if (status) {
      this.addNotesFun();
    }
  }

  // 当编辑笔记弹窗关闭时，大弹窗也关闭
  @Watch('updateVisible')
  watchUpdateVisible() {
    if (!this.updateVisible) {
      this.syncVisible = false;
    }
  }

  // 唤起笔记编辑弹窗
  addNotesFun(mobile = '') {
    // 用户24小时内是否点击过跳过手机号
    const timeLength = process.env.NODE_ENV !== 'production' ? 120000 : 86400000;
    const nowTime = new Date().getTime();
    const jumpVerifyTime = Number(localStorage.getItem('question-note-bind-mobile-jump-verify') || nowTime);
    const clickJump = nowTime - jumpVerifyTime > 0 && nowTime - jumpVerifyTime < timeLength;
    console.log('=== 用户24小时内是否点击过跳过手机号 ===', clickJump);
    // 用户已绑定的手机号
    const userMobile = this.me.verifiedMobile;
    console.log('=== 唤起弹窗，获取用户绑定的手机号 ===', userMobile, mobile);
    // 如果配置了需要手机号且没有绑定手机号码且点击的不是跳过绑定，唤起手机号绑定弹窗
    if (!clickJump && this.needMobile && !(userMobile || mobile) && mobile !== 'jumpVerify') {
      this.bindMobileVisible = true;
      return;
    }
    console.log('关闭手机号绑定弹窗，并且打开编辑笔记弹窗');
    // 否则关闭手机号绑定弹窗，并且打开编辑笔记弹窗
    this.bindMobileVisible = false;
    this.updateVisible = true;
  }

  // 跳转规则页面
  openRules() {
    window.open('https://www.btclass.cn/page/privacy');
  }
}
