



































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getCity } from '@/api/settings';
import { setActMinePrizeRecoAddress } from '@/api/raffle';

import AiAddress from './ai-address.vue';

@Component({
  components: {
    AiAddress,
  },
})
export default class Address extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  @Prop({ type: Boolean }) visible?: boolean;
  @Prop({ type: Object, default: () => ({}) }) curRecord;

  rules = {
    truename: [{ required: true, message: '请输入收件人', trigger: 'blur' }],
    mobile: [
      { required: true, message: '请输入联系电话', trigger: 'blur' },
      { min: 11, max: 11, message: '请输入有效的电话号码', trigger: 'blur' },

      { required: true, message: '请输入有效的电话号码', validator: this.validatePhone, trigger: 'blur' },
    ],
    areas: [{ required: true, message: '请选择所在地区', trigger: 'change' }],
    address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
  };
  city = [];
  form: any = {
    truename: '',
    mobile: '',
    areas: '',
    address: '',
  };
  areaWord = '';
  submitting = false;

  async handleOpen() {
    const cityData = await getCity();
    const cityList = this.getOptions(cityData);
    this.city = cityList;
    if (this.curRecord.addressInfo) {
      const { areaId, areaWord, ...rest } = this.curRecord.addressInfo;
      //需要自己用id去递归
      const newIdList = this.getTreeDeepArr(areaId, cityList);
      console.log('newIdList', newIdList);
      this.areaWord = areaWord;
      this.form = Object.assign({ ...this.form }, { ...rest, areas: newIdList });
    }
  }
  @Watch('form.mobile') ruleChange() {
    if (this.curRecord.addressInfo && this.curRecord.addressInfo.mobile === this.form.mobile) {
      this.rules['mobile'] = [
        { required: true, message: '请输入联系电话', trigger: 'blur' },
        { min: 11, max: 11, message: '请输入有效的电话号码', trigger: 'blur' },
      ];
    } else {
      this.rules['mobile'] = [
        { required: true, message: '请输入联系电话', trigger: 'blur' },
        { min: 11, max: 11, message: '请输入有效的电话号码', trigger: 'blur' },

        { required: true, message: '请输入有效的电话号码', validator: this.validatePhone, trigger: 'blur' },
      ];
    }
  }

  validatePhone(rule, value, callback) {
    if (!/^1[3456789]\d{9}$/.test(value)) {
      callback(new Error('手机号码不合法，请重新输入'));
    }
    callback();
  }
  getOptions(list) {
    let i = 0,
      out: any = [];
    for (; i < list.length; i++) {
      const { type, name, id } = list[i];
      if (type === 'province') {
        out.push({ id, value: id, label: name });
      } else if (type === 'city') {
        let parent = out[out.length - 1];
        parent.children ? '' : (parent.children = []);
        parent.children.push({ id, value: id, label: name });
      } else if (type === 'county') {
        let parent = out[out.length - 1].children;
        let child = parent[parent.length - 1];
        child.children ? '' : (child.children = []);
        child.children.push({ id, value: id, label: name });
      }
    }
    return out;
  }
  // 和移动端用的一个接口
  submitForm() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        const { areas, address, mobile, truename } = this.form;
        if (!areas || areas.length <= 2) {
          this.$message.error('请选择正确的地址');
          return;
        }
        const params = {
          truename,
          address: `${this.areaWord ? `${this.areaWord}-` : ''} ${address}`,
          areaId: areas[2],
          mobile,
        };

        try {
          this.submitting = true;
          await setActMinePrizeRecoAddress({ recoId: this.curRecord.recordId, addressInfo: params });
          if (this.curRecord.addressInfo) {
            this.$message.success('修改成功');
          } else {
            this.$message.success('添加成功');
          }

          this.$emit('update');
          this.submitting = false;
          this.handleClose();
        } catch {
          this.submitting = false;
        }
      } else {
        return false;
      }
    });
  }
  handleClose() {
    this.form = {
      truename: '',
      mobile: '',
      address: '',
      areas: '',
    };
    (this.$refs['form'] as HTMLFormElement).resetFields();
    this.$emit('close');
    this.submitting = false;
  }

  getCascaderObj = function (val, opt) {
    return val.map(function (value) {
      for (var itm of opt) {
        if (itm.value == value) {
          opt = itm.children;
          return itm;
        }
      }
      return null;
    });
  };
  getAreaWord(e) {
    //获取名字
    const newArr = this.getCascaderObj(e, this.city);

    this.areaWord = newArr.map(item => item.label).join(' - ');
  }

  // 通过id获取
  getTreeDeepArr(key, treeData) {
    let arr: any = []; // 在递归时操作的数组

    let returnArr = []; // 存放结果的数组

    let depth = 0; // 定义全局层级

    // 定义递归函数

    const childrenEach = (childrenData, depthN) => {
      for (var j = 0; j < childrenData.length; j++) {
        depth = depthN; // 将执行的层级赋值 到 全局层级

        arr[depthN] = childrenData[j].id;

        if (childrenData[j].id == key) {
          returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，

          break;
        } else {
          if (childrenData[j].children) {
            depth++;

            childrenEach(childrenData[j].children, depth);
          }
        }
      }

      return returnArr;
    };
    return childrenEach(treeData, depth);
  }
  onAi(value) {
    const { code, mobile, name, details } = value;
    name && (this.form.truename = name);
    mobile && (this.form.mobile = mobile);
    details && (this.form.address = details);
    console.log('code', code);
    code && (this.form.areas = this.getTreeDeepArr(code, this.city));
    this.getAreaWord(this.form.areas);
  }
}
