import { request } from '@/utils/axios';

// 获取用户的必答券列表
export function getRequiresByUser(params: any) {
  return request.get('/v2/requires/tickets/user', params, { requestBase: 'doubt' });
}

// 获取测验单题信息
export function getQuizQuestionInfo(params: any) {
  return request.get(`/v2/quiz/question/${params.questionId}/info`, params, { requestBase: 'exam' });
}

// 新问题列表（只提供question表内容）
// export function getQuestions(params:any) {
//   return request.get(`/v2/questions/new`, params, { requestBase: 'exam' });
// }

// 获取题目的关联提问列表
export function getQuestionDoubts(params: any) {
  return request.get(`/v2/doubt/phoenix/question/${params.questionId}/doubts`, params, { requestBase: 'doubt' });
}

// 获取问答分类树
export function getCategoryTree(params: any) {
  return request.get('/v2/doubt/category-tree', params, { requestBase: 'doubt' });
}

// 新增问答提问
export function addDoubt(params: any) {
  return request.post('/v2/doubt', params, { requestBase: 'doubt' });
}

// 获得易错题列表
export function getEasyToWrongQuestions(params: any) {
  return request.get('/v2/review/category/easytowrong', params, { requestBase: 'exam' });
}

// 获取收藏问题列表
export function getQuestionsFavorites(params: any) {
  return request.get('/v2/question/favorites', params, { requestBase: 'exam' });
}

// 获取B豆悬赏数量可选项
export function getRewardChoices(params: any) {
  return request.get('/v2/setting/reward', params, { requestBase: 'doubt' });
}

// 获取用户B豆余额
export function getCashAccount(params: any) {
  return request.get('/v2/user/cash-account', params, { requestBase: 'order' });
}

// 获取用户某分类可用的必答券列表
export function getRequireTicketsByUser(params: any) {
  return request.get('/v2/requires/tickets/user/useable', params, { requestBase: 'doubt' });
}

// 获取当前用户的提问列表
export function getMyDoubtListByPC(params: any) {
  return request.get('/v2/doubt/phoenix/mine/doubt/list', params, { requestBase: 'doubt' });
}

// 获取PC提问悬赏详情
export function getDoubtFlowTips(params: any) {
  return request.get(`/v2/doubt/phoenix/doubt/${params.doubtId}/flow/tips`, params, { requestBase: 'doubt' });
}

// 搜索问答提问信息
export function searchDoubtListByPC(params: any) {
  return request.get('/v2/doubt/phoenix/search', params, { requestBase: 'doubt' });
}

// 搜索问答提问信息
export function getDoubtDetailByPC(params: any) {
  return request.get('/v2/doubt/phoenix/doubt/detail', params, { requestBase: 'doubt' });
}

// 采纳回答
export function adoptExplanation(params: any) {
  return request.post(`/v2/explanations/${params.explanationId}/adopt`, params, { requestBase: 'doubt' });
}

// 添加追问/追答
export function addNewDiscussion(params: any) {
  return request.post('/v2/discussions', params, { requestBase: 'doubt' });
}

// 问答提问好问题点赞
export function addDoubtLike(params: any) {
  return request.post('/v2/doubt/like', params, { requestBase: 'doubt' });
}

// 取消提问好问题点赞
export function delDoubtLike(params: any) {
  return request.delete('/v2/doubt/like', params, { requestBase: 'doubt' });
}

// 收藏问答回答
export function addExplanationFavorite(params: any) {
  return request.post('/v2/favorite/explanation', params, { requestBase: 'doubt' });
}

// 删除问答回答收藏
export function delExplanationFavorite(params: any) {
  return request.delete(`/v2/favorite/${params.explanationId}/explanation`, params, { requestBase: 'doubt' });
}

// 点赞回答信息
export function likeExplanation(params: any) {
  return request.post(`/v2/explanations/${params.explanationId}/like`, params, { requestBase: 'doubt' });
}

// 移出点赞回答信息
export function dislikeExplanation(params: any) {
  return request.delete(`/v2/explanations/${params.explanationId}/like`, params, { requestBase: 'doubt' });
}

// 追加B豆悬赏
export function addDoubtReward(params: any) {
  return request.put(`/v2/doubt/${params.doubtId}/reward`, params, { requestBase: 'doubt' });
}

// 获取必答服务信息
export function getRequireService(params: any) {
  return request.get('/v2/require-service', params, { requestBase: 'doubt' });
}

// 购买必答服务
export function addRequireOrder(params: any) {
  return request.post('/v2/require-service/order', params, { requestBase: 'doubt' });
}

// 根据问答分类获取推荐课程
export function getRecCourseByCategoryId(params: any) {
  return request.get(`/v2/marketing/${params.parentCategoryId}/recommend-courses`, params, { requestBase: 'doubt' });
}

// 获取首页推荐回答
export function getRecDoubts(params: any) {
  return request.get('/v2/doubt/phoenix/doubt/recommend', params, { requestBase: 'doubt' });
}

// 获取题目相关的推荐问答
export function getQuestionRecDoubts(params: any) {
  return request.get(`/v2/doubt/phoenix/question/${params.questionId}/recommend`, params, { requestBase: 'doubt' });
}

// 获取PC问答首页列表
export function getDoubtListByPC(params: any) {
  return request.get('/v2/doubt/phoenix/list', params, { requestBase: 'doubt' });
}

// 获取用户当前提问权限
export function getPermission(params: any) {
  return request.get('/v2/doubt/permission', params, { requestBase: 'doubt' });
}

// 获取有效的配置列表
export function getCfgValids(params: any) {
  return request.get('/quiz/cfg/valids', params, { requestBase: 'guser' });
}

// 获取当前用户的回答收藏列表
export function getMyExplanationFavorite(params: any) {
  return request.get('/v2/doubt/phoenix/mine/explanation/favorite', params, { requestBase: 'doubt' });
}

// 问答提问分享
export function askShare(params: any) {
  return request.post(`/v2/doubt/${params.doubtId}/share`, params, { requestBase: 'doubt' });
}

//获取目标用户的提问列表
export function getUserDoubtList(params: any) {
  return request.get('/v2/doubt/phoenix/user/doubt/list', params, { requestBase: 'doubt' });
}

//获取当前用户的提问列表
export function getUserFavoriteDoubtList(params: any) {
  return request.get('/v2/doubt/phoenix/user/explanation/favorite', params, { requestBase: 'doubt' });
}
