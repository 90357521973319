<template>
  <el-form class="verify-code-login" :model="form" :rules="rules" ref="form">
    <el-form-item prop="mobile" :error="mobileErrorMsg">
      <el-input placeholder="手机号" maxlength="11" @keyup.enter.native="sendVerifyCode" v-model.trim="form.mobile"> </el-input>
    </el-form-item>
    <el-form-item prop="verifyCode" class="verify-code-wrap" :error="codeErrorMsg">
      <el-input placeholder="验证码" maxlength="6" @keyup.enter.native="login" v-model.trim="form.verifyCode"> </el-input>
      <div class="send-msg-btn" :class="verifyCodeTiming || !isMobileValid ? 'disabled' : ''" @click="sendVerifyCode">
        {{ verifyCodeTiming ? `${verifyCodeTiming}秒后重试` : verifyCodeTip }}
      </div>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :loading="loading" class="full-width-btn" :disabled="!isConfirmButtonValid" @click="login">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { loginByMobileAndVerifyCode } from '@/api/login';
import { getSmscode } from '@/api/mojave-v2';
import {setLoginStorage} from '@/utils/repeat-function';

export default {
  name: 'VerifyCodeLoginForm',
  data() {
    return {
      loading: false, // 登录loading
      form: {
        mobile: '',
        verifyCode: '',
      },
      verifyCodeTip: '获取验证码', // 按钮文案
      verifyCodeTiming: 0, // 验证码倒计时
      timingInterval: null,
      mobileErrorMsg: '', // 手机号错误信息
      codeErrorMsg: '', // 验证码错误信息
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^\d{11}$/, message: '请输入正确的手机号', trigger: 'blur' },
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { max: 6, message: '长度最大为 6 位', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    isMobileValid() {
      // 手机号是否合法
      return !!this.form.mobile.match(/^\d{11}$/);
    },
    isConfirmButtonValid() {
      // 登录按钮状态
      return this.isMobileValid && this.form.verifyCode;
    },
  },
  methods: {
    login() {
      // 登录
      if (this.loading) {
        return false;
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.codeErrorMsg = '';

          loginByMobileAndVerifyCode(this.form.mobile, this.form.verifyCode)
            .then(res => {
              setLoginStorage(res);
              this.$emit('loginSuccess');
            })
            .catch(errorData => {
              if (errorData) {
                this.codeErrorMsg = errorData.msg || errorData.message;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    startTiming() {
      // 开始计时
      this.verifyCodeTiming = 60;
      this.timeingInterval = setInterval(() => {
        this.verifyCodeTiming--;
        if (this.verifyCodeTiming <= 0) {
          this.verifyCodeTip = '重新获取';
          clearInterval(this.timeingInterval);
        }
      }, 1000);
    },
    stopTiming() {
      // 结束计时
      this.verifyCodeTiming = 0;
      if (this.timeingInterval) {
        clearInterval(this.timeingInterval);
      }
    },
    sendVerifyCode() {
      if (this.verifyCodeTiming > 0) {
        // 时间未结束
        return;
      }
      this.$refs['form'].validateField('mobile', errMsg => {
        // 本地校验手机号
        if (!errMsg) {
          this.startTiming();

          this.mobileErrorMsg = '';
          getSmscode(this.form.mobile)
            .then(res => {
              console.log(res);
            })
            .catch(errorData => {
              this.mobileErrorMsg = errorData.msg || errorData.message;
              this.stopTiming();
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.verify-code-login {
  width: 252px;
  margin: 20px auto 0;

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    color: #363636;
    height: 31px;
    padding: 7px 7px 7px 0;
    outline-style: none;
    line-height: normal;
    border-radius: 0;
    font-size: 12px;
  }
  ::v-deep .el-form-item__content {
    line-height: 32px;
  }
  .forget-password {
    color: #bbbbbb;
    float: right;
    font-size: 12px;
    margin-top: -15px;
  }
  ::v-deep .verify-code-wrap .el-form-item__content {
    display: flex;
    align-items: flex-end;
  }
  ::v-deep .el-form-item__error {
    text-align: left;
  }
  .send-msg-btn {
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #4b8fff;
    color: #4b8fff;
    height: 26px;
    line-height: 26px;
    flex-shrink: 0;
    padding: 0 8px;
    cursor: pointer;
    margin-left: 10px;

    &.disabled {
      border-color: #cccccc;
      color: #cccccc;
    }
  }
}
.full-width-btn {
  width: 100%;
  background: #4b8fff;
  height: 34px;
  line-height: 34px;
  padding: 0;
  border: none;
  margin: 10px 0 20px 0;

  &:disabled {
    background: #cccccc;
    border-color: #cccccc;
    color: #ffffff;
  }
}
</style>
