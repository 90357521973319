import { request } from '@/utils/axios';
import { accountLoginParams, smsCodeLoginParams } from '@/typings';
import { IConfig } from 'weixin-js-sdk';

// 账号密码登录
export function accountPasswordLogin(params: accountLoginParams) {
  return request.post('/user/account-password/login', params, { requestBase: 'mojave', noAccess: true });
}

// 获取手机验证码
export function getSmscode(mobile: string) {
  return request.get('/user/login-smscode', { mobile }, { requestBase: 'mojave', noAccess: true });
}

// 手机验证码登录
export function smscodeLogin(params: smsCodeLoginParams) {
  return request.post('/user/smscode/login', params, { requestBase: 'mojave', noAccess: true });
}

// 获取七牛音频信息
export function getQiniuAudioInfo(src) {
  return request.get('/v2/qiniu/audio-avinfo', { src }, { requestBase: 'mojave' });
}

// 获取微信 js sdk 配置
export function getWxJsConfig() {
  return request.get<IConfig>('/v2/wx/js-config', { url: location.href }, { requestBase: 'mojave', noAccess: true });
}

// 上报日志
export function postLogData(data) {
  return request.post<IConfig>('/log-data', data, { requestBase: 'mojave' });
}
