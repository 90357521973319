









































import { Vue, Component, Prop } from 'vue-property-decorator';
import NP from 'number-precision';

@Component({
  components: {},
})
export default class FacingCard extends Vue {
  @Prop({ type: Object }) cardData: any;
  @Prop({ type: String, default: 'default' }) type: any; // default 默认 ｜ unuseable 已使用/历史 ｜ choose 可选择
  @Prop({ type: Boolean, default: false }) fixedNum: any; // 固定张数为1张
  @Prop({ type: Number, default: 0 }) activeId: any;

  tipVisible: boolean = false; // 规则说明弹窗

  // 历史必答券已使用used/已过期failure
  get useType() {
    return this.cardData.useCount === this.cardData.usedCount ? 'used' : 'failure';
  }

  // 是否历史（已使用）模式
  get isUnuseable() {
    return this.type === 'unuseable';
  }

  // 是否可选择
  get isChoose() {
    return this.type === 'choose';
  }

  // 承诺天数
  get replyTimeText() {
    const replyTime = this.cardData.replyTime;
    // 超过48小时显示天数
    if (replyTime > 48) {
      return {
        cn: NP.divide(replyTime, 24) + '天',
        us: NP.divide(replyTime, 24) + '天',
      };
    }
    // 否则显示小时制
    return {
      cn: replyTime + '小时',
      us: replyTime + 'h',
    };
  }

  // 获取所有适用科目名称
  get categorysText() {
    // 全部分类
    if (!this.cardData.categorys.length) {
      return { str: '全部分类', allStr: '全部分类' };
    }
    // 否则统计科目
    let str = '';
    let allStr = '';
    this.cardData.categorys.forEach(item => {
      const parentName = item.parentName ? item.parentName + '·' : '';
      // 有二级科目显示，兼容旧数据
      if (item.name) {
        if (!str) {
          str = `${parentName}${item.name}`;
          allStr = `${parentName}${item.name}`;
        } else {
          allStr = `${allStr}、${parentName}${item.name}`;
        }
      }
    });
    return { str: str || '-', allStr: allStr || '-' } as any;
  }

  // 适用科目数量
  get categorysLength() {
    return this.cardData.categorys.length;
  }

  // 打开说明弹窗
  openRules() {
    this.tipVisible = true;
  }

  // 关闭说明弹窗
  closeRules() {
    this.tipVisible = false;
  }

  // 选择当前必答券
  activeCard() {
    this.$emit('activeCard', this.cardData);
  }
}
