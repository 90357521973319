const FetchSetting = () => import('@/pc/views/fetch-setting/index.vue');

export default [
  {
    path: '/fetch-setting',
    name: 'fetch_setting',
    component: FetchSetting,
    meta: {
      isPC: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
];
