


























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { getReportActScoreConfig } from '@/api/course';
import LiveLessons from './components/live-lessons.vue';
import { trackEvent } from '@/utils/helper';

@Component({
  components: {
    swiper,
    swiperSlide,
    LiveLessons,
  },
})
export default class GradeModule extends Vue {
  @Prop({ type: Object, default: () => ({}) }) rankData;
  dataReady: boolean = false; // 是否加载完数据
  rankList: any[] = []; // 排行榜
  isDialogVisible: boolean = false; //
  qrDialogData: any = {};

  // 左边排行榜swiper配置
  rankSwiperOption: any = {
    loop: true,
    autoplay: {
      delay: 2000,
      // stopOnLastSlide: false,
    },
    slidesPerView: 15,
    direction: 'vertical',
    noSwiping: true,
  };

  get actId() {
    return this.rankData.actId || 78;
  }

  async mounted() {
    this.getData();
    this.winScrollHandler();
    window.addEventListener('scroll', this.winScrollHandler);
  }
  winScrollHandler() {
    const el = this.$refs.rankContent as HTMLElement;
    if (document.documentElement.scrollTop + window.innerHeight > el.offsetTop + 250) {
      if (this.dataReady) {
        if (this.rankList.length && this.rankList.length > 15) {
          (this.$refs.rankList as any).swiper.autoplay.start();
        }
        window.removeEventListener('scroll', this.winScrollHandler);
      }
    }
  }

  destroyed() {
    window.removeEventListener('scroll', this.winScrollHandler);
  }
  // 获取排行榜数据
  async getData() {
    this.rankList = [];
    let { list = {}, groupName = [] } = await getReportActScoreConfig(this.actId);
    groupName.forEach(name => {
      if (list[name].length) {
        if (name !== 'nogroup') {
          this.rankList.push({ type: 'title', title: name, _id: name });
        }
        list[name].forEach((item, index) => {
          this.rankList.push(Object.assign({}, item, { type: 'data', sIndex: index }));
        });
      }
    });
    this.dataReady = true;
    if (!this.rankList.length || this.rankList.length < 15) {
      this.rankSwiperOption = Object.assign({}, this.rankSwiperOption, { loop: false, autoplay: false });
    } else {
      this.$nextTick(() => {
        (this.$refs.rankList as any).swiper.autoplay.stop();
      });
    }
  }

  mouseenter() {
    (this.$refs.rankList as any).swiper.autoplay.stop();
  }
  mouseleave() {
    (this.$refs.rankList as any).swiper.autoplay.start();
  }

  // scrollTo(name) {
  //   try {
  //     scrollWatch.scrollTo(name);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  goLink(link) {
    window.open(link);
    try {
      this.$sensors.track('bt_10601', {
        description: '查看经验帖',
        url: link,
      });
    } catch (e) {
      console.log('查看经验帖埋点出错', e);
    }
  }

  // 底部按钮点击事件
  clickHandle({ isDialog = false, dialogData = {}, url = '', eventId, description }) {
    //eventId = 0, description = ''
    if (isDialog) {
      this.qrDialogData = dialogData;
      this.isDialogVisible = true;
    } else if (url) {
      window.open(url);
    }
    trackEvent(`bt_${eventId}`, { description });
  }

  track(id) {
    trackEvent('bt_10658', { dynamicId: id, description: '查看经验贴' });
  }
}
