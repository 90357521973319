<template>
  <transition name="fade" mode="out-in" v-if="menus && menus.length">
    <el-scrollbar class="home-menu home-right-menu affix" :class="{ 'no-border': !show }">
      <div class="menus" id="menus" v-show="show">
        <template v-for="(menu, index) in menus">
          <a :key="index" :data-code="menu.code" :id="menu.code" @click="scrollTo(menu.code)" :class="active == menu.code ? 'active' : ''">{{ menu.name }}</a>
        </template>
        <a href class="mask-replace" v-if="showBottomMask"></a>
      </div>
      <div class="bottom-mask" v-if="showBottomMask"></div>
    </el-scrollbar>
  </transition>
</template>

<script>
export default {
  props: {
    scrollTo: Function,
    active: String,
    courseCategorys: Array,
  },
  data() {
    return {
      show: false,
      showBottomMask: false,
    };
  },
  computed: {
    menus() {
      let courseCategorys = this.courseCategorys;
      return [].concat(courseCategorys);
    },
  },
  watch: {
    show: {
      handler: function (val) {
        this.$bus.$emit('homeMenuStatus', val);
      },
      immediate: true,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
  },
  methods: {
    onScroll() {
      let height = window.innerHeight;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.show = scrollTop >= height - 100;
      let codes = [];
      this.menus.forEach(el => {
        codes.push(el.code);
      });
      if (codes.includes(this.active)) {
        document.getElementById(this.active).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        // let lastCodes = this.menus.slice(this.menus.length - 5).map(el => el.code);
        // if (lastCodes.includes(this.active)) {
        //   this.showBottomMask = false;
        // } else {
        //   this.showBottomMask = true;
        // }
      }
    },
  },
};
</script>

<style lang="scss">
.home-right-menu {
  .el-scrollbar__bar {
    display: none;
  }
}
</style>
