<template>
  <div class="home-course-open">
    <div class="course-area">
      <div class="pc-container">
        <div class="text-line head">
          <h5 class="anniversary-model-title">
            <span>免费公开课</span>
            <!-- <a v-if="$root.isAnniversary && $root.anniversaryLinkImg" :href="$root.anniversaryLink" target="_blank" class="invite-anniversary">
              <img :src="$root.anniversaryLinkImg" v-default-img-alt="'七周年'" />
            </a> -->
            <OperatPlaceCom :subscene="0" title="免费公开课"></OperatPlaceCom>
          </h5>
          <a href="/open/course/explore" class="open">查看更多</a>
        </div>
        <open-course-model :data="data" v-if="data && hasMounted"></open-course-model>
      </div>
    </div>
  </div>
</template>

<script>
import OpenCourseModel from './components/OpenCourseModel';
import { getCourseList } from '@/api/course';
import OperatPlaceCom from '@/pc/views/home/components/OperatPlace.vue';

export default {
  components: {
    OpenCourseModel,
    OperatPlaceCom,
  },
  data() {
    return {
      data: 8,
      hasMounted: false,
    };
  },
  mounted() {
    this.hasMounted = true;
    this.init();
  },
  methods: {
    async init() {
      // 获取公开课列表
      let { rows } = await getCourseList({ limit: 8, type: 'open', status: 'published', ns: 'bt' });
      this.data = rows;
    },
  },
};
</script>
