








































import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';

import { getBookCategoryTree, getBookErrataList } from '@/api/book-errata';

@Component({
  components: {
    MainLoading: () => import('@/pc/components/loading/main-loading.vue'),
    DropdownType: () => import('./dropdown-type.vue'),
    SelectSearch: () => import('./select-search.vue'),
    ItemBook: () => import('./item-book.vue'),
  },
})
export default class Demo extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({
    type: Object,
    default: () => ({
      bookInfo: {},
      page: '',
      content: '',
      pictures: [],
    }),
  })
  errataInfo: any;

  chooseBookInfo: any = {};

  pageLoading: boolean = true;
  // 搜索栏数据
  filtersData: any = {
    categories: [],
    years: [],
  };
  history: any = {}; // 搜索历史
  recommend: any = {}; // 推荐
  // 搜索数据
  filters: any = {
    bookCategoryInfo: {},
    categoryInfo: {},
    yearInfo: {},
  };

  // 分页器数据
  pagination: any = {
    total: 0,
    page: 1,
    limit: 10,
    needCount: 1,
  };
  isLoading: boolean = true; // 刷新加载
  moreLoading: boolean = false; // 更多加载
  booksList: any = [];

  closeFun() {
    this.syncVisible = false;
  }

  // 选择书籍
  submit() {
    try {
      this.$emit('chooseBookFun', this.chooseBookInfo);
    } catch (e) {
      console.log('选择书籍失败', e);
    } finally {
      this.syncVisible = false;
    }
  }

  // 获取分类信息
  allCategoryMap: any = {};
  async _getBookCategoryTree() {
    try {
      const { rows, history, recommend } = await getBookCategoryTree({});
      // 过滤掉没有子分类的项目
      const filterRows = rows.filter((parent: any, parentIndex: any) => {
        if ((parent.children || []).length) {
          parent.children = parent.children.filter((child: any, childIndex: any) => {
            return child.showStatus === 1;
          });
          return parent.showStatus === 1 && parent.name !== '其他';
        }
        return false;
      });
      filterRows.forEach((parent: any, parentIndex: any) => {
        this.allCategoryMap[parent.id] = parentIndex;
        parent.children.forEach((child: any, childIndex: any) => {
          this.allCategoryMap[child.id] = childIndex;
        });
      });
      this.filtersData.categories = filterRows || [];
      this.history = history || {};
      this.recommend = recommend || {};
      // 则默认选择第一个
      if (!this.filters.bookCategoryInfo.id) {
        this.filters.categoryInfo = this.filtersData.categories[0];
        console.log('系统默认');
        this.filters.bookCategoryInfo = this.filters.categoryInfo.children[0];
      }
    } catch (e) {
      console.log('获取分类信息失败', e);
    }
  }

  // 获取年份信息
  async _getYears() {
    // 获取当前日期
    const currentDate = new Date();
    // 获取当前年份
    const currentYear = currentDate.getFullYear();
    // 计算前一年和后一年的年份
    const lastYear = currentYear - 1;
    const nextYear = currentYear + 1;
    this.filtersData.years = [
      {
        id: lastYear,
        shortName: lastYear + '年',
        name: lastYear + '年',
      },
      {
        id: currentYear,
        shortName: currentYear + '年',
        name: currentYear + '年',
      },
      {
        id: nextYear,
        shortName: nextYear + '年',
        name: nextYear + '年',
      },
    ];
    // 默认选择当前年份
    if (!this.filters.yearInfo.id) {
      this.filters.yearInfo = this.filtersData.years[1];
    }
  }

  async updateList(init = true) {
    if (init) {
      this.isLoading = true;
      this.pagination.page = 1;
    }
    try {
      const { count, rows } = await getBookErrataList({
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        needCount: this.pagination.needCount,
        bookCategoryId: this.filters.bookCategoryInfo.id,
        year: this.filters.yearInfo.id,
        justBook: 1,
        status: 'published',
        showInErrata: 1,
      });
      this.pagination.total = count;
      if (init) {
        this.booksList = rows || [];
      } else {
        this.booksList = [...this.booksList, ...rows];
      }
      console.log('刷新列表', count, rows);
    } catch (e) {
      console.log('刷新列表失败', e);
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    }
  }

  // 更多加载
  async moreList() {
    if (this.moreLoading) return;
    this.moreLoading = true;
    // 页码+1
    this.pagination.page++;
    try {
      await this.updateList(false);
    } finally {
      this.moreLoading = false;
    }
  }

  async watchScroll(e) {
    const scrollTop = e.target.scrollTop || 0;
    const clientHeight = e.target.clientHeight || 0;
    // @ts-ignore
    const scrollHeight = (this.$refs.bookList && this.$refs.bookList.scrollHeight) || 0;
    if (scrollHeight - scrollTop - clientHeight < 100) {
      await this.moreList();
      console.log('滚动底部');
    }
    console.log('watchScroll', scrollHeight - scrollTop - clientHeight);
  }

  @Watch('syncVisible', { immediate: true })
  async watchVisible() {
    // 重新打开时，选择已选择的教材
    if (this.syncVisible) {
      this.chooseBookInfo = this.errataInfo.bookInfo;
      // 有已选择的教材，加载对应分类数据
      if (this.chooseBookInfo.bookCategoryId && !this.pageLoading) {
        const parentIndex = this.allCategoryMap[this.chooseBookInfo.bookCategoryPid];
        const childIndex = this.allCategoryMap[this.chooseBookInfo.bookCategoryId];
        const next = parentIndex !== undefined && childIndex !== undefined;
        // 找不到不处理
        if (next) {
          // 父
          this.filters.categoryInfo = this.filtersData.categories[parentIndex] || {};
          // 子
          this.filters.bookCategoryInfo = this.filters.categoryInfo.children[childIndex] || {};
          // 子下标
          this.filters.bookCategoryIndex = childIndex;
        }
      }
      await this.updateList();
    }
  }

  // 选择教材
  chooseBookFun(bookInfo) {
    this.chooseBookInfo = bookInfo;
  }

  async created() {
    await this._getBookCategoryTree();
    await this._getYears();
    this.pageLoading = false;
    await this.updateList();
    console.log('获取分类信息', this.filters, this.filtersData);
  }

  // 点击在当前页进入【我的反馈-已处理列表页】
  goMyErrata() {
    this.$router.push({ name: 'book-errata-my' });
    console.log('跳转我的修订页面');
  }
}
