import { request } from '@/utils/axios';
import * as qiniu from 'qiniu-js';

// 需要登陆

// 获取七牛token
export function getQiniuToken(prefix) {
  if (prefix) {
    return request.throughMojaveApi({
      url: '/qiniu/up-token',
      method: 'get',
      params: {
        prefix,
      },
    });
  }
  return request.get('/upload/qiniu-token', {}, { requestBase: 'phoenix' });
}

//上传文件结束
export function uploadFinished(data) {
  return request.post('/upload/finished', data, { requestBase: 'phoenix' });
}

//获取七牛url
export function getQiniuUrl(token) {
  return qiniu.getUploadUrl({}, token).then(res => {
    return res;
  });
}
