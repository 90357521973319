


















































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import SmsCodeHelper from '@/utils/sms-code-helper';
const smsCodeHelper = new SmsCodeHelper('<% %>秒后重试');

type FormValidator = {
  val: string;
  errorMessage: string;
  validator: (form?: any) => Promise<boolean>;
};

@Component({
  components: {},
  computed: {
    ...mapGetters('my', ['me']),
  },
  methods: {
    ...mapActions('my', ['getMyInfo']),
  },
})
export default class BindMobileModal extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        title: '绑定手机号',
        submitText: '完成激活',
        jumpText: '暂不绑定手机，继续领券',
        customCallBack: false,
        canJump: false,
        customSubtitle: false,
      };
    },
  })
  options: any;
  @PropSync('visible', { type: Boolean, default: false }) visibleSync;
  @Prop({ type: String, default: 'bind-mobile' }) typeName: any;

  me: any;
  isSendSmsCode: boolean = false;
  smsCodeBtnText: string = '获取验证码';
  form = {
    phoneNum: {
      val: '',
      errorMessage: '',
      async validator() {
        if (!this.val) {
          this.errorMessage = '手机号不能为空';
          return false;
        }
        if (!/^1\d{10}$/.test(this.val)) {
          this.errorMessage = '手机格式错误';
          return false;
        }
        return true;
      },
    },
    smsCode: {
      val: '',
      errorMessage: '',
      async validator(form: any) {
        if (!this.val) {
          this.errorMessage = '验证码不能为空';
          return false;
        }

        const valid = await SmsCodeHelper.verifySmsCode({ mobile: form.phoneNum.val, smscode: this.val });
        if (!valid) {
          this.errorMessage = '验证码输入错误';
          return false;
        }
        return true;
      },
    },
    async validator() {
      const form = this;
      const task: Promise<boolean>[] = [];
      Object.entries(form).forEach(async ([key, val]) => {
        if (key !== 'validator') {
          task.push((val as FormValidator).validator(form));
        }
      });

      const values = await Promise.all(task);

      return values.every(v => v);
    },
  };

  // 校验手机号码
  get checkPhone() {
    return !!this.form.phoneNum.val.match(/^\d{11}$/) && this.form.phoneNum.errorMessage === '';
  }
  // 清除报错
  clearError(field: FormValidator) {
    field.errorMessage = '';
  }

  @Watch('visibleSync')
  watchVisible() {
    // 打开窗口的时候，初始化一些数据
    if (this.visibleSync) {
      this.isSubmitting = false;
      this.form.phoneNum.val = '';
      this.form.smsCode.val = '';
      smsCodeHelper.clearInterval();
    }
  }

  mounted() {
    smsCodeHelper.onWaitSecondsChange = msg => {
      this.smsCodeBtnText = msg;
      this.isSendSmsCode = true;
    };
    smsCodeHelper.onStop = () => {
      this.smsCodeBtnText = '获取验证码';
      this.isSendSmsCode = false;
    };
  }

  // 发送验证码
  async sendSmsCode() {
    const mobileValid = await this.form.phoneNum.validator();
    if (!mobileValid) {
      return;
    }
    this.isSendSmsCode = true;
    await smsCodeHelper.sendSmsCode({ mobile: this.form.phoneNum.val });
  }

  // 绑定手机号
  isSubmitting: boolean = false;
  async submit() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    try {
      const valid = await this.form.validator();
      if (!valid) {
        this.isSubmitting = false;
        return;
      }
      // @ts-ignore 绑定成功更新下本地用户信息
      this.getMyInfo({});
      // 自定义回调
      if (this.options.customCallBack) {
        this.$emit('bindMobileSuccess', this.form.phoneNum.val);
      } else {
        this.visibleSync = false;
      }
    } catch (e) {
      this.isSubmitting = false;
    }
  }
  // 跳过校验
  jumpVerify() {
    const nowTime = new Date().getTime();
    localStorage.setItem(`${this.typeName}`, nowTime + '');
    this.$emit('bindMobileSuccess', 'jumpVerify');
  }
  // 关闭弹窗
  closeDialog() {
    this.visibleSync = false;
    this.$emit('closeDialog');
  }
}
