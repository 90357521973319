
























import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component({})
export default class BindMobileModal extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) visibleSync;
}
