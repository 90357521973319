



















import { Vue, Component } from 'vue-property-decorator';
import CircleCardCom from './components/circle-card-item.vue';
import SearchBlock from './components/side-bar/search-block.vue';
import HotLessons from './components/side-bar/hot-lesson.vue'; // 直播公开课
import HotStoried from './components/side-bar/hot-storied.vue'; // 热门动态
import { getMoreCircleList } from '@/api/circle';
import { CircleItem } from '@/typings';

@Component({
  components: {
    CircleCardCom,
    SearchBlock,
    HotLessons,
    HotStoried,
  },
})
export default class ComName extends Vue {
  circleList: CircleItem[] = [];
  pagination;
  isLoading: boolean = false;

  async mounted() {
    try {
      this.isLoading = true;
      const { rows } = await getMoreCircleList({ page: 0, limit: 100 });
      this.circleList = rows;
      this.$emit('breadChange', {
        show: true,
        data: [
          { name: 'BT教育', path: '/' },
          { name: '校友圈主页', to: { path: '/circle' } },
          { name: '更多圈子', path: this.$route.path },
        ],
      });
    } finally {
      this.isLoading = false;
    }
  }
}
