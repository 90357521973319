


















import { Vue, Component, PropSync } from 'vue-property-decorator';
import CircleCardCom from './circle-card-item.vue';
import { CircleItem } from '@/typings';
import { getStartPageCircle, enterAluminCircle } from '@/api/circle';

@Component({
  components: {
    CircleCardCom,
  },
})
export default class ComName extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) visibleSync;

  isLoading: boolean = false;
  circleList: CircleItem[] = [];

  get choseCircles() {
    return (this.circleList && this.circleList.filter(item => item.isEntered)) || [];
  }

  mounted() {
    this.getCircleList();
  }

  async getCircleList() {
    try {
      this.isLoading = true;
      const { startPageLimit, rows } = await getStartPageCircle();
      console.log('startPageLimit', startPageLimit);
      this.circleList = rows;
    } finally {
      this.isLoading = false;
    }
  }

  goCircleHome() {
    this.visibleSync = false;
    enterAluminCircle();
  }
}
