import { render, staticRenderFns } from "./transparent-mode.vue?vue&type=template&id=2bc62e63&scoped=true&"
import script from "./transparent-mode.vue?vue&type=script&lang=ts&"
export * from "./transparent-mode.vue?vue&type=script&lang=ts&"
import style0 from "./transparent-mode.vue?vue&type=style&index=0&id=2bc62e63&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc62e63",
  null
  
)

export default component.exports