










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class MenuShell extends Vue {
  isFixed: boolean = false;
  isAbsolute: boolean = false;
  menuContentStyle: any = {
    height: '80%',
  };
  menuRestHeight: number = 0;
  kongStyle: any = {
    width: '0px',
  };
  watchResize() {
    const clientHeight: any = (document.documentElement || document.body).clientHeight || 0;
    this.menuContentStyle.height = clientHeight * 0.8 + 'px';
    this.menuRestHeight = clientHeight * 0.2;
  }

  watchScroll(e) {
    const domHead: any = document.querySelector('.bt-header');
    const domFooter: any = document.querySelector('.bt-footer');
    const scrollTop: any = (document.documentElement || document.body).scrollTop || 0;
    const clientHeight: any = (document.documentElement || document.body).clientHeight || 0;
    const scrollHeight: any = (document.documentElement || document.body).scrollHeight || 0;
    this.kongStyle.width = ((document.getElementById('menu-content') || {}).clientWidth || 0) + 'px';
    this.isFixed = scrollTop > domHead.clientHeight + 30;
    this.isAbsolute = scrollHeight - clientHeight - scrollTop - 30 + this.menuRestHeight < domFooter.clientHeight;
    // console.log('domHead', domHead.clientHeight);
    // console.log('scrollTop', scrollHeight - clientHeight - scrollTop - 30 + this.menuRestHeight, domFooter.clientHeight);
  }

  mounted() {
    window.addEventListener('resize', this.watchResize);
    window.addEventListener('scroll', this.watchScroll);
    this.watchResize();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.watchResize);
    window.removeEventListener('scroll', this.watchScroll);
  }
}
