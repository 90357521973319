


























import { Vue, Component } from 'vue-property-decorator';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import LiveLessonItem from './live-lesson-item.vue';
import BindMobile from '../bind-mobile.vue';
import { getChoicenessPageLiveItems } from '@/api/home';

@Component({
  components: {
    swiper,
    swiperSlide,
    LiveLessonItem,
    BindMobile,
  },
})
export default class HotLesson extends Vue {
  lessons: any[] = [];
  dataIsReady: boolean = false;
  scrollIndex: number = 0;
  isBindDialogVisible: boolean = false; // 绑定手机号弹窗
  editLesson: any = null; // 当前编辑lesson

  // // 视频swiper配置
  // videoListSwiperOption = {
  //   loop: false,
  //   direction: 'vertical',
  //   slidesPerView: 3,
  //   slidesPerGroup: 1,
  //   noSwiping: true,
  //   // autoplay: {
  //   //   delay: 2000,
  //   // },
  //   navigation: {
  //     nextEl: '.turn-btn.next-btn',
  //     prevEl: '.turn-btn.prev-btn',
  //   },
  // };

  mounted() {
    this.getLessonData();
  }

  async getLessonData() {
    try {
      // 获取直播课时数据
      this.lessons = (await getChoicenessPageLiveItems()) || [];
    } catch (e) {
      console.log('获取直播失败');
    } finally {
      this.dataIsReady = true;
    }
  }

  // 绑定手机号结束后自动调用预约逻辑
  closeHandle() {
    if (!this.editLesson) return;
    (this.$refs[`refLesson${this.editLesson.id}`] as any)[0].sendReserveRequest();
  }
}
