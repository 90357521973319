<template>
  <div class="inline-block">
    <div class="inline-block"><img class="mr5" v-for="(val, index) in num" :key="index" src="../../../../../../assets/images/home/icon-star.png" /></div>
  </div>
</template>

<script>
export default {
  name: 'Star',
  props: {
    num: {
      type: Number,
      default: 0,
      required: false,
      validator: num => num >= 0 && num <= 5
    }
  }
};
</script>
