



























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Lotter9 from './lottery-9.vue';
import { mapGetters, mapMutations } from 'vuex';
import { getPrizes, getFreeTasks, getUserRouletteRecords, getFreeNum } from '@/api/activity';
import { getRemainingSum } from '@/api/my_coin';
import TipsDialog from './tips-dialog.vue';
import AddressDialog from './address/modal.vue';
import { trackEvent } from '@/utils/helper';
import dayjs from 'dayjs';

@Component({
  filters: {
    formatTime(val) {
      if (!val) return '-';
      return dayjs(Math.floor(val * 1000)).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  components: {
    Lotter9,
    TipsDialog,
    AddressDialog,
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class LotteryCom extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data;
  @Prop({ type: Boolean, default: false }) isHasScoreAndInTime;
  curMenu: string = 'task';
  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  prizeList: any[] = [];
  remainingFreeNum: number = 0; // 免费抽奖次数
  myCoinNum: number = 0; // 我的B豆
  myLotteryRecords: any[] = [];
  ruleTipsDialog: boolean = false;
  isAddressDialogVisible: boolean = false;
  curRecord: any = {};

  // 抽中奖要获取更新b豆以及我的奖品列表
  async updateInfo() {
    try {
      const complteTaskRes = await getFreeTasks();
      Object.keys(this.taskList).forEach(key => {
        this.taskList[key]['isComplte'] = complteTaskRes[key];
      });
      console.log('freeTasks', this.taskList);
      const [records, { remainingFreeNum }, { cash }] = await Promise.all([getUserRouletteRecords(), getFreeNum(), getRemainingSum()]);
      this.remainingFreeNum = remainingFreeNum || 0;
      this.myCoinNum = cash || 0;
      this.myLotteryRecords = records;
      console.log('records', records);
    } catch (e) {
      console.log('出错了', e);
    }
  }

  // 获取中奖记录
  async getRecords() {
    const records = await getUserRouletteRecords();
    this.myLotteryRecords = records;
    this.$forceUpdate();
  }

  async created() {
    // 获取奖品列表和免费
    const prizeList = await getPrizes();
    [0, 1, 2, 7, 3, 6, 5, 4].map((key: number) => {
      this.prizeList.push(prizeList[key]);
    });
    console.log('prizeList', this.prizeList);
    if (this.isLogin) {
      this.updateInfo();
    }
    this.getRandomNotice();
  }



  taskList: any = {
    reportScore: {
      img: 'https://tmp.btclass.cn/yunyin/o_1hf8sl5qs1jvmqll1k6s1pss1rd9a.png',
      title: 'CPA报分有奖',
      tip: '赠送1次抽奖机会',
      isComplte: false,
      isCpaReport: true
    },
    loginAct: {
      img: 'https://tmp.btclass.cn/yunyin/o_1he25hkh7isn11da1tm81evd6ave.png',
      title: '每天在活动主页登录',
      tip: '每天赠送1次抽奖机会',
      isComplte: false,
    },
    buyCourse: {
      img: 'https://tmp.btclass.cn/yunyin/o_1he25hkh71u8k1p171g0h1pl1inci.png',
      title: '每单购课满￥300',
      tip: '每单赠送1次抽奖机会',
      isScroll: true,
      isComplte: false,
      eventId: '10687',
    },
    rcmdV2: {
      img: 'https://tmp.btclass.cn/yunyin/o_1he25hkh71png8ci1en8ppshqoh.png',
      title: '首次推荐好友购课成功',
      tip: '每天赠送1次抽奖机会',
      isInvite: true,
      isComplte: false,
      eventId: '10688',
    },
    learn: {
      img: 'https://tmp.btclass.cn/yunyin/o_1he25hkh71dbdli713pse8d62rg.png',
      title: '每天听课超过10分钟',
      tip: '每天赠送1次抽奖机会',
      url: '/my/courses/learning',
      isComplte: false,
      eventId: '10689',
    },
    finishTest: {
      img: 'https://tmp.btclass.cn/yunyin/o_1he25hkh7i1cvh11c931op21jo4f.png',
      title: '每天完成一次交卷',
      tip: '每天赠送1次抽奖机会',
      url: '/my/quiz/index',
      isComplte: false,
      eventId: '10690',
    },
  };

  // 修改分类获取对应数据
  changeMenu(menu) {
    this.curMenu = menu;
    trackEvent(`bt_${menu === 'task' ? '10683' : '10684'}`, {
      description: menu === 'task' ? '做任务得抽奖机会' : '我的奖品',
    });
  }
  menuTypeChange({ type }) {
    this.curMenu = type;
  }

  // 去登录
  loginHandle() {
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
    }
  }

  // 增加编辑地址
  editAddress({ id, data }) {
    console.log('id', id, 'data', data);
    this.curRecord['recordId'] = id;
    this.curRecord['type'] = data['addressInfo'] ? 'edit' : 'add';
    this.curRecord['addressInfo'] = null;
    if (data['addressInfo']) {
      this.curRecord['addressInfo'] = data['addressInfo'];
    }
    this.$forceUpdate();
    this.isAddressDialogVisible = true;
  }

  // 去我的B豆页面
  seeMyCoin() {
    window.open('/my/coin');
  }

  openQiyu() {
    // @ts-ignore
    window.ysf('open');
  }

  // 跳转至优惠券详情页
  goCouponDetial({ couponBatchInfo }) {
    const { href } = this.$router.resolve({
      name: 'coupon_receive',
      query: {
        batchId: couponBatchInfo.id,
        promoteType: couponBatchInfo.promoteType,
      },
      params: {
        token: couponBatchInfo.token,
      },
    });
    window.open(href);
  }

  // 跳转至双十一板块
  goDouble11Block() {
    this.$emit('scrollTo', 'normalCourses');
  }

  // 查看规则
  seeRule() {
    trackEvent('bt_10691', {
      description: '抽奖详情规则查看',
    });
    this.ruleTipsDialog = true;
  }

  // 获取抽奖次数任务点击
  taskHandle({ isScroll, url, isInvite, eventId, title, isCpaReport }) {
    trackEvent(`bt_${eventId}`, {
      description: title,
    });
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    // 判定为购课跳转双十一板块
    if (isScroll) {
      this.goDouble11Block();
    } else if (isCpaReport) {
      this.$emit('dialog', {
        qrUrl: "https://app-cdn.btclass.cn/new-prefix/turtle/FpKsKNrcBScHhTJw9ld2d2zrBvVN.png",
        sTitle: '报分有奖，查看全国排行榜',
        tips: '微信扫码即可报分',
        title: '中级会计报分'
      });
    } else if (url) {
      // 课程和做题记录链接跳转
      window.open(url);
    } else if (isInvite) {
      // 邀请好友打开弹窗
      this.$emit('dialog', this.data.dialogData);
      console.log('打开推荐好友弹窗');
    }
  }

  // 抽奖任务去报分
  reportScoreHandle(type) {
    // 1: cpa 综合报分 2:cpa专题报分
    const dialogData = {
      // qrUrl: type == 1 ? 'https://app-cdn.btclass.cn/new-prefix/turtle/FjJgXBMRNw1HjKbHupY29inG1mLg.png' : 'https://app-cdn.btclass.cn/new-prefix/turtle/FqbONe-3vueCEZfQbLAoeGzDtUnn.png',
      // sTitle: '报分有奖，查看全国排行榜',
      // tips: '微信扫码即可报分',
      // title: type == 1 ? 'CPA【综合】报分' : 'CPA【专业】报分',
      type: "imgDailog",
      imgUrl: "https://app-cdn.btclass.cn/new-prefix/turtle/FgUCMixe_ikxoiBCWwva8ypOVPBD.png",
      closeHot: {
        width: "1rem",
        height: "1rem"
      }
    };
    this.$emit('dialog', dialogData);
  }

  // 生成随机播报数据
  randomNoticeList: any = [];
  // 姓氏
  surnames: any = ['王', '李', '张', '刘', '陈', '杨', '赵', '黄', '周', '吴', '徐', '郑', '谢', '韩', '唐', '冯', '锺', '汪', '田', '任', '姚', '方', '金', '古', '米', '吴', '鲁', '汤', '俞', '皮'];
  // 名字
  nameText: any = ['**', '*'];
  // 时间
  timeText: any = ['今天', '1天前'];
  // 文案
  giftText: any = ['抽中华为Mate XT手机一台', '抽中120元优惠券 一张', '抽中卡西欧计算器 一台', '抽中1B豆', '抽中1000元优惠券 一张', '抽中定制毛毡包 一个', '抽中20B豆'];

  getRandomNotice() {
    this.randomNoticeList = [];
    Array.from({ length: 50 }).forEach(() => {
      let surnames = this.surnames[Math.floor(Math.random() * this.surnames.length)] || '陈';
      let nameText = this.nameText[Math.floor(Math.random() * this.nameText.length)] || '*';
      let giftText = this.giftText[Math.floor(Math.random() * this.giftText.length)] || '抽中20B豆';
      let timeText = this.timeText[Math.floor(Math.random() * this.timeText.length)] || '今天';
      this.randomNoticeList.push('恭喜' + surnames + nameText + ' ' + timeText + giftText);
    });
  }
}
