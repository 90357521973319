import { request } from '@/utils/axios';

// 获取题库分类列表 用户有权限的排在前面
export function getTikuCategorys(params: any) {
  return request.get('/v2/category/subjects', params, { requestBase: 'exam' });
}

// 获取收藏题目数据及额外信息
export function getFavoriteRangeInfo(params: any) {
  return request.get('/v2/question/favorite/rangeInfo', params, { requestBase: 'exam' });
}

// 导出题目 导出 | 导出题目信息
export function paperExport(params: any) {
  return request.post(`/v2/exam/export/questions`, params, { requestBase: 'dataAnalysis' });
  // return request.throughApi('data-analysis.exam.exportQuestions', params, { authKey: 'userId', isAuth: true });
}

// 导出题目 导出 V2接口
export function paperExportV2(params: any) {
  return request.post('/v2/exam/export/questions', params, { requestBase: 'dataAnalysis' });
}

// 导出题目 导出
export async function getFile(url: string) {
  try {
    let pdfResponse = '';
    const res = await request.head(url, { hideMessage: true, needResponse: true }, { withCredentials: false });
    const { headers, status } = res;
    if (status === 200 && headers && headers['content-type'].includes('pdf')) {
      pdfResponse = 'PDF';
    }
    return pdfResponse;
  } catch (e) {
    console.log('未发现PDF文件');
    return '';
  }
}

// 获取做题记录
export function getTestpaperRecords(params: any) {
  return request.get('/v2/exam/testpaper/records', params, { requestBase: 'exam' });
}

// 根据时间范围获取用户考试记录
export function getTestpaperRecordsByTime(params: any) {
  return request.get('/v2/exam/testpaper/records/time', params, { requestBase: 'exam' });
}

// 获取收藏记录
export function getQuestionsFavorites(params: any) {
  return request.get('/v2/question/favorites', params, { requestBase: 'exam' });
}

// 根据时间获取用户收藏题目列表
export function getQuestionsFavoritesByTime(params: any) {
  return request.get('/v2/question/favorites/time', params, { requestBase: 'exam' });
}

// 获取有权限的试卷
export function getTestpaperByPermission(params: any) {
  return request.get('/v2/testpaper/permission/list', params, { requestBase: 'exam' });
}

// 获取试卷发布时间
export function getPaperPublishTime(params: any) {
  return request.get(`/v2/testpaper/publish-time/${params.categoryId}/${params.type}`, {}, { requestBase: 'exam' });
}

// 获取错题巩固
export function getMistakeTestpapers(params: any) {
  return request.get('/v2/review/testpapers', params, { requestBase: 'exam' });
}

// 获取错题本列表
export function getMistakeTestpapersByTime(params: any) {
  return request.get('/v2/review/testpapers/time', params, { requestBase: 'exam' });
}

// 获取错题巩固 易错题列表
export function getEasyToWrongQuestions(params: any) {
  return request.get('/v2/review/category/easytowrong', params, { requestBase: 'exam' });
}

// 获取试卷详情
export function getTestpaperById(testpaperId: number) {
  return request.get(`/v2/testpapers/${testpaperId}`, {}, { requestBase: 'exam' }) as any;
}

// 获取试卷是否有权限
export function getPermission(params: any) {
  return request.get('/v2/exam/permission', params, { requestBase: 'exam' });
}

// 获取用户是否有云考场权限
export function getCloundExamPermission(params: any) {
  return request.get('/v2/testpaper/cloud-test/member', params, { requestBase: 'exam' });
}

// 开卷
export function addNewTest(params: any) {
  return request.post('/v2/exam/test/create', params, { requestBase: 'exam' });
}

// 根据试卷id获取最新的 或 根据id获取testpaper_result，只查当前登录用户的关联试卷信息
export function getTestpaperResult(params: any) {
  return request.get('/tiku/testpaper/result', params, { requestBase: 'phoenix' });
}

// 获取试卷记录信息
export function getTestpaperResultInfo(params: any) {
  return request.get(`/v2/testpaper-results/${params.resultId}`, { params }, { requestBase: 'exam' });
}

// 获取当前试卷答题情况
export function getTestResultDetail(params: any) {
  return request.get('/v2/exam/test/result/detail', params, { requestBase: 'exam' });
}
//获取当前试卷答题情况，支持查看他人
export function getTestResultDetailOther(params: any) {
  return request.get('/v2/exam/test/result/detail/user', params, { requestBase: 'exam' });
}

// 通过中间层获取试卷题目列表
export function getTestpaperQuestion(params: any) {
  return request.post('/tiku/testpaper/questions', params, { requestBase: 'phoenix' });
}

// 标记题目
export function addBookmarks(params: any) {
  return request.post('/v2/exam/test/bookmarks', params, { requestBase: 'exam' });
}

// 取消标记题目
export function delBookmarks(params: any) {
  return request.delete('/v2/exam/test/bookmarks', params, { requestBase: 'exam' });
}

// 收藏
export function addQuestionFavorite(params: any) {
  return request.post(`/v2/questions/${params.questionId}/favorite`, params, { requestBase: 'exam' });
}

// 取消收藏题目
export function delQuestionFavorite(params: any) {
  return request.patch('/v2/questions/favorite', params, { requestBase: 'exam' });
}

// 提交答案
export function addTestAnswers(params: any) {
  return request.post(`/v2/exam/test/result/${params.resultId}/answers`, params, { requestBase: 'exam' });
}

// 自评
export function addTestReviewResult({resultId, ...params}) {
  return request.put(`/v2/exam/test/result/${resultId}/review`, params, { requestBase: 'exam' });
}

// 交卷
export function finishTest(params: any) {
  return request.put(`/v2/exam/test/result/${params.resultId}/finish`, params, { requestBase: 'exam' });
}

// 获取需要自评的题目
export function getTestReviewQuestions(params: any) {
  return request.get(`/v2/exam/test/result/${params.resultId}/review/quesetions`, {}, { requestBase: 'exam' });
}

// 获取自评题目统计信息
export function getTestResultStatistics(params: any) {
  return request.get(`/v2/exam/test/result/${params.resultId}/statistics`, {}, { requestBase: 'exam' });
}
// 获取其它用户的答题报告统计数据
export function getTestResultStatisticsOther(resultId, params) {
  return request.get(`/v2/exam/test/result/${resultId}/statistics/user`, params, { requestBase: 'exam' });
}

// 移除我的错题
export function removeQuestion(params: any) {
  return request.post('/v2/review/remove', params, { requestBase: 'exam' });
}

// 取消移除我的错题
export function undoRemoveQuestion(params: any) {
  return request.post('/v2/review/remove/undo', params, { requestBase: 'exam' });
}

// 获取试卷错题列表
export function getReviewQuestions(params: any) {
  return request.get('/v2/review/questions', params, { requestBase: 'exam' });
}

// 获取答题报告
export function getTestpaperReports(params: any) {
  return request.get(`/v2/testpaper/reports/${params.resultId}`, params, { requestBase: 'exam' });
}

// 回答错题 用于统计错题
export function answerQuestion(params: any) {
  return request.post('/v2/review/answer', params, { requestBase: 'exam' });
}

// 获取题目列表
export function showQuestion(params: any) {
  return request.get('/v2/questions/show', params, { requestBase: 'exam' });
}

// 获取背题记录
export function getTestpaperPracitce(params: any) {
  return request.get(`/v2/testpapers/${params.testpaperId}/practice`, params, { requestBase: 'exam' });
}

// 背题模式做题提交答案/开卷
export function addTestpaperPracitce(params: any) {
  return request.post(`/v2/testpapers/${params.testpaperId}/practice`, params, { requestBase: 'exam' });
}

// 获取法律法规目录树
export function getListTreeLaw() {
  return request.get('/v2/law/tree', {}, { requestBase: 'exam' });
}

// 获取某个二级目录下所有章节的内容
export function getListLawContent(id: any) {
  return request.get(`/v2/law/content/${id}`, {}, { requestBase: 'exam' });
}

// 主观题手动加入错题集
export function postAddMistakesSubjectivity(params) {
  return request.post('/v2/reviews', params, { requestBase: 'exam' });
}

// 获取CC视频播放代码
export function getVideoPlaycode(params: any) {
  return request.get('/v2/cc/video/playcode', params, { requestBase: 'course' });
}

// 答题权限
export function getTestpaperMember(params: any) {
  return request.get('/v2/testpaper/member', params, { requestBase: 'exam' });
}

// listTopics, 知识点列表
export function getExamTopic(params: any) {
  return request.get('/v2/topics', params, { requestBase: 'exam' });
}

// 智能组卷
export function createAIPaper(params: any) {
  return request.post('/v2/testpaper/composition/create', params, { requestBase: 'exam' });
}

// 获得智能组卷的试卷规则列表
export function getAIPaperRuleList(params: any) {
  return request.get('/v2/testpaper/composition/list', params, { requestBase: 'exam' });
}

// 删除智能组卷规则列表
export function delAIPaperRuleList(params: any) {
  return request.post('/v2/testpaper/composition', params, { requestBase: 'exam' });
}

// 获取标签下的题目列表
export function getTikuTopicQues(params) {
  return request.get('/v2/topics-questions/list', params, { requestBase: 'exam' });
}

// 获取考点管理标签详情
export function showTikuTopic(params) {
  return request.get(`/v2/topics/${params.topicId}`, params, { requestBase: 'exam' });
}

// 获取当前标签下该题目的前后两道题
export function getTopicRangeInfo(params) {
  return request.get(`/v2/topics-questions/${params.topicId}/detail/${params.questionId}`, params, { requestBase: 'exam' });
}
/* 考试成绩单 - 云考场 */

// 用户排行榜数据
export function getUserTestpaperTopRankt(params: any) {
  return request.get(`/v2/testpaper/${params.testpaperId}/rank`, params, { requestBase: 'exam' });
}

// 获取用户的答题报告
export function getUserExamReports(params: any) {
  return request.get(`/v2/testpaper/reports/${params.resultId}`, {}, { requestBase: 'exam' });
}

// 获取试卷答题情况 => 做题数据 、题目解析 、答案状态、标记/收藏/评阅状态.
export function getUserExamResultDetail(params: any) {
  return request.get('/v2/exam/test/result/detail', params, { requestBase: 'exam' });
}

// 获取题目详情列表
export function getUserTestpaperQuestion(params: any) {
  return request.get(`/v2/testpaper/${params.testpaperId}/questions`, params, { requestBase: 'exam' });
}

// 题目导出接口, 答题报告解析下载选项
export function getUserTopicExports(params: any) {
  return request.post(`/v2/exam/export/questions`, params, { requestBase: 'dataAnalysis' });
  // return request.throughApi('data-analysis.exam.exportQuestions', params, { authKey: 'userId', isAuth: true });
}

// 获取用户的该试卷记录的各种统计数据
export function getUserTestResultStatDetail(params: any) {
  return request.get(`/v2/exam/test/result/${params.resultId}/stat-detail`, {}, { requestBase: 'exam' });
}

// 获取试卷记录的状态
export function getUserTestpaperResult(params: any) {
  return request.get('/v2/testpaper/result', params, { requestBase: 'exam' });
}

// 提交反馈
export function getUserAddFeedback(params: any) {
  return request.throughApi('ms-user.feedback.userAddFeedback', params, { authKey: 'userId', isAuth: true });
}

// 获取考点标签试卷列表
export function getTopicPaperList(params: any) {
  return request.get('/v2/topics-testpaper', params, { requestBase: 'exam' });
}

// 批量导出题目信息
export function batchExportQuestions(params: any) {
  return request.post(`/v2/exam/export/batchExportQuestions`, params, { requestBase: 'dataAnalysis' });
  // return request.throughApi('data-analysis.exam.batchExportQuestions', params, { authKey: 'userId', isAuth: true });
}

// 获取分类列表
export function getCategorys(params: any) {
  return request.get(`/v2/categorys`, params, { requestBase: 'course' });
  // return request.throughApi('ms-course.category.getCategorys', params);
}

// 获取其它用户的考试记录
export function getTestpaperRecordsOther(params) {
  return request.get('/v2/exam/testpaper/records/user', params, { requestBase: 'exam' });
}

// 导出题目笔记信息
export function exportQuestionConfusion(params: any) {
  return request.post('/v2/exam/export/confusion', params, { requestBase: 'dataAnalysis' });
}

// 导出上课笔记信息
export function exportUserCourseNote(params: any) {
  return request.post('/v2/course/export-course-note', params, { requestBase: 'dataAnalysis' });
}

// 获取分类及其父分类信息
export function getCategorysItem(params: any) {
  return request.get('/category/parent', params, { requestBase: 'guser' });
}

// 生成初级会计三方实操平台登录链接
export function accountingPracticeLogin() {
  return request.post('/v2/accounting-practice/login', {}, { requestBase: 'course' });
}

// 获取bt分类信息
export function getBtCategoryInfo(params: any) {
  return request.get('/categorys', params, { requestBase: 'guser' });
}