















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class TipsDialog extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) visibleSync;
  @Prop({ type: Object, default: () => ({}) }) options: any;
}
