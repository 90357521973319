import dayjs from 'dayjs';
import Vue from 'vue';

export const questionTypes = {
  single_choice: '单选题',
  choice: '多选题',
  uncertain_choice: '不定项选择题',
  fill: '填空题',
  determine: '判断题',
  essay: '问答题',
  material: '材料题',
};

export const testpaperTypes = {
  homework: '每日一练',
  checkpoint: '章节练习',
  phaseTest: '周测月考',
  key: '模考押题',
  simulation: '真题演练',
  cloudTest: '全真模拟',
  AI: '智能组卷',
  topic: '考点练习',
};

export const questionTypeInResult = {
  single_choice: '单选题',
  choice: '多选题',
  uncertain_choice: '不定项选择题',
  determine: '判断题',
  fill: '填空题',
  essay: '问答题',
  material: '材料题',
};

export const subjectiveTypes = ['fill', 'essay', 'material'];

export const objectiveTypes = ['single_choice', 'choice', 'uncertain_choice', 'determine'];

export const difficultys = {
  simple: '简单',
  normal: '一般',
  difficulty: '困难',
};

// 基于时间戳获取该时间戳当天的00:00:00 和 23::59:59
export const getTimeStampZero = (time:number) => {
  const day:any = dayjs(time); // 13位换算
  const beforeTime = dayjs(`${day.$y}-${day.$M + 1}-${day.$D}`).unix(); // 当天的00:00:00
  const lastTime = dayjs(`${day.$y}-${day.$M + 1}-${day.$D} 23:59:59`).unix(); // 当天的23::59:59
  return {
    ...day,
    beforeTime, // 10位秒级
    lastTime, // 10位秒级
  }
}

// 获取时间范围,默认一年
export const getDefaultTimes = (number:number = 365) => {
  const nowDate = new Date().getTime() - Vue.prototype.$store.state.offsetTime; // 服务器时间
  const { lastTime } = getTimeStampZero(nowDate);
  const { beforeTime } = getTimeStampZero(lastTime * 1000 - (86400 * 1000 * number) );
  return {
    beforeTime: beforeTime, // 10位秒级
    lastTime: lastTime, // 10位秒级
  }
}
