import { request } from '@/utils/axios';
// import { UserModel, PageResponse } from '@/typings';
import { UpdateUserPostData } from '@/typings';

// 获取用户简介列表
export const getUserAndProfiles = () => {
  return request.get('/v2/users/profiles', {}, { requestBase: 'user' });
  // return request.throughApi<PageResponse<UserModel>>('ms-user.user.getUserAndProfiles', {}, { authKey: 'ids' });
};

export const updateUser = (postData: UpdateUserPostData) => {
  return request.patch('/v2/user/info', postData);
};

export const certifyUserInfo = params => {
  return request.throughApi('ms-user.user.certifyUserInfo', params);
};

export const bindingUserMobile = (mobile: string, smscode: string, scan?: string) => {
  return request.throughApi('ms-user.user.bindingUserMobile', { mobile, smscode, scan }, { authKey: 'id' });
};

export const updateUserPassword = (oldPassword: string, newPassword: string) => {
  return request.throughApi('ms-user.user.updateUserPassword', { oldPassword, newPassword });
};

export const setPasswordFirst = (password: string) => {
  return request.throughApi('ms-user.user.setPasswordFirst', { password });
};

export const resetUserPassword = (params: any) => {
  return request.patch('/v2/user/mobile/password', params, { requestBase: 'user' });
  // return request.throughApi('ms-user.user.resetUserPassword', params, { isAuth: false });
};

export const getUpdateMobiletatus = () => {
  return request.throughApi<{ state: boolean }>('ms-user.user.getUpdateMobiletatus');
};

// 获取用户非敏感信息
export function getUserSetting() {
  return request.throughMojaveApi({
    url: '/user/setting',
    method: 'get',
  });
}

export const resetUserPasswordLogin = (params: any) => {
  return request.patch('/v2/users/mobile/password/user', params, { requestBase: 'user' });
  // return request.throughApi('ms-user.user.resetUserPasswordLogin', params, { isAuth: true });
};

//地址接口

// 获取全部地址 | 获取我的地址
export const getUserAddress = () => {
  return request.get('/v2/address/mine', {}, { requestBase: 'newBook' });
  // return request.throughApi('ms-book.index.getAddressList', { isAuth: true });
};
//  添加地址
export const addAddress = (params: any) => {
  return request.post('/v2/address', params, { requestBase: 'newBook' });
  // return request.throughApi('new-book.address.create', params, { isAuth: true });
};
// 修改用户地址
export function putUserInfo(params: any) {
  return request.patch(`/v2/address/${params.id}/mine`, params, { requestBase: 'newBook' });
  // return request.throughApi('new-book.address.update', params);
}

// 删除用户地址
export function deleteAddress(addressId: number) {
  return request.throughApi('ms-book.index.deleteAddress', { addressId });
}
// 获取城市 | 获取地区信息
export function getCity() {
  return request.get('/v2/address/areas', {}, { requestBase: 'user' });
  // return request.throughApi('ms-user.address.getAreas');
}

// 获取校验手机绑定配置
export function getCheckMobileBindConfig() {
  return request.get('/v2/setting/check-mobile-bind', {}, { requestBase: 'course' });
}