// ⚠️⚠️⚠️
// 此路由在移动端页面重写了，需要检查是否需要同步修改
// ⚠️⚠️⚠️

const Course = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/Course');
const CourseHome = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/home/CourseHome');
const CourseInfo = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/info/CourseInfo');
const CourseLesson = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/lesson/CourseLesson');
const CourseTestpaper = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/testpaper/CourseTestpaper');
const CourseReview = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/reviews/CourseReview');
const CourseMaterial = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/material/CourseMaterial');
const CourseNotes = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/notes/CourseNotes');
const NewCourseLearn = () => import(/* webpackChunkName: "course-learn" */ '@/pc/views/course/learn-new/CourseLearn');
// const EnsurePassAgreementPage = () => import(/* webpackChunkName: "ensure-pass-agreement-index" */ '@/pc/views/course/ensure-pass-agreement/index');
const CcReplayEntry = () => import(/* webpackChunkName: "cc-replay-entry" */ '@/pc/views/course/learn-new/CcReplayEntry');
const CourseBuyingGuide = () => import(/* webpackChunkName: "course-buying-guide" */ '@/pc/views/course/buying-guide/index');
const CourseExplore = () => import(/* webpackChunkName: "course-explore" */ '@/pc/views/course/course-explore');
const CourseFeedback = () => import(/* webpackChunkName: "course-feedback" */ '@/pc/views/course/feedback/course-feedback');
//公开课主页面
const OpenCourseExplore = () => import(/* webpackChunkName: "open-course-explore" */ '@/pc/views/open-course/course-explore');
const CourseDetailForSEO = () => import(/* webpackChunkName: "course-for-seo" */ '@/pc/views/course_detail/index');
//激活课程确认页
const CourseActivate = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/course-activate/index.vue');
const ActivateIntermediate = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/course-activate/intermediate.vue');
//课程服务引导步骤页面
const CourseService = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/course-service/index.vue');
const BuyCourseSuccess = () => import(/* webpackChunkName: "course" */ '@/pc/views/course/course-service/buy-course-success.vue');

export default [
  {
    path: '/course/explore',
    name: 'course-explore',
    component: CourseExplore,
    meta: { isPC: true },
  },
  // 匹配旧版本在使用的路由/:code
  {
    path: '/course/explore/:code',
    name: 'course-explore-deprecated',
    component: CourseExplore,
    meta: { isPC: true },
  },
  {
    path: '/open/course/explore',
    name: 'open-course-explore',
    component: OpenCourseExplore,
    meta: { isPC: true },
  },
  {
    path: '/course/:id',
    name: 'course-entry',
    component: Course,
    props: router => ({
      courseId: Number(router.params.id),
    }),
    children: [
      {
        path: '',
        name: 'course-home',
        component: CourseHome,
        props: router => ({
          courseId: Number(router.params.id),
        }),
      },
      {
        path: '/course/:id/lesson/list',
        name: 'course-lesson-list',
        component: CourseLesson,
        props: router => ({
          courseId: Number(router.params.id),
        }),
      },
      {
        path: '/course/:id/testpaper',
        name: 'course-testpaper',
        component: CourseTestpaper,
        props: router => ({
          courseId: Number(router.params.id),
        }),
      },
      {
        path: '/course/:id/reviews',
        name: 'course-reviews',
        component: CourseReview,
        props: router => ({
          courseId: Number(router.params.id),
        }),
      },
      {
        path: '/course/:id/material',
        name: 'course-material',
        component: CourseMaterial,
        props: router => ({
          courseId: Number(router.params.id),
        }),
      },
      {
        path: '/course/:id/notes',
        name: 'course-notes',
        component: CourseNotes,
        props: router => ({
          courseId: Number(router.params.id),
        }),
      },
      {
        path: '/course/:id/info',
        name: 'course-info',
        component: CourseInfo,
        props: router => ({
          courseId: Number(router.params.id),
        }),
      },
    ],
  },
  /** 
  {
    path: '/course/:id/ensure-pass-agreement-page',
    name: 'ensure-pass-agreement-page',
    component: EnsurePassAgreementPage,
    props: router => ({
      courseId: Number(router.params.id),
    }),
  },
  ***/
  {
    path: '/course/:id/learn',
    name: 'course-learn',
    component: NewCourseLearn,
    props: router => ({
      courseId: Number(router.params.id),
      lessonId: router.hash.match(new RegExp(/lesson\/[0-9]+/)) ? Number(router.hash.match(new RegExp(/lesson\/[0-9]+/))[0].split('/')[1]) : Number(router.query.lessonId),
    }),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: '/course/:id/lesson/:lessonId/cc_replay_entry',
    name: 'cc-replay-entry',
    component: CcReplayEntry,
    props: router => ({
      courseId: Number(router.params.id),
      lessonId: Number(router.params.lessonId),
    }),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: '/course-guide-tpl/:tplId',
    name: 'course-buying-guide-old',
    component: CourseBuyingGuide,
    props: route => ({
      tplId: Number(route.query.tplId) || 0,
      courseId: Number(route.query.courseId) || 0,
    }),
    meta: {
      hideFooter: true,
      needLogin: true,
    },
  },
  {
    path: '/course-guide-tpl',
    name: 'course-buying-guide',
    component: CourseBuyingGuide,
    beforeEnter: async (to, from, next) => {
      /***
      todo: APP内暂时不跳至whale
      if (window.JsBridge.hasBridge) { // 在APP中打开
        let params = {};
        if (to.query.courseId) {
          params.courseId = to.query.courseId;
        }
        try {
          let res = await getMyToken();
          params.userId = res.userId;
          params.token = res.token;
        } catch (e) {
          console.error(e);
        }
        // query拼接
        let finalUrl = `${process.env.VUE_APP_WHALE_URL}course-guide-tpl/${to.params.tplId}`;
        const queryArray = [];
        Object.keys(params).forEach(key => params[key] && queryArray.push(`${key}=${params[key]}`));
        if (queryArray.length) {
          if (finalUrl.search(/\?/) === -1) {
            finalUrl += `?${queryArray.join('&')}`;
          } else {
            finalUrl += `&${queryArray.join('&')}`;
          }
        }
        window.location.href = finalUrl;
      } else {
      **/
      next();
      // }
    },
    props: route => ({
      courseId: Number(route.query.courseId) || 0,
      tplId: Number(route.query.tplId) || 0,
    }),
    meta: {
      hideFooter: true,
      needLogin: true,
    },
  },
  {
    path: '/course/:courseId/lesson/:lessonId/feedback',
    name: 'course_feedback',
    component: CourseFeedback,
    props: route => ({
      courseId: Number(route.params.courseId) || 0,
      lessonId: Number(route.params.lessonId) || 0,
      watchTime: Number(route.query.watchTime) || 0,
    }),
    meta: {
      hideFooter: true,
      needLogin: true,
    },
  },
  {
    path: '/course/seo/:id',
    name: 'course_detail_seo',
    component: CourseDetailForSEO,
    props: route => ({
      id: Number(route.params.id) || 0,
    }),
    meta: {
      hideFooter: true,
    },
  },
  // 激活课程确认页
  {
    path: '/course-activate',
    name: 'course_activate',
    component: CourseActivate,
    props: route => ({
      courseId: Number(route.query.courseId) || 0,
      picture: route.query.largePicture,
      title: route.query.title,
    }),
    meta: {
      hideFooter: true,
      needLogin: true,
    },
  },
  {
    path: '/activate-intermediate',
    name: 'activate_intermediate',
    component: ActivateIntermediate,
    props: route => ({
      courseId: Number(route.query.courseId),
    }),
  },
  // 购课成功后进入该页面判断是否须填写手机号等信息
  {
    path: '/course-service',
    name: 'course_service',
    component: CourseService,
    props: route => ({
      courseId: Number(route.query.courseId) || 0,
    }),
    meta: {
      hideFooter: true,
      needLogin: true,
    },
  },
  {
    path: '/buy-course-success',
    name: 'buy_course_success',
    component: BuyCourseSuccess,
    props: route => ({
      courseId: Number(route.query.courseId) || 0,
    }),
    meta: {
      hideFooter: true,
      needLogin: true,
    },
  },
];
