import axios, { AxiosRequestConfig } from 'axios';
import Vue from 'vue';
import { refreshUserToken } from '@/api/token-v2';
import { genAuthorization, getRandomStr } from './encrypt';
import { store } from "@/store/index";
import { getBrowser } from '@/utils/helper';

const ua = navigator.userAgent;
const isMobile = getBrowser(ua).mobile;

interface IAxiosRequestConfig extends AxiosRequestConfig {
  requestBase?: string; // 服务类型
  noAccess?: boolean;
}

// promise 节流器
let lockMap = {};
async function throttleMachine<T = any>(key: string, gap = 10000, handler: Function) {
  return new Promise<T>(resolve => {
    const currentTime = Date.now();
    // 存在有效的锁
    if (lockMap[key] && lockMap[key].time > currentTime) {
      const callbackList = lockMap[key].callbackList;
      return callbackList.push(function (data) {
        resolve(data);
      }); // 插入回调函数
    } else {
      // 上锁
      lockMap[key] = {
        time: currentTime + gap,
        callbackList: [],
      };
      const refreshToken = localStorage.getItem('refreshToken');
      handler(refreshToken).then(data => {
        const lock = lockMap[key];
        // 解锁
        lock.time = 0;
        while (lock.callbackList && lock.callbackList.length) {
          const func = lock.callbackList.shift();
          func(data);
        }
        resolve(data);
      });
    }
  });
}

async function getAccessToken() {
  // 拿accessToken，有则取无则拿新的
  const refreshToken = localStorage.getItem('refreshToken') || '';
  let accessToken = localStorage.getItem('accessToken') || '';
  const expiresTime: any = localStorage.getItem('expiresTime') || 0;
  if (refreshToken && (!accessToken || (accessToken && Date.now() - expiresTime >= 0))) {
    // 有refreshToken && (没有accessToken 或者 accessToken过期时间到了)直接拿新的
    try {
      const res: any = await throttleMachine<string>('getAccessToken', 10000, refreshUserToken);
      if (res && res.accessToken) {
        // 目前看，有时候res值会不对劲
        accessToken = res.accessToken;
        localStorage.setItem('accessToken', accessToken);
        const expiresTime = new Date().getTime() - Vue.prototype.$store.state.offsetTime + res.expires * 1000 - 30000; // 先校对服务器时间，过期时间是真实时间 + 过期时间 - 30秒，这样提前判断过期就去取新的
        localStorage.setItem('expiresTime', String(expiresTime));
      }
    } catch (e) {
      console.log('刷新accessToken error', e);
    }
  }
  return accessToken;
}

async function getDataInMojave(requestConfig: IAxiosRequestConfig) {
  const accessToken = await getAccessToken();
  // 有的isAuth默认是false，但是登录情况下，如果有带authKey，那isAuth要设为true
  if (requestConfig.data && requestConfig.data.config && requestConfig.data.config.authKey && !requestConfig.data.config.isAuth) {
    if (accessToken) {
      requestConfig.data.config.isAuth = true;
    } else {
      delete requestConfig.data.config.authKey;
    }
  }
  return service.request(requestConfig);
}

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
const service = axios.create({
  withCredentials: true,
  timeout: 30000,
});

function getBaseUrl() {
  const baseUrl = {
    phoenix: '/api',
    exam: `${process.env.VUE_APP_BASE_API}v2-exam`,
    mojave: `${process.env.VUE_APP_BASE_API}v2-mojave`,
    token: `${process.env.VUE_APP_BASE_API}v2-token`,
    rabbit: `${process.env.VUE_APP_BASE_API}v2-rabbit`,
    gordon: `${process.env.VUE_APP_BASE_API}v2-gordon`,
    course: `${process.env.VUE_APP_BASE_API}v2-course`,
    user: `${process.env.VUE_APP_BASE_API}v2-user`,
    task: `${process.env.VUE_APP_BASE_API}v2-task`,
    guser: `${process.env.VUE_APP_BASE_API}v2-guser`,
    dove: `${process.env.VUE_APP_BASE_API}v2-dove`,
    learning: `${process.env.VUE_APP_BASE_API}v2-learning`,
    marketing: `${process.env.VUE_APP_BASE_API}v2-marketing`,
    dataAnalysis: `${process.env.VUE_APP_BASE_API}v2-data-analysis`,
    doubt: `${process.env.VUE_APP_BASE_API}v2-doubt`,
    order: `${process.env.VUE_APP_BASE_API}v2-order`,
    newBook: `${process.env.VUE_APP_BASE_API}v2-book`
  };
  for (let key in baseUrl) {
    if (!['turtle'].includes(key)) {
      const skey = `${key}BaseUrl`;
      baseUrl[key] = localStorage.getItem(skey) || baseUrl[key];
    }
  }
  return baseUrl;
}
export const baseUrl = getBaseUrl();
const pkg = require('../../../../package.json');
service.interceptors.request.use(async (config: IAxiosRequestConfig) => {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  if (SSR_ENV === 'server') {
    let baseURLMap = {
      development: 'http://localhost:4907/api',
      // test: 'http://localhost:7001/api',
      test: 'https://dev.btclass.net/api',
      production: 'http://localhost:7001/api',
    };
    // @ts-ignore
    baseUrl.phoenix = baseURLMap[process.env.NODE_ENV];
  } else {
    config.headers['x-access-token'] = config.noAccess ? '' : await getAccessToken();
  }
  const localTime = Date.now();
  const timestamp = localTime - store.getters.offsetTime;
  const { authorization } = genAuthorization({
    timestamp,
    access_token: config.headers['x-access-token'],
  })
  config.headers['authorization'] = authorization;
  config.headers['timestamp'] = timestamp;
  const env = localStorage.getItem('env');
  const isAPP = window.JsBridge && window.JsBridge.hasBridge;
  let userCustomId = localStorage.getItem('USER_CUSTOM_ID');
  if (!userCustomId) {
    userCustomId = getRandomStr({ length: 16 })
    localStorage.setItem('USER_CUSTOM_ID', userCustomId);
  }
  config.headers['x-version'] = `env=${env}&name=${pkg.name}&device=${!isAPP ? 'pc' : 'h5'}&version=${pkg.version}&time=${localTime}&code=${userCustomId}`;

  config.baseURL = baseUrl[config.requestBase || 'mojave'];
  // @ts-ignore
  // eslint-disable-next-line no-undef
  if ((!SSR_ENV || SSR_ENV !== 'server') && config.requestBase === 'phoenix' && config.url?.indexOf('/api') !== -1) {
    config.url = config.url?.replace('/api', '');
  }
  return config;
});

function logout() {
  // refreshToken过期了，这时候要清掉所有token并且提示需要去登录
  Vue.prototype.$message.warning('登录已过期，请重新登录');
  setTimeout(() => {
    Vue.prototype.$store.commit('my/LOGOUT');
    Vue.prototype.$store.commit('entry/LOGOUT');
    let goto = location.href.replace(location.origin, '');
    location.replace(`/login?goto=${goto}`);
  }, 1500);
}

//code状态码200判断
let isRefreshing = false; // 标记是否正在刷新 token， 防止多次刷新token
let requests: any = []; // 存储待重发请求的数组(同时发起多个请求的处理)
service.interceptors.response.use(
  response => {
    if (response.headers['deprecated'] === '1') {
      // 说明这个接口过期了
      console.log('---废弃接口response---', response);
      if (process.env.NODE_ENV === 'production') {
        console.warn('监测到页面有废弃接口，联系技术更新');
      } else {
        Vue.prototype.$message.warning('监测到页面有废弃接口，联系技术更新');
      }
    }
    const { config } = response;
    if (config && config.params && config.params.needResponse) {
      return response;
    }
    let data = response.data || {};
    if (!data.code) {
      return data;
    }
    if (data.message) {
      data.msg = data.message;
    }

    if (data.code === 410) {
      logout();
      return Promise.reject(data);
    } else if (data.code === 401) {
      // 拿新的accessToken再请求一遍
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken: string = localStorage.getItem('refreshToken') || '';
        if (!refreshToken)  {
          logout();
          return;
        }
        return refreshUserToken(refreshToken)
          .then(res => {
            localStorage.setItem('accessToken', res.accessToken);
            const expiresTime = new Date().getTime() - Vue.prototype.$store.state.offsetTime + res.expires * 1000 - 30000; // 先校对服务器时间，过期时间是真实时间 + 过期时间 - 30秒，这样提前判断过期就去取新的
            localStorage.setItem('expiresTime', String(expiresTime));
            // token 刷新后将数组的方法重新执行
            requests.forEach((cb: any) => cb());
            requests = []; // 重新请求完清空
            return service(response.config);
          })
          .catch(err => {
            console.log('签名已失效，请重新登录');
            return Promise.reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        // 返回未执行 resolve 的 Promise
        return new Promise(resolve => {
          // 用函数形式将 resolve 存入，等待刷新后再执行
          requests.push(() => {
            resolve(service(response.config));
          });
        });
      }
    } else if (data.code !== 200) {
      let needShowNormalFail = true;
      setTimeout(() => {
        // 针对学习记录上报频繁问题处理,先单独根据文案处理
        if (needShowNormalFail && data.msg !== '您的学习记录提交过于频繁') {
          Vue.prototype.$message.warning(data.msg || '服务端错误！');
        }
      });

      return Promise.reject(
        Object.assign(
          {
            hideNormalFail: () => {
              needShowNormalFail = false;
            },
          },
          data
        )
      );
    }
    if (response.config && response.config.headers && response.headers['respond-time']) {
      // 记录下服务器时间和本地时间差值
      Vue.prototype.$store.commit('SET_SERVE_TIME', Number(response.headers['respond-time']));
    }
    return data.res;
  },
  err => {
    console.log('请求错误------------', err);
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误';
          break;
        case 401:
          err.message = '未授权，请登录';
          break;
        case 403:
          err.message = '拒绝访问';
          break;
        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`;
          break;
        case 408:
          err.message = '请求超时';
          break;
        case 500:
          err.message = '服务器内部错误';
          break;
        case 501:
          err.message = '服务未实现';
          break;
        case 502:
          err.message = '网关错误';
          break;
        case 503:
          err.message = '服务不可用';
          break;
        case 504:
          err.message = '网关超时';
          break;
        case 505:
          err.message = 'HTTP版本不受支持';
          break;
        default:
      }
    }
    
    // 网络错误
    const isNetworkError = err.message == 'Network Error';
    // 除网络错误外指定情况下的错误（未知，待溯源）
    const isCustomError = err.message !== 'Network Error' && err.response && err.response.config && (!err.response.config.params || !err.response.config.params.hideMessage);
    
    /* 网络错误或者指定错误，优化提示语，弹出toast提示 */
    if (isNetworkError) {
      err.message = '网络异常，请检查网络后重试';
    }
    // pc重定向到h5时，会被客户端取消请求 Stream cancelled by CLIENT，触发 Network Error，需要通过判断环境 isMobile 屏蔽一下
    if ((!isMobile && isNetworkError) || isCustomError) {
      Vue.prototype.$message({
        message: err.message || '网络异常！',
        type: 'error',
        duration: 3000,
      });
    }
    return Promise.reject(err);
  }
);

function checkAuth (config:any) {
  return config && config.isAuth && !localStorage.getItem('refreshToken');
}


export const request = {
  async get<T = any>(url: string, params?: object, config?: IAxiosRequestConfig) {
    if (checkAuth(config)) return Promise.reject({ code: 410, msg: '需要登录' });

    const data = await service.get<T>(url, { params, ...config });
    return data as any as T;
  },
  async post<T = any>(url: string, data?: object, config: IAxiosRequestConfig = {}) {
    if (checkAuth(config)) return Promise.reject({ code: 410, msg: '需要登录' });

    const res = await service.post<T>(url, data, config);
    return res as any as T;
  },
  async put<T = any>(url: string, data?: object, config?: IAxiosRequestConfig) {
    if (checkAuth(config)) return Promise.reject({ code: 410, msg: '需要登录' });

    const res = await service.put<T>(url, data, config);
    return res as any as T;
  },
  async delete<T = any>(url: string, params?: object, config?: IAxiosRequestConfig) {
    if (checkAuth(config)) return Promise.reject({ code: 410, msg: '需要登录' });

    const res = await service.delete(url, { params, ...config });
    return res as any as T;
  },
  async patch<T = any>(url: string, data?: object, config?: IAxiosRequestConfig) {
    if (checkAuth(config)) return Promise.reject({ code: 410, msg: '需要登录' });

    const res = await service.patch<T>(url, data, config);
    return res as any as T;
  },
  async head<T = any>(url: string, params?: object, config?: IAxiosRequestConfig) {
    if (checkAuth(config)) return Promise.reject({ code: 410, msg: '需要登录' });
    
    // @ts-ignore
    const res = await service.head<T>(url, { params, ...config });
    return res as any as T;
  },
  async throughApi<T = any>(servicePath: string, params?: object, config: { isAuth?: boolean; authKey?: string } = {}) {
    let requestConfig: IAxiosRequestConfig = {
      url: '/through-api',
      data: {
        serviceName: servicePath,
        params,
        config,
      },
      method: 'post',
    };
    const res = await this.throughMojaveApi(requestConfig, config.isAuth);
    return res as any as T;
  },
  async throughMojaveApi<T = any>(requestConfig: IAxiosRequestConfig, needLogin?: boolean) {
    if (needLogin && !localStorage.getItem('refreshToken')) {
      return Promise.reject({ code: 410, msg: '需要登录' });
    }
    const response = await getDataInMojave(requestConfig);
    return response as any as T;
  },
};
