






















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import LiveLessonItem from './live-lesson-item-raw.vue';

@Component({
  components: {
    LiveLessonItem,
  },
})
export default class CurLessons extends Vue {
  @Prop({ type: Array, default: () => [] }) list;
  @PropSync('editLesson', { type: Object, default: null }) editLessonSync;
  @PropSync('isReserveShow', { type: Boolean, default: false }) isReserveShowSync;

  curSwiperOption: any = {
    navigation: {
      nextEl: '.cur-swiper-right-btn',
      prevEl: '.cur-swiper-left-btn',
    },
    loop: false,
    grabCursor: true,
    spaceBetween: 20,
    initialSlide: 0,
    direction: 'horizontal',
    slidesPerView: 1,
  };

  bindMobileHandle() {
    this.$emit('bindMobile');
  }

  refreshHandle() {
    this.$emit('refresh');
  }
}
