
















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class TipsDialog extends Vue {
  @Prop({ type: String, default: '' }) title;
  @PropSync('visible', { type: Boolean, default: false }) visibleSync;
  @Prop({ type: Boolean, default: true }) hasClose;
}
