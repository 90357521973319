var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BindMobile',{attrs:{"typeName":"question-note-bind-mobile-jump-verify","visible":_vm.bindMobileVisible,"options":{
      title: '验证绑定手机号',
      submitText: '确定',
      jumpText: '本次跳过，继续添加笔记',
      customCallBack: true,
      canJump: _vm.canJump,
      customSubtitle: true,
    }},on:{"update:visible":function($event){_vm.bindMobileVisible=$event},"bindMobileSuccess":_vm.bindMobileSuccess,"closeDialog":_vm.closeDialog}},[_c('template',{slot:"subtitle"},[_c('div',{staticClass:"subtitle-text"},[_vm._v("\n        应国家相关法规要求，您在发布笔记等社区UGC内容前，需先完成手机号实名认证，填写的手机号仅用于验证绑定，详见 "),_c('span',{staticClass:"btn-text",on:{"click":_vm.openRules}},[_vm._v("隐私政策>>")])])])],2),(_vm.updateVisible)?_c('UpdateNotesDialog',{attrs:{"questionId":_vm.notesOption.questionId,"visible":_vm.updateVisible,"notesInfo":_vm.notesInfo},on:{"update:visible":function($event){_vm.updateVisible=$event},"updateList":_vm.updateList}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }