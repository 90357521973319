







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TextField extends Vue {
  @Prop({ type: String, default: '' }) value!: string;
  @Prop({ type: String, default: '' }) error!: string;
  @Prop({ type: Object, default: () => {} }) classes!: object;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Array, default: ()=> ['input'] }) event!: string[];
  blurHanler(e) {
    if (this.event.includes('blur')) {
      this.$emit('input', e.target.value);
    }
  }
  inputHanler(e) {
    if (this.event.includes('input')) {
      this.$emit('input', e.target.value);
    }
  }
}
