import types from './mutation-types';
import { postThread, getThreadHotPosts, getThreadPosts } from '@/api/thread';

export default {
  namespaced: true,
  state: () => ({
    showMobilePostContentInput: false,
    currentReplyUser: {
      nickname: '',
      postId: '',
      userId: ''
    },
    postContent: '',
    postType: 'all' || 'onlyOwner',
    sortType: 'desc' || 'asc',
    pageNum: 1,
    pageSize: 15,
    threadId: '',
    hotList: {},
    postList: {},
    total: 0,
    likeList: []
  }),
  mutations: {
    [types.CHANGE_SHOW_MOBILE_CONTENT_POST_INPUT]: (state, status) => {
      state.showMobilePostContentInput = status;
    },
    [types.CHANGE_CURRENT_REPLY_USER]: (state, value) => {
      state.currentReplyUser = value;
    },
    [types.CHANGE_POST_CONTENT]: (state, value) => {
      state.postContent = value;
    },
    [types.SET_THREAD_ID]: (state, value) => {
      if (state.threadId !== value) {
        // 重置状态
        state.pageNum = 1;
      }
      state.threadId = value;
    },
    [types.SET_HOT_POSTS]: (state, value) => {
      state.hotList = value;
    },
    [types.SET_POSTS]: (state, value) => {
      state.postList = value;
      state.total = value.count;
    },
    [types.SET_POST_TYPE]: (state, value) => {
      state.postType = value;
    },
    [types.SET_SORT_TYPE]: (state, value) => {
      state.sortType = value;
    },
    [types.SET_LIKE_LIST]: (state, value) => {
      state.likeList = value;
    },
    [types.SET_PAGE_NUM]: (state, value) => {
      state.pageNum = value;
    }
  },
  actions: {
    async postThreadHandler({ state, dispatch }) {
      let params = {
        threadId: state.threadId,
        content: state.postContent,
        postId: state.currentReplyUser.postId,
        fromUserId: state.currentReplyUser.userId
      };
      await postThread(params);
      state.postContent = `回复 ${state.currentReplyUser.nickname} :`;
      dispatch('initPostList');
      state.currentReplyUser.cb && state.currentReplyUser.cb();
    },
    async initPostList({ commit, state }, args) {
      if (args) {
        commit(types.SET_PAGE_NUM, args.pageNum);
      }

      let params = {
        id: state.threadId,
        type: state.postType,
        sort: state.sortType,
        limit: state.pageSize,
        start: (state.pageNum - 1) * state.pageSize
      };
      const hotPosts = await getThreadHotPosts(params);
      commit(types.SET_HOT_POSTS, hotPosts);

      const postList = await getThreadPosts(params);
      commit(types.SET_POSTS, postList);
    }
  }
};
