import { getQiniuToken, getQiniuUrl } from '@/api/upload';

const info = {
  namespaced: true,
  state: () => ({
    prefix: '',
    upToken: '',
    upUrl: '',
    tokenTime: 0,
    sendHotKey: 2
  }),
  getters: {
    prefix: state => state.prefix,
    upToken: state => state.upToken,
    sendHotKey: state => state.sendHotKey
  },
  mutations: {
    SET_PREFIX: (state, prefix) => {
      state.prefix = prefix;
    },
    SET_TOKEN: (state, token) => {
      state.tokenTime = Date.now();
      state.upToken = token;
    },
    SET_UPLOAD_URL: (state, url) => {
      state.upUrl = url;
    },
    SET_SEND_HOT_KEY: (state, sendHotKey) => {
      state.sendHotKey = sendHotKey;
    }
  },
  actions: {
    getQiniuToken({ commit, state }, prefix) {
      if (prefix !== state.prefix) {
        return getQiniuToken(prefix).then(data => {
          commit('SET_TOKEN', data.token);
          commit('SET_PREFIX', prefix);
          return data.token;
        });
      } else {
        const curTime = Date.now();
        if (curTime - state.tokenTime <= 1000 * 60 * 20) {
          return Promise.resolve(state.upToken);
        } else {
          return getQiniuToken(prefix).then(data => {
            commit('SET_TOKEN', data.token);
            commit('SET_PREFIX', prefix);
            return data.token;
          });
        }
      }
    },
    async getQiniuUrl({ dispatch, commit, state }, prefix) {
      if (state.upUrl) {
        return Promise.resolve(state.upUrl);
      } else {
        if (!state.upToken) {
          await getQiniuToken(prefix).then(data => {
            commit('SET_TOKEN', data.token);
          });
        }
        return getQiniuUrl(state.upToken).then(v => {
          commit('SET_UPLOAD_URL', v);
          return v;
        });
      }
    }
  }
};

export default info;
