<template>
  <div class="home-bt-community course-area pb80">
    <div class="pc-container top-left">
      <div class="content">
        <div class="text-line head">
          <h5 class="anniversary-model-title">
            <span>学院社团</span>
            <!-- <a v-if="$root.isAnniversary&&$root.anniversaryLinkImg" :href="$root.anniversaryLink" target="_blank" class="invite-anniversary">
              <img :src="$root.anniversaryLinkImg" v-default-img-alt="'七周年'" />
            </a> -->
            <OperatPlaceCom :subscene="0" title="学院社团"></OperatPlaceCom>
          </h5>
        </div>
        <div class="area"><bt-group-hot></bt-group-hot></div>
      </div>
    </div>
    <div class="pc-container top-right">
      <div class="content">
        <div class="area"><bt-latest-info></bt-latest-info></div>
      </div>
    </div>
    <div class="pc-container bottom-left">
      <div class="content">
        <div class="area"><bt-student-coment></bt-student-coment></div>
      </div>
    </div>
    <div class="pc-container bottom-right">
      <div class="content">
        <div class="area"><bt-student-plan></bt-student-plan></div>
      </div>
    </div>
  </div>
</template>

<script>
import BtStudentComent from './components/BtStudentComent';
import BtStudentPlan from './components/BtStudentPlan';
import BtLatestInfo from './components/BtLatestInfo';
import BtGroupHot from './components/BtGroupHot';
import OperatPlaceCom from '@/pc/views/home/components/OperatPlace.vue';

export default {
  components: {
    BtStudentComent,
    BtStudentPlan,
    BtLatestInfo,
    BtGroupHot,
    OperatPlaceCom,
  },
};
</script>
