





















































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { getCategoryTree } from '@/api/ask';

@Component({})
export default class modulesProjectClass extends Vue {
  @Prop({ type: Array, default: () => [] }) categoryTree: any; // 一级列表
  @Prop({ type: Object, default: () => {} }) defaultOption: any;
  @Prop({ type: Array, default: () => [] }) filterTypes: any;
  @Prop({ type: String, default: 'myAsk' }) type: any;
  cateorysList: any = []; // 二级列表

  projectId: number = 0; // 一级
  categoryId: number = 0; // 二级
  createTimes: any = []; // 时间

  categoryTreeMap: any = {}; // 一级列表字典
  categorys: any = []; // 展开的全部二级列表
  categoryMap: any = {}; // 展开的全部二级列表字典

  timeTypeText: any = {
    myAsk: '提问时间：',
    myFavorite: '收藏时间：',
  };

  async created() {
    this.getBtCategoryTree();
    this.categoryId = this.defaultOption.categoryId;
    this.createTimes = this.defaultOption.initCreateTimes;
    this.watchCategoryId(this.categoryId);
  }

  // 问答分类树整理
  async getBtCategoryTree() {
    let categoryTreeMap: any = {};
    let categorys: any = [];
    let categoryMap: any = {};
    this.categoryTree.forEach(item => {
      categoryTreeMap[item.id] = item;
      (item.children || []).forEach(items => {
        categoryMap[items.id] = items;
        categorys.push(items);
      });
    });
    this.categorys = categorys;
    this.categoryMap = categoryMap;
    this.categoryTreeMap = categoryTreeMap;
  }

  // 初始化科目分类，找到一级分类id
  watchCategoryId(val) {
    console.log('this.categoryMap', this.categoryMap, val, this.categoryMap[val]);
    this.projectId = this.categoryMap[val].parentId;
    this.cateorysList = this.categoryTreeMap[this.projectId].children;
    this.$emit('change', { update: false, projectId: this.projectId, categoryId: this.categoryId, createTimes: this.createTimes });
  }

  // 修改项目分类
  changeProjectId(item) {
    this.projectId = item.id;
    this.cateorysList = item.children;
    this.categoryId = this.cateorysList[0].id;
    this.$emit('change', { update: true, projectId: this.projectId, categoryId: this.categoryId, createTimes: this.createTimes });
  }

  // 修改科目分类
  changeCategoryId(item) {
    this.categoryId = item.id;
    this.$emit('change', { update: true, projectId: this.projectId, categoryId: this.categoryId, createTimes: this.createTimes });
  }

  changeTimes() {
    this.$emit('change', { update: true, projectId: this.projectId, categoryId: this.categoryId, createTimes: this.createTimes });
  }

  // 重置搜索时间
  resetTimes() {
    this.createTimes = this.defaultOption.createTimes;
    this.$emit('change', { update: true, projectId: this.projectId, categoryId: this.categoryId, createTimes: this.createTimes });
  }
}
