










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import UpdateNotesDialog from '../update-dialog.vue';
import DeleteNotesDialog from '../delete-dialog.vue';

@Component({
  components: {
    UpdateNotesDialog,
    DeleteNotesDialog,
  },
  computed: {
    ...mapGetters('my', ['me', 'isLogin']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class AskItem extends Vue {
  // @ts-ignore 显示的工具栏
  @Prop({ type: Array, default: () => [] }) showTools: any;
  // 卡片的数据
  @Prop({ type: Object, default: () => {} }) detail: any;

  me: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  // 新增/编辑笔记
  updateVisible: boolean = false; // 弹窗开关

  // 删除笔记
  deleteVisible: boolean = false; // 弹窗开关

  // 是否已登陆
  get myUserId() {
    return (this.me && this.me.id) || 0;
  }

  // 笔记内容
  get detailsContent() {
    let content = this.detail.content;
    return content;
  }

  // 提问用户
  get userInfo() {
    return this.detail.userInfo || {};
  }

  // 关联题目
  get questionInfo() {
    return this.detail.question || {};
  }

  // 编辑笔记
  editNote() {
    if (this.questionInfo.isDel) {
      this.$message.warning('该题目已被删除，无法编辑笔记');
      return;
    }
    this.$emit('openUpdateDialog', { notesOption: { questionId: this.questionInfo.id }, notesInfo: this.detail });
    // this.updateVisible = true;
  }

  // 删除笔记
  deleteNote() {
    this.deleteVisible = true;
  }

  // 跳转用户详情页面
  goUserInfoPage() {
    if (!this.userInfo.id) return;
    window.open(`/new-user/${this.userInfo.id}`);
    console.log('跳转个人页面');
  }

  // 跳转关联题目页
  toDetails() {
    // @ts-ignore
    const link = this.$router.resolve({
      name: 'ask-details-questions',
      params: { doubtId: '0' },
      query: { questionId: this.questionInfo.id },
    });
    window.open(link.href, '_blank');
  }
}
