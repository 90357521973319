






















import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { deleteConfusion } from '@/api/notes';

@Component({
  components: {},
})
export default class UpdateNotesDialog extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Number, default: 0 }) questionId: any;
  @Prop({ type: Object, default: () => ({}) }) notesInfo: any;

  submitLoading: boolean = false;

  form: any = {
    content: '',
  };

  // 确定
  async submit() {
    this.$emit('closeDialog');
  }

  closeDialog() {
    this.syncVisible = false;
  }
}
