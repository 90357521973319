



















































































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import { getNotesByQuestionId } from '@/api/notes';
import NotesItem from '@/pc/views/notes/components/modules/notes-item.vue';
import MainLoading from './modules/main-loading.vue';
import { mapGetters } from 'vuex';
import BindMobileToUpdateDialog from './bind-mobile-to-update-dialog.vue';

@Component({
  components: {
    NotesItem,
    MainLoading,
    BindMobileToUpdateDialog,
  },
  computed: {
    ...mapGetters('my', ['me']),
  },
})
export default class NotesDrawer extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible!: any;
  @Prop({ type: Object }) notesOption: any;

  me: any;

  modal: boolean = true; // 是否需要遮罩层
  direction: string = 'rtl'; // Drawer 打开的方向
  closeOnPressEscape: boolean = false; // 是否可以通过按下 ESC 关闭 Drawer
  destroyOnClose: boolean = true; // 控制是否在关闭 Drawer 之后将子元素全部销毁
  showClose: boolean = false; // 是否显示关闭按钮
  size: any = '812px';
  withHeader: boolean = false; // 控制是否显示 header 栏
  wrapperClosable: boolean = true; // 点击遮罩层是否可以关闭 Drawer

  isLoading: boolean = true;

  activeName: string = '1';

  notesList: any = []; // 列表数据
  pagination: any = {
    // 分页器数据
    total: 0,
    page: 1,
    limit: 5,
  };

  // 新增/编辑笔记
  updateVisible: boolean = false; // 弹窗开关
  // 编辑笔记的数据
  updateOption: any = {
    notesOption: {},
    notesInfo: {},
  };
  // 打开编辑弹窗
  openUpdateDialog({ notesOption, notesInfo }) {
    this.updateOption.notesOption = notesOption || {};
    this.updateOption.notesInfo = notesInfo || {};
    this.updateVisible = true;
  }

  @Watch('syncVisible', { immediate: true })
  async watchVisible(visible) {
    // 弹窗打开时，重置数据
    if (visible) {
      this.pagination = {
        total: 0,
        page: 1,
        limit: 5,
      };
      this.notesList = [];
      await this.updateList();
    }
  }

  // tabs栏切换
  async tabsClick(tab, event) {
    console.log('切换', this.activeName);
    await this.updateList(true);
  }

  // 刷新/加载列表数据
  async updateList(reset = false, loading = true) {
    try {
      if (loading) {
        this.isLoading = true;
      }
      if (reset) {
        this.pagination.page = 1;
        this.pagination.total = 0;
      }
      const { count, rows } = await getNotesByQuestionId({
        questionId: this.notesOption.questionId,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        needCount: 1,
        onlyMy: +this.activeName,
      });
      this.notesList = rows;
      this.pagination.total = count;
      console.log('刷新列表数据', { count, rows });
    } catch (e) {
      console.log('弹窗列表数据加载失败', e);
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    }
  }

  // 切换页数
  async handleCurrentChange(val) {
    this.pagination.page = val;
    await this.updateList();
    console.log('切换页数', val, this.pagination.page);
  }

  // 关闭弹窗
  closeDrawer() {
    this.syncVisible = false;
  }

  // 点击【导出笔记】，打开新标签页跳转【我的笔记】-【做题笔记】页面：
  goExport() {
    const link = this.$router.resolve({
      name: 'my-notebooks',
      query: { activeTab: 'questionNote' },
    });
    window.open(link.href, '_blank');
  }

  // 点击【添加笔记】，在当前页打开新增笔记弹窗
  addNotesFun() {
    if (this.notesOption.isDel) {
      this.$message.warning('该题目已被删除，无法添加笔记');
      return;
    }
    this.openUpdateDialog({ notesOption: this.notesOption, notesInfo: {} });
    // this.updateVisible = true;
  }
}
