import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/index.vue'),
    children: [
      {
        path: '',
        name: 'setting_profile',
        component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/profile/index.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'my-address',
        name: 'addresss',
        component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/address/index.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'realname-authentication',
        name: 'setting_realname_authentication',
        component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/realname-authentication/index.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'bind-mobile',
        component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/bind-mobile/index.vue'),
        children: [
          {
            path: '',
            name: 'my_mobile',
            component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/bind-mobile/mobile-display.vue'),
            meta: {
              needLogin: true,
            },
          },
          {
            path: 'edit',
            name: 'setting_edit_mobile',
            component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/bind-mobile/edit.vue'),
            meta: {
              needLogin: true,
            },
          },
        ],
      },
      {
        path: 'passwd',
        component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/edit-passwd/index.vue'),
        children: [
          {
            path: '',
            name: 'my_passwd',
            component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/edit-passwd/passwd-display.vue'),
            meta: {
              needLogin: true,
            },
          },
          {
            path: 'set-init-passwd',
            name: 'setting_set_init_passwd',
            component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/edit-passwd/set-passwd.vue'),
            props: () => ({
              opType: 'setting_set_init_passwd',
            }),
            meta: {
              needLogin: true,
            },
          },
          {
            path: 'set-new-passwd',
            name: 'setting_set_new_passwd',
            component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/edit-passwd/set-passwd.vue'),
            props: () => ({
              opType: 'setting_set_new_passwd',
            }),
            meta: {
              needLogin: true,
            },
          },
          {
            path: 'refound-passwd',
            name: 'setting_refound_passwd',
            component: () => import(/* webpackChunkName: "setting" */ '@/pc/views/settings/edit-passwd/set-passwd.vue'),
            props: () => ({
              opType: 'setting_refound_passwd',
            }),
            meta: {
              needLogin: true,
            },
          },
        ],
      },
    ],
  },
] as RouteConfig[];
