export default {
  CHANGE_SHOW_MOBILE_CONTENT_POST_INPUT: 'CHANGE_SHOW_MOBILE_CONTENT_POST_INPUT',
  CHANGE_CURRENT_REPLY_USER: 'CHANGE_CURRENT_REPLY_USER',
  CHANGE_POST_CONTENT: 'CHANGE_POST_CONTENT',
  SET_THREAD_ID: 'SET_THREAD_ID',
  SET_HOT_POSTS: 'SET_HOT_POSTS',
  SET_POSTS: 'SET_POSTS',
  SET_POST_TYPE: 'SET_POST_TYPE',
  SET_SORT_TYPE: 'SET_SORT_TYPE',
  SET_LIKE_LIST: 'SET_LIKE_LIST',
  SET_PAGE_NUM: 'SET_PAGE_NUM'
};
