import { getGroupPopular, getMyGroup, getGroupNew, getGroupDetail } from '@/api/group';
import { getRecentlyThread, getGroupThread, getThreadDetail } from '@/api/thread';
const group = {
  namespaced: true,
  state: () => ({
    popularGroups: [],
    myGroups: [],
    newGroups: [],
    recentlyThreads: [],
    total: 0,
    datalist: [],
    detailData: {},
    groupData: {},
    threadData: {},
    dataLoaded: false,
  }),
  getters: {
    dataLoaded: state => state.dataLoaded,
    threadData: state => state.threadData,
    groupData: state => state.groupData,
    detailData: state => state.detailData,
    datalist: state => state.datalist,
    total: state => state.total,
    recentlyThreads: state => state.recentlyThread,
    newGroups: state => state.newGroups,
    myGroups: state => state.myGroups,
    popularGroups: state => state.popularGroups
  },
  mutations: {
    SET_GROUP_POPULAR: (state, data) => {
      state.popularGroups = data;
    },
    SET_MY_GROUP: (state, data) => {
      state.myGroups = data;
    },
    SET_NEW_GROUP: (state, data) => {
      state.newGroups = data;
    },
    SET_RECENTLY_THREAD: (state, data) => {
      state.recentlyThreads = data;
    },
    SET_GROUP_DETAIL_LIST: (state, data) => {
      state.datalist = data.data;
      state.total = data.count;
    },
    SET_GROUP_DETAIL: (state, data) => {
      state.detailData = data;
      state.groupData = data;
      state.dataLoaded = data && Object.keys(data).length > 0;
    },
    SET_THREAD_DETAIL: (state, data) => {
      state.threadData = data;
    },
  },
  actions: {
    async getGroupPopulars({ commit }, limit) {
      let data = await getGroupPopular({ limit });
      commit('SET_GROUP_POPULAR', data);
      return data;
    },
    async getMyGroups({ commit }) {
      let data = await getMyGroup();
      commit('SET_MY_GROUP', data);
      return data;
    },
    async getGroupNews({ commit }) {
      let data = await getGroupNew();
      commit('SET_NEW_GROUP', data);
      return data;
    },
    async getRecentlyThreads({ commit }) {
      let data = await getRecentlyThread();
      commit('SET_RECENTLY_THREAD', data);
      return data;
    },
    async getDetailList({ commit }, params) {
      let data = await getGroupThread(params);
      commit('SET_GROUP_DETAIL_LIST', data);
      return data;
    },
    async getGroupDetail({ commit, state }, groupId) {
      if (groupId != state.groupData.id) {
        commit('SET_GROUP_DETAIL', {});
      }
      let data = await getGroupDetail(groupId);
      commit('SET_GROUP_DETAIL', data);
      return data;
    },
    async getThreadDetail({ commit }, threadId) {
      let data = await getThreadDetail(threadId);
      commit('SET_THREAD_DETAIL', data);
      return data;
    }
  }
};

export default group;
