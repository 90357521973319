// eslint-disable-next-line no-unused-vars
import { RouteConfig } from 'vue-router';

/**
 * 账户中心的路由配置
 */
export default [
  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/index.vue'),
    redirect: '/my/coin',
    children: [
      {
        path: 'coin',
        component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/b-coin/index.vue'),
        children: [
          {
            path: '',
            name: 'my_coin',
            component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/b-coin/index-home.vue'),
            meta: {
              needLogin: true,
            },
          },
          // {
          //   path: 'pay_card',
          //   name: 'my_coin_card_pay',
          //   component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/b-coin/coin-card-charge.vue'),
          // },
          {
            path: 'pay_money',
            name: 'my_coin_money_pay',
            component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/b-coin/coin-money-charge.vue'),
            meta: {
              needLogin: true,
            },
          },
          {
            path: 'withdraw',
            name: 'my_coin_withdraw',
            component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/b-coin/withdraw.vue'),
            meta: {
              needLogin: true,
            },
          },
        ],
      },
      {
        path: 'invitecode',
        name: 'my_invitecode',
        component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/b-coin/index-home.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'orders',
        name: 'my_orders',
        component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/orders/index.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'coupon',
        name: 'my_coupon',
        component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/package/index.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'red-envelope',
        name: 'my_red_envelope',
        component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/package/index.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'facing',
        name: 'my_facing',
        component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/package/index.vue'),
        meta: {
          needLogin: true,
        },
      },
      {
        path: 'bind-alipay',
        name: 'my_bind_alipay',
        component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/bind-alipay/index.vue'),
        meta: {
          needLogin: true,
        },
      },
    ],
  },
  {
    path: '/coin/order',
    name: 'my_coin_money_order',
    component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/b-coin/coin-money-order.vue'),
    meta: {
      needLogin: true,
    },
  },
  {
    path: '/coin/show',
    name: 'coin_show',
    component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/single-page/b-explain.vue'),
    meta: {
      needLogin: true,
    },
  },
  {
    path: '/coupon/:token/receive',
    name: 'coupon_receive',
    component: () => import(/* webpackChunkName: "coin" */ '@/pc/views/my-coin/coupon/receive.vue'),
    props: router => ({
      token: router.params.token,
      batchId: Number(router.query.batchId),
    }),
    meta: {
      needLogin: true,
      hideFooter: true,
    },
  },
] as RouteConfig[];
