import * as Service from '@/api/sms_code';

export interface SmsCodePostData {
  title?: string;
  mobile: string;
  msg?: string;
}

export interface VerifySmsCodePostData {
  mobile: string;
  smscode: string | number;
}

export default class SmsCodeHelper {
  private intervalHandle?: number;
  private initWaitSeconds: number = 60;
  public onWaitSecondsChange?: (msg: string) => void;
  public onStop?: (v: number) => void;
  /**
   * @param initWaitSeconds 初始化等待是多少秒
   * @param displayText 按钮点击后需要显示的内容，如： <% seconds %> 秒后重新发送
   */
  constructor(private displayText: string) {}

  private get waitSeconds() {
    return this.initWaitSeconds;
  }
  private set waitSeconds(v: number) {
    this.initWaitSeconds = v;
    if (typeof this.onWaitSecondsChange !== 'function') {
      return;
    }
    this.onWaitSecondsChange(this.displayText.replace(/<%.*%>/, this.waitSeconds as any as string));
  }

  private initInterval(initWaitSeconds: number = 60) {
    this.initWaitSeconds = initWaitSeconds;
    this.waitSeconds -= 1;
    this.intervalHandle = window.setInterval(() => {
      if (this.waitSeconds <= 0) {
        this.clearInterval();
      } else {
        this.waitSeconds -= 1;
      }
    }, 1000);
  }

  public clearInterval() {
    window.clearInterval(this.intervalHandle);
    this.intervalHandle = undefined;
    if (typeof this.onStop !== 'function') {
      return;
    }
    this.onStop(this.waitSeconds);
  }

  public async sendSmsCode(data: SmsCodePostData, checkPhoneExist: boolean = false) {
    if (this.intervalHandle) {
      return;
    }
    this.initInterval();
    try {
      await Service.sendSmsCode(data, checkPhoneExist);
    } catch (e) {
      this.clearInterval();
      throw e;
    }
  }

  public async sendSmsCodeById() {
    if (this.intervalHandle) {
      return;
    }
    this.initInterval();
    try {
      await Service.sendSmsCodeById();
    } catch (e) {
      this.clearInterval();
      throw e;
    }
  }

  public static async verifySmsCode(data: VerifySmsCodePostData) {
    return await Service.verifySmsCode(data);
  }
}
