

































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { addConfusion, editConfusion } from '@/api/notes';
import CloseNotesDialog from './close-dialog.vue';

@Component({
  components: {
    CloseNotesDialog,
  },
})
export default class UpdateNotesDialog extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Number, default: 0 }) questionId: any;
  @Prop({ type: Object, default: () => ({}) }) notesInfo: any;

  submitLoading: boolean = false;
  closeVisible: boolean = false;

  oldContent: any = '';
  oldStatus: any = 1;

  form: any = {
    content: '',
    status: 1,
  };

  @Watch('syncVisible', { deep: true, immediate: true })
  watchNotesInfo(status) {
    if (status) {
      this.form.content = this.notesInfo.content || '';
      this.form.status = this.notesInfo.status === 0 ? 0 : 1;
      this.oldContent = this.notesInfo.content || '';
      this.oldStatus = this.notesInfo.status === 0 ? 0 : 1;
    } else {
      this.form.content = '';
    }
  }

  // 新增或者编辑笔记
  async submit() {
    if (this.form.content.trim().length < 5) {
      this.$message.warning('输入内容必须大于5字');
      return;
    }
    if (this.submitLoading) return;
    try {
      this.submitLoading = true;
      // 新增
      if (!this.notesInfo.id) {
        await this.addNotesFun();
      }
      // 编辑
      if (this.notesInfo.id) {
        await this.editNotesFun();
      }
      // setTimeout(() => {
      this.$emit('updateList');
      this.$message.success('发布成功');
      this.closeDialog(false);
      this.submitLoading = false;
      // }, 200);
    } catch (error) {
      console.log('接口请求失败', error);
      // @ts-ignore
      if (error && error.code === 4044) {
        this.$message.warning(`该题目已被删除，无法${!this.notesInfo.id ? '新增' : '编辑'}笔记`);
        // @ts-ignore
        error.hideNormalFail && error.hideNormalFail();
      }
      this.submitLoading = false;
    }
  }

  // 新增
  async addNotesFun() {
    await addConfusion({
      content: this.form.content,
      status: this.form.status,
      questionId: this.questionId,
    });
  }

  // 编辑
  async editNotesFun() {
    await editConfusion({
      content: this.form.content,
      status: this.form.status,
      confusionId: this.notesInfo.id,
    });
  }

  closeDialog(check = true) {
    if (check && (this.form.content !== this.oldContent || this.form.status !== this.oldStatus)) {
      this.closeVisible = true;
      console.log('二次确认');
      return;
    }
    this.syncVisible = false;
  }
}
