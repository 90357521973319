

















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import UserAvator from '../user-com/user-avator.vue';
import UserTagVip from '../user-com/user-tag-vip.vue';
import ComplainDialog from '../complain-dailog.vue';
import { CircleCommentItem } from '@/typings';
import { mapGetters, mapMutations } from 'vuex';

import {
  likeCircleDynamicComment,
  cancelLikeCircleDynamicComment,
  likeCircleDynamicCommentReply,
  cancelLikeCircleDynamicCommentReply,
  deleteCircleDynamicComment,
  deleteCircleDynamicCommentReply,
  addCircleDynamicCommentReply,
  getCommentReplyList,
} from '@/api/circle';

@Component({
  name: 'CommentItemCom',
  components: {
    UserAvator,
    UserTagVip,
    ComplainDialog,
  },
  computed: {
    ...mapGetters('my', ['me', 'isLogin']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class CommentItemCom extends Vue {
  @Prop({ type: Object, default: () => ({}) }) commentData!: CircleCommentItem;
  @Prop({ type: Boolean, default: false }) isNeedHotIcon;
  @Prop({ type: Boolean, default: false }) isInReplayDialog?: boolean; // 是否在回复弹窗中
  @Prop({ type: Boolean, default: false }) isVialot;

  isLogin: any;
  me: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  isChanging: boolean = false;
  editComment: CircleCommentItem | null = null;
  isSubmitting: boolean = false;
  commentContent: string = '';
  placeholder: string = '';

  isComplainDialogVisible: boolean = false; //投诉弹窗
  complianData = {
    id: 0,
    type: 'comment',
  };

  isMe(userId) {
    if (!this.me || !this.me.id) return false;
    return this.me.id === userId;
  }

  // 评论的点赞
  async likeDynamicCommentHandle() {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      if (this.isChanging) return;
      this.isChanging = true;
      const { isLiked, id } = this.commentData;
      if (isLiked) {
        await cancelLikeCircleDynamicComment(id);
        this.commentData['likeNum'] -= 1;
        this.commentData.isLiked = 0;
      } else {
        await likeCircleDynamicComment(id);
        this.commentData['likeNum'] += 1;
        this.commentData.isLiked = 1;
      }
    } finally {
      this.isChanging = false;
    }
  }

  // 评论回复的点赞
  async likeCommentReplayHandle(replayComment) {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      if (this.isChanging) return;
      this.isChanging = true;
      const { isLiked, id } = replayComment;
      if (isLiked) {
        await cancelLikeCircleDynamicCommentReply(id);
        replayComment['likeNum'] -= 1;
        replayComment['isLiked'] = 0;
      } else {
        await likeCircleDynamicCommentReply(id);
        replayComment['likeNum'] += 1;
        replayComment['isLiked'] = 1;
      }
    } finally {
      this.isChanging = false;
    }
  }

  // 删除评论
  async deleteDynamicComment() {
    this.$confirm('确定删除该评论吗', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      customClass: 'circle-confirm-dialog',
    }).then(async () => {
      await deleteCircleDynamicComment(this.commentData.id);
      this.$message.success('评论已删除');
      this.$emit('refresh');
      this.$emit('numChange');
    });
  }

  // 删除评论回复
  async deleteDynamicCommentReplay(item) {
    this.$confirm('确定删除该评论吗', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      customClass: 'circle-confirm-dialog',
    }).then(async () => {
      await deleteCircleDynamicCommentReply(item.id);
      this.$message.success('评论已删除');
      // 删除评论回复后拉取当前评论回复，更新评论
      const { rows } = await getCommentReplyList(this.commentData.id, {
        page: 0,
        limit: 100,
      });
      this.$set(this.commentData, 'replyNum', rows.length);
      if (this.isInReplayDialog) {
        this.$set(this.commentData, 'topReplys', rows);
      } else {
        this.$set(this.commentData, 'topReplys', rows.slice(0, 3));
      }
      this.$emit('numChange');
    });
    // .catch(() => {
    //   this.$message({
    //     type: 'info',
    //     message: '已取消删除',
    //   });
    // });
  }

  // 回复评论或者回复评论的评论
  async replayHandle(comment) {
    // 判断当前编辑与comment值一致，表示二次点击，所以关闭评论
    if (this.editComment && this.editComment.id == comment.id) {
      this.editComment = null;
      this.commentContent = '';
      return;
    }
    this.editComment = comment;
    this.commentContent = '';
    this.placeholder = `回复${comment.userName}`;
    await this.$nextTick();
    const targetInput = this.$refs.refInput as any;
    if (!targetInput) return;
    Array.isArray(targetInput) ? targetInput[0].focus() : targetInput.focus();
  }

  // 增加评论，以及评论的评论
  async addComment() {
    try {
      if (!this.isLogin) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      if (!this.editComment) return;
      if (!this.commentContent) {
        this.$message.warning('请先填写回复内容');
        return;
      }
      this.isSubmitting = true;
      const { id } = this.editComment;
      await addCircleDynamicCommentReply(id, { content: this.commentContent });
      this.$message.success('发布成功');
      // 删除评论回复后拉取当前评论回复，更新评论
      const { rows } = await getCommentReplyList(this.commentData.id, {
        page: 0,
        limit: 100,
      });
      this.$set(this.commentData, 'replyNum', rows.length);
      if (this.isInReplayDialog) {
        this.$set(this.commentData, 'topReplys', rows);
      } else {
        this.$set(this.commentData, 'topReplys', rows.slice(0, 3));
      }
      this.$emit('numChange');
    } finally {
      this.editComment = null;
      this.isSubmitting = false;
    }
  }

  // 投诉
  complainHandle(data, type) {
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
      return;
    }
    this.complianData.id = data.id;
    this.complianData.type = type;
    this.isComplainDialogVisible = true;
  }

  // 查看全部评论回复
  async seeAllReplay() {
    this.$emit('seeAllReplay', this.commentData);
  }

  // 跳转至用户详情页
  goUserPage(userId) {
    window.open(`/new-user/${userId}`);
  }
}
