




import { Vue, Component, Prop } from 'vue-property-decorator';

import SmsCodeHelper from '@/utils/sms-code-helper';
// const smsCodeHelper = new SmsCodeHelper('<% %> 秒后重新发送');

@Component
export default class SendSmsCodeBtn extends Vue {
  @Prop({ type: String, default: '' }) mobile!: string;
  @Prop({ type: Function }) sendSmsBefore!: (next: () => void) => boolean;
  @Prop({ type: Boolean, default: false }) shouldCheckPhoneExist!: boolean;
  @Prop({ type: Boolean }) hadBindMobile?: boolean;

  smsCodeHelper = new SmsCodeHelper('<% %>s后重试');
  smsCodeBtnText: string = '获取验证码';
  isSendSmsCode: boolean = false;

  get disabled() {
    return (!this.hadBindMobile && !/^1[3456789]\d{9}$/.test(this.mobile)) || this.isSendSmsCode;
  }

  mounted() {
    this.smsCodeHelper.onWaitSecondsChange = msg => {
      this.smsCodeBtnText = msg;
      this.isSendSmsCode = true;
    };
    this.smsCodeHelper.onStop = () => {
      this.smsCodeBtnText = '获取验证码';
      this.isSendSmsCode = false;
    };
  }

  destroyed() {
    // smsCodeHelper.clearInterval();
  }

  async sendSmsCode() {
    const self = this;
    const next = async () => {
      self.isSendSmsCode = true;
      try {
        if (this.hadBindMobile) {
          await self.smsCodeHelper.sendSmsCodeById();
        } else {
          await self.smsCodeHelper.sendSmsCode({ mobile: self.mobile }, this.shouldCheckPhoneExist);
        }
      } catch (e) {
        // if (e.code === 400) {
        this.$message.error(e.msg || e.message);
        // }
      }
    };
    if (this.sendSmsBefore) {
      this.sendSmsBefore(next);
      return;
    }
    next();
  }
}
