






















import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { deleteConfusion } from '@/api/notes';

@Component({
  components: {},
})
export default class UpdateNotesDialog extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Number, default: 0 }) questionId: any;
  @Prop({ type: Object, default: () => ({}) }) notesInfo: any;

  submitLoading: boolean = false;

  form: any = {
    content: '',
  };

  @Watch('syncVisible', { deep: true, immediate: true })
  watchNotesInfo(status) {
    if (status) {
      this.form.content = this.notesInfo.content || '';
    } else {
      this.form.content = '';
    }
  }

  // 删除笔记
  async submit() {
    if (this.submitLoading) return;
    try {
      this.submitLoading = true;
      await deleteConfusion({ confusionId: this.notesInfo.id });
      // setTimeout(() => {
      this.$message.success('笔记已删除');
      this.$emit('updateList');
      this.closeDialog();
      this.submitLoading = false;
      // }, 200);
    } catch (e) {
      console.log('接口请求失败', e);
      this.submitLoading = false;
    }
  }

  closeDialog() {
    this.syncVisible = false;
  }
}
