import askMain from '@/pc/views/ask/main.vue';
import askIndex from '@/pc/views/ask/pages/index.vue';
import askDetails from '@/pc/views/ask/pages/details.vue';
import askSearch from '@/pc/views/ask/pages/search.vue';
import askDetailsQuestion from '@/pc/views/ask/pages/question.vue';
import askDetailsQuestionDo from '@/pc/views/ask/pages/question-do.vue';
import askCreate from '@/pc/views/ask/pages/create.vue';
import askMyAsk from '@/pc/views/ask/my/ask.vue';
import askMyFavorite from '@/pc/views/ask/my/favorite.vue';

export default {
  path: '/ask',
  meta: { isPC: true },
  component: askMain,
  redirect: { name: 'ask-main' },
  children: [
    {
      path: '',
      name: 'ask-index', // 问答首页
      meta: { isPC: true },
      component: askIndex,
    },
    {
      path: 'search',
      name: 'ask-search', // 问答搜索页
      meta: { isPC: true },
      component: askSearch,
      props: router => ({
        searchText: decodeURIComponent(router.query.searchText || ''),
        page: Number(router.query.page) || 1,
        limit: Number(router.query.limit) || 10,
      }),

    },
    {
      path: 'create',
      name: 'ask-create', // 去提问
      meta: { isPC: true, needLogin: true },
      component: askCreate,
      props: router => ({
        categoryId: Number(router.query.categoryId) || 0,
        questionId: Number(router.query.questionId) || 0,
        from: router.query.from || '',
        isFixed: !!router.query.isFixed || false,
      }),
    },
    {
      path: ':doubtId/details',
      name: 'ask-details', // 问答详情
      meta: { isPC: true },
      component: askDetails,
      props: router => ({
        doubtId: Number(router.params.doubtId),
      }),
    },
    {
      path: ':doubtId/details/question',
      name: 'ask-details-questions', // 问答详情-关联题目-解析模式
      meta: { isPC: true, hideFooter: true, needLogin: true },
      component: askDetailsQuestion,
      props: router => ({
        categoryId: Number(router.query.categoryId),
        doubtId: Number(router.params.doubtId),
        questionId: Number(router.query.questionId),
        autoOpen: !!Number(router.query.autoOpen || 0),
        autoOpenNote: !!Number(router.query.autoOpenNote || 0),
      }),
    },
    {
      path: ':doubtId/details/question-do',
      name: 'ask-details-questions-do', // 问答详情-关联题目-答题模式
      meta: { isPC: true, hideFooter: true, needLogin: true },
      component: askDetailsQuestionDo,
      props: router => ({
        categoryId: Number(router.query.categoryId),
        doubtId: Number(router.params.doubtId),
        questionId: Number(router.query.questionId),
        autoOpen: !!Number(router.query.autoOpen || 0),
      }),
    },
    {
      path: 'my/ask',
      name: 'ask-my-ask', // 问答详情-我的问答
      meta: { isPC: true, needLogin: true },
      component: askMyAsk,
      props: router => ({
      }),
    },
    {
      path: 'my/favorite',
      name: 'ask-my-favorite', // 问答详情-我的收藏
      meta: { isPC: true, needLogin: true },
      component: askMyFavorite,
      props: router => ({
      }),
    },
  ],
};
