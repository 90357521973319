<template>
  <div class="home-bt-group-hot bt-model">
    <div class="bt-head"><span class="bt-title">热门圈子</span> <a href="/group" class="bt-open">查看更多</a></div>

    <div class="bt-body" v-if="hasMounted">
      <lazy-component @show="handler">
        <div class="list" v-for="(item, index) in groups" :key="index">
          <div class="list-left">
            <a :href="`/group/${item.id}`"> <img :src="item.logo" v-default-img-alt="item.title"/> </a>
          </div>
          <div class="list-right">
            <div class="list-title">
              <a :href="`/group/${item.id}`">{{ item.title | sub_text(10) }}</a>
            </div>
            <div class="list-num">{{ item.memberNum }}人</div>
          </div>
        </div>
      </lazy-component>

      <template v-if="!groups">
        <div class="list" v-for="(item, index) in 6" :key="index">
          <div class="list-left"><a></a></div>
          <div class="list-right">
            <div class="list-title"><a></a></div>
            <div class="list-num"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getGroupPopular } from '@/api/group';

export default {
  data() {
    return {
      groups: null,
      hasMounted: false,
    };
  },
  mounted() {
    this.hasMounted = true;
  },
  methods: {
    handler() {
      getGroupPopular({ limit: 6 }).then(data => {
        this.groups = data;
      });
    },
  },
};
</script>
