import asyncLoadJs from '@/utils/async-load-js';
import { getBrowser } from '@/utils/helper';

const browser = getBrowser(window.navigator.userAgent);
const isWx = browser.wx;
if (process.env.NODE_ENV !== 'production' && isWx) {
  (async function () {
    await asyncLoadJs('https://app-cdn.btclass.cn/web/libs/vconsole.min.js', true);
    new window.VConsole();
  })();
}
