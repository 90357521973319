










import { Vue, Component } from 'vue-property-decorator';
import AddressParse from 'address-parse';
@Component({})
export default class AiAdress extends Vue {
  adress = '';

  onAiClick() {
    const [result] = AddressParse.parse(this.adress);
    this.$message.success('请检查拆分信息是否准确，如有遗漏请手动补充');
    this.$emit('ai', result);
  }
}
