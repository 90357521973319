<template>
  <ol class="breadcrumb breadcrumb-o">
    <li v-for="(item, i) in filterData" :key="i" :class="{ active: i + 1 === data.length }">
      <router-link :to="item.path" v-if="item.path !== path">{{ item.name }}</router-link>
      <span v-else>{{ item.name }}</span>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: {
    value: Boolean,
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    path() {
      return this.$route.path;
    },
    filterData() {
      let data = [...this.data];
      if (this.$mobile()) {
        data = data.filter((e, i) => i === 0 || i === data.length - 1);
      }
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumb {
  padding: 8px 10px;
  margin-bottom: 20px;
  list-style: none;
  border-radius: 4px;
  background-color: #f5f5f5;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  content: '/\00a0';
  padding: 0 5px;
  color: #ccc;
}
.breadcrumb > .active {
  color: #e1e1e1;
}
.breadcrumb.breadcrumb-o {
  background: none;
  margin: 12px auto;
  padding: 8px 10px;
}
.breadcrumb li.active {
  color: #919191;
}
.breadcrumb a {
  color: #919191;
  transition: all 0.3s ease;
}
.breadcrumb a:hover {
  color: #1e73ff;
  transition: all 0.3s ease;
}
</style>
