import { getCustomPage } from '@/api/home';


const customPage = {
  namespaced: true,
  state: () => ({
    pageData: {},
    isLoading: false,
  }),
  getters: {
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_PAGE_DATA(state, payload) {
      state.pageData = payload;
    }
  },
  actions: {
    async getCustomPage({ state, commit }, alias) {
      commit('SET_LOADING', true);
      const pageData = await getCustomPage(alias).finally(() => commit('SET_LOADING', false));
      commit('SET_PAGE_DATA', pageData);
    },
  },
};

export default customPage;