














import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import FacingCard from '@/pc/views/my-coin/facing/_component/facing-card.vue';
import { getRequireTicketsByUser } from '@/api/ask';

@Component({
  components: {
    FacingCard,
  },
})
export default class ChooseCoupon extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Object, default: () => {} }) activeCategory: any;
  useType: string = 'choose';
  activeInfo: any = {};

  facingList: any = [];
  page: number = 0;
  limit: number = 100;

  @Watch('syncVisible')
  async watchVisible(val) {
    // 关闭重置
    if (!val) {
      this.activeInfo = {};
      await this.getFacingList();
    }
  }

  // 分类修改，则重新拉一下列表
  @Watch('activeCategory.id', { immediate: true })
  async watchCategoryId(id) {
    if (id) {
      await this.getFacingList();
    }
  }

  // 获取可用的必答券,只拉第一张，打开弹窗再拉全部
  async getFacingList() {
    try {
      this.facingList = [];
      this.page = 0;
      await this.getlist();
      // 存在第一张，默认选中
      if (this.facingList.length) {
        this.activeInfo = this.facingList[0];
      }
      console.log('可用的必答券-弹窗', this.facingList);
    } catch (e) {
      console.log('获取可用的必答券失败', e);
    }
  }

  // 批量获取必答券，直到结束
  async getlist() {
    const { rows } = await getRequireTicketsByUser({
      categoryId: this.activeCategory.id,
      page: this.page,
      limit: this.limit,
    });
    // @ts-ignore
    this.facingList = [...this.facingList, ...rows];
    if (rows && rows.length) {
      this.page = this.page + 1;
      await this.getlist();
    }
  }

  // 选择必答券
  activeCard(info) {
    this.activeInfo = info;
    console.log('选择必答券', info);
  }

  // 关闭弹窗
  closeDialog() {
    this.syncVisible = false;
  }

  // 确定
  submitHandler() {
    this.$emit('chooseFacing', this.activeInfo);
    this.syncVisible = false;
  }
}
