



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

@Component({
  components: {
    swiper,
    swiperSlide,
  },
})
export default class modulesOperating extends Vue {
  @Prop({ type: Array, default: () => [] }) cgfInfo: any;
  @Prop({ type: String, default: 'swiper' }) type: any;

  swiperOption: any = {
    direction: 'horizontal',
    grabCursor: true,
    autoHeight: true,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 0,
    loop: true,
    observer: true,
    observeParents: true,
    allowTouchMove: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  get list() {
    if (!this.cgfInfo.length) {
      return [];
    }
    return this.cgfInfo.map(item => {
      return { ...JSON.parse(item.resource), url: item.url };
    });
  }

  jumpUrl(row) {
    console.log('jumpUrl', row.url);
    window.open(row.url, '_blank');
  }
}
