import { request } from '@/utils/axios';
import { FriendLinkInfo } from '@/typings';

// 头部导航菜单
export function getMenu() {
  return request.get('/top-menu', {}, { requestBase: 'phoenix' });
}

// 获取首页顶部轮播图
export function getHomeTopBanner() {
  return request.get('/home/top-banner', {}, { requestBase: 'phoenix' });
}

export function getLoginModal() {
  return request.get('/login/modal', {}, { requestBase: 'phoenix' });
}

export function getFriendLinks() {
  return request.get<FriendLinkInfo[]>('/friend-link', {}, { requestBase: 'phoenix' });
}

export function getActiveLinks(params = {}) {
  return request.get('/v2/setting', params, { requestBase: 'course' }); // v2
  // return request.throughApi('ms-course.setting.index', params); // v1
}

// 获取android版本发布信息列表
export function getVersionInfoByAndroid(params) {
  return request.get('/v2/version-manage/android', params, { requestBase: 'user' });
}
