








import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

@Component
export default class ComName extends Vue {
  @PropSync('visible', { type: Boolean }) visibleSync!: boolean;
  @Prop({ type: Boolean, default: false }) closeOnClickModal?: boolean;
  @Prop({ type: Boolean, default: false }) appendToBody;

  dialogOpenHandle() {
    this.$emit('openDialog');
  }

  closeHandle() {
    this.$emit('close');
  }
}
