


























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ToAsk from '../components/modules/to-ask.vue';
import MyAskFavorite from '../components/modules/my-ask-favorite.vue';
import Operating from '../components/modules/operating.vue';
import RecommendAsk from '../components/modules/recommend-ask.vue';
import RecommendCourse from '../components/modules/recommend-course.vue';
import AskItem from '../components/modules/ask-item.vue';
import AskAnswer from '../components/modules/ask-answer.vue';
import MainLoading from '../components/modules/main-loading.vue';
import AskAddReward from '../components/ask-add-reward.vue';
import AskUpgradeFacing from '../components/ask-upgrade-facing.vue';

import { getDoubtDetailByPC, getRequireService, getCfgValids } from '@/api/ask';
import { mapGetters, mapMutations } from 'vuex';

@Component({
  components: {
    ToAsk,
    MyAskFavorite,
    Operating,
    RecommendAsk,
    RecommendCourse,
    AskItem,
    AskAnswer,
    MainLoading,
    AskAddReward,
    AskUpgradeFacing,
  },
  computed: {
    ...mapGetters('my', ['me', 'isLogin']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class AskDetails extends Vue {
  @Prop({ type: Number, default: 0 }) doubtId: any;
  me: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  isLoading: any = true;
  askInfo: any = {};
  requireServeceInfo: any = {}; // 必答券升级信息
  rewardVisible: boolean = false;
  facingVisible: boolean = false;
  cgfInfo: any = [];

  get questionId() {
    return (this.askInfo.questionInfo && this.askInfo.questionInfo.id) || 0;
  }

  // 是否已登陆
  get myUserId() {
    console.log('this.me', this.me);
    return (this.me && this.me.id) || 0;
  }

  // 是否我的问答
  get isMyAsk() {
    return this.askInfo.userId === this.myUserId;
  }

  // 问题状态[opened, closed, resolved]（正常打开状态/关闭问题/问题已解决
  get status() {
    return this.askInfo.status;
  }

  // 分类信息
  get categoryInfo() {
    return this.askInfo.categoryInfo || {};
  }

  // B豆悬赏模式
  get isBDouMode() {
    return !this.askInfo.requireInfo.id;
  }

  // 回答列表
  get explanationList() {
    return this.askInfo.explanationList || [];
  }

  async created() {
    this.setInit();
    await this.getDetails();
    this.setABC();
  }

  setInit() {
    // 布局初始化
    this.$bus.$emit('setlayout', { openFlex: false, mainWidth: '1080px' });
  }

  // 设置面包屑导航
  setABC() {
    this.$bus.$emit('setAskBreadCrumb', {
      show: true,
      data: [{ name: 'BT教育' }, { name: '问答主页', to: { path: `/ask?categoryId=${this.categoryInfo.id}` } }, { name: '问答详情页' }],
    });
  }

  // 获取详情数据
  async getDetails() {
    try {
      this.isLoading = true;
      const [askInfo, requireServeceInfo] = await Promise.all([getDoubtDetailByPC({ doubtId: this.doubtId }), getRequireService({})]);
      const cgfInfo = await getCfgValids({
        type: 10,
        categoryId: askInfo.categoryInfo.id,
      });
      this.cgfInfo = cgfInfo;
      this.askInfo = askInfo;
      this.requireServeceInfo = requireServeceInfo;
      console.log('获取详情数据', askInfo, requireServeceInfo, cgfInfo);
    } catch (e) {
      console.log('获取详情数据失败', e);
    } finally {
      this.isLoading = false;
    }
  }

  // 去追加悬赏
  async toAddReward() {
    this.rewardVisible = true;
  }

  // 去升级必答服务
  async toUpgradeFacing() {
    this.facingVisible = true;
    const requireServeceInfo = await getRequireService({});
    this.requireServeceInfo = requireServeceInfo;
  }

  // 去问答中心
  goAskIndex() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-index', query: { categoryId: this.categoryInfo.id } });
    window.open(link.href, '_blank');
  }
}
