import ActivityIndex from '@/pc/views/activity/index.vue';
import AnniversaryIndex from '@/pc/views/activity/anniversary/index.vue';
import Double11Index from '@/pc/views/activity/double11/index.vue';
import ActivityPage from '@/pc/views/activity/page/index.vue';
import ActivityAct from "@/pc/views/activity/act/index.vue"

export default {
  path: '/activity-page',
  meta: { isPC: true },
  component: ActivityIndex,
  redirect: { name: '10th' },
  children: [
    {
      path: '10th',
      name: '10th',
      meta: { isPC: true, isFixed: true, layoutClassName: 'activity-layout' },
      component: AnniversaryIndex,
    },
    {
      path: 'act/:pageId',
      name: 'activity-act',
      meta: { isPC: true, isFixed: true, layoutClassName: 'activity-con' },
      component: ActivityAct,
      props: router => ({
        pageId: Number(router.params.pageId),
      }),
    },
    {
      path: 'page/:id',
      name: 'activity_page_detail',
      meta: { isPC: true },
      props: router => ({
        pageId: Number(router.params.id),
      }),
      component: ActivityPage,
    },
    {
      path: '**',
      redirect: { name: '10th' },
    },
  ],
};
