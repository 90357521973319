<template>
  <el-form class="password-login" :model="form" :rules="rules" ref="form">
    <input type="text" class="is-hidden" />
    <input type="password" class="is-hidden" />
    <el-form-item prop="account">
      <el-input placeholder="账号" maxlength="11" @keyup.enter.native="login" v-model.trim="form.account"> </el-input>
    </el-form-item>
    <el-form-item prop="password" :error="errorMsg">
      <el-input type="password" placeholder="密码" maxlength="20" @keyup.enter.native="login" v-model.trim="form.password"> </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :loading="loading" class="full-width-btn" :disabled="!isConfirmButtonValid" @click="login">登录</el-button>
    </el-form-item>
    <el-link :underline="false" @click="SET_RESET_PASSWORD_DIALOG_VISIBLE(true)" class="forget-password" target="_blank">忘记密码？</el-link>
  </el-form>
</template>

<script>
import { loginByAccountAndPassword } from '@/api/login';
import { mapMutations } from 'vuex';
import {setLoginStorage} from '@/utils/repeat-function';
import { encrypt } from '@/utils/encrypt.ts'

export default {
  name: 'passwordLoginForm',
  data() {
    return {
      loading: false, // 登录loading
      errorMsg: '',
      form: {
        account: '',
        password: '',
      },
      rules: {
        account: [
          { required: true, message: '请输入帐号', trigger: 'blur' },
          { pattern: /^\d+$/, message: '请输入正确的学号或手机号', trigger: 'blur' },
          { max: 11, message: '长度最大为 11 位', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 位', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    isPasswordValid() {
      return this.form.password && this.form.password.length >= 5;
    },
    isConfirmButtonValid() {
      // 登录按钮状态
      return this.form.account && this.isPasswordValid;
    },
  },
  methods: {
    ...mapMutations('my', ['SET_RESET_PASSWORD_DIALOG_VISIBLE']),
    login() {
      // 登录
      if (this.loading) {
        return false;
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.errorMsg = '';
          loginByAccountAndPassword({
            account: this.form.account,
            password: encrypt(this.form.password + '_password'),
          })
            .then(res => {
              setLoginStorage(res);
              this.$emit('loginSuccess');
            })
            .catch(errorData => {
              this.errorMsg = errorData.msg || errorData.message;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.is-hidden {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.password-login {
  width: 252px;
  margin: 20px auto 20px;
  position: relative;

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    color: #363636;
    height: 31px;
    padding: 7px 7px 7px 0;
    outline-style: none;
    line-height: normal;
    border-radius: 0;
    font-size: 12px;
  }
  ::v-deep .el-form-item__content {
    line-height: 32px;
  }
  ::v-deep .el-form-item__error {
    text-align: left;
  }
  ::v-deep .el-link {
    font-weight: normal;
  }
  .forget-password {
    color: #bbbbbb;
    font-size: 12px;
    position: absolute;
    right: -6px;
    bottom: -4px;
  }
}
.full-width-btn {
  width: 100%;
  background: #4b8fff;
  height: 34px;
  line-height: 34px;
  padding: 0;
  border: none;
  margin: 10px 0 20px 0;
  &:disabled {
    background: #cccccc;
    border-color: #cccccc;
    color: #ffffff;
  }
}
</style>
