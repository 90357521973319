















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CarouselCom extends Vue {
  @Prop({ type: Array, default: () => [] }) posters!: any[];
  userAgent = navigator.userAgent;
  isIE = this.userAgent.indexOf('Trident') > -1 && this.userAgent.indexOf('rv:11.0') === -1; // ie & !ie11
  swiperOption: any = {
    loop: true,
    grabCursor: true,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    effect: this.isIE ? 'slide' : 'fade',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    slideChange: () => {
      this.setImgSrc();
    },
  };
  swiper: any;

  setImgSrc() {
    // 根据轮播图位置加载图片
    let activeIndex = this.swiper.activeIndex;
    let slideEl = this.swiper.slides[activeIndex];
    let bannerImgEl = slideEl.getElementsByClassName('img-responsive')[0];
    let src = bannerImgEl.getAttribute('src');
    if (!src) {
      bannerImgEl.setAttribute('src', bannerImgEl.getAttribute('data-src'));
    }
  }
  async mounted() {
    this.setImgSrc();
  }
}
