





























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class modulesMainLoading extends Vue {
  @Prop({ type: String, default: '' }) loadingText!: any;
}
