<template>
  <div class="home-bt-student-plan bt-model" v-if="hasMounted">
    <div class="bt-head"><span class="bt-title">学员动态</span></div>
    <div class="bt-body">
      <lazy-component @show="handler">
        <template v-if="data">
          <div class="list" v-for="(item, index) in data" :key="index">
            <div class="left"><user-preview :user="item.user" :showImgCase="showImgCase" class="info" model="img"></user-preview></div>
            <div class="right">
              <div class="title">
                <span class="text">
                  <user-preview :user="item.user" class="info" model="name"></user-preview>
                  <span class="time">{{ item.createdTime | smartTime }}</span>
                </span>
              </div>

              <span class="content"> <user-operate-model :item="item" string="color-8a9499"></user-operate-model> </span>
            </div>
          </div>
        </template>
      </lazy-component>
    </div>
  </div>
</template>

<script>
import { getStudentStatus } from '@/api/course';
import UserOperateModel from '@/pc/views/course/components/UserOperateModel';

export default {
  components: {
    UserOperateModel,
    UserPreview: () => import('@/pc/components/ui/user/UserPreview'),
  },
  data() {
    return {
      data: null,
      showImgCase: 'avatar',
      hasMounted: false,
    };
  },
  mounted() {
    this.hasMounted = true;
  },
  methods: {
    handler() {
      getStudentStatus(0, 3).then(e => (this.data = e.rows));
    },
  },
};
</script>
