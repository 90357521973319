'use strict';

// 获取query参数
export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const blocks = query.split('&');
  let value = '';
  blocks.forEach(block => {
    let pair = block.split('=');
    if (pair[0] === variable) {
      value = pair[1];
    }
  });
  return window.decodeURIComponent(value);
}

export function getUrlQueryVariable(url, variable) {
  const arr = url.split('?');
  const query = arr && arr[1];
  const blocks = query.split('&');
  let value = '';
  blocks.forEach(block => {
    let pair = block.split('=');
    if (pair[0] === variable) {
      value = pair[1];
    }
  });
  return window.decodeURIComponent(value);
}

// 拼接query参数
export function buildHttpQuery(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}
// 替换url
export function replaceState(params) {
  const query = buildHttpQuery(params);
  const url = location.origin + location.pathname + '?' + query;
  window.history.replaceState({}, '', url);
}
/**
 * @param query 泛指this.$route.query
 * @param inspect 用于判断query获取到的对应字段的值，是否存在于该字段原有值的集合內
 **/
export function getUrlQueryInspect(query = {}, inspect = {}) {
  let correct = {}; // 导出的对象
  const inspectKeys = Object.keys(inspect); // 用于比对的键名数组
  for (let field in query) {
    // 判断是否存在值
    if (query[field] !== void 0) {
      // 判断是否有当前field值
      if (inspectKeys.includes(field)) {
        // 判断用于比对的值 —— 数组类型
        if (Array.isArray(inspect[field])) {
          if (inspect[field].includes(query[field])) {
            correct[field] = query[field]; // 有值
          } else {
            correct[field] = inspect[field][0]; // 沒值，默认第一个数
          }
        } else {
          // 判断用于比对的值 —— 非数组类型
          if (String(inspect[field]) === query[field]) {
            correct[field] = query[field];
          } else {
            correct[field] = inspect[field];
          }
        }
      } else {
        // number类型，但无固定值范围的键名
        if (['userId', 'categoryId', 'studentId', 'startTime', 'endTime', 'page', 'limit', 'index'].includes(field)) {
          // 判断值是否包含非数字元素
          if (isNaN(query[field])) {
            correct[field] = query[field].replace(/\D/g, ''); // 替代所有非数字
          } else {
            correct[field] = Number(query[field]);
          }
        } else {
          correct[field] = query[field];
        }
      }
    }
  }
  replaceState(correct);
}
