const course = {
  namespaced: true,
  state: () => ({
    courseAuth: {
      isOwner: 0,
    },
    protocolId: 0, // 保过协议id,
    course: {},
  }),
  getters: {
    courseAuth: state => state.courseAuth,
    course: state => state.course,
  },
  mutations: {
    SET_COURSE_AUTH: (state, data) => {
      console.log('课程-state.courseAuth', data);
      state.courseAuth = data;
    },
    SET_PROTOCOL_ID: (state, data) => {
      state.protocolId = data;
    },
    UPDATE_OPEN_COURSE: (state, data) => {
      state.course = Object.assign({}, data);
    },
  },
  actions: {
    // 未购课 课程未过期 可买
    // 未购课 课程过期 不可买
    // 已购课 但过期 课程未过期可重买
    // 已购课 但过期 课程过期不可重买
    // 已购课
    getCourseAuth({ state, commit }, { authInfo, courseInfo }) {
      const now = Math.round(Date.now() / 1000);
      const { isOwner, deadline } = authInfo;
      const isUserNeverBuy = !!(!isOwner && !deadline); // 学员未买
      const isUserExpires = !!(!isOwner && deadline); // 学员过期
      const isUserValid = !!isOwner; // 学员未过期
      Object.assign(authInfo, {
        isUserNeverBuy,
        isUserValid,
        isUserExpires,
      });

      if (courseInfo) {
        const { expiryMode, expiryDay, buyable, singleBuy, status } = courseInfo;
        const isCourseExpires = !!(expiryMode === 'date' && expiryDay < now); // 课程过期
        const canBuyCourse = !!(!isCourseExpires && buyable && singleBuy && status === 'published');
        Object.assign(authInfo, {
          isCourseExpires,
          canBuyCourse,
        });
      }

      commit('SET_COURSE_AUTH', authInfo);
    },
  },
};

export default course;
