<template>
  <el-dialog v-if="!$login() && loginDialogVisible" custom-class="login-dialog" :visible.sync="isVisible" :width="$isMobile ? '310px' : '380px'" :show-close="false" :append-to-body="true">
    <login-container @onLoginSuccess="onLoginSuccess"></login-container>
  </el-dialog>
</template>

<script>
import LoginContainer from './components/loginContainer';
import { mapState, mapMutations } from 'vuex';
import Vue from 'vue';
export default {
  name: 'LoginDialog',
  components: {
    LoginContainer,
  },
  data() {
    return {
      isVisible: this.loginDialogVisible,
    };
  },
  computed: {
    ...mapState('my', ['loginDialogVisible']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE', 'SET_RESET_PASSWORD_DIALOG_VISIBLE']),
    onLoginSuccess() {
      // 正常情况下，是不需要由浏览器端设置的。这里主要是用于本地开发，dev-server的情况
      Vue.cookie.set('is_phoenix_login', 'true');
      if (typeof this.loginDialogVisible === 'boolean') {
        window.location.reload();
      } else if (typeof this.loginDialogVisible === 'string') {
        window.location.href = this.loginDialogVisible;
      }
    },
  },
  watch: {
    isVisible: function (val) {
      if (val) {
        setTimeout(() => {
          this.SET_RESET_PASSWORD_DIALOG_VISIBLE(false); // 关闭改密码弹窗
        });
      }
      if (val !== this.loginDialogVisible) {
        this.SET_LOGIN_DIALOG_VISIBLE(val);
      }
    },
    loginDialogVisible: {
      handler: function (val) {
        if (this.$route.name === 'login') {
          this.isVisible = false;
        } else if (this.isVisible !== val) {
          this.isVisible = val;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .login-dialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
