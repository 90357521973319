












































import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
@Component({})
export default class ItemErrataInfo extends Vue {
  @Prop({ type: Object, default: () => ({}) }) errataInfo: any;

  // 默认教材图不显示阴影
  get showShadow() {
    return this.errataInfo.bookPicture !== 'https://tmp.btclass.cn/yunyin/%E6%95%99%E6%9D%90%E5%B0%81%E9%9D%A2%E4%BA%AE%E8%89%B2.png';
  }
}
