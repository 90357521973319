import Vue from 'vue';

function getList(data, lessons) {
  data.forEach(item => {
    if (item.type !== 'lesson') {
      getList(item.children, lessons);
    } else {
      lessons.push(item.entity);
    }
  });
  return lessons;
}

// const media = {
//   playTimer: 0
// };
// const type = ["replay", "video", "audio"];
const entry = {
  namespaced: true,
  state: () => ({
    defaultVoice: 100,
    audioSetting: {
      curPlayMode: 3,
      curSortType: 1,
      curVolume: 100,
    },
    videoSetting: {},
    courseLesson: {},
    openCourseLesson: {},
    flatLessonList: [], // 扁平化的lessons
    lessonList: [],
    asideCurMenu: 'interaction', // 课时右侧互动类型
  }),
  getters: {
    defaultVoice: state => state.defaultVoice,
    audioSetting: state => state.audioSetting,
    videoSetting: state => state.videoSetting,
    courseLesson: state => state.courseLesson,
    openCourseLesson: state => state.openCourseLesson,
    flatLessonList: state => state.flatLessonList,
    lessonList: state => state.lessonList,
    asideCurMenu: state => state.asideCurMenu,
  },
  mutations: {
    LOGOUT: state => {
      state.defaultVoice = 50;
      state.audioSetting = {
        curPlayMode: 3,
        curSortType: 1,
        curVolume: 50,
      };
      state.videoSetting = {};
      state.courseLesson = {};
      state.openCourseLesson = {};
    },
    PATCH_VIDEO_SETTING: (state, [courseId, key, value]) => {
      if (!state.videoSetting[courseId]) {
        Vue.set(state.videoSetting, courseId, { [key]: value });
        if (!state.videoSetting[courseId][key]) {
          Vue.set(state.videoSetting[courseId], key, value);
        } else {
          state.videoSetting[courseId][key] = value;
        }
      } else {
        state.videoSetting[courseId] = { [key]: value };
      }
    },
    PATCH_AUDIO_SETTING: (state, [key, value]) => {
      if (!state.audioSetting[key]) {
        Vue.set(state.audioSetting, key, value);
      } else {
        state.audioSetting[key] = value;
      }
    },
    PATCH_COURSE_LESSON_RECORD: (state, [id, type, data]) => {
      if (!state.courseLesson[id]) {
        Vue.set(state.courseLesson, id, {});
      }
      if (!state.courseLesson[id][type]) {
        Vue.set(state.courseLesson[id], type, {});
      }
      state.courseLesson[id][type] = Object.assign(state.courseLesson[id][type], data);
    },
    PATCH_OPEN_COURSE_LESSON_RECORD: (state, [id, type, data]) => {
      if (!state.openCourseLesson[id]) {
        Vue.set(state.openCourseLesson, id, {});
      }
      if (!state.openCourseLesson[id][type]) {
        Vue.set(state.openCourseLesson[id], type, {});
      }
      state.openCourseLesson[id][type] = Object.assign(state.openCourseLesson[id][type], data);
    },
    PATCH_LESSSON_MENU_LIST: (state, data) => {
      state.lessonList = data;
      state.flatLessonList = getList(data, []);
    },
    PATCH_ASIDE_MENU: (state, data) => {
      state.asideCurMenu = data;
    },
  },
  actions: {
    patchVideoSetting({ commit }, data) {
      commit('PATCH_VIDEO_SETTING', data);
    },
    patchLessonMenuList({ commit }, data) {
      commit('PATCH_LESSSON_MENU_LIST', data);
    },
    pathAsideMenu({ commit }, data) {
      commit('PATCH_ASIDE_MENU', data);
    },
  },
};

export default entry;
