























import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import MainLoading from '@/pc/components/loading/main-loading.vue';
import ProgressCircle from './progress-circle.vue';

@Component({
  components: {
    MainLoading,
    ProgressCircle,
  },
})
export default class TabsPage extends Vue {
  @Prop({ type: Number, default: 0 }) bookId!: any;
  @Prop({ type: Number, default: 0 }) activeChapterId!: any;
  @Prop({ type: Array, default: [] }) chapterPageList!: any; // 章节页码列表
  @Prop({ type: Number, default: 0 }) activeChildPage!: any; // 当前选中的章节页码
  @PropSync('errataLoading', { type: Boolean, default: true }) errataLoadingSync: any; // 修订加载loading

  cachePageList: any = [];
  cacheActiveChildPage: number = 0;

  isReadStatus(data) {
    return data.errataNum === data.userReadNum;
  }

  @Watch('activeChildPage')
  watchPage() {
    // 页码为0，不执行
    if (!this.activeChildPage) return;
    this.cacheActiveChildPage = this.activeChildPage;
  }

  // 每次加载新的页码数据时，重新初始化数据
  @Watch('chapterPageList', { deep: true })
  watchList(newList, oldList) {
    // 没有页码，不往下执行
    if (!this.chapterPageList.length) return;
    // 有页码数据，缓存起来
    this.cachePageList = this.chapterPageList;
    // 获取页码对应的修订数据 优先级： 路由> 本地缓存 > 默认第一个
    const { pageNum: sPageNum } = this.getStorage()[this.activeChapterId] || {};
    // const pageNum = +(this.$route.query.pageNum || sPageNum || this.chapterPageList[0].page);

    // 页码数量没变，只改变了里面的userReadNum，此时要优先读取路由的pageNum
    const isChangeUserReadNum = (oldList || []).length === (newList || []).length;
    console.log('isChangeUserReadNum', isChangeUserReadNum);
    const pageNum = Number(isChangeUserReadNum ? this.$route.query.pageNum || this.chapterPageList[0].page : this.chapterPageList[0].page);
    // 初始化的时候忽略掉loading，init=true
    this.$emit('getChapterPageErrata', { pageNum, init: true });
  }

  // 获取缓存 按页码查看：bookDetailsPage、 按章节查看： ChapterDetailsPage
  getStorage() {
    const bookErrata = JSON.parse(localStorage.getItem('book-errata') || `{}`);
    const bookInfo = bookErrata[this.bookId] || {};
    return bookInfo['ChapterDetailsPage'] || {};
  }
}
