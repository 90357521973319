import { request } from '@/utils/axios';

// 获取配置活动页面数据
export function getPageData(pageId: number) {
  return request.get(`/v2/pages/${pageId}`, {}, { requestBase: 'course' });
}

// 获取优惠券领取状态
export function getCouponsReceiveStatus(batchIds) {
  return request.get('/v2/coupon-batch/attach-coupon', { batchIds }, { requestBase: 'course' });
}

// 批量领取优惠券
export function bulkReceiveCoupons(batchs) {
  return request.post('/v2/coupons/bulk-receive', { batchs }, { requestBase: 'course' });
}

// 获取带课时的公开课列表
export function getCoursesAttachLessonInfo(params) {
  return request.get('/v2/open/attach-lesson-courses', params, { requestBase: 'course' });
  // return request.throughApi('ms-course.openCourse.getCoursesAttachLessonInfo', params);
}

// 获取9宫格抽奖列表
export function getPrizes() {
  return request.get('/v2/roulette/new-year-2024/prizes', {}, { requestBase: 'order' });
}

// 获取免费抽奖任务完成情况
export function getFreeTasks() {
  return request.get('/v2/roulette/new-year-2024/users/mine/free-num-tasks', {}, { requestBase: 'order' });
}

// 完成免费抽奖次数任务
export function completeFreeTask(task: string) {
  return request.post(`/v2/roulette/new-year-2024/free-num-tasks/${task}/complete`, {}, { requestBase: 'order' });
}

// 用户抽奖
export function userRoulette(params) {
  return request.post('/v2/roulette/new-year-2024/roulette-game/lottery', params, { requestBase: 'order' });
}

// 获取用户的用户抽奖记录
export function getUserRouletteRecords() {
  return request.get('/v2/roulette/new-year-2024/users/mine/prize-reco', {}, { requestBase: 'order' });
}

// 获取当前登录用户的免费抽奖次数
export function getFreeNum() {
  return request.get('/v2/roulette/new-year-2024/users/mine/free-num', {}, { requestBase: 'order' });
}

// 设置用户中奖的收货地址
export function setPrizeAddress(recoId: number, params) {
  return request.patch(`/v2/roulette/new-year-2024/users/mine/prize-reco/${recoId}/address`, params, { requestBase: 'order' });
}
