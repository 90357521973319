



























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import RaffleModule from '@/pc/components/raffle-module/index.vue';
import DialogCode from './components/dialog-code.vue';
import { getActInfo, getRaffleActivityConfig } from '@/api/raffle';
import QRCode from 'qrcode';

@Component({
  components: {
    RaffleModule,
    DialogCode,
  },
})
export default class RaffleActivity extends Vue {
  @Prop({ type: Number, default: 0 }) activityId: any;
  @Prop({ type: Object, default: () => ({}) }) TopicItem: any;

  // 移动端链接
  mobileCodeUrl: any = '';
  // 活动信息
  activityInfo: any = null;
  // 全局配置
  raffleSetting: any = {};
  // 展示给学员端的活动状态
  get statusForUser() {
    // 未开始/BEFORE_STARTED, 进行中/IN_PROGRESS, 已结束/AFTER_ENDED;
    return this.activityInfo?.actInfo?.statusForUser;
  }
  // 活动标题
  get actTitle() {
    return this.activityInfo?.actInfo?.title;
  }
  // 活动开始时间
  get startTime() {
    return this.activityInfo?.actInfo?.startTime || 0;
  }
  // 推广渠道
  get promotionChannel() {
    return this.activityInfo?.actInfo?.promotionChannel || 'SELF';
  }

  async created() {
    try {
      // 全局配置
      const settingRes = await getRaffleActivityConfig('raffle-activity-config');
      this.raffleSetting = JSON.parse(settingRes.value || '{}');
      console.log('=== 全局配置 ===', this.raffleSetting);
      // 活动信息
      this.activityInfo = await getActInfo(this.activityId, {});
      console.log(' === 活动信息 ===', this.activityInfo);
      if (this.promotionChannel === 'SELF') {
        document.title = this.activityInfo?.actInfo?.title;
      }
      this.mobileCodeUrl = await QRCode.toDataURL(`${process.env.VUE_APP_WHALE_URL}raffle/${this.activityId}`);
    } catch (e) {
      console.log('获取活动信息失败', e);
    }
  }
  // 回到首页
  goHome() {
    location.href = '/';
  }

  // 查看我的中奖记录
  lookMyGift() {
    this.openCodeDialog({
      title: '查看中奖记录',
      subtitle: '当前渠道暂不支持打开此链接，请使用微信扫码在手机上查看',
      codeUrl: this.mobileCodeUrl,
      codeTips: '使用微信扫码',
    });
    console.log('查看我的中奖记录');
  }

  // 打开二维码弹窗
  codeOptions: any = {
    title: '',
    subtitle: '',
    codeUrl: '',
    codeTips: '',
  };
  isDialogVisible: boolean = false;
  openCodeDialog(data) {
    this.codeOptions = data;
    this.isDialogVisible = true;
  }

  // 跳转到对应锚点位置
  scrollTo(name) {
    this.$emit('scrollTo', name);
  }
}
