











import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';

@Component({
  methods: {
    ...mapActions('customPage', ['getCustomPage'])
  },
  computed: {
    ...mapState('customPage', ['pageData', 'isLoading'])
  },
})
export default class CustomPage extends Vue {
  @Prop({ type: String }) alias;
  getCustomPage!: any;
  pageData!: any;
  isLoading!: boolean;
  serverPrefetch() {
    return this.getCustomPage(this.alias);
  }
  async created() {
    if (Object.keys(this.pageData).length > 0) return;
    this.$store.dispatch('customPage/getCustomPage', this.alias);
  }
}
