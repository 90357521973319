








































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getCourseListForUser } from '@/api/course';
import dayjs from 'dayjs';
import QRCode from 'qrcode';
import { mapGetters } from 'vuex';
import { getBrowser } from '@/utils/helper';

const userIcons = [
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkhu34l6vpns1tn885k.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh19tn124j1690182vekl.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkhsbnntt1sql1rsj6u9m.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh18861v441m2er79d68n.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh15vo15kg1pm81ml71mggo.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh1t2d1u1917eh10jifl3p.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh17m29ujohu1o1a1mhnq.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh7bc1m6k1piu18kd1ccvr.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh17cvlmt1qkp1tlt6as.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkhpg03fjof8af814nct.png',
  'https://tmp.btclass.cn/yunyin/o_1h56gvpkh1ccnrv4umphc51h6au.png',
];

@Component({
  filters: {
    clockFormat(val) {
      return val.split(':')[0];
    },
  },
  computed: {
    ...mapGetters(['offsetTime']),
  },
})
export default class MiaoshaCom extends Vue {
  @Prop() data!: any;
  courseMap: any = {};
  timeMap: any = {};
  isDialogVisible: boolean = false; // 秒杀弹窗
  dislogData: any = {}; // 弹窗内容
  qrcodeUrl: string = ''; // 弹窗二维码
  imgUrlMap = {
    reverse: 'https://tmp.btclass.cn/yunyin/o_1hcm53doul6m19p54sdt9ppn1f.png',
    finished: 'https://tmp.btclass.cn/yunyin/o_1hcm53doujul5bkffb12iusuog.png',
    inProgress: 'https://tmp.btclass.cn/yunyin/o_1hcm53dou1mlg1ka21l13171o2gkh.png',
  };
  offsetTime: any;

  timmer: any = null; // 定时函数
  countDowTime: number = 0; // 进行中倒计时
  countTimeObj = {
    hours: '0',
    minutes: '0',
    seconds: '0',
  };
  nowServeTime: number = 0;
  miaoshaList: any[] = [];
  secKillUpdateMines: number = 2; // 间隔几分钟增加预约人数
  choseTime: string = ''; // 选中的时间点
  curActivity: any = {}; // 当前活动
  icons: any = [];
  browserInfo: any = {};
  get courseDetail() {
    return this.courseMap[this.curActivity['courseId']];
  }

  // 修改时间点
  changeTime(item) {
    this.choseTime = item.time;
    this.icons = userIcons.sort(() => 0.5 - Math.random()).slice(0, 5);
    const index = this.miaoshaList.findIndex(mItem => mItem.time == this.choseTime);
    if (index <= 1) {
      (this.$refs.timeTabsRef as any).scrollLeft = 0;
    } else if (index >= this.miaoshaList.length - 2) {
      (this.$refs.timeTabsRef as any).scrollLeft = (this.$refs.timeTabsRef as any).scrollWidth;
    }
  }

  async created() {
    this.miaoshaList = [].concat(this.data.list);
    this.choseTime = dayjs().format('MM.DD');
    await this.getCourse();
    this.startTimer();
    this.browserInfo = getBrowser(navigator.userAgent);
  }

  startTimer() {
    this.timmer = setInterval(() => {
      this.updateSecKill();
    }, 1000);
  }

  // 从当天0点开始，每隔secKillUpdateMines分钟，加固定的预约秒杀人数
  updateSecKill() {
    this.nowServeTime = dayjs().valueOf() - this.offsetTime;
    const { $H, $m } = dayjs(this.nowServeTime) as any;
    // console.log(this.miaoshaList);
    this.miaoshaList.forEach((activity, index) => {
      // 预约人数处理
      const addCount = $H * 30 * activity.addNum + Math.floor($m / this.secKillUpdateMines) * activity.addNum; // 每次要加的人数
      activity.count = +activity.startCount + addCount; // 最终的预约人数
      // 秒杀状态处理，默认进行中
      if (!activity.stauts || activity.status === 'inProgress') {
        activity.status = this.transStatus(activity);
        if (activity.status === 'inProgress') {
          this.timemove(activity);
        }
      }
      if (activity.status === 'finished') {
        activity.count = 0;
      }
      this.timeMap[activity['time']] = Object.assign({}, activity);
    });
    this.curActivity = this.timeMap[this.choseTime];
    this.$forceUpdate();
  }

  transStatus({ time, clock }) {
    // 判断时间点为当前时间晚某个点（固定晚7点）前则时进行中,大于就是可预约，小于就是已结束
    const targetTime = dayjs(('2024-' + time).replace('.', '-'));
    const isToday = dayjs(this.nowServeTime).isSame(targetTime, 'day');
    if (isToday) {
      const isCurDate = dayjs(this.nowServeTime).format('YYYY-MM-DD');
      // 当天判断时间点是否是7点前
      const endTime = dayjs(`${isCurDate} ${clock}`);
      if (dayjs().isBefore(endTime, 'minute')) {
        return 'inProgress';
      } else {
        return 'finished';
      }
    } else if (targetTime.isBefore(dayjs(), 'day')) {
      // 时间点在当天之前，认为已经结束了
      return 'finished';
    } else if (targetTime.isAfter(dayjs(), 'day')) {
      return 'reverse';
    }
  }

  // 倒计时时钟
  timemove({ clock }) {
    const isCurDate = dayjs(this.nowServeTime).format('YYYY-MM-DD');
    const targetTime = dayjs(`${isCurDate} ${clock}`).valueOf();
    // 计算剩余时间（单位：毫秒）
    this.countDowTime = targetTime - this.nowServeTime;
    // 计算剩余小时、分钟和秒格式化剩余时间
    var hours = ('0' + parseInt(((this.countDowTime / 1000 / 60 / 60) % 24) + '')).slice(-2);
    var minutes = ('0' + parseInt(((this.countDowTime / 1000 / 60) % 60) + '')).slice(-2);
    var seconds = ('0' + parseInt(((this.countDowTime / 1000) % 60) + '')).slice(-2);
    this.countTimeObj = { hours, minutes, seconds };
  }

  // 获取秒杀课程
  async getCourse() {
    try {
      const allCourseIds: any[] = this.data.list.map(item => item.courseId);
      console.log('====', allCourseIds)
      let { rows } = await getCourseListForUser({ids: [...new Set(allCourseIds)].join(','), needPriceDetail: 1});
      rows.forEach(item => {
        if (!this.courseMap[item.id]) {
          this.courseMap[+item.id] = item;
        }
      });
      this.changeTime({ time: this.choseTime });
      console.log('---------------双十一好课获取成功----------------');
      this.$forceUpdate();
    } catch (e) {
      console.log('获取双十一好课失败', e);
    }
  }

  async dialogHandle(status) {
    if (status === 'finished') return;
    this.qrcodeUrl = await QRCode.toDataURL(this.timeMap[this.choseTime].qrLnk);
    this.dislogData = this.data[status === 'inProgress' ? 'dialog' : 'reserveDialog'];
    this.isDialogVisible = true;
  }

  goCourseDetail(courseId) {
    window.open(`/course/${courseId}`);
  }

  unmounted() {
    clearInterval(this.timmer);
  }
}
