import { request } from '@/utils/axios';

/* 抽奖转盘活动配置化 */

// 获取登录用户免费抽奖机会任务完成情况
export function getActMineTask(actId, params) {
  return request.get(`/v2/roulette/users/mine/acts/${actId}/tasks/completed-status`, params, { requestBase: 'order' });
}

// 获取登录用户当前剩余免费抽奖次数
export function getActMineFreeNum(actId, params) {
  return request.get(`/v2/roulette/users/mine/acts/${actId}/free-num`, params, { requestBase: 'order' });
}

// 用户抽奖
export function rouletteActGameLottery(actId, params) {
  return request.post(`/v2/roulette/users/mine/acts/${actId}/lottery`, params, { requestBase: 'order' });
}

// 获取登录用户抽奖记录(奖品列表)
export function getActMinePrizeReco(params) {
  return request.get(`/v2/roulette/users/mine/win-reco`, params, { requestBase: 'order' });
}

// 给用户中奖记录设置收货地址
export function setActMinePrizeRecoAddress({ recoId, ...params }) {
  return request.patch(`/v2/roulette/users/mine/reco/${recoId}/address`, params, { requestBase: 'order' });
}

// 用户端 - 获取活动信息
export function getActInfo(actId, params) {
  return request.get(`/v2/roulette/users/mine/acts/${actId}`, params, { requestBase: 'order' });
}

// 获取抽奖全局配置
export function getRaffleActivityConfig(key, params = {}) {
  return request.get(`/bt-settings/${key}`, params, { requestBase: 'guser' });
}
