














import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters('home', ['adConfig']),
  },
})
export default class OperatPlaceCom extends Vue {
  @Prop({ type: Number, default: 1 }) subscene;
  @Prop({ type: Number, default: 0 }) categoryId;
  @Prop({ type: Number, default: 0 }) tikuCategoryId;
  @Prop({ type: String, default: '' }) title;
  @Prop({ type: String, default: '' }) blockTitle;

  backgroundColors: string[] = [
    '',
    'linear-gradient(90deg, #FFEBF8 0%, #FFF2EC 58%, #FFCBCB 100%)',
    'linear-gradient(90deg, #FFF4E5 0%, #FFF4E5 56%, #FFDEC1 100%)',
    'linear-gradient(90deg, #DBF9FF 0%, #E5FBFF 56%, #BEDFFF 100%)',
    'linear-gradient(90deg, #EAFFEF 0%, #F0FFF3 54%, #AFEFE4 100%)',
    'linear-gradient(90deg, #DFF0FF 0%, #E5F3FF 52%, #BFD3FF 100%)',
  ];
  colors: string[] = ['', '#FF6262', '#F8B83C', '#86B7FF', '#33C74E', '#4B8FFF'];
  iconImages = [
    '',
    '',
    'https://app-cdn.btclass.cn/new-prefix/turtle/FlrxoIsmqJOXjx0kCnOr6OaRimoV.png',
    'https://app-cdn.btclass.cn/new-prefix/turtle/FqbxERoYWEIC3-F5NvqReB3lKjC_.png',
    'https://app-cdn.btclass.cn/new-prefix/turtle/Fo0FIUYoyG_my1rrg-rPqGOmOwTn.png',
    'https://app-cdn.btclass.cn/new-prefix/turtle/FlLHkdOg4PjdBZHDQ0vPBzQxJ8bM.png',
    'https://app-cdn.btclass.cn/new-prefix/turtle/Fi_XWnxKCRBuGM2_TSV9YdtuiWnk.png',
  ];
  adConfig: any;
  curBlockId: number = 0;
  get oData() {
    const lastData =
      this.adConfig.filter(
        item =>
          (!this.subscene && item.title === this.title) ||
          (this.tikuCategoryId && item.tikuCategoryIds && item.tikuCategoryIds.includes(this.tikuCategoryId)) ||
          (this.subscene && !this.tikuCategoryId && item.subscene == this.categoryId)
      )[0] || {};
    this.curBlockId = lastData['id'];
    // console.log('this.tikuCategoryId', this.tikuCategoryId, lastData);
    return lastData.data || {};
  }
  goLink() {
    const targetUrl = (this.oData.link && this.oData.link.url) || '';
    // console.log('targetUrl', targetUrl);
    if (!targetUrl) return;

    try {
      this.$sensors.track(this.tikuCategoryId ? 'bt_10221' : 'bt_10703', {
        description: this.tikuCategoryId ? '题库首页-首页运营位' : 'PC首页-各模块小宣传位',
        blockId: this.tikuCategoryId || this.curBlockId,
        blockTitle: this.title || this.blockTitle,
      });
    } catch (e) {
      console.log('埋点报错');
    }
    window.open(targetUrl);
  }
}
