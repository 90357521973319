












































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
// import ClipboardJS from 'clipboard';
import QRCode from 'qrcode';
import AskItemReward from '../ask-item-reward.vue';
import NP from 'number-precision';

import { addDoubtLike, delDoubtLike, askShare } from '@/api/ask';
import { mapGetters, mapMutations } from 'vuex';

@Component({
  components: {
    AskItemReward,
  },
  computed: {
    ...mapGetters('my', ['me', 'isLogin']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class AskItem extends Vue {
  // @ts-ignore 卡片类型【 default ｜ 默认, detail ｜详情 】
  @Prop({ type: String, default: 'default' }) type: any;
  // @ts-ignore 显示的模块【】
  @Prop({ type: Array, default: () => [] }) showModule: any;
  // @ts-ignore 开通的能力 【 imgPreview ｜ 图片预览 】
  @Prop({ type: Array, default: () => [] }) abilities: any;
  // @ts-ignore 显示的工具栏 【 answer 回答 ｜ goodProblem 好问题 ｜ share 分享 ｜ reward 悬赏去向 】
  @Prop({ type: Array, default: () => ['answer', 'goodProblem', 'share'] }) showTools: any;
  // 卡片的数据
  @Prop({ type: Object, default: () => {} }) detail: any;
  @Prop({ type: String, default: '' }) searchText: any;
  // 问题内容是否展示鼠标悬浮样式
  @Prop({type: Boolean, default: false}) needContentHover: any;

  me: any;
  SET_LOGIN_DIALOG_VISIBLE: any;

  // 是否已登陆
  get myUserId() {
    return (this.me && this.me.id) || 0;
  }

  /*

    根据 questionInfo 和 explanationInfo 判断是否有关联题目以及采纳回答。

    若采纳回答 explanationInfo.isTeacherAnswerer 标识答主身份，需要展示特殊角标，其余用户取 explanationInfo.userInfo 中的数据展示
  */

  rewardVisible: any = false;

  // 工具栏
  tools: any = [
    {
      key: 'answer',
      name: '回答',
      count: 0,
      color: '#4B8FFF',
      active: false, // 是否使用选中图标
      icon: 'https://tmp.btclass.cn/yunyin/o_1h9d89vtss983fb1vl5ofafhpa.png',
      iconActive: 'https://tmp.btclass.cn/yunyin/o_1ha4adq3v1sr15p010lm8vp13qhe.png',
    },
    {
      key: 'goodProblem',
      name: '好问题',
      count: 0,
      color: '#FFA934',
      active: false,
      icon: 'https://tmp.btclass.cn/yunyin/o_1ha4adq3v12ss1f1o1ilmoofvkad.png',
      iconActive: 'https://tmp.btclass.cn/yunyin/o_1ha6r4c7b174g10rp1jc51g8512bla.png',
    },
    {
      key: 'share',
      name: '分享',
      count: 0,
      color: '#919191',
      active: false,
      icon: 'https://tmp.btclass.cn/yunyin/o_1h903r2sg5md1c98ael5ogm6gg.png',
      iconActive: 'https://tmp.btclass.cn/yunyin/o_1h903r2sg5md1c98ael5ogm6gg.png',
    },
    {
      key: 'reward',
      name: '悬赏去向',
      count: 0,
      color: '#919191',
      active: false,
      icon: 'https://tmp.btclass.cn/yunyin/o_1ha954la5d9s6kk1c4gv7818fia.png',
      iconActive: 'https://tmp.btclass.cn/yunyin/o_1ha954la5d9s6kk1c4gv7818fia.png',
    },
  ];

  get detailsContent() {
    let content = this.detail.content;
    if (this.searchText) {
      content = content.replace(new RegExp(this.searchText), '<span class="bt-ask-highlight">' + this.searchText + '</span>');
    }
    return content;
  }

  // 提问用户
  get userInfo() {
    return this.detail.userInfo || {};
  }

  // 科目分类
  get categoryLongName() {
    const categoryInfo = this.detail.categoryInfo || {};
    return `${categoryInfo.parentName}·${categoryInfo.name} `;
  }

  // 关联题目
  get questionInfo() {
    return this.detail.questionInfo || {};
  }

  // 当前问答的图片
  get images() {
    return this.detail.images || [];
  }

  // 采纳的回答
  get explanationInfo() {
    return this.detail.explanationInfo || {};
  }

  // 必答券信息
  get requireInfo() {
    let info = JSON.parse(JSON.stringify(this.detail.requireInfo || {}));
    info.text = info.replyTime + 'h';
    if (info.type === 'ticket') {
      info.text = info.replyTime > 48 ? NP.divide(info.replyTime, 24) + '天' : info.replyTime + 'h';
    }
    return info;
  }

  // 收藏模式
  get favoriteInfo() {
    return this.detail.favoriteInfo || {};
  }

  // 分享链接
  get shareUrl() {
    return `${window.location.origin}/ask/${this.detail.id}/details`;
  }

  // 分享文案
  get shareText() {
    return `${this.detail.content.substr(0, 50)} - BT教育`;
  }

  // 移动端页面二维码
  qrcodeUrl: any = '';

  @Watch('detail', { immediate: true, deep: true })
  async watchDetail() {
    const { explanationCount, likeCount, isLike } = this.detail;
    // 评论
    this.tools[0].count = explanationCount;
    this.tools[0].active = !!explanationCount;
    // 点赞
    this.tools[1].count = likeCount;
    this.tools[1].active = !!isLike;
    // console.log('问答卡片详情', this.detail);

    const h5Url = `${process.env.VUE_APP_WHALE_URL}doubt/${this.detail.id}/detail`;
    this.qrcodeUrl = await QRCode.toDataURL(decodeURIComponent(h5Url), { margin: 0 });
  }

  // // 复制按钮绑定方法
  // clipboard!: ClipboardJS;
  async mounted() {
    // this.copyUrlInit();
  }
  copyUrlInit() {
    // console.log('clipboard', `.copy-btn-${this.detail.id}`);
    // // this.clipboard = new ClipboardJS(`#copy-btn-${this.detail.id}`, {
    // //   text: () => {
    // //     return window.location.href;
    // //   },
    // // });
    // this.clipboard = new ClipboardJS(`.copy-btn-${this.detail.id}`);
    // // @ts-ignore
    // this.clipboard.on('success', e => {
    //   this.$message.success('复制链接成功');
    // });
    // // @ts-ignore
    // this.clipboard.on('error', e => {
    //   this.$message.error('复制链接失败');
    // });
  }

  // 复制模板内容
  copyTemplate() {
    this.copyToClipboard(this.shareText + this.shareUrl); // 需要复制的文本内容
    this.$message.success('复制链接成功');
  }
  // 点击复制到剪贴板函数
  copyToClipboard(content) {
    //window.clipboardData的作用是在页面上将需要的东西复制到剪贴板上，
    //提供了对于预定义的剪贴板格式的访问，以便在编辑操作中使用。
    // @ts-ignore
    if (window.clipboardData) {
      /*
          window.clipboardData有三个方法:
        （1）clearData(sDataFormat) 删除剪贴板中指定格式的数据。sDataFormat:"text","url"
        （2）getData(sDataFormat) 从剪贴板获取指定格式的数据。 sDataFormat:"text","url"
        （3）setData(sDataFormat, sData) 给剪贴板赋予指定格式的数据。返回 true 表示操作成功。
          */
      // @ts-ignore
      window.clipboardData.setData('text', content);
    } else {
      (function (content) {
        //oncopy 事件在用户拷贝元素上的内容时触发。
        document.oncopy = function (e: any) {
          e.clipboardData.setData('text', content);
          e.preventDefault(); //取消事件的默认动作
          document.oncopy = null;
        };
      })(content);
      //execCommand方法是执行一个对当前文档/当前选择/给出范围的命令。
      //'Copy':将当前选中区复制到剪贴板。
      document.execCommand('Copy');
    }
    askShare({ doubtId: this.detail.id });
  }

  // 微博分享
  wbShare() {
    askShare({ doubtId: this.detail.id });
    var query: any = {};
    query.url = this.shareUrl;
    query.title = this.shareText;
    const url = 'http://service.weibo.com/share/share.php?' + this.buildUrlQuery(query);
    window.open(url);
  }
  buildUrlQuery(query) {
    let queryItems: any = [];
    for (var q in query) {
      queryItems.push(q + '=' + encodeURIComponent(query[q] || ''));
    }
    return queryItems.join('&');
  }

  // 点击工具栏
  async clickTools(key) {
    console.log('点击工具栏', key);
    // 悬赏
    if (key === 'reward') {
      this.rewardVisible = true;
      return;
    }
    // 好问题
    if (key === 'goodProblem') {
      // 未登陆先走登陆
      if (!this.myUserId) {
        this.SET_LOGIN_DIALOG_VISIBLE(true);
        return;
      }
      await this.changeLike();
      return;
    }
    // 分享
    if (key === 'share') return;
    // 回答
    if (key === 'answer') {
      this.jumpDetail();
    }
  }

  // 点赞/取消点赞 当前问题
  likeLoading: boolean = false;
  async changeLike() {
    if (this.likeLoading) return;
    this.likeLoading = true;
    try {
      if (this.detail.isLike) {
        await delDoubtLike({ doubtId: this.detail.id });
        this.detail.isLike = 0;
        this.detail.likeCount = this.detail.likeCount - 1;
      } else {
        await addDoubtLike({ doubtId: this.detail.id });
        this.detail.isLike = 1;
        this.detail.likeCount = this.detail.likeCount + 1;
      }
      this.$emit('toolsStatusUpdate', { id: this.detail.id, update: { isLike: this.detail.isLike, likeCount: this.detail.likeCount } });
      // this.$message.success('操作成功');
    } catch (e) {
      console.log('点赞/取消点赞失败', e);
    } finally {
      this.likeLoading = false;
    }
  }

  // 跳转详情页
  jumpDetail() {
    // 详情页不跳转
    if (this.type === 'detail') return;
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-details', params: { doubtId: this.detail.id } });
    window.open(link.href, '_blank');
  }

  // 查看同类问题
  lookSameAsk(autoOpen) {
    // @ts-ignore
    const link = this.$router.resolve({
      name: 'ask-details-questions',
      params: { doubtId: this.detail.id },
      query: { questionId: this.questionInfo.id, categoryId: this.detail.categoryInfo.id, autoOpen: autoOpen },
    });
    window.open(link.href, '_blank');
  }
}
