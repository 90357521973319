











import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ChooseCoupon extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Number, default: 0 }) acceptDoubtId: any;
  @Prop({ type: Number, default: 0 }) categoryId: any;

  // 回主页
  backIndex() {
    this.$router.replace({ name: 'ask-index', query: { categoryId: this.categoryId } });
  }

  // 去采纳
  goAccept() {
    this.$router.replace({ name: 'ask-details', params: { doubtId: this.acceptDoubtId } });
  }
}
