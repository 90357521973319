


















































import { Vue, Component } from 'vue-property-decorator';
import AskClassFilter from '../components/modules/ask-class-filter.vue';
import ToAsk from '../components/modules/to-ask.vue';
import MyAskFavorite from '../components/modules/my-ask-favorite.vue';
import RecommendAsk from '../components/modules/recommend-ask.vue';
import RecommendCourse from '../components/modules/recommend-course.vue';
import AskItem from '../components/modules/ask-item.vue';
import MainLoading from '../components/modules/main-loading.vue';

import { getCategoryTree, getMyDoubtListByPC } from '@/api/ask';
import NP from 'number-precision';
import { getTimes } from '../common';

@Component({
  components: {
    AskClassFilter,
    ToAsk,
    MyAskFavorite,
    RecommendAsk,
    RecommendCourse,
    AskItem,
    MainLoading,
  },
})
export default class AskMyAsk extends Vue {
  defaultOption: any = {
    categoryId: 0, // 默认
    createTimes: [], // 默认时间
    initCreateTimes: [], // 初始化时间
  };

  categoryTree: any = [];
  projectId: number = 0;
  categoryId: number = 0; // 科目分类Id
  createTimes: any = [];

  isLoading: boolean = true; // 请求数据的loading
  pagination: any = {
    page: 1,
    limit: 10,
    total: 0,
  };
  askList: any = [];

  async created() {
    const { page, limit, startTime, endTime } = this.$route.query;
    console.log('this.$route.query', this.$route.query);
    this.setInit();
    this.setABC();
    // 初始化默认时间
    this.setCreateTimes();
    // 路由有值，同步路由时间
    if (startTime && endTime) {
      this.createTimes = [NP.times(Number(startTime), 1000), NP.times(Number(endTime), 1000)];
      this.defaultOption.initCreateTimes = [NP.times(Number(startTime), 1000), NP.times(Number(endTime), 1000)];
    }
    // 初始化科目
    await this.getBtCategoryTree();
    this.pagination.page = (Number(page) || 0) + 1;
    this.pagination.limit = Number(limit) || 10;
    // 加载数据
    await this.getList();
  }

  setInit() {
    // 布局初始化
    this.$bus.$emit('setlayout', { openFlex: false, mainWidth: '1080px' });
  }

  // 设置面包屑导航
  setABC() {
    this.$bus.$emit('setAskBreadCrumb', {
      show: true,
      data: [{ name: 'BT教育' }, { name: '问答主页', to: { path: `/ask?categoryId=${this.categoryId}` } }, { name: '我的提问页' }],
    });
  }

  // 获取分类树
  async getBtCategoryTree() {
    let categoryIdArr: any = [];
    this.categoryTree = ((await getCategoryTree({})) || []).filter(item => {
      return item.doubtShow;
    });
    this.categoryTree.forEach((item: any) => {
      item.children = item.children.filter((child: any) => {
        if (child.doubtShow) {
          categoryIdArr.push(child.id);
        }
        return child.doubtShow;
      });
    });
    // 路由有值，优先用路由的
    const { categoryId } = this.$route.query;
    this.categoryId = Number(categoryId) || 0;
    this.defaultOption.categoryId = Number(categoryId) || 0;
    const noCategoryId = !categoryIdArr.includes(this.categoryId);
    // 没有默认取第一个
    if (!this.categoryId || noCategoryId) {
      this.defaultOption.categoryId = this.categoryTree[0].children[0].id;
      this.categoryId = this.categoryTree[0].children[0].id;
    }
    console.log('===获取问答分类树', this.categoryTree, this.categoryId);
  }

  // 默认近一年
  setCreateTimes() {
    const { oneYearAgoStartTimestamp, todayEndTimestamp } = getTimes();
    this.defaultOption.createTimes = [oneYearAgoStartTimestamp, todayEndTimestamp];
    this.defaultOption.initCreateTimes = [oneYearAgoStartTimestamp, todayEndTimestamp];
    this.createTimes = [oneYearAgoStartTimestamp, todayEndTimestamp];
  }

  // 筛选器修改
  async filterChange(data) {
    console.log('filterChange', data);
    this.categoryId = data.categoryId;
    this.projectId = data.projectId;
    this.createTimes = data.createTimes;
    // 重新获取列表数据
    if (data.update) {
      await this.getList(true);
    }
  }

  // 获取列表数据
  async getList(reset: boolean = false) {
    try {
      this.isLoading = true;
      // 重置
      if (reset) {
        this.pagination.page = 1;
      }
      const params: any = {
        categoryId: this.categoryId,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        needCount: 1,
        startTime: NP.divide(this.createTimes[0], 1000),
        endTime: NP.divide(this.createTimes[1], 1000),
      };
      this.$router.replace({ name: 'ask-my-ask', query: params });
      this.setABC();
      const { count, rows } = await getMyDoubtListByPC(params);
      this.pagination.total = count;
      this.askList = rows;
      console.log('===我的提问===', params, { count, rows });
    } catch (e) {
      console.log('列表获取失败', e);
    } finally {
      this.isLoading = false;
    }
  }
  // 切换页数
  async handleCurrentChange(val) {
    this.pagination.page = val;
    await this.getList();
    console.log('切换页数', val, this.pagination.page);
  }

  // 去问答中心
  goAskIndex() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'ask-index', query: { categoryId: this.categoryId } });
    window.open(link.href, '_blank');
  }
}
