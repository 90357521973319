'use strict';

export default function asyncLoadJs (url, reload = false) {
  return new Promise((resolve, reject) => {
    if (!reload) {
      const hasLoaded = $('script[src="'+url+'"]').length > 0;
      if (hasLoaded) {
        resolve();
        return;
      }
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
    script.onload = () => {
      resolve();
    };
    script.onerror = (err) => {
      reject(err);
    };
  });
}
