












import { Vue, Component, Prop } from 'vue-property-decorator';
import UserTagVip from './user-tag-vip.vue';

@Component({
  components: {
    UserTagVip,
  },
})
export default class UserAvator extends Vue {
  @Prop({ type: Number, default: 0 }) userId;
  @Prop({ type: String }) avatarUrl!: string;
  @Prop({ type: String }) vipIconUrl?: string;
  @Prop({ type: Array }) roles?: any[];
  @Prop({ type: Boolean, default: false }) isNeedTagOrVip?: boolean;
  @Prop({ type: Number, default: 48 }) avatorWidth?: number; // 根据头像宽度决定部分布局
  @Prop({ type: Boolean, default: false }) isNeedTooltip?: boolean;

  get isOfficeV() {
    return (this.roles && this.roles.findIndex(item => item.code === 'ROLE_OFFICIAL') !== -1) || 0;
  }

  // 跳转用户详情页面
  goUserInfoPage() {
    if (!this.userId) return;
    window.open(`/new-user/${this.userId}`);
    console.log('跳转个人页面');
  }
}
