






import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class MyComponent extends Vue {
  @Prop({ default: 1000 }) interval!: number;
  @Prop({ default: 0 }) from!: number | string;
  @Prop({ required: true }) to!: number | string;
  @Prop({ default: true }) dislocation!: boolean;
  @Prop({ type: Number, default: 20 }) itemHeightProp;

  elArr: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
  listHeight: number = 0;
  step: number = 0;
  oldVal: number = 0;

  get itemHeight() {
    const hResult = this.listHeight / this.elArr.length || this.itemHeightProp || 3;
    this.$emit('heighChange', hResult);
    return hResult;
  }

  get listStyle() {
    return {
      transitionDuration: `${(this.interval / 1000) * this.step}s`,
    };
  }

  async mounted() {
    await this.$nextTick();
    this.listHeight = (this.$refs.listConRef as any).clientHeight;
  }

  @Watch('to', { immediate: true })
  async onToChanged(val: any, oldVal: any) {
    this.oldVal = oldVal || 0;
    this.setPosition(+val);
  }

  async setStep(val: number, oldVal: number, isInvert = false) {
    await this.$nextTick();
    let oldIndex = 0;
    let newIndex = 0;
    if (this.dislocation) {
      if (isInvert) {
        oldIndex = oldVal ? this.elArr.lastIndexOf(+oldVal) : 0;
        newIndex = this.elArr.lastIndexOf(+val);
        this.step = Math.abs(oldIndex - newIndex);
      } else {
        oldIndex = oldVal ? this.elArr.indexOf(+oldVal) : 0;
        newIndex = this.elArr.indexOf(+val);
        this.step = Math.abs(newIndex - oldIndex);
      }
    } else {
      this.step = 1;
    }
  }

  async setPosition(target) {
    await this.$nextTick();
    if (!this.$refs.listConRef) return;
    let elIndex: number = -1;
    if (target == 0) {
      this.step = 0; // 动画重置为0，将数字移动到第二轮
      (this.$refs.listConRef as any).style.transform = `translateY(0px)`;
      return;
    }
    if (this.oldVal > target) {
      this.step = 0; // 动画重置为0，将数字移动到第二轮
      elIndex = this.elArr.lastIndexOf(+this.oldVal);
      const oldOffset = elIndex * this.itemHeight;
      (this.$refs.listConRef as any).style.transform = `translateY(-${oldOffset}px)`;
      setTimeout(async () => {
        await this.setStep(this.oldVal, target, true);
        elIndex = this.elArr.lastIndexOf(+target);
        const sencondOffset = elIndex * this.itemHeight;
        (this.$refs.listConRef as any).style.transform = `translateY(-${sencondOffset}px)`;
      });
    } else {
      this.step = 0; // 动画重置为0，将数字移动到第二轮
      elIndex = this.elArr.indexOf(+this.oldVal);
      const oldOffset = elIndex * this.itemHeight;
      // console.log('elIndex', elIndex, 'oldOffset', oldOffset);
      (this.$refs.listConRef as any).style.transform = `translateY(-${oldOffset}px)`;
      setTimeout(async () => {
        await this.setStep(this.oldVal, target, false);
        elIndex = this.elArr.indexOf(+target);
        const offset = elIndex * this.itemHeight;
        (this.$refs.listConRef as any).style.transform = `translateY(-${offset}px)`;
      });
    }
  }
}
