







import { Vue, Component } from 'vue-property-decorator';
import loadingBooks from '@/assets/json/loading_books.json';
import Lottie from '@/pc/components/lottie/Lottie.vue';

@Component({
  components: {
    Lottie,
  },
})
export default class loading extends Vue {
  lottieOption = { animationData: loadingBooks };
}
