<template>
  <div class="bt-container" :class="[showMobileMenu ? 'show-sidebar' : '', layoutClassName]" :style="fixedStyle" id="layout">
    <keep-alive>
      <bt-header v-if="showHeader" :isPC="isPC" class="bt-header" :class="isHeadFixed ? 'fixed-head' : ''" />
    </keep-alive>
    <div class="bt-aside" v-if="hasMounted && showMobileMenu">
      <mobile-menu v-model="showMobileMenu" />
    </div>
    <div :class="['bt-main', `${routeName}_class`, { 'auto-height': autoHeight }]">
      <router-view v-if="isRouterAlive" />
    </div>
    <keep-alive>
      <bt-footer v-if="showFooter" class="bt-footer" />
    </keep-alive>
    <div class="bt-mask" @click="toggleMobileMenu(false)"></div>
    <!-- 520心意卡 -->
    <template v-if="loveYouTime && !inHideLoveYouPage.includes(routerName)">
      <love-you :hasQiyu="hasQiyu" :homeMenuShow="homeMenuShow" />
      <div v-show="showLoveYou" class="black-mask">
        <div class="love-you">
          <img class="love-you-pic" src="https://tmp.btclass.cn/yunyin/o_1f60fjsa8gqk1k181dg12sl1384g.png" alt="" />
          <img class="close-icon" src="https://tmp.btclass.cn/yunyin/close.png" alt="" @click="toggleLoveYou(false)" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getBrowser } from '@/utils/helper';
export default {
  name: 'Layout',
  components: {
    BtHeader: () => import('../components/header/PcHeader'),
    MobileMenu: () => import('../components/ui/sidebar/MobileMenu'),
    BtFooter: () => import('../components/footer/Index'),
    LoveYou: () => import('@/pc/components/love-you/index'),
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  props: {
    routerName: {
      type: String,
      required: '',
    },
    hasQiyu: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      hasMounted: false,
      showMobileMenu: false,
      isRouterAlive: true,
      homeMenuShow: false,
      showLoveYou: false,
      ensure: false,
      amount: 0, // 申请提现的金额
      autoHeight: false,
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    isHeadFixed() {
      return this.$route && this.$route.meta.isFixed;
    },
    layoutClassName() {
      return (this.$route && this.$route.meta.layoutClassName) || '';
    },
    isPC() {
      return this.$route.meta.isPC;
    },
    fixedStyle() {
      let style = {};
      const iPad = getBrowser(navigator.userAgent).iPad;
      if (this.$route) {
        if (this.$route.meta.isFlex) {
          style = Object.assign({}, style, {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          });
        }
        if (this.$route.meta.isPC || this.$route.path.indexOf('aboutus') !== -1) {
          style = Object.assign({}, style, {
            minWidth: '1300px',
          });
        }
        if (this.$route.meta.isPC || this.$route.path.indexOf('accounting-exam') !== -1 || this.$route.path.indexOf('zj-accounting-exam') !== -1) {
          style = Object.assign({}, style, {
            minWidth: '0px',
          });
        }
        if (this.$route.path.indexOf('cloud-exam') !== -1) {
          style = Object.assign({}, style, {
            minWidth: '0px',
          });
        }
        if (this.$route.path.indexOf('score-reports') !== -1) {
          style = Object.assign({}, style, {
            minWidth: '1360px',
          });
        }
        if (this.$route.path.indexOf('ask') !== -1) {
          style = Object.assign({}, style, {
            minWidth: '1300px',
          });
        }
        if (iPad) {
          style = Object.assign({}, style, {
            overflow: 'hidden',
          });
        }
      }
      return style;
    },
    showHeader() {
      return this.$route.name && !this.$route.meta.hideHeader && !this.$route.query.hideHeader;
    },
    showFooter() {
      return this.$route.name && !this.$route.meta.hideFooter && !this.$route.query.hideFooter;
    },
    loveYouTime() {
      const beginTime = new Date('2021/5/19 00:00:00').getTime();
      const endTime = new Date('2021/5/21 23:59:59').getTime();
      const now = new Date().getTime();
      return beginTime < now && now < endTime;
    },
    inHideLoveYouPage() {
      return ['payment', 'wxpay'];
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // 云考场环境不需最小高度
        if (to.path.indexOf('/cloud-exam') !== -1) {
          this.autoHeight = true;
        }
      },
    },
  },
  mounted() {
    this.hasMounted = true;
    this.$bus.$on('toggleMobileMenu', this.toggleMobileMenu);
    // 这是是开图片弹窗的
    this.$bus.$on('toggleLoveYou', this.toggleLoveYou);
    // 这个是首页是否展示分类列表
    this.$bus.$on('homeMenuStatus', this.homeMenuStatus);
    this.$store.dispatch('home/getAdConfig');
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    toggleMobileMenu(val) {
      if (typeof val === 'undefined') {
        this.showMobileMenu = !this.showMobileMenu;
      } else {
        this.showMobileMenu = Boolean(val);
      }
    },
    toggleLoveYou(val) {
      this.showLoveYou = val;
    },
    homeMenuStatus(val) {
      this.homeMenuShow = val;
    },
  },
  beforeDestroy() {
    this.$bus.$off('toggleMobileMenu', this.toggleMobileMenu);
    this.$bus.$off('toggleLoveYou', this.toggleLoveYou);
    this.$bus.$off('homeMenuStatus', this.homeMenuStatus);
  },
};
</script>

<style lang="scss" scoped>
.el-message {
  z-index: 3000;
}
.bt-container {
  .fixed-head {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
  }
  .bt-aside {
    position: absolute;
    top: 0;
    left: -250px;
    height: 100%;
    width: 250px;
  }
  .bt-main {
    flex: 1;
    min-height: 500px;
    height: 100%;
    background-color: #f6f9fb;
    .container {
      margin-bottom: 50px;
    }
    #content-container {
      min-height: 400px;
    }
    @media (max-width: 767px) {
      #content-container {
        min-height: 250px;
      }
    }
  }
  .auto-height {
    min-height: 100%;
  }
  &.show-sidebar {
    transform: translateX(250px);
    .bt-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .black-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
  }
  .love-you {
    position: absolute;
    height: 421px;
    width: 713px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close-icon {
      position: absolute;
      top: -50px;
      right: 0;
      cursor: pointer;
    }
  }
}
.activity-layout {
  ::v-deep {
    .es-footer {
      padding-bottom: 100px;
    }
  }
}
</style>
