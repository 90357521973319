import Teacher from '@/pc/views/more-teacher/index.vue';

const AboutBtPage = () => import('@/pc/views/about_us/index.vue');

export default [
  {
    path: '/aboutus/:type',
    name: 'about_us_page',
    component: AboutBtPage,
    props: $route => ({
      type: $route.params.type,
    }),
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: Teacher,
  },
];
