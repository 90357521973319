 
// v-dialogDrag: 弹窗拖拽
export const dialogDrag = {
  async bind(el, binding, vnode, oldVnode) {
    await vnode.context.$nextTick();
    const value = binding.value || '.el-dialog__header';
    const dialogHeaderEl = el.querySelector(value)
    const dragDom = el.querySelector('.el-dialog');
    dialogHeaderEl.style.cursor = 'move';
    dragDom.style.margin = 'revert';
    let clientWidth = document.documentElement.clientWidth, clientHeight = document.documentElement.clientHeight;
    dragDom.style.left = `${(clientWidth - dragDom.offsetWidth) / 2}px`;
    dragDom.style.top = `${clientHeight * 0.4 - 150}px`;
    const dialogBodyEl = el.querySelector('.el-dialog__body') as HTMLElement;
    
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)
    let screenLeftDis, screenTopDis, screenRightDis, screenBottomDis, resizeBeforeLeft = 0, resizeBeforeTop = 0;
    dialogHeaderEl.onmousedown = (e) => {
      const { left, top, right, bottom } = dialogBodyEl.getBoundingClientRect();
      screenLeftDis = left;
      screenTopDis = top;
      screenRightDis = clientWidth - right;
      screenBottomDis =  clientHeight - bottom;
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop
 
      // 获取到的值带px 正则匹配替换
      let styL, styT
 
      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
      } else {
        styL = +sty.left.replace(/\px/g, '')
        styT = +sty.top.replace(/\px/g, '')
      }
 
      document.onmousemove = function(e) {
        // 通过事件委托，计算移动的距离
        let l = e.clientX - disX
        let t = e.clientY - disY
        if (l < 0) {
          // 向左移
          l = l < -screenLeftDis ? -screenLeftDis : l;
        } else {
          // 向右移
          l = l > screenRightDis ? screenRightDis : l;
        }
        if (t < 0) {
          // 向上移
          t = t < -screenTopDis ? -screenTopDis : t;
        } else {
          // 向下移
          t = t > screenBottomDis ? screenBottomDis : t;
        }
        
        // 移动当前元素
        dragDom.style.left = `${l + styL}px`
        dragDom.style.top = `${t + styT}px`
      }
 
      document.onmouseup = function(e) {
        document.onmousemove = null;
        document.onmouseup = null;
      }
    }
    window.onresize = function() {
      clientWidth = document.documentElement.clientWidth;
      clientHeight = document.documentElement.clientHeight;
    }
  }
}