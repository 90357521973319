









import { Vue, Component } from 'vue-property-decorator';
import BreadCrumb from './components/breadcrumb.vue';

@Component({
  components: {
    BreadCrumb,
  },
})
export default class CommentItemCom extends Vue {
  breadcrumb: any = {
    show: false,
    data: [],
  };

  breadChange(data) {
    this.breadcrumb = data;
  }

  async mounted() {
    await this.$nextTick();
    (document.getElementsByClassName('bt-main')[0] as any).style.backgroundColor = '#f7f8f9';
  }

  unmounted() {
    (document.getElementsByClassName('bt-main')[0] as any).style.backgroundColor = '#f6f9fb';
  }

  // 用于编辑页面顶部点击返回
  backHandle() {
    (this.$refs.routeViewRef as any).backHandle();
  }
}
