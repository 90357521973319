






































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import scrollWatch from '@/libs/vue-scrollwatch';
import SideBar from './components/sidebar.vue';
import NormalCoursesCom from '@/pc/views/activity/double11/components/normal-course.vue';
import LowPriceCourse from './components/low-price-course.vue';
import CouponsCom from './components/coupons.vue';
import MiaoshaCom from './components/miaosha.vue';
import VipCom from './components/vip-com.vue';
import LowPrice from './components/low-price.vue';
import CardGivesLive from './components/live-com/live-com-col.vue'; // 2023年8周年 直播板块的新样式(竖排列)
import LiveComRaw from './components/live-com/live-com-raw.vue';
import LiveTabList from './components/live-com/live-tab-list.vue';
import GradeRankNew from '@/pc/components/grade-rank-new/index.vue'; // 2023年8周年排行榜侧边更改为直播课时的新样式
import GradeRank from '@/pc/components/grade-rank/index.vue';
import Carousel from './components/carousel.vue';
import Lottery from './components/lottery.vue';
import SameCourses from '../double11/components/same-course.vue';
import { getActiveLinks } from '@/api/base';
import { getAnnouncementsByPage } from '@/api/course';
import { getPreTitle } from '@/utils/env';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { trackEvent } from '@/utils/helper';
const options = require('./10th_mock_dev.json');


@Component({
  components: {
    SideBar,
    CouponsCom,
    NormalCoursesCom,
    VipCom,
    CardGivesLive,
    LiveComRaw,
    LowPrice,
    MiaoshaCom,
    GradeRank,
    Carousel,
    Lottery,
    SameCourses,
    LowPriceCourse,
    LiveTabList,
    GradeRankNew
  },
  computed: {
    ...mapGetters(['offsetTime']),
  },
})
export default class AnniversaryCom extends Vue {
  settingKey: string = 'activity-10th-reserve'; // 管理后台配置的setting的key

  @Prop() isSdkReady!: boolean;
  isSettingLoad: boolean = false;
  data: any = null;
  activeBlock: string = '';
  normalCourseActiveTabName: string = 'CPA';
  isDialogVisible: boolean = false;
  headHeight: number = 80;
  isOpenRank: number = 0; // 排行榜开启状态
  isHasScore: number = 0; // 报分活动开启状态
  isHasScoreAndInTime: boolean = false // 报分活动开启并且报分活动未过期
  qrDialogData: any = {};
  offsetTime: any;
  nowServeTime: number = 0;
  isShowLottery: boolean = false;


  /* 
    左侧悬浮的导航栏内容
    以下情况过滤不显示
    · gradeRank 排行榜未开启
    · miaosha 秒杀不在活动时间范围内
    · lottery 抽奖活动不显示
  */
  get menus() {
    if (!this.data) {
      return [];
    }
    return this.data.blocks
      .filter(
        item =>
          item.menuName &&
          item.isShow &&
          (!['gradeRank', 'miaosha', 'lottery', 'getRedEnvelope'].includes(item.type) ||
            (item.type === 'gradeRank' && this.isHasScoreAndInTime) ||
            (item.type === 'miaosha' && this.nowServeTime < item.endTime) ||
            (item.type === 'lottery' && this.isShowLottery)) ||
            (item.type === 'getRedEnvelope' && item.isShow && this.nowServeTime > item.startTime && this.nowServeTime < item.endTime)
      )
      .map(item => {
        return {
          code: item.type,
          name: item.menuName,
        };
      });
  }

  /* 
    底部悬浮按钮的内容
    isHasScore 
    · 有报分活动 => 活动开启用 hasScoreBoVisibleBttomBtns，活动关闭用 hasScoreBottomBtns
    · 无报分活动 => 用bottomBtns
    isHasScoreAndInTime 报分活动开启并且未过期
  */
  get bottomBtns() {
    const { hasScoreBottomBtns, bottomBtns, hasScoreBoVisibleBttomBtns } = this.data;
    return this.isHasScoreAndInTime ? (this.isOpenRank ? hasScoreBoVisibleBttomBtns : hasScoreBottomBtns) : bottomBtns;
  }

  /* 
    顶部banner数据
    isHasScore
    · 有报分活动 => 显示 visibleRule === 'hasScore' 的
    · 无报分活动 => 根据时间区间动态显示
  */
  get topBanners() {
    if (!this.data.topBanner.banners) return [];
    if (this.isHasScore) {
      return this.data.topBanner.banners.filter(item => this.nowServeTime > item.startTime && this.nowServeTime < item.endTime && item.visibleRule !== 'noScore')
      // return this.data.topBanner.banners.filter(item => item.visibleRule === 'hasScore');
    } else {
      return this.data.topBanner.banners.filter(item => (!item.visibleRule || item.visibleRule == 'noScore') && this.nowServeTime > item.startTime && this.nowServeTime < item.endTime);
    }
  }

  // 获取管理后台的配置
  async getSetting() {
    try {
      this.isSettingLoad = false;
      const { rows } = await getActiveLinks({ name: this.settingKey });
      const { lotteryTimes = {}, pc: pcData, visible: isOpenRank, publish: isHasScore, reportScoreEndTime } = JSON.parse(rows[0].value);
      this.data = pcData; // pc端数据
      this.isOpenRank = isOpenRank; // 排行榜开启状态
      this.isHasScore = isHasScore; // 报分活动开启状态
      this.isHasScoreAndInTime = !!(isHasScore && reportScoreEndTime * 1000 >= this.nowServeTime)
      // 抽奖活动开启状态
      this.isShowLottery = lotteryTimes && lotteryTimes['startTime'] * 1000 <= this.nowServeTime && lotteryTimes['endTime'] * 1000 >= this.nowServeTime;
    } catch (e) {
      console.log('获取失败', e);
    } finally {
      this.isSettingLoad = true;
    }
  }

  // 页面初始化加载
  created() {
    document.title = getPreTitle('【BT教育10周年】拾年为期，重拾记忆，千万礼券派送中！');
    this.nowServeTime = dayjs().valueOf() - this.offsetTime;
  }
  isLoacl = false;
  async mounted() {
    if (this.isLoacl) {
      const localOptions = JSON.parse(JSON.stringify(options));
      this.isOpenRank = localOptions['visible'];
      this.isHasScore = localOptions['publish'];
      this.isHasScoreAndInTime = !!(this.isHasScore && localOptions.reportScoreEndTime * 1000 >= this.nowServeTime)
      this.data = { ...localOptions.pc };
      this.isSettingLoad = true;
      this.isShowLottery = true;
    } else {
      this.getSetting();
    }
    await this.$nextTick();
    setTimeout(async () => {
      /* 
        scrollToName 滚动到对应的模块
        scrollToId 滚动到对应的模块ID
        两者只能传其一
      */
      const { scrollToName, scrollToId } = this.$route.query;
      if (scrollToId && scrollToId === 'get-prizes') {
        setTimeout(() => {
          this.scrollTo('lottery');
          // 同时清理链接中的query
          this.$router.replace({ query: {} });
        }, 1000);
      }
      if (scrollToName) {
        setTimeout(() => {
          this.scrollTo(scrollToName);
          // 同时清理链接中的query
          this.$router.replace({ query: {} });
        }, 1000);
      }
      // @ts-ignore
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.headHeight = entry.target.offsetHeight || 80;
        }
      });
      const domHead = document.querySelector('.bt-header');
      // 对进入页面还没有头部dom的问题进行兼容处理
      if (!domHead) {
        // @ts-ignore
        let isRead = this.$cookie.get('close_announcements_alert');
        if (!isRead) {
          const { announcements } = await getAnnouncementsByPage({ targetType: 'global' });
          if (announcements && announcements.length > 0) {
            this.headHeight = 118;
          }
        }
      } else {
        resizeObserver.observe(domHead);
      }
    }, 300);
  }

  // 底部导航栏按钮点击事件
  bottomHandle({ dialog, scrollTo, url, isShowChat, eventId, label }) {
    // 埋点
    try {
      if (eventId) {
        this.$sensors.track(`bt_${eventId}`, {
          description: label || '',
          url: url,
        });
      }
    } catch (e) {
      console.log('头部底部的跳转有问题');
    }
    console.log('dialog', dialog, 'scrollTo', scrollTo, 'url', url, 'isShowChat', isShowChat);
    if (dialog) {
      // 打开弹窗
      this.qrDialogData = dialog;
      this.isDialogVisible = true;
    } else if (isShowChat) {
      // 打开七鱼客服
      this.showChat();
    } else if (scrollTo) {
      // 滚动到对应模块
      this.scrollTo(scrollTo);
    } else if (url) {
      // 打开链接
      window.open(url);
    }
  }

  // 限时免福利点击埋点
  cpaGiftClick(item) {
    try {
      if (item.eventId) {
        trackEvent(`bt_${item.eventId}`, {
          description: item.title + '-' + item.sTitle,
          link: item.link,
        });
      }
    } catch (e) {
      console.log('限时免福利埋点点击报错');
    }
    console.log('点击', item);
    if (item.type === 'link') {
      window.open(item.link, '_blank');
    }
    if (item.type === 'dialog') {
      // 无限畅听弹窗
      this.dialogHandle(item.freeDialogData);
    }
  }

  // 解锁题库
  unlockTiku(data) {
    this.qrDialogData = data.qrDialog;
    this.isDialogVisible = true;
    trackEvent('bt_10667', {
      description: '无门槛福利-8周年无限畅听',
    });
  }

  // 打开弹窗
  dialogHandle(data) {
    this.qrDialogData = data;
    this.isDialogVisible = true;
  }

  // 领取红包，跳转红包详情页
  receiveCoinHandle(url) {
    window.open(url, "_blank");
  }

  // 点击头图返回首页
  goHome() {
    // document.title = getPreTitle('BT教育 - BT学院陪伴奋斗年华-注册会计师_职称考试_法考_保荐代表人培训');
    // this.$router.replace({
    //   name: 'home',
    // });
  }

  // 打开链接
  goLink(link) {
    window.open(link);
    trackEvent('bt_10673', {
      description: '8周年限定礼盒',
    });
  }

  scroll(node) {
    this.activeBlock = node.name;
  }

  scrollTo(name) {
    // console.log('scrollTo-name', name);
    try {
      scrollWatch.scrollTo(name);
    } catch (error) {
      console.log(error);
    }
  }

  // 打开7鱼客服
  showChat() {
    if (this.isSdkReady) {
      // @ts-ignore
      window.ysf('open');
    }
  }

  xuetongClick(hot) {
    if (hot.type === 'link') {
      window.open(hot.link, '_blank');
    }
    if (hot.type === 'dialog') {
      this.dialogHandle(hot.dialog);
    }
  }
}
