import { request } from '@/utils/axios';

// 获取用户的必答券列表
export function getNotesByQuestionId({ questionId, ...params }) {
  return request.get(`/v2/notes/question/${questionId}/list`, params, { requestBase: 'exam' });
}

// 新增一个疑问笔记
export function addConfusion({ ...params }) {
  return request.post(`/v2/confusions`, params, { requestBase: 'exam' });
}

// 删除一个疑问笔记
export function deleteConfusion({ ...params }) {
  return request.delete(`/v2/confusions`, params, { requestBase: 'exam' });
}

// 编辑一个疑问笔记
export function editConfusion({ confusionId, ...params }) {
  return request.patch(`/v2/confusions/${confusionId}`, params, { requestBase: 'exam' });
}
