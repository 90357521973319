













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CountDown extends Vue {
  @Prop() deadline!: number;
  @Prop({ type: String, default: '距离活动结束' }) text;

  now: number = Date.now();
  timing: number = parseFloat((this.deadline - this.now) / 1000 + '');
  timingInterval: any = null;
  timingObj: any = null;

  get hasCountDown() {
    return this.timing && this.timing > 0;
  }

  toTwoNumber(n) {
    return n < 10 ? '0' + n : n;
  }

  timingFormat(t) {
    var d = Math.floor(t / 60 / 60 / 24);
    var h = this.toTwoNumber(Math.floor((t / 60 / 60) % 24));
    var m = this.toTwoNumber(Math.floor((t / 60) % 60));
    var s = this.toTwoNumber(Math.floor(t % 60));
    return { d, h, m, s };
  }
  startCountTiming() {
    if (!this.timing || this.timing < 0) {
      return false;
    }
    if (!this.hasCountDown) {
      return;
    }
    this.timingInterval = setInterval(() => {
      this.timing--;
      if (this.timing <= 0) {
        clearInterval(this.timingInterval);
      }
    }, 1000);
  }
  created() {
    this.startCountTiming();
  }
}
