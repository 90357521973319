import { request } from '@/utils/axios';

// 账号密码登录
export function loginByAccountAndPassword(data) {
  return request.post('/login/account-password', data, { requestBase: 'phoenix', noAccess: true });
}

// 扫码登录
export function loginByScanCode(code) {
  return request.post('/login/scan-code', { code }, { requestBase: 'phoenix', noAccess: true });
}

// 通过手机和验证码登录
export function loginByMobileAndVerifyCode(mobile, verifyCode) {
  return request.post('/login/mobile-verify-code', { mobile, verifyCode }, { requestBase: 'phoenix', noAccess: true });
}

// userId登录
export function loginByAppRereshToken(id) {
  return request.post('/login/id-code', { id }, { requestBase: 'phoenix' });
}

// 发送手机验证码(会验证手机是否注册)
export function logout() {
  return request.get('/logout', {}, { requestBase: 'phoenix', noAccess: true });
}

// 从中间层拿refreshToken等
export function getToken() {
  return request.get('/get-token', {}, { requestBase: 'phoenix', noAccess: true });
}

// 获取手动授权链接
export function authUrlRedirect(url) {
  return request.get('/v2/redirect/wechat-login-url', { url }, { requestBase: 'rabbit', noAccess: true });
}
