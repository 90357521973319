import Vue from 'vue';


// ⚠️SSR时有内存泄漏问题
// 因为涉及地方较多，没办法一一修改。大部分事件监听为用户触发事件，所以直接在后端渲染环境中去掉
// 如果需要在后端渲染时使用，请自行修改实现
// 建议在使用前考虑能否用vuex替代

let bus = {};
if (SSR_ENV === 'client') {
  bus = new Vue();
} else {
  bus = {
    $on: () => {},
    $emit: () => {},
    $off: () => {},
  };
}

const eventBus = {
  install(Vue) {
    Vue.prototype.$bus = bus;
  }
};

Vue.use(eventBus);
