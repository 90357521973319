












import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'TikuBreadCrumb',
})
export default class TikuBreadCrumb extends Vue {
  @Prop({ type: Array, default: () => [] }) data!: any;
  @Prop({ type: Boolean, default: false }) show!: boolean;

  goCircleHome() {
    const { href } = this.$router.resolve({
      name: 'circle-index',
    });
    location.href = href;
  }

  backHandle() {
    this.$emit('backHandle');
  }
}
