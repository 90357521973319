<template>
  <div :class="'home-course-model course-area ' + (serial % 2 === 0 ? 'fafafa' : 'fff')" :data-id="id">
    <div class="pc-container">
      <div class="text-line head" v-if="parent">
        <h5 class="anniversary-model-title">
          <span>{{ parent.name }}</span>
          <!-- <a v-if="$root.isAnniversary && $root.anniversaryLinkImg" :href="$root.anniversaryLink" target="_blank" class="invite-anniversary">
            <img :src="$root.anniversaryLinkImg" v-default-img-alt="'七周年'" />
          </a> -->
          <OperatPlaceCom :categoryId="parent.id" :blockTitle="parent.name"></OperatPlaceCom>
        </h5>
        <!-- <a :href="`/course/explore/${code}?subCategory=${parent.code}`" class="open">查看更多</a> -->
        <a :href="`/course/explore?categoryId=${parent.id}`" class="open">查看更多</a>
      </div>
      <el-menu v-if="lists" default-active="0" text-color="#8A9499" active-text-color="#002333" mode="horizontal">
        <el-menu-item index="0" @click="handleSelect(0)">推荐</el-menu-item>
        <template v-if="lists.length">
          <template v-for="(menu, index) in lists">
            <el-menu-item @click="handleSelect(menu.id)" :index="index + 1 + ''" :key="index" v-if="menu.id">{{ menu.name }}</el-menu-item>
          </template>
        </template>
        <el-menu-item index="-1" @click="handleSelect(-1)" v-if="!lists.length">全部</el-menu-item>
      </el-menu>
      <template v-if="hasMounted">
        <template v-if="lists">
          <list-model :id="0" :active="active" :category-id="id" v-show="active === 0"></list-model>
          <template v-if="lists.length">
            <template v-for="(menu, index) in lists">
              <list-model :key="index" :id="menu.id" :active="active" v-show="active === menu.id"></list-model>
            </template>
          </template>
          <lazy-component v-if="!lists.length"> <list-model :id="-1" :active="active" :category-id="id" v-show="active === -1"></list-model> </lazy-component>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ListModel from './components/ListModel';
import OperatPlaceCom from '@/pc/views/home/components/OperatPlace.vue';

export default {
  props: {
    id: {
      type: Number,
      require: true,
    },
    courseCategorys: {
      type: Array,
      require: true,
    },
    categorys: {
      type: Array,
      require: true,
    },
    code: {
      type: String,
      require: true,
    },
    serial: {
      type: Number,
      require: false,
      default() {
        return 0;
      },
    },
  },
  components: {
    ListModel,
    OperatPlaceCom,
  },
  data() {
    return {
      // parent: null, // 父分类信息
      // lists: null,
      active: 0,
      isLoading: false,
      hasMounted: false,
    };
  },
  created() {
    // this.init();
  },
  mounted() {
    this.hasMounted = true;
  },
  computed: {
    parent() {
      let parent = null;
      this.courseCategorys.map(item => {
        if (item.id === this.id) {
          parent = item;
        }
      });
      return parent;
    },
    lists() {
      let result = [];
      this.courseCategorys.map(item => {
        if (item.id === this.id) {
          if (item.children) {
            result = item.children;
          } else {
            result = [];
          }
        }
      });
      return result;
    },
  },
  methods: {
    init() {
      this.getParent();
    },
    getParent() {
      this.courseCategorys.map(item => {
        if (item.id === this.id) {
          this.parent = item;

          if (item.children) {
            this.lists = item.children;
          } else {
            this.lists = [];
          }
        }
      });
    },
    handleSelect(id) {
      this.active = id;
    },
  },
};
</script>
