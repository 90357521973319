<template>
  <div class="wechat-login">
    <template v-if="!qrcode">
      <img class="loading-img" key="loading" :src="require('@/assets/images/login_loading.gif')" />
      <p class="loading-tips">小才鲸正在努力加载哦～</p>
    </template>
    <template v-else>
      <img class="qrcode-img" key="qrcode" :src="qrcode" alt="二维码" />
      <p class="error-tip">{{ errorMsg }}</p>
      <p class="qrcode-tip"><i class="el-icon-info"></i>未注册的用户，只需使用微信进行扫码即可注册</p>
    </template>
  </div>
</template>

<script>
import { request } from '@/utils/axios';
import io from 'socket.io-client';
import { loginByScanCode } from '@/api/login';
import {setLoginStorage} from '@/utils/repeat-function';

export default {
  name: 'WechatLoginForm',
  data() {
    return {
      qrcode: '',
      ticket: '',
      errorMsg: '',
      expireSeconds: 0,
      hunterMsgUrl: process.env.VUE_APP_HUNTER_MSG_URL,
      socket: null,
    };
  },
  created() {
    this.getLoginQRCode();
  },
  destroyed() {
    if (this.socket) {
      this.socket.close();
    }
  },
  methods: {
    getLoginQRCode() {
      // 获取登录二维码
      // request.throughApi('ms-rabbit.wxComponent.getWxQrcodeForLogin', {}, { isAuth: false }).then(res => {
        request.get(`/v2/wx/qrcode/forlogin`, {}, { requestBase: 'rabbit' }).then(res => {
        this.qrcode = res.url;
        this.ticket = res.ticket;
        this.expireSeconds = res.expireSeconds;
        this.buildLink();
      });
    },
    buildLink() {
      // 建立socket链接
      let socket = io(this.hunterMsgUrl, {
        path: '/ws',
        secure: true,
      });
      this.socket = socket;
      socket.emit(
        'chat message',
        JSON.stringify({
          ticket: this.ticket,
          expire: this.expireSeconds,
        })
      );
      socket.on('chat message', msg => {
        loginByScanCode(msg)
          .then(res => {
            setLoginStorage(res);
            this.$emit('loginSuccess');
          })
          .catch(errorData => {
            this.errorMsg = errorData.msg || errorData.message;
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wechat-login {
  p {
    font-size: 12px;
  }
  .qrcode-img {
    width: 220px;
    height: 220px;
  }
  .error-tip {
    color: #f56c6c;
    text-align: left;
    margin: 9px 18px 0;
  }
  .qrcode-tip {
    display: flex;
    align-items: center;
    color: #aeaeae;
    margin: 0 0 14px 18px;
    text-align: left;
    i {
      font-size: 16px;
      margin-right: 3px;
    }
  }
  .loading-img {
    margin-top: 35px;
    width: 256px;
  }
  .loading-tips {
    color: #bbbbbb;
    height: 18px;
    line-height: 18px;
    margin: 22px 0 32px;
  }
}
</style>
