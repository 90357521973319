import Vue from 'vue';

const login = {
  install(Vue) {
    Vue.prototype.$login = () => {
      return Vue.prototype.$store.getters['my/isLogin'];
    };
  }
};

Vue.use(login);
