'use strict';
import Vue from 'vue';
import { getMyInfo } from '@/api/my';
import { loginByAppRereshToken } from '@/api/login';
import { getQueryVariable } from '@/utils/parseUrlParams';

export function getBrowser(ua) {
  return {
    // 移动终端浏览器版本信息
    chrome: ua.indexOf('Chrome') > -1, // chrome浏览器
    trident: ua.indexOf('Trident') > -1, // IE内核
    presto: ua.indexOf('Presto') > -1, // opera内核
    webKit: ua.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') === -1, // 火狐内核
    qq: ua.indexOf('QQBrowser') > -1, // QQ浏览器
    mobile: !!ua.match(/AppleWebKit.*(Mobile|HuaweiBrowser).*/), // 是否为移动终端
    ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1, // android终端或uc浏览器
    iPhone: ua.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: ua.indexOf('iPad') > -1, // 是否iPad
    webApp: ua.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
    wx: /micromessenger/i.test(ua), // 是否微信浏览器
    win: ua.indexOf('Win') > -1,
    mac: ua.indexOf('Mac') > -1,
    huawei: /huawei/i.test(ua),
  };
}

export function insertNewlineToChat(element) {
  window.testElement = element;
  element.focus();
  let flag = document.execCommand('insertText', 'false', '\r\n');
  if (!flag) {
    element.value = element.value.substr(0, element.selectionStart) + '\r\n' + element.value.substring(element.selectionStart, element.value.length);
  }
}

export function hasUsableSWF() {
  let swf;
  if (typeof window.ActiveXObject !== 'undefined') {
    swf = new window.ActiveXObject('ShockwaveFlash.ShockwaveFlash');
  } else {
    swf = window.navigator.plugins['Shockwave Flash'];
  }
  return !!swf;
}

export function getByteLength(str) {
  // eslint-disable-next-line no-control-regex
  return str.replace(/[^\x00-\xff]/g, '01').length;
}

export function getMarqueeActions(duration = 6, interval = 5) {
  const randActions = [];
  for (let i = 0; i < 50; i = i + 2) {
    const randomX = Math.random() * (0.7 - 0.2) + 0.2;
    const randomY = Math.random() * 0.05 + 0.02;
    randActions.push({
      index: i,
      duration: duration * 1000,
      start: { xpos: randomX, ypos: randomY, alpha: 0.6 },
      end: { xpos: randomX, ypos: randomY, alpha: 0.6 },
    });
    randActions.push({
      index: i + 1,
      duration: interval * 1000,
      start: { xpos: -1, ypos: 0.1, alpha: 0 },
      end: { xpos: -1, ypos: 0.1, alpha: 0 },
    });
  }
  return randActions;
}

/**
 *
 * @param {*} video
 * @param {*} x
 * @param {*} y
 * @param {*} tWidth 要截取的宽度。
 * @param {*} tHeight 要截取的高度
 * @param {*} width  要绘制的图像的宽度
 * @param {*} height  要绘制的图像的高度
 * @returns
 */
export function getShot(video, x, y, tWidth, tHeight, width, height) {
  video.crossOrigin = 'anonymous';

  video.addEventListener('loadeddata', function () {
    console.log('x', x, 'y', y, 'tWidth', tWidth, 'tHeight', tHeight, 'width', width, 'height', height);
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, x, y, tWidth, tHeight, 0, 0, width, height);
    const result = canvas.toDataURL('image/png');
    console.log(result);
  });
}

export function isArrEqual(arr1, arr2) {
  return arr1.length === arr2.length && arr1.every(ele => arr2.includes(ele));
}

/**
 * 文本框根据输入内容自适应高度
 * @param  {HTMLElement}   输入框元素
 * @param  {Number}   设置光标与输入框保持的距离(默认0)
 * @param  {Number}    设置最大高度(可选)
 */

export function autoTextarea(elem, extra = 0, maxHeight = '') {
  extra = extra || 0;

  var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
    isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
    addEvent = function (type, callback) {
      elem.addEventListener ? elem.addEventListener(type, callback, false) : elem.attachEvent('on' + type, callback);
    },
    getStyle = elem.currentStyle
      ? function (name) {
          var val = elem.currentStyle[name];

          if (name === 'height' && val.search(/px/i) !== 1) {
            var rect = elem.getBoundingClientRect();

            return rect.bottom - rect.top - parseFloat(getStyle('paddingTop')) - parseFloat(getStyle('paddingBottom')) + 'px';
          }

          return val;
        }
      : function (name) {
          return getComputedStyle(elem, null)[name];
        },
    minHeight = parseFloat(getStyle('height'));

  elem.style.resize = 'none';

  var change = function () {
    var height,
      padding = 0,
      style = elem.style;

    if (elem._length === elem.value.length) return;

    elem._length = elem.value.length;

    if (!isFirefox && !isOpera) {
      padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
    }

    elem.style.height = minHeight + 'px';

    if (elem.scrollHeight > minHeight) {
      if (maxHeight && elem.scrollHeight > maxHeight) {
        height = maxHeight - padding;

        style.overflowY = 'auto';
      } else {
        height = elem.scrollHeight - padding;

        style.overflowY = 'hidden';
      }

      style.height = height + extra + 'px';
      elem.currHeight = parseInt(style.height);
    }
  };

  addEvent('propertychange', change);

  addEvent('input', change);

  addEvent('focus', change);

  change();
}

/**
 * 在光标后插入文本
 * 参数：
 *  textDom [JavaScript DOM String] 当前对象
 *  value [String] 要插入的文本
 */
export function insertAfterText(textDom, value) {
  var selectRange;
  if (document.selection) {
    // IE Support
    textDom.focus();
    selectRange = document.selection.createRange();
    selectRange.text = value;
    textDom.focus();
  } else {
    // 获取选中的问题
    var selectionStart; // textarea选中文本的开始索引
    var selectionEnd; // textarea选中文本的结束索引
    selectionStart = textDom.selectionStart;
    selectionEnd = textDom.selectionEnd;
    var selectStr = textDom.value.substring(selectionStart, selectionEnd);
    if (selectStr && selectStr.substring(selectStr.length - 1) == ' ') {
      value += ' ';
    }
    var leftStr = textDom.value.substring(0, selectionStart);
    var rightStr = textDom.value.substring(selectionEnd, textDom.value.length);
    textDom.value = leftStr + value + rightStr;
    // 重新选中新文本;
    selectionEnd = selectionStart + value.length;
    // textDom.setSelectionRange(selectionStart, selectionEnd);
    textDom.setSelectionRange(selectionEnd, selectionEnd);
    //非IE浏览器必须获取焦点
    textDom.focus();
  }
  // else if (textDom.selectionStart || textDom.selectionStart == '0') {
  //   // Firefox support
  //   var startPos = textDom.selectionStart;
  //   var endPos = textDom.selectionEnd;
  //   var scrollTop = textDom.scrollTop;
  //   textDom.value = textDom.value.substring(0, startPos) + value + textDom.value.substring(endPos, textDom.value.length);
  //   textDom.focus();
  //   textDom.selectionStart = startPos + value.length;
  //   textDom.selectionEnd = startPos + value.length;
  //   textDom.scrollTop = scrollTop;
  // } else {
  //   textDom.value += value;
  //   textDom.focus();
  // }
}

async function loginServer() {
  const myInfo = await getMyInfo();
  await loginByAppRereshToken(myInfo.id);
  const goto = getQueryVariable('goto');
  console.log('登录结束，返回原有流程', goto);
  if (goto) {
    // 当存在重定向地址时，开启app的loading
    Vue.prototype.$appLoginLoading = true;
    console.log('开启appLoginLoading', Vue.prototype.$appLoginLoading);
    location.replace(location.href);
  }
}

// app内 如果没有refreshToken，手动获取一下
export async function appTokenBefore() {
  /* eslint-disable no-async-promise-executor */
  return new Promise(async resolve => {
    try {
      const hasBridge = window.JsBridge && window.JsBridge.hasBridge; // APP环境

      if (!hasBridge) return resolve(); // 非APP环境直接跳过

      const isAndriod = getBrowser(navigator.userAgent).android; // 安卓
      const appFunctionName = isAndriod ? 'getAndroidRefreshToken' : 'getRefreshToken'; // 获取refreshtoken方法名
      const isPhoenixLoginLogin = Vue.cookie.get('is_phoenix_login') === 'true'; // 服务端是否已登录
      const refreshToken = localStorage.getItem('refreshToken');

      // refreshToken
      console.log('APP内登录态校验开始', hasBridge, isAndriod, appFunctionName, isPhoenixLoginLogin, refreshToken);

      /*
        APP环境且服务端未登录
        · 无token，则调app提供的方法获取，写入本地localStorage，并进行服务端登录
        · 有token，则直接进行服务端登录
       */
      if (hasBridge && !isPhoenixLoginLogin) {
        if (!refreshToken) {
          // 无token
          window.JsBridge.callHandler(appFunctionName, {}, async token => {
            console.log('【调用内置方法获取refreshToken】', token);
            if (token) {
              localStorage.setItem('refreshToken', token);
              await loginServer();
            }
            return resolve(); // 已登录
          });
        } else {
          // 有token
          await loginServer();
          return resolve(); // 已登录
        }
      } else {
        return resolve(); // 兜底加一个成功回调，避免影响主流程
      }
    } catch {
      return resolve(); // 有任何一步出错，也继续往下走，避免影响主流程
    }
  });
  /* eslint-enable no-async-promise-executor */
}

export function removeAllLocalstorageOfKey(keyword) {
  const len = localStorage.length;
  const localKeys = [];
  for (let i = 0; i < len; i++) {
    const key = localStorage.key(i);
    localKeys.push(key);
  }
  localKeys.forEach(key => {
    if (key.includes(keyword)) {
      localStorage.removeItem(key);
    }
  });
}

export function trackEvent(id, params) {
  const { description = '数据' } = params;
  try {
    Vue.prototype.$sensors.track(id, params);
  } catch (e) {
    console.log(`${description}埋点错误`, e);
  }
}

export function throttle(fn, time = 200) {
  let isDoing = false; // 通过闭包保存一个标记
  return function () {
    // 在函数开头判断标记是否为true，不为true则return
    if (isDoing) return;
    //  设置为false,维护的时间起点
    isDoing = true;
    setTimeout(() => {
      fn.apply(this, arguments);
      isDoing = false; //执行完毕，时间终点
    }, time);
  };
}

export function removeHtmlTagsAndNewlines(input) {
  // 去除 HTML 标签
  var withoutTags = input.replace(/<[^>]+>/g, '');
  // 去除换行符
  var withoutNewlines = withoutTags.replace(/\n|\r|\t|\s/g, '');
  return withoutNewlines;
}

export function copyHandle(text) {
  const input = document.createElement('input');
  input.setAttribute('readonly', 'readonly');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.focus({ preventScroll: true });
  input.setSelectionRange(0, 99999);
  if (document.execCommand('copy')) {
    document.execCommand('copy');
    Vue.prototype.$message.success('复制成功');
  }
  document.body.removeChild(input);
}

export function jump({ jumpUrlToWx, jumpUrlToNotWx }) {
  const { target, params = {} } = getBrowser(navigator.userAgent).wx ? jumpUrlToWx : jumpUrlToNotWx;
  console.log('isWx', getBrowser(navigator.userAgent).wx, 'target', target, 'params', params);
  if (target === 'link') {
    window.open(params.url || '', '_blank');
  } else if (target === 'channelPage') {
    // 跳转圈子
    if (params.channelConfig && params.channelConfig.channelPageUrl) {
      window.open(params.channelConfig.channelPageUrl || '', '_blank');
    }
  } else if (target === 'dialog') {
    openCodeDialog(params.popupConfig)
    return { type: 'dialog', ...params.popupConfig };
  } else if (target === 'textDialog') {
    openTextDialog(params.textConfig);
    return { type: 'textDialog', ...params.textConfig };
  } else if (target === 'targetBlock') {
    console.log('targetBlockId', params.targetBlockId);
    if (!params.targetBlockId) return;
    const targetDom = document.querySelector(`div[componentid='${params.targetBlockId}']`);
    console.log('targetDom', targetDom);
    if (!targetDom) {
      console.log('未找到要跳转的目标元素，请检查目标元素id是否正确', target, params);
      return;
    } else {
      targetDom.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    return { type: 'targetBlock', targetBlockId: params.targetBlockId };
  } else if (target === 'courseBuyingDetail') {
    window.open(`/course/${params.courseId}`);
  } else if (target === 'normalCourseList') {
    window.open(`/course/explore?categoryId=${params.categoryId}`);
  }
}

// 全角字符转半角字符
export function transformHalfAngle(str) { 
  let result = ""; 
  for(let i = 0;i < str.length;i++){ 
      if (str.charCodeAt(i) === 12288){
          result += String.fromCharCode(str.charCodeAt(i) - 12256);
          continue;
      }
      if(str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375){ 
          result += String.fromCharCode(str.charCodeAt(i) - 65248); 
      } 
      else{ 
          result += String.fromCharCode(str.charCodeAt(i)); 
      } 
  } 
  return result;
}
export function openTextDialog(dialogConfig) {
  const TextDialog = require('../pc/views/activity/act/components/text-dialog.vue').default;

  console.log('=== textDialog ===', dialogConfig);
  const propsData = {
    options: dialogConfig,
    visible: true,
  };
  const dialog = new TextDialog({
    propsData,
    // @ts-ignore
    _parentListeners: {
      'update:visible'(val) {
        // @ts-ignore
        dialog._props.visible = val;
      },
    },
  });
  const vm = dialog.$mount();
  document.getElementById('app').appendChild(vm.$el);
}

export function openCodeDialog(dialogConfig) {
  const CodeDialog = require('../pc/views/activity/act/components/code-dialog.vue').default;

  console.log('=== codeDialog ===', dialogConfig);
  const propsData = {
    options: dialogConfig,
    visible: true,
  };
  const dialog = new CodeDialog({
    propsData,
    // @ts-ignore
    _parentListeners: {
      'update:visible'(val) {
        // @ts-ignore
        dialog._props.visible = val;
      },
    },
  });
  const vm = dialog.$mount();
  document.getElementById('app').appendChild(vm.$el);
}
