import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/order/:sn/payment',
    component: () => import(/* webpackChunkName: "payment" */ '@/pc/views/payment/index.vue'),
    name: 'payment',
  },
  {
    path: '/order/:sn/wxpay',
    component: () => import(/* webpackChunkName: "payment" */ '@/pc/views/payment/wxpay.vue'),
    name: 'wxpay',
  },
  {
    path: '/order/paysuccess',
    component: () => import(/* webpackChunkName: "payment" */ '@/pc/views/payment/result.vue'),
    name: 'pay_success',
    props: router => ({
      courseId: Number(router.query.courseId),
    }),
  },
] as RouteConfig[];
