


































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';

import { getRewardChoices, getCashAccount } from '@/api/ask';

@Component({})
export default class modules extends Vue {
  @PropSync('option', {
    type: Object,
    default: () => {
      return {
        minPrice: 2,
        rewardChoices: [2, 4, 8],
      };
    },
  })
  bDouOption: any;
  @PropSync('reward', { type: Number, default: 0 }) syncReward: any;
  @PropSync('balance', { type: Number, default: 0 }) bDouBalance: any;
  @Prop({ type: String, default: '' }) inputTip: any;
  @Prop({ type: String, default: ''}) definedClass: any;

  definedChoicesNum: any = ''; // 自定义选择数量
  useBDouNum: any = 0; // 固定的数量
  activeBDouKey: any = ''; // defined 自定义 | fixed 固定
  isRecharge: boolean = false; // 用户是否点了去充值

  get watchAll() {
    return {
      definedChoicesNum: this.definedChoicesNum,
      useBDouNum: this.useBDouNum,
      activeBDouKey: this.activeBDouKey,
    };
  }
  numberChange () {
    this.definedChoicesNum = this.definedChoicesNum.replace(/[^0-9]/g,'')
  }

  @Watch('watchAll', { deep: true })
  syncVal() {
    this.syncReward = this.activeBDouKey === 'fixed' ? this.useBDouNum : Number(this.definedChoicesNum);
    console.log('this.syncReward', this.syncReward, this.bDouOption, this.activeBDouKey, this.useBDouNum, Number(this.definedChoicesNum));
  }

  // B豆-去充值
  toRecharge() {
    // @ts-ignore
    const link = this.$router.resolve({ name: 'my_coin_money_pay' });
    window.open(link.href, '_blank');
    this.isRecharge = true;
  }

  async created() {
    await this.getBdouNum();
  }

  mounted() {
    document.addEventListener('visibilitychange', this.watchVisibilitychange);
  }

  destroyed() {
    document.removeEventListener('visibilitychange', this.watchVisibilitychange);
  }

  // 从后台切回来刷新状态
  async watchVisibilitychange() {
    if (this.isRecharge) {
      await this.getBdouNum();
    }
  }

  // 选择固定B豆
  chooseFixedBDou(num) {
    this.useBDouNum = num;
    this.activeBDouKey = 'fixed';
    this.definedChoicesNum = '';
  }

  // 选择自定义B豆
  chooseDefinedBDou() {
    this.activeBDouKey = 'defined';
  }

  // 获取B豆相关配置
  cashLoading:boolean = false;
  async getBdouNum() {
    if (this.cashLoading) return;
    try {
      this.cashLoading = true;
      const optionInfo = await getRewardChoices({});
      this.bDouOption = optionInfo;
      console.log('B豆悬赏数量可选项', optionInfo);
      const balanceInfo = await getCashAccount({
        timestamp: new Date().getTime(),
      });
      this.bDouBalance = balanceInfo.cash || 0;
      console.log('B豆余额', balanceInfo);
      this.isRecharge = false;
    } catch (e) {
      console.log('获取B豆相关配置失败', e);
    } finally {
      setTimeout(() => {
        this.cashLoading = false;
      }, 300);
    }
  }

  // 重置一下数据
  reset() {
    this.definedChoicesNum = '';
    this.useBDouNum = 0;
    this.activeBDouKey = '';
  }
}
