export function getESubjectType() {
  return {
    Normal: 0,
    Subject: 1, // 专题课（专题课可以只买部分，如果要升级可以来父课程买全程课）
    Combine: 2, // 组合课
  };
}

// 获取当前课程最优价格享有的校友卡信息，若没有则返回null
export function getVipList() {
  const xuetong = 'https://app-cdn.btclass.cn/turtle/xuetong-ka.png';
  const xueba = 'https://app-cdn.btclass.cn/turtle/xueba-ka.png';
  const xuesheng = 'https://app-cdn.btclass.cn/turtle/xuesheng-ka.png';
  const xuemo = 'https://app-cdn.btclass.cn/turtle/xuemo-ka.png';
  const wunian = 'https://app-cdn.btclass.cn/turtle/wunian-vip.png';
  const zhongsheng = 'https://app-cdn.btclass.cn/turtle/zhongsheng-vip.png';
  const zuanshi = 'https://app-cdn.btclass.cn/turtle/zuanshi-ka.png';

  const xuetongIcon = 'https://app-cdn.btclass.cn/turtle/xuetong-ka-icon.png';
  const xuebaIcon = 'https://app-cdn.btclass.cn/turtle/xueba-ka-icon.png';
  const xueshengIcon = 'https://app-cdn.btclass.cn/turtle/xuesheng-ka-icon.png';
  const xuemoIcon = 'https://app-cdn.btclass.cn/turtle/xuemo-ka-icon.png';
  const wunianIcon = 'https://app-cdn.btclass.cn/turtle/wunian-vip-icon.png';
  const zhongshengIcon = 'https://app-cdn.btclass.cn/turtle/zhongsheng-vip-icon.png';
  const zuanshiIcon = 'https://app-cdn.btclass.cn/turtle/zuanshi-ka-icon.png';
  return {
    // 卡片，说明，说明字体颜色, icon, border样式， 字体color样式
    1: [xuetong, '学童卡专享x折', '#0083DB', xuetongIcon, 'rgba(134, 183, 255, 0.5)', 'rgba(0, 131, 219, 1)'],
    2: [xueba, '学霸卡专享x折', '#B9653A', xuebaIcon, 'rgba(238, 183, 154, 0.5)', 'rgba(185, 101, 58, 1)'],
    3: [xuesheng, '学神卡专享x折', '#D08900', xueshengIcon, 'rgba(248, 184, 60, 0.5)', 'rgba(208, 137, 0, 1)'],
    4: [xuemo, '学魔卡专享x折', '#4B8FFF', xuemoIcon, 'rgba(75, 143, 255, 0.5)', 'rgba(75, 143, 255, 1)'],
    7: [wunian, '五年VIP学习卡专享0元购课', '#34344A', wunianIcon, 'rgba(52, 52, 74, 0.5)', 'rgba(52, 52, 74, 1)'],
    8: [zhongsheng, '终身VIP学习卡专享0元购课', '#2E1B0A', zhongshengIcon, 'rgba(46, 27, 10, 0.5)', 'rgba(46, 27, 10, 1)'],
    19: [zuanshi, '五年VIP钻石卡专享0元购课', '#CC8BFB', zuanshiIcon, 'rgba(193, 132, 251, 0.5)', 'rgba(193, 132, 251, 1)'],
  };
}

export function getVipShortName() {
  return {
    1: '学童',
    2: '学霸',
    3: '学神',
    4: '学魔',
    7: '五年VIP',
    8: '终身VIP',
    19: '钻石',
  };
}

/*
  判断当前课程是否支持校友卡折扣
  ·课程可用时享有「校友卡」和「限时折扣」，此时标签文案为：折上X折
  ·课程不享有「限时折扣」，但享有「校友卡」优惠，此时标签文案为：X折
*/
export function getShowVip(course, verifyCoupon = true) {
  const { priceDetail, joinVipDiscount, studentInfo } = course;
  const vipList = getVipList();
  const vipShortName = getVipShortName();
  const showLimitedTime = course.discount < 10 && course.discount !== 0;
  // 优惠券是否可叠加 不可叠加，不显示校友卡
  if (priceDetail && priceDetail.couponBatch && verifyCoupon) {
    if (!priceDetail.couponBatch.usedWithVipDiscount) {
      return null;
    }
  }
  // 参与会员折扣的条件 => 当前学员拥有校友卡 && 校友卡有等级 && 校友卡等级信息的key && 课程参与了会员折扣
  if (studentInfo && studentInfo.userVip && studentInfo.userVip.levelId && studentInfo.userVip.usedVipKey && joinVipDiscount) {
    const userVip = studentInfo.userVip;
    const usedVipKey = userVip.usedVipKey; // 校友卡等级信息的key
    const levelInfo = userVip[usedVipKey]; // 校友卡等级信息
    const levelId = levelInfo.id;
    return {
      id: levelId, // 校友卡ID
      miniCard: vipList[levelId][0], // 校友卡小卡片
      text:
        usedVipKey === 'autoLevelInfo'
          ? `本课程不支持VIP 0元购课，只享原校友卡${showLimitedTime ? '折上' : ''}${levelInfo.discount}折优惠`
          : [7, 8, 19].includes(levelId)
          ? vipList[levelId][1]
          : vipList[levelId][1].replace('x', `${showLimitedTime ? '折上' : ''}${levelInfo.discount}`), // 校友卡优惠文案
      textColor: vipList[levelId][2],
      icon: vipList[levelId][3], // 卡片icon
      shortName: vipShortName[levelId], // 校友卡名称缩写
      discountText: [7, 8, 19].includes(levelId) ? '0折优惠' : `${showLimitedTime ? '折上' : ''}${levelInfo.discount}折`, // 限时折扣叠加校友卡折扣
      border: vipList[levelId][4], // 边框样式
      color: vipList[levelId][5], // 字体样式
    };
  } else {
    return null;
  }
}
