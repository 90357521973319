const lesson = {
  namespaced: true,
  state: () => ({
    lessonsDots: {},
  }),
  getters: {
    lessonsDots: state => state.lessonsDots,
  },
  mutations: {
    SET_LESSON_DOT_NOTES: (state, { lessonId, dots }) => {
      const data = {};
      data[lessonId] = dots;
      state.lessonsDots = Object.assign({}, state.lessonsDots, data);
      console.log('lessonsDots', state.lessonsDots);
    },
  },
  actions: {
    setLessonDots({ commit }, { lessonId, dots }) {
      commit('SET_LESSON_DOT_NOTES', { lessonId, dots });
    },
  },
};

export default lesson;
