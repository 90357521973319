export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "help_center" */ '@/pc/views/help-center/index.vue'),
    children: [
      {
        path: '/help-center',
        name: 'help-center',
        meta: {
          isPC: true,
          activeMenuName: 'help-center',
          title: '客服中心',
        },
        component: () => import(/* webpackChunkName: "help_center" */ '@/pc/views/help-center/help-center.vue'),
      },
      // {
      //   path: ':id/detail',
      //   name: 'help-center-detail',
      //   meta: {
      //     isPC: true,
      //     hideFooter: true,
      //     title: '客服中心',
      //   },
      //   component: () => import(/* webpackChunkName: "help_center" */ '@/pc/views/help-center/detail/index.vue'),
      // },
    ],
  },
]