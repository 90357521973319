





import { Vue, Component } from 'vue-property-decorator';
import './activity-main.scss';

@Component
export default class Activity extends Vue {
  isSdkReady: boolean = false; // 七牛是否加载成功
  created() {
    // @ts-ignore
    window.ysf('onready', () => {
      this.isSdkReady = true;
    });
  }
}
