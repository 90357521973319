<template>
  <div :class="['home-course-list', cardType === 'alone' ? '' : 'col-xs-3 col-sm-3']">
    <div class="course-item bt-course-card" v-if="typeof course === 'object' && isLoad" @click="$emit('sensors', course.id)">
      <router-link :to="{ name: 'course-entry', params: { id: course.id }, query: query }" target="_blank">
        <div class="course-img">
          <!-- 首页课程类型列表 在这里加周年标签 -->
          <img v-if="$root.isAnniversary && $root.anniversaryImg" class="tag-anniversary-item" :src="$root.anniversaryImg" v-default-img-alt="'七周年'" />
          <img v-else-if="course && course.newTagStatus === 'opened'" class="tag-new" src="@assets/images/course/new-course.png" v-default-img-alt="course.title" />
          <div class="learn-status" v-if="course && course.isLiving">
            <img class="lottie" src="@assets/images/course/living.gif" height="12" width="12" v-default-img-alt="course.title + '直播中'" />
            <span class="status">直播中</span>
          </div>
          <img :src="coverPicture | transWebp" v-default-img:course class="img-responsive" v-default-img-alt="course.title" />
        </div>
        <!-- 直播间卡片 - isLiveCard -->
        <div class="course-img-live" v-if="config && config.isLiveCard">
          <div class="seq" :class="config.isExplaining && config.isShowIcon ? 'is-explaining' : ''">
            {{ config.seq }}
            <template v-if="config.isExplaining && config.isShowIcon"> 讲解中 <img src="https://tmp.btclass.cn/yunyin/o_1ha3hja2t6c51v4uaf2bt216l2a.gif" /></template>
          </div>
          <div class="hot-num" v-if="config.onSaleNum && config.isOnSale && config.isShowIcon">
            <img src="https://tmp.btclass.cn/yunyin/o_1ha3i8ptc1rv516gj1biucn148tm.gif" />
            <span class="dot">x</span><digital-com :num="config.onSaleNum" :interval="300" :key="config.courseDetail.id" :itemHeight="24"></digital-com>
          </div>
          <img :src="coverPicture | transWebp" v-default-img:course class="img-responsive" v-default-img-alt="course.title" />
        </div>
        <div class="course-info">
          <div class="metas clearfix bt-course-card-metas">
            <!-- 课程标题 -->
            <div class="course-title" v-if="course.title">{{ course.title }}</div>
            <!-- 授课老师及学员人数 -->
            <!-- <div class="course-person">
              <span class="teacher pull-left">{{ course.teachers | teacher }}</span>
              <span class="num pull-right"> <i class="es-icon es-icon-people icon-person"></i> {{ course.studentNum | person }} </span>
            </div> -->
            <!-- 课程卡片-价格展示区域 -->
            <div class="bt-course-card-footer">
              <div class="n-flex-row">
                <!--  砍价活动的展示  -->
                <template v-if="course.bargainPriceInfo && course.bargainPriceInfo.lowestPrice !== -1 && course.bargainPriceInfo.lowestPrice < finalPrice">
                  <img class="pc-kanjia-icon" src="https://app-cdn.btclass.cn/turtle/pc-kanjia-icon.png" alt="" />
                  <div class="bargain-price-ctx">
                    <span class="char">￥</span>
                    <span class="integer-text">{{ integerFilter(course.bargainPriceInfo.lowestPrice) }}</span>
                    <span v-if="hasDecimal(course.bargainPriceInfo.lowestPrice)" class="decimal-text">.{{ hasDecimal(course.bargainPriceInfo.lowestPrice) }}</span>
                  </div>
                </template>
                <template v-else>
                  <div class="price-ctx">
                    <!-- 非限时免费且参与校友卡折扣 -->
                    <span v-if="showVipShortName" class="coupon-after">到手</span>
                    <!-- 5年 || 永久vip || 五年钻石卡 显示【折后】 -->
                    <span v-else-if="vipHideCoupon" class="coupon-after">折后</span>
                    <!-- 有优惠券且非限时免费 || 限时折扣 显示【券后/折后】 -->
                    <span v-else-if="showAfter" class="coupon-after">{{ (showCoupon || showRedEnvelope) ? '到手' : '折后' }}</span>
                    <!-- 金额符号 -->
                    <span class="char">￥</span>
                    <!-- 到手最低价格 -->
                    <span class="integer-text">{{ integerFilter(finalPrice) }}</span>
                    <span v-if="hasDecimal(finalPrice)" class="decimal-text">.{{ hasDecimal(finalPrice) }}</span>
                    <!-- 优惠后如果存在价格区间，显示【起】 -->
                    <span v-if="showQi" class="limited-free">起</span>
                    <!-- 限时免费活动+非五年/永久VIP或者钻石卡 显示【限时免费】 -->
                    <span v-if="showLimitedFree" class="limited-free">限时免费</span>
                  </div>
                </template>
                <div v-if="course.studentNum" class="student-num">{{ course.studentNum | person }}人在学</div>
              </div>
              <!-- 大前提: 参与校友卡折扣/ 参与限时折扣 / 使用优惠券 -->
              <template v-if="!!showVip || showLimitedTime || !!showCoupon || !!showRedEnvelope">
                <!-- 限时免费+五年永久vip/ 非砍价+非限时免费 显示优惠信息 -->
                <div class="add-discount" v-if="(vipHideCoupon && showLimitedFree) || (!isBargain && !showLimitedFree)">
                  <!-- 校友卡信息 -->
                  <div v-if="!!showVip" class="student-vip">
                    <div class="student-vip-card" :style="{color: showVip.color, border: `1px solid ${showVip.border}`}">
                      <img class="vip-icon" :src="showVip.icon" />
                      <div class="vip-icon-absolute">
                        <img :src="showVip.icon" />
                      </div>
                      <div class="vip-text">{{ showVip.discountText }}</div>
                    </div>
                  </div>
                  <!-- 非五年永久或钻石VIP 显示折扣/优惠券信息 -->
                  <template v-if="!vipHideCoupon">
                    <div v-if="showLimitedTime" class="limited-time">限时{{ course.discount }}折</div>
                    <div v-if="!!showCoupon" class="coupon-type">{{ showCoupon === 'minus' ? '满减券' : '折扣券' }}</div>
                  </template>
                </div>
              </template>
            </div>
            <!-- <div class="course-prices is-vip" v-if="$login() && course.joinVipDiscount">
              <img
                class="member-role"
                :src="`${course.studentInfo.userVip[course.studentInfo.userVip.usedVipKey].rightAngleIcon}.svg`"
                v-if="course && course.studentInfo && course.studentInfo.userVip && course.studentInfo.userVip.usedVipKey"
                v-default-img-alt="course.title"
              />
              <div class="is-vip-price">
                <span class="course-price">¥{{ finalPrice }}</span> <span class="course-origin-price del" v-if="originPrice">¥{{ originPrice }}</span>
              </div>
            </div>
            <div class="course-prices" v-else-if="course.discount < 10">
              <span class="course-price ml0">
                <span class="sales-zk">折扣价：</span> <span>¥{{ finalPrice }}</span>
              </span>
              <span class="course-origin-price del" v-if="originPrice">¥{{ originPrice }}</span>
            </div>
            <div class="course-prices" v-else>
              <span class="course-origin-price">¥{{ finalPrice }}</span>
            </div> -->
          </div>
        </div>
      </router-link>
    </div>

    <div class="course-item loading" v-else></div>
  </div>
</template>

<script>
import { getShowVip } from '@/utils/course-helper';
import DigitalCom from '@/pc/components/digital/index.vue';

export default {
  props: {
    course: [Object, Number],
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    salesCode: {
      type: String,
      default() {
        return '';
      },
    },
    isLoad: {
      type: Boolean,
      default() {
        return true;
      },
    },
    cardType: {
      type: String,
      default() {
        return 'default'
      }
    }
  },

  components: {
    // VipPicture
    DigitalCom
  },
  computed: {
    query() {
      return this.salesCode ? { salesCode: this.salesCode, isJumpPaymentPage: 0 } : {};
    },
    // finalPrice() {
    //   let buyingInfo = this.course.buyingInfo;
    //   if (buyingInfo && buyingInfo.finalIntervalPrice[0]) {
    //     return buyingInfo.finalIntervalPrice[0];
    //   }

    //   return buyingInfo.finalPrice;
    // },
    originPrice() {
      let buyingInfo = this.course.buyingInfo;
      if (buyingInfo && buyingInfo.originIntervalPrice[0]) {
        return buyingInfo.originIntervalPrice[0];
      }
      return this.course.originPrice;
    },
    coverPicture() {
      return (this.course && this.course.largePicture) || '';
    },
    isAnniversary: function () {
      // 是否周年庆活动
      return this.$root.isAnniversary;
    },
    /*
      判断当前课程是否支持校友卡折扣
      ·课程可用时享有「校友卡」和「限时折扣」，此时标签文案为：折上X折
      ·课程不享有「限时折扣」，但享有「校友卡」优惠，此时标签文案为：X折
    */
    showVip() {
      return getShowVip(this.course);
    },
    // 5年 || 永久vip || 五年钻石卡 不显示优惠信息
    vipHideCoupon() {
      if (!this.showVip) return false;
      return [7, 8, 19].includes(this.showVip.id);
    },
    /* 
      显示限时打折
      课程同时参与了限时打折（免费）、和限时打折（折扣），则仅展示限时免费
      仅参与了限时打折（折扣），展示对应折扣
    */
    showLimitedTime() {
      return this.course.discount < 10 && this.course.discount !== 0;
    },
    // 显示券后折后(有优惠券且非限时免费 || 限时折扣)
    showAfter() {
      return (!!this.showRedEnvelope && !this.showLimitedFree) || (!!this.showCoupon && !this.showLimitedFree) || this.showLimitedTime;
    },
    /* 
      可用优惠券由后端priceDetail.couponBatch提供
      若券为「满减券」，显示的标签为「 ¥ XX满减券」
      若券为「折扣券」，显示的标签为「 X折满减券」
      显示优惠券 minus为满减，discount为打折
    */
    showCoupon() {
      const { priceDetail } = this.course;
      if (priceDetail && priceDetail.couponBatch) {
        return priceDetail.couponBatch.type;
      }
      return false;
    },
    /* 
      可用红包由后端priceDetail.redEnvelopeBatch提供
    */
    showRedEnvelope() {
      const { priceDetail } = this.course;
      if (priceDetail && priceDetail.redEnvelopeBatch) {
        return priceDetail.redEnvelopeBatch.type;
      }
      return false;
    },
    // 最优惠价格 = 到手价 - 现金红包后的价格
    finalPrice() {
      if (!this.course.buyingInfo) return this.course.price;
      let buyingInfo = this.course.buyingInfo;
      let subjectType = this.course.subjectType;
      // subjectType = 0普通课，1专题课，2组合课
      if (subjectType !== 0) {
        // 专题或组合课程
        // return buyingInfo.minIntervalPriceAfterCoupon[0]; // 专题组合拿区间的最低价
        return buyingInfo.minIntervalPriceAfterRedEnvelope[0]; // 专题组合拿使用红包后区间的最低价
      }
      // return buyingInfo.minPriceAfterCoupon; // 普通课拿券后价
      return buyingInfo.minPriceAfterRedEnvelope; // 普通课拿使用红包后价
    },
    // 限时免费，打0折
    showLimitedFree() {
      return this.course.discount === 0 && !this.vipHideCoupon;
    },
    // 是否砍价活动
    isBargain() {
      const { course, finalPrice } = this;
      return course.bargainPriceInfo && course.bargainPriceInfo.lowestPrice !== -1 && course.bargainPriceInfo.lowestPrice < finalPrice;
    },
    // 判断finalPrice是否有范围值，可以用来界定是否要展示起字
    finalPriceIsEquals() {
      const { buyingInfo } = this.course || {};
      if (!buyingInfo) return true;
      return buyingInfo.finalIntervalPrice[0] === buyingInfo.finalIntervalPrice[1];
    },
    // 是否显示"起"字(打折后的价格区间是否相等)
    showQi() {
      return !this.finalPriceIsEquals && !this.vipHideCoupon && this.course.discount !== 0;
    },
    showVipShortName () {
      const showInfo = (this.vipHideCoupon && this.showLimitedFree) || (!this.isBargain && !this.showLimitedFree);
      return showInfo && this.showVip;
    }
  },
  filters: {
    person(num) {
      if (num >= 1000) {
        return '999+';
      }
      return num;
    },
    teacher(arr) {
      if (arr && arr.length) {
        let teachers = arr.filter(e => e.name && e.name !== '');

        if (teachers && teachers.length) {
          let name = teachers[0].name;

          if (name.indexOf('老师') > -1) {
            return `${name}`;
          }
          return `${name}老师`;
        }
        return '';
      } else {
        return '';
      }
    },
  },
  methods: {
    // 取价格整数部分
    integerFilter(val) {
      return (val + '').split('.')[0];
    },
    // 取价格小数部分
    hasDecimal(val) {
      return (val + '').split('.')[1];
    },
  },
};
</script>

<style lang="scss" scoped>
@import './CourseList.scss';

.bt-course-card-footer {
  // border: 1px solid red;
}

.icon-person {
  margin-right: 2px;
  color: #8a9499;
  opacity: 0.6;
}

.teacher {
  height: 16px;
  line-height: 16px;
}

.learn-status {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #ff5151;
  top: 4px;
  right: 4px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0 4px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  border-radius: 2px;
  .lottie {
    margin-right: 2px;
    margin-bottom: 1px;
  }
}
.tag-new {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  width: 41px;
  height: auto;
}
.course-person {
  border: 1px solid red;
}
.vip-icon-absolute {
  top: -1px !important;
  left:  -1px !important;
  bottom:  -1px !important;
  height: calc(100% + 2px) !important;
  img {
    height: 100%;
    display: block;
  }
}
.course-img-live {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  .seq {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 6px;
    height: 18px;
    background: rgba(0, 46, 122, 0.55);
    border-radius: .078125rem 0px .078125rem 0px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    &.is-explaining {
      background: linear-gradient(314deg, rgba(255, 155, 155, 0.58) 0%, #ff6262 100%);
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .hot-num {
    padding-top: 10px;
    background-image: url('https://tmp.btclass.cn/yunyin/o_1hajri0mp1gim9sr1a5e10os14g2a.png');
    background-size: cover;
    width: 100%;
    height: 49px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    font-family: JinBuTi;
    .dot {
      font-size: 14px;
    }
    ::v-deep {
      .ani-content {
        font-size: 18px;
      }
    }
    img {
      width: 72px;
      height: 30px;
      margin-right: 3px;
    }
  }
}
</style>
