


































import Vue from 'vue';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import { myBookErrataFeedbacks, showBookErrata } from '@/api/book-errata';
import MainLoading from '@/pc/components/loading/main-loading.vue';
import TabsErrataList from '../components/tabs-errata-list.vue';
import ItemErrataInfo from '../components/item-errata-info.vue';
import ErrataDrawer from '../components/errata-drawer.vue';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {
    MainLoading,
    TabsErrataList,
    ItemErrataInfo,
    ErrataDrawer,
  },
})
export default class bookErrataMy extends Vue {
  async beforeRouteEnter(to: any, from: any, next: any) {
    console.log('from', from.name);
    next(vm => {
      vm.routerFromName = from.name;
    });
  }
  routerFromName: string = '';

  initLoading: boolean = true; // 全局loading

  tabName: any = ''; // 选择的模块名称
  dealType: any = ''; // 选择的模块名称

  // 列表
  listLoading: boolean = true;
  params: any = {
    page: 0,
    limit: 0,
    needCount: 1,
  };
  errataList: any = [];
  count: number = 0;

  // 详情
  infoLoading: boolean = true;
  errataId: number = 0;
  errataInfo: any = {};

  addVisible: boolean = false;

  async created() {
    const dealStatusMap: any = {
      1: 'waitHandle',
      2: 'isHandle',
    };
    const { dealType, errataId } = this.$route.query;
    let newDealType: any = dealType;
    try {
      // 移动端跳过来的
      if (dealType === 'undefined' && errataId) {
        const info = await showBookErrata({ errataId });
        newDealType = dealStatusMap[info.dealStatus];
      }
    } catch (e) {
      // 不属于自己的反馈，则抛错
      e.hideNormalFail && e.hideNormalFail();
    } finally {
      // 优先级：路由的tab > 默认选择【按页码查看】
      this.tabName = newDealType || 'isHandle';
      this.dealType = this.tabName;
      await this.updateList();
    }
  }

  // 切换查看模式时，要先重置一下数据，避免路由携带的参数产生影响
  async changeTabType(tab) {
    // 不响应重复点击
    if (this.dealType === tab.name) return;
    // 重置一下数据
    this.errataId = 0;
    this.errataInfo = {};
    this.infoLoading = true;
    // 更新路由
    this.$router.replace({ query: { dealType: tab.name } });
    // 切换tab
    this.dealType = tab.name;
    await this.updateList();
  }

  // 更新列表
  async updateList() {
    try {
      this.listLoading = true;
      this.params.page = 0;
      this.errataList = [];
      const { count, rows } = await myBookErrataFeedbacks({
        ...this.params,
        dealStatus: this.dealType !== 'isHandle' ? 1 : 2,
      });
      this.errataList = rows || [];
      this.count = count || 0;
      console.log('我的修订列表', count, rows);
    } catch (e) {
      console.log('获取我的修订列表失败', e);
    } finally {
      if (this.$route.query.errataId) {
        // 路由有传值，优先用路由的
        this.errataId = Number(this.$route.query.errataId);
      } else if (!this.errataList.length) {
        // 没有数据，显示兜底页
        this.errataInfo = {};
        this.infoLoading = false;
      } else {
        // 有数据，默认加载优先级：路由 > 默认第一个
        this.errataId = Number(this.errataList[0].id);
      }
      this.listLoading = false;
    }
  }

  @Watch('errataId')
  watchErrataId() {
    if (!this.errataId) return;
    this.getDetails(this.errataId);
  }

  // 获取修订详情
  async getDetails(errataId) {
    try {
      this.infoLoading = true;
      const info = await showBookErrata({ errataId });
      this.errataInfo = info || {};
      this.$router.replace({
        query: {
          dealType: this.dealType,
          errataId,
        },
      });
      console.log('修订详情', this.dealType, errataId, info);
    } catch (e) {
      if (e.code === 1033) {
        // 不属于自己的修订，默认选择第一个
        e.hideNormalFail && e.hideNormalFail();
        if (!this.errataList.length) {
          this.errataInfo = {};
          this.infoLoading = false;
        }
      }
      console.log('获取修订详情失败', e);
    } finally {
      this.infoLoading = false;
    }
  }

  // 新增修订反馈
  addNewErrata() {
    this.addVisible = true;
  }

  // 返回上一页
  goBack() {
    if (this.routerFromName !== 'book-errata-list') {
      this.$router.replace({ name: 'book-errata-list' });
      return;
    }
    this.$router.go(-1);
  }

  // 新建成功
  async addSuccess() {
    // 重置一下数据
    this.errataId = 0;
    this.errataInfo = {};
    this.infoLoading = true;
    // 更新路由
    this.$router.replace({ query: { dealType: 'waitHandle' } });
    // 切换tab
    this.tabName = 'waitHandle';
    this.dealType = 'waitHandle';
    await this.updateList();
  }
}
