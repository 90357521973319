

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { trackEvent } from '@/utils/helper';

@Component
export default class LowPrice extends Vue {
  @Prop({ type: Object, default: () => ({}) }) data;
  activeIndex: number | null = null;

  mouseenter(index) {
    this.activeIndex = index;
  }
  mouseleave() {
    this.activeIndex = null;
  }
  showQiyu() {
    this.$emit('ysf');
    trackEvent('bt_10654', { description: '全平台同价-咨询客服' });
  }
  goChoseCourse() {
    window.open(this.data.link || '/course/explore');
    trackEvent('bt_10655', { description: '全平台同价-一键去官网购买' });
  }
}
