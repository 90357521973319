






























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import UploadItem from './upload-item.vue';

@Component({
  components: {
    UploadItem,
  },
})
export default class modulesUploadImgs extends Vue {
  @PropSync('images', { type: Array, default: () => [] }) syncImages: any;
  @Prop({ type: Number, default: 1 }) uploadLimit;
  @Prop({ type: Boolean, default: false }) isRect;
  @Prop({ type: Number, default: 140 }) width;
  @Prop({ type: Number, default: 140 }) height;

  // 删除图片
  handleRemove(removeUrl) {
    const delIndex = this.syncImages.findIndex(url => url == removeUrl);
    if (delIndex !== -1) {
      this.syncImages.splice(delIndex, 1);
    }
  }
}
