


































import { Vue, Component, Prop } from 'vue-property-decorator';
import LiveLessonItem from './live-lesson-item-raw.vue';
import CurLessons from './cur-lessons-raw.vue';
import SuccessDialog from '../success-dialog.vue';
import BindMobile from '../../activity-bind-mobile-dialog.vue';
import { getLessonByLessonIdOrCourseId } from '@/api/course';
import { getPageData } from '@/api/activity';
import '../index.scss';

@Component({
  components: {
    LiveLessonItem,
    CurLessons,
    SuccessDialog,
    BindMobile,
  },
})
export default class LiveCom extends Vue {
  @Prop() contentData!: any;

  dataIsReady: boolean = false;
  isReserveSuccess: boolean = false;
  isBindDialogVisible: boolean = false;
  editLesson: any = null;
  lessons: any[] = [];
  curLessons: any[] = [];
  swiperOption: any = {
    navigation: {
      nextEl: '.swiper-right-btn',
      prevEl: '.swiper-left-btn',
    },
    loop: false,
    grabCursor: true,
    spaceBetween: 0,
    initialSlide: 0,
    direction: 'horizontal',
    slidesPerView: 'auto',
  };
  // 今日直播轮播图配置
  curSwiperOption: any = {
    navigation: {
      nextEl: '.cur-swiper-right-btn',
      prevEl: '.cur-swiper-left-btn',
    },
    loop: false,
    grabCursor: true,
    spaceBetween: 20,
    initialSlide: 0,
    direction: 'horizontal',
    slidesPerView: 1,
  };

  mounted() {
    this.getLessonData();
  }

  isToday(time) {
    let td = new Date();
    td = new Date(td.getFullYear(), td.getMonth(), td.getDate());
    time = time.toString().length === 10 ? time * 1000 : time;
    let od = new Date(time);
    od = new Date(od.getFullYear(), od.getMonth(), od.getDate());
    let xc = Math.abs((Number(od) - Number(td)) / 1000 / 60 / 60 / 24);
    return xc === 0;
  }

  async getLessonData() {
    try {
      this.dataIsReady = true;
      const itemLessons = this.contentData.content.courseLessons || [];
      // 对配置数据进行过滤兼容处理
      if (itemLessons.length) {
        const courseIdOrLessonIdAndOrders = itemLessons.map((item, index) => ({
          courseId: item.courseId,
          lessonId: item.id,
          orderNumber: index,
        }));
        const params = {
          courseIdOrLessonIdAndOrders: courseIdOrLessonIdAndOrders,
        };
        const lessons = (await getLessonByLessonIdOrCourseId(params)).map(item => Object.assign(item.course, { orderNumber: item.orderNumber, originData: itemLessons[item.orderNumber] }));
        this.lessons = lessons;
        this.curLessons = lessons.filter(item => this.isToday(item.lesson.startTime));
        this.$forceUpdate();
      }
    } catch (e) {
      console.log('获取直播失败');
    } finally {
      this.dataIsReady = true;
    }
  }

  // 绑定手机号结束后自动调用预约逻辑
  closeHandle() {
    if (!this.editLesson) return;
    (this.$refs[`refLesson${this.editLesson.id}`] as any)[0].sendReserveRequest();
  }
}
