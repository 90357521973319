// 圈子相关接口
import { request } from '@/utils/axios';
import { Pagination } from '@/typings';

// 获取用户是否进过校友圈 isEnteredAlumniCircle 根据这个返回值判断
export function getUserMine() {
  return request.get('/v2/user/mine', {}, { requestBase: 'mojave' });
}

// 获取展示的频道列表
export function getCircleChannelList() {
  return request.get('/v2/circle-channel/show-list', {}, { requestBase: 'user' });
}

// 获取更多圈子列表
export function getMoreCircleList(params: Pagination) {
  return request.get('/v2/circle/more', params, { requestBase: 'user' });
}

// 获取频道动态列表
export function getChannelDynamicList(channelId: number, params: Pagination) {
  return request.get(`/v2/circle-channel/${channelId}/dynamics`, params, { requestBase: 'user' });
}

// 获取圈子启动页列表
export function getStartPageCircle() {
  return request.get('/v2/circle/start-page', {}, { requestBase: 'user' });
}

// 加入圈子
export function joinCircle(circleId: number) {
  return request.post(`/v2/circle/${circleId}/enter`, {}, { requestBase: 'user' });
}

// 退出圈子
export function quitCircle(circleId: number) {
  return request.post(`/v2/circle/${circleId}/exit`, {}, { requestBase: 'user' });
}

// 获取用户主页详情
export function getUserHomePage(userId: number) {
  return request.get(`/v2/user/${userId}/homepage`, {}, { requestBase: 'user' });
}

// 关注用户
export function focusUsre(params: { focusType: string; targetId: number }) {
  return request.post('/v2/users/focus', params, { requestBase: 'user' });
}

// 取消用户关注
export function cancleFocusUser(params: { focusType: string; targetId: number }) {
  return request.post('/v2/users/cancle-focus', params, { requestBase: 'user' });
}
// 点赞圈子动态
export function likeCircleDynamic(dynamicId: number) {
  return request.post(`/v2/circle-dynamic/${dynamicId}/like`, {}, { requestBase: 'user' });
}

// 取消圈子动态点赞
export function cancelLikeCircleDynamic(dynamicId: number) {
  return request.post(`/v2/circle-dynamic/${dynamicId}/cancel-like`, {}, { requestBase: 'user' });
}

// 统计动态分享
export function countShare(dynamicId: number) {
  return request.post(`/v2/circle-dynamic/${dynamicId}/share`, {}, { requestBase: 'user' });
}

// 删除圈子动态
export function deleteCircleDynamic(dynamicId: number) {
  return request.delete(`/v2/circle-dynamic/${dynamicId}`, {}, { requestBase: 'user' });
}

// 新增圈子动态评论
export function addCircleDynamicComment(params: { dynamicId: number; content: string }) {
  return request.post('/v2/circle-dynamic/comment', params, { requestBase: 'user' });
}

// 获取动态热门评论数据
export function getDynamicHotComment(dynamicId: number) {
  return request.get(`/v2/circle-dynamic/${dynamicId}/hot-comment`, {}, { requestBase: 'user' });
}

// 获取圈子动态评论列表
export function getCircleCommentList(params: { page: number; limit: number; needCount?: number; dynamicId: number }) {
  return request.get('/v2/circle-dynamic/comment', params, { requestBase: 'user' });
}

// 点赞圈子动态动态评论
export function likeCircleDynamicComment(commentId: number) {
  return request.post(`/v2/circle-dynamic/comment/${commentId}/like`, {}, { requestBase: 'user' });
}

// 取消点赞圈子动态评论
export function cancelLikeCircleDynamicComment(commentId: number) {
  return request.post(`/v2/circle-dynamic/comment/${commentId}/cancel-like`, {}, { requestBase: 'user' });
}

// 删除圈子动态评论
export function deleteCircleDynamicComment(commentId: number) {
  return request.delete(`/v2/circle-dynamic/comment/${commentId}`, {}, { requestBase: 'user' });
}

// 获取圈子动态评论的回复列表
export function getCommentReplyList(commentId: number, params: Pagination) {
  return request.get(`/v2/circle-dynamic/comment/${commentId}/reply`, params, { requestBase: 'user' });
}

//新增圈子动态评论回复。回复的回复
export function addCircleDynamicCommentReply(commentId: number, params: { content: string }) {
  return request.post(`/v2/circle-dynamic/comment/${commentId}/reply`, params, { requestBase: 'user' });
}

// 点赞圈子动态评论回复
export function likeCircleDynamicCommentReply(replyId: number) {
  return request.post(`/v2/circle-dynamic/comment/reply/${replyId}/like`, {}, { requestBase: 'user' });
}

// 取消点赞动态评论回复
export function cancelLikeCircleDynamicCommentReply(replyId: number) {
  return request.post(`/v2/circle-dynamic/comment/reply/${replyId}/cancel-like`, {}, { requestBase: 'user' });
}

// 删除圈子动态评论回复
export function deleteCircleDynamicCommentReply(replyId: number) {
  return request.delete(`/v2/circle-dynamic/comment/reply/${replyId}`, {}, { requestBase: 'user' });
}

/**
 * 获取圈子动态详情
 * @param dynamicId
 * @param params 列表关键词, model: 'type:data', 用于详情页获取上下篇动态。type: channel-频道动态列表、circle-圈子动态列表、user-用户动态列表
 * @returns
 */
export function getCircleDynamicDetail(dynamicId: number, params: { isPreview?: 0 | 1; listKey?: string; seq?: number }) {
  return request.get(`/v2/circle-dynamic/${dynamicId}`, params, { requestBase: 'user' });
}

// 获取圈子详情
export function getCircleDetail(circleId: number) {
  return request.get(`/v2/circle/${circleId}/detail`, {}, { requestBase: 'user' });
}

// 获取圈子详情动态列表
export function getCircleDynamicList(circleId: number, params: Pagination) {
  return request.get(`/v2/circle/${circleId}/detail-dynamic`, params, { requestBase: 'user' });
}

// 获取动态详情页动态推荐列表
export function getDynamicRecommendList(dynamicId: number) {
  return request.get(`/v2/circle-dynamic/${dynamicId}/recommend`, {}, { requestBase: 'user' });
}

// 获取频道详情
export function getChannelDetail(channelId: number) {
  return request.get(`/v2/circle-channel/${channelId}`, {}, { requestBase: 'user' });
}

// 创建圈子动态 discussion-讨论、longArticle-长文
export function createCircleDynamic(params: { content: string; pictures?: string[]; circleIds?: number[]; dynamicType: string }) {
  return request.post('/v2/circle-dynamic', params, { requestBase: 'user' });
}

// 获取圈子相关投诉标签列表
export function getComplaintTagList() {
  return request.get('/v2/circle-complaint/tag/list', {}, { requestBase: 'user' });
}

// 新增圈子投诉
export function addComplaint(params: { complaintType: 'dynamic' | 'comment' | 'reply'; targetId: number; complaintTagId: number }) {
  return request.post('/v2/circle-complaint', params, { requestBase: 'user' });
}

// 获取用户投诉标签列表
export function getUserComplaintTagList() {
  return request.get('/v2/user/complaint/tag', {}, { requestBase: 'user' });
}

// 新增用户投诉
export function addUserComplaint(params: { complaintType: 'user' | 'groupChat' | 'privateMessage' | 'tutor'; targetId: number; complaintTagId: number; des: string; pictures?: string[] }) {
  return request.post('/v2/user/complaint', params, { requestBase: 'user' });
}

// 获取圈子名师列表
export function getCircleFamousTeacherList(nowUserId) {
  return request.get('/v2/circle/famous-teacher', { nowUserId }, { requestBase: 'user' });
}

// 获取用户粉丝列表
export function getUserFans(params) {
  return request.get('/v2/users/fans', params, { requestBase: 'user' });
}

// 获取用户关注用户列表
export function getUserFocusUserList(params) {
  return request.get('/v2/users/focus', params, { requestBase: 'user' });
}

// 获取用户关注的圈子列表
export function getUserFocusCircleList(params) {
  return request.get('/v2/users/focus-circle', params, { requestBase: 'user' });
}

// 获取目标用户的动态列表
export function getTargetUserDynamicList(userId: number, params) {
  return request.get(`/v2/user/${userId}/dynamic`, params, { requestBase: 'user' });
}

// 进入校友圈
export function enterAluminCircle() {
  return request.post('/v2/circle/enter-alumni-circle', {}, { requestBase: 'user' });
}

// 获取热门动态列表
export function getHotDyanmicList() {
  return request.get('/v2/circle-dynamic/hot', {}, { requestBase: 'user' })
}

// 获取更多推荐圈子列表 
export function getMoreRecommendCircle() {
  return request.get('/v2/circle/more-recommend', {}, { requestBase: 'user' })
}