import { request } from '@/utils/axios';

//创建小组
export function createGroup(data) {
  return request.post('/group', data, { requestBase: 'phoenix' });
}
//热门小组
export function getGroupPopular(data) {
  return request.get('/group/popular', data, { requestBase: 'phoenix' });
}
//新晋小组
export function getGroupNew() {
  return request.get('/group/new', {}, { requestBase: 'phoenix' });
}
//搜索小组
export function searchGroup(data) {
  return request.get('/group/search', data, { requestBase: 'phoenix' });
}
//小组详情
export function getGroupDetail(id) {
  return request.get(`/group/show/${id}`, {}, { requestBase: 'phoenix' });
}
//我的小组
export function getMyGroup() {
  return request.get('/group/my', {}, { requestBase: 'phoenix' });
}
//加入小组
export function addGroup(id) {
  return request.post(`/group/${id}/join`, {}, { requestBase: 'phoenix' });
}
//退出小组
export function quitGroup(id) {
  return request.delete(`/group/${id}/quit`, {}, { requestBase: 'phoenix' });
}
//新加组员
export function getRecentlyMember(id) {
  return request.get(`/group/${id}/recently-join-member`, {}, { requestBase: 'phoenix' });
}
//小组成员列表
export function getGroupMember(data) {
  return request.get(`/group/${data.id}/members`, data, { requestBase: 'phoenix' });
}
//编辑小组
export function editGroup(data) {
  return request.patch(`/group/${data.id}`, data, { requestBase: 'phoenix' });
}
//设置副组长
export function setAdmin(data) {
  return request.patch(`/group/${data.id}`, data.memberIds, { requestBase: 'phoenix' });
}
//撤销副组长
export function cancelAdmin(data) {
  return request.patch(`/group/${data.groupId}/cancel-admin`, data.memberIds, { requestBase: 'phoenix' });
}
//踢出成员
export function deleteMember(data) {
  return request.delete(`/group/${data.groupId}/members`, data.memberIds, { requestBase: 'phoenix' });
}
