<template>
  <div class="home-open-course-model course-list">
    <div class="row clearfix" v-if="Array.isArray(data) && !isLoad">
      <template v-for="(course, index) in data">
        <div class="col-xs-3 col-sm-3 open-course-li" :key="index" v-if="index < limit">
          <div class="recent-time" v-if="isShowRecentLiveTime">最近直播时间</div>
          <div class="course-item">
            <a :href="`/course/${course.id}`" target="_blank">
              <div class="course-img">
                <!-- 首页的免费公开课列表 - 在这里加周年标签 -->
                <img v-if="$root.isAnniversary&&$root.anniversaryImg" class="tag-anniversary-item" :src="$root.anniversaryImg" v-default-img-alt="'七周年'" />
                <img v-else-if="course && course.newTagStatus === 'opened'" class="tag-new" src="@assets/images/course/new-course.png" v-default-img-alt="course.title" />
                <div class="learn-status" v-if="course && course.isLiving">
                  <img class="lottie" src="@assets/images/course/living.gif" height="12" width="12" v-default-img-alt="course.title + '直播中'" />
                  <span class="status">直播中</span>
                </div>
                <img :src="course.largePicture | transWebp" v-default-img:course class="img-responsive" v-default-img-alt="course.title" />
              </div>
              <div class="course-info">
                <div class="metas">
                   <h3  class="left-title" :title="course.title">{{ course.title }}</h3>
                   <div class="num">
                      <i class="es-icon es-icon-removeredeye icon-eye"></i> {{ course.hitNum | person }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </template>
    </div>

    <div class="row" v-else>
      <template v-for="(course, index) in data">
        <div class="col-xs-3 col-sm-3" :key="index" v-if="index < limit"><div class="course-item loading"></div></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: [Array, Number],
    isLoad: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 8,
    },
    isShowRecentLiveTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAnniversary: function () {
      // 是否周年庆活动
      return this.$root.isAnniversary;
    },
  },
  data() {
    let time = Date.parse(new Date()).toString().substr(0, 10);

    return {
      time: time,
      living: ['living', 'pending'],
    };
  },
  filters: {
    person(num) {
      if (num) {
        return `${num}`;
      } else {
        return '0';
      }
    },
    learnType(status) {
      switch (status) {
        case 'living':
          return '正在直播';
        case 'pending':
          return '即将直播';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.learn-status {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #ff5151;
  top: 4px;
  right: 4px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0 4px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  border-radius: 2px;
  .lottie {
    margin-right: 2px;
    margin-bottom: 1px;
  }
}
.tag-new {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  width: 41px;
  height: auto;
}

.icon-eye {
  margin-right: 2px;
  color: #8a9499;
  opacity: 0.6;
}
.recent-time {
  margin-bottom: 24px;
}
</style>
