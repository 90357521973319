import { addTestAnswers } from '@/api/tiku';

const TIKU_ANSWER = 'TIKU_ANSWER';
const QUESTION_FONT_SIZE = 'QUESTION_FONT_SIZE';

const UPDATED = 'UPDATED';
const UPLOADED = 'UPLOADED';

// 过滤出需要本地存储和需要提交的答案
const filterStorageAnswers = ({ storageAnswers, paperResultId, lastDoingQid, usedTime }) => {
  let answers = [];
  let storage = JSON.parse(localStorage.getItem(TIKU_ANSWER) || '{}');
  let model = {
    lastDoingQid,
    usedTime,
  };
  for (let key in storageAnswers[paperResultId]) {
    const { status, answerContent, updatedTime } = storageAnswers[paperResultId][key];
    if (status !== UPLOADED) {
      const { choice, images, tableArr, text } = answerContent;
      const answer = {
        itemId: Number(key),
        choice,
        images,
        tableArr,
        text,
      };
      model[key] = {
        answerContent: answer,
        updatedTime,
      };
      answers.push(answer);
    }
  }
  storage[paperResultId] = model;
  return { answers, storage };
};

const info = {
  namespaced: true,
  state: () => ({
    questionFontSize2: localStorage.getItem(QUESTION_FONT_SIZE) || 18, // 默认中号18
    answerList: [],
    storageAnswers: {}, // 答案持久化数据
    waitAnswers: [], //本地修改后待提交的答案
  }),
  getters: {
    questionFontSize: state => state.questionFontSize2,
    answerList: state => state.answerList,
    waitAnswers: state => state.waitAnswers,
    getHasChangeAnswerCount(state) {
      return function (paperResultId) {
        let storage = state.storageAnswers;
        let model = storage[paperResultId] || {};
        let count = 0;
        for (let key in model) {
          const { status } = model[key];
          if (status !== UPLOADED) {
            count++;
          }
        }
        return count;
      };
    },
  },
  mutations: {
    SET_QUESTION_FONT_SIZE: (state, data) => {
      localStorage.setItem(QUESTION_FONT_SIZE, data);
      state.questionFontSize2 = data;
    },
    SET_ANSWERLIST: (state, data) => {
      state.answerList = data;
    },
    SET_STORAGE_ANSWERS(state, { paperResultId, paperResultDetail }) {
      state.waitAnswers = [];
      // 初始化storageAnswers
      paperResultDetail = JSON.parse(JSON.stringify(paperResultDetail));
      let model = {};
      for (let val of paperResultDetail) {
        const { id, result } = val;
        const { answer } = result;
        const status = UPLOADED;
        const updatedTime = parseInt(Date.now() / 1000);
        const answerContent = answer && Object.keys(answer).length > 0 ? { ...answer } : null;
        model[id] = {
          answerContent,
          updatedTime,
          status,
        };
      }
      state.storageAnswers[paperResultId] = model;
    },
    SET_STORAGE_ANSWER(state, { paperResultId, id, answerContent, lastDoingQid, usedTime }) {
      // 单个答案更改
      answerContent = JSON.parse(JSON.stringify(answerContent));
      const { storageAnswers } = state;
      storageAnswers[paperResultId] = storageAnswers[paperResultId] || {};
      storageAnswers[paperResultId][id] = {
        answerContent,
        updatedTime: parseInt(Date.now() / 1000),
        status: UPDATED,
      };
      const { answers, storage } = filterStorageAnswers({ lastDoingQid, storageAnswers, paperResultId, usedTime });
      if (answers.length === 0) {
        return;
      }
      state.waitAnswers = answers;
      localStorage.setItem(TIKU_ANSWER, JSON.stringify(storage));
    },
    SET_STORAGE_ANSWERS_AFTER_FETCH(state, { paperResultId, status }) {
      // 批量设置答案状态
      let storage = JSON.parse(localStorage.getItem(TIKU_ANSWER) || '{}');
      delete storage[paperResultId];
      localStorage.setItem(TIKU_ANSWER, JSON.stringify(storage));
      const { storageAnswers } = state;
      for (let key in storageAnswers[paperResultId]) {
        storageAnswers[paperResultId][key].status = status;
      }
      delete state.storageAnswers[paperResultId];
    },
  },
  actions: {
    async fetchStorageAnswers({ commit, state }, { paperResultId, lastDoingQid, usedTime }) {
      // 提交答案
      const { storageAnswers } = state;
      const { answers } = filterStorageAnswers({ storageAnswers, paperResultId, lastDoingQid, usedTime });
      const params = {
        answers,
        lastDoingQid,
        usedTime,
        resultId: paperResultId,
      };
      await addTestAnswers(params);
      commit('SET_STORAGE_ANSWERS_AFTER_FETCH', { paperResultId, status: UPLOADED });
    },
    async fetchStorageAnswersOffline({ commit }) {
      // 提交离线答案
      let storage = JSON.parse(localStorage.getItem(TIKU_ANSWER) || '{}');
      let all = [];
      for (let key in storage) {
        let val = storage[key];

        let answers = [],
          lastDoingQid,
          usedTime;
        for (let k in val) {
          let v = val[k];
          if (k !== 'lastDoingQid' && k !== 'usedTime') {
            const { answerContent, updatedTime } = v;
            answers.push({ ...answerContent, itemId: Number(k), updatedTime });
          } else if (k === 'lastDoingQid') {
            lastDoingQid = v;
          } else if (k === 'usedTime') {
            usedTime = v;
          }
        }
        let params = {
          answers,
          lastDoingQid,
          usedTime,
          resultId: Number(key),
        };
        all.push(params);
      }
      for (let i = 0; i < all.length; i++) {
        try {
          await addTestAnswers(all[i]);
          commit('SET_STORAGE_ANSWERS_AFTER_FETCH', { paperResultId: all[i].resultId, status: UPLOADED });
        } catch (err) {
          console.log('答案提交失败！', err);
          if (err.code === 404) {
            // 404找不到时，可能是归档被删，此时清除数据，防止下次继续提交
            commit('SET_STORAGE_ANSWERS_AFTER_FETCH', { paperResultId: all[i].resultId, status: UPLOADED });
          }
        }
      }
    },
  },
};

export default info;
