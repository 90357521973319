import { getBrowser, appTokenBefore } from '@/utils/helper';
import Vue from 'vue';
import { getToken } from '@/api/login';
import { setLoginStorage } from '@/utils/repeat-function';
// 路由拦截重定向
const reloadPathList = [];
const mobileReloadPathList = ['/'];
const ua = navigator.userAgent;
const isMobile = getBrowser(ua).mobile;

export async function beforeEachRouterHook(to, from, next) {
  await appTokenBefore();

  // 判断是否登录
  // eslint-disable-next-line no-undef
  if (to.meta.needLogin && SSR_ENV !== 'server') {
    if (Vue.cookie.get('is_phoenix_login') === 'true') {
      if (!localStorage.getItem('refreshToken')) {
        const res = await getToken();
        setLoginStorage(res);
      }
    } else {
      location.href = `/login?goto=${to.fullPath}`;
      return;
    }
  } else {
    if (Vue.cookie.get('is_phoenix_login') === 'true' && !localStorage.getItem('refreshToken')) {
      const res = await getToken();
      setLoginStorage(res);
    }
  }

  // 某些路径需要重新加载，从后端请求正确的资源
  if (from.name && to.name !== from.name) {
    if (isMobile && mobileReloadPathList.includes(to.path)) {
      location.href = to.fullPath;
      return;
    }
    if (reloadPathList.includes(to.path)) {
      location.href = to.fullPath;
      return;
    }
  }

  next();
}

export function afterEachRouterHook(to) {
  Vue.nextTick(() => {
    // 路由切换后手动触发页面事件上报（根据后端用户信息里面的isAutoUpSc字段）
    if (localStorage.getItem('isAutoUpSc')) {
      Vue.prototype.$sensors && Vue.prototype.$sensors.quick('autoTrackSinglePage');
    }
  });

  // 解决iOS
  const u = navigator.userAgent.toLowerCase();
  // IOS触发窗口加载并显示指定的URL的内容
  if (u.indexOf('like mac os x') < 0 || u.match(/MicroMessenger/i) !== 'micromessenger') {
    return;
  }
  // @ts-ignore
  if (to.path !== global.location.pathname) {
    location.assign(to.fullPath);
  }
}
