






























































import { Vue, Component } from 'vue-property-decorator';
import ChooseCircleDialog from './choose-circle-dialog.vue';
import UploadImgs from '../upload-img/index.vue';
import { getMoreCircleList, createCircleDynamic } from '@/api/circle';
import { CircleItem } from '@/typings';

@Component({
  components: {
    ChooseCircleDialog,
    UploadImgs,
  },
})
export default class EditDiscussiion extends Vue {
  formData: any = {
    content: '',
    pictures: [],
    circleIds: [],
  };
  circleList: CircleItem[] = [];
  circleMap: any = {};
  isLoading = false;
  rules = {
    content: [{ required: true, validator: this.contentValidator, trigger: 'blur' }],
  };

  contentValidator(rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入正文'));
    } else if ((value + '').length < 4) {
      return callback(new Error('输入正文文字不得少于4个字'));
    } else {
      callback();
    }
  }

  isChooseCircleDialogVisible: boolean = false;

  destroyed() {
    window.onbeforeunload = null;
  }

  mounted() {
    // 离开的拦截操作
    // const that = this;
    // $(window).bind('beforeunload', function (event) {
    //   setTimeout(function () {
    //     /*var retVal = confirm("Do you want to continue ?");
    //             if (retVal == true) {
    //                 alert("User wants to continue!");
    //                 return true;
    //             }
    //             else {
    //                 window.stop();
    //                 return false;
    //             }*/
    //     that
    //       .$confirm('确定返回么?返回后当前页面内容将不会被保存', '', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning',
    //         customClass: 'circle-confirm-dialog',
    //       })
    //       .then(async () => {
    //         window.close();
    //       });
    //   });
    //   return false;
    // });
    this.getMoreCircleListHandle();
  }

  // 获取所有圈子列表
  async getMoreCircleListHandle() {
    try {
      this.isLoading = true;
      const { rows } = await getMoreCircleList({ page: 0, limit: 100 });
      this.circleList = rows;
      this.circleList.forEach(item => {
        this.circleMap[item.id] = item;
      });
    } finally {
      this.isLoading = false;
    }
  }

  circleIdsChange(ids) {
    this.formData.circleIds = ids;
  }

  deleteCircle(index) {
    this.formData.circleIds.splice(index, 1);
  }

  // 选择发布圈子
  addCircleHandle() {
    this.isChooseCircleDialogVisible = true;
  }

  async creatHandle() {
    try {
      await (this.$refs.form as any).validate();
      const params: any = {
        dynamicType: 'discussion',
        ...this.formData,
      };
      if (!this.formData.circleIds.length) delete params['circleIds'];
      if (!this.formData.pictures.length) delete params['pictures'];
      const { id } = await createCircleDynamic(params);
      this.$message.success('发布成功');
      this.$emit('createSuccess', id);
    } catch (e) {
      console.log('创建讨论失败');
    }
  }

  // 取消事件
  cancleHandle() {
    const { content, pictures, circleIds } = this.formData;
    if (content || pictures.length || circleIds.length) {
      this.$confirm('确定返回么?返回后当前页面内容将不会被保存', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'circle-confirm-dialog',
      }).then(async () => {
        this.$router.replace({
          name: 'circle-index',
        });
      });
    } else {
      this.$router.replace({
        name: 'circle-index',
      });
    }
  }
}
