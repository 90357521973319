






































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { getReportActScoreConfig } from '@/api/course';
import scrollWatch from '@/libs/vue-scrollwatch';
import { trackEvent } from '@/utils/helper';
import { getReportActScoreDetail } from '@/api/course'

@Component({
  components: {
    swiper,
    swiperSlide,
  },
})
export default class GradeModule extends Vue {
  @Prop({ type: Object, default: () => ({}) }) rankData;
  dataReady: boolean = false; // 是否加载完数据
  videoList: any[] = []; //视频数据
  rankList: any[] = []; // 排行榜
  isDialogVisible: boolean = false;
  qrDialog: any = {}
  year: number = 2024
  projectName: string = '中级会计'

  // 左边排行榜swiper配置
  rankSwiperOption: any = {
    loop: true,
    autoplay: {
      delay: 2000,
      // stopOnLastSlide: false,
    },
    mousewheel: true,
    slidesPerView: 15,
    direction: 'vertical',
    noSwiping: true,
  };
  // 视频swiper配置
  videoListSwiperOption = {
    loop: true,
    direction: 'vertical',
    slidesPerView: 3.3,
    slidesPerGroup: 3,
    noSwiping: true,
    navigation: {
      nextEl: '.turn-btn.next-btn',
      prevEl: '.turn-btn.prev-btn',
    },
  };

  get actId() {
    return this.rankData.actId || 78;
  }

  get isVideoScroll() {
    return this.videoList.length > 4;
  }

  async getActDetail(actId) {
    const { rows } = await getReportActScoreDetail({ page: 0, limit: 10, needCount: 1, id: actId })
    if (rows && rows.length == 1) {
      this.projectName = rows[0].project.shortName
      this.year = rows[0].year
    }
  }
  async mounted() {
    this.getData();
    this.getActDetail(this.actId)
    // this.setVideoListHeight();
    // window.addEventListener('resize', this.setVideoListHeight);
    this.winScrollHandler();
    window.addEventListener('scroll', this.winScrollHandler);
  }
  winScrollHandler() {
    const el = this.$refs.rankContent as HTMLElement;
    if (document.documentElement.scrollTop + window.innerHeight > el.offsetTop + 250) {
      if (this.dataReady) {
        if (this.rankList.length && this.rankList.length > 15) {
          (this.$refs.rankList as any).swiper.autoplay.start();
        }
        window.removeEventListener('scroll', this.winScrollHandler);
      }
    }
  }

  destroyed() {
    // window.removeEventListener('resize', this.setVideoListHeight);
    window.removeEventListener('scroll', this.winScrollHandler);
  }
  // 获取排行榜数据
  async getData() {
    this.rankList = [];
    let { videoList = [], list = {}, groupName = [] } = await getReportActScoreConfig(this.actId);
    this.videoList = videoList;
    const noGroupIndex = groupName.findIndex(name => name === 'nogroup');
    if (noGroupIndex > -1) {
      groupName.push(...groupName.splice(noGroupIndex, 1));
    }
    const hasGroupData = groupName.find(name =>  name !== 'nogroup' && list[name].length > 0 ); // 判断是否有分组数据
    groupName.forEach(name => {
      if (list[name].length) {
        if (name !== 'nogroup') {
          this.rankList.push({ type: 'title', title: name, _id: name });
        } else if(hasGroupData) {
          const noGroupName = '更多过关学员';
          this.rankList.push({ type: 'title', title: noGroupName, _id: noGroupName });
        }
        list[name].forEach((item, index) => {
          this.rankList.push(Object.assign({}, item, { type: 'data', sIndex: index }));
        });
      }
    });
    this.dataReady = true;
    if (!this.rankList.length || this.rankList.length < 15) {
      this.rankSwiperOption = Object.assign({}, this.rankSwiperOption, { loop: false, autoplay: false });
    } else {
      this.$nextTick(() => {
        (this.$refs.rankList as any).swiper.autoplay.stop();
      });
    }
  }

  mouseenter() {
    (this.$refs.rankList as any).swiper.autoplay.stop();
  }
  mouseleave() {
    (this.$refs.rankList as any).swiper.autoplay.start();
  }

  scrollTo(name) {
    try {
      scrollWatch.scrollTo(name);
    } catch (error) {
      console.log(error);
    }
  }

  track(id) {
    trackEvent('bt_10658', { dynamicId: id, description: '查看经验贴' });
  }

  // 点击右侧的视频
  goVideo(row) {
    // window.open(this.$router.resolve({ name: 'course-learn', params: { id: row.courseId }, query: { lessonId: row.lessonId } }).href);
    trackEvent('bt_10604', { description: '学员经验谈点击', lessonId: row.lessonId });
  }

  // 底部按钮点击事件
  clickHandle({ isDialog = false, scrollTo = '', url = '', eventId = 0, description = '', qrDialog }) {
    if (isDialog) {
      this.qrDialog = qrDialog
      // 此处需要判断是不是在周年庆不是话跳转到周年庆页面
      this.isDialogVisible = true;
    } else if (scrollTo) {
      if (scrollTo === 'qiyu') {
        // @ts-ignore
        window.ysf('open');
      } else {
        if (this.$route.name === '10th') {
          this.scrollTo(scrollTo);
        } else {
          window.open(`/activity-page/10th?scrollToName=${scrollTo}`);
        }
      }
    } else if (url) {
      window.open(url);
    }
    trackEvent(`bt_${eventId}`, { description });
  }

  // videoList如果出现小数，可能会除不尽导致最下面出现横线
  // setVideoListHeight() {
  //   if (!this.isVideoScroll) {
  //     return false;
  //   }
  //   // let fontSize = document.documentElement.style.fontSize.slice(0, -2);
  //   // let height: number | string = Number(fontSize) * 14.0625;
  //   // height = height.toFixed(0) + 'px';
  //   // ((this.$refs.videoList as Vue).$el as HTMLElement).style.height = height;
  // }
}
