





































import { Vue, Component, Prop } from 'vue-property-decorator';
import NP from 'number-precision';
// @ts-ignore
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

@Component({
  components: {
    ElImageViewer,
  },
})
export default class PreviewImages extends Vue {
  @Prop({ type: Number, default: 600 }) outWidth;
  @Prop({ type: Array, default: () => [] }) images;
  curIndex: number = -1;
  curImgInfo = {
    index: -1,
    imgWidth: 0, // 图片原始宽
    imgHeight: 0, // 图片原始高
    colAfterWidth: 0, //图片转换为竖方向后的宽度
    colAfterHeight: 0, // 图片转换为竖方向后高度
    rotateDeg: 0,
  };
  isPreviewImg: boolean = false;

  focusHandle(index) {
    this.curImgInfo.index = index;
  }
  packUp() {
    this.curImgInfo.index = -1;
  }

  rotateHandle(direct) {
    const { colAfterWidth, colAfterHeight } = this.curImgInfo;
    if (direct == 'right') {
      this.curImgInfo.rotateDeg += 90;
    } else {
      this.curImgInfo.rotateDeg -= 90;
    }
    //  const transformValMap={
    //   0:
    //   }
    // 判断图片是不是竖方向了
    const isCol = (Math.abs(this.curImgInfo.rotateDeg) / 90) % 2;
    if (isCol) {
      (this.$refs.previewImgBodyRef as any).style.width = colAfterWidth + 'px';
      (this.$refs.previewImgBodyRef as any).style.height = colAfterHeight + 'px';

      (this.$refs.previewImgRef as any).style.width = colAfterHeight + 'px';
      (this.$refs.previewImgRef as any).style.height = colAfterWidth + 'px';
    } else {
      (this.$refs.previewImgRef as any).style.width = this.curImgInfo.imgWidth + 'px';
      (this.$refs.previewImgRef as any).style.height = this.curImgInfo.imgHeight + 'px';
      (this.$refs.previewImgBodyRef as any).style.width = this.curImgInfo.imgWidth + 'px';
      (this.$refs.previewImgBodyRef as any).style.height = this.curImgInfo.imgHeight + 'px';
    }
    console.log('rotateDeg', this.curImgInfo.rotateDeg);
    this.$forceUpdate();
    (this.$refs.previewImgRef as any).style.transform = `translate(-50%, -50%) rotate(${this.curImgInfo.rotateDeg}deg)`;
  }

  prevHandle() {
    console.log('上一张');
    this.curImgInfo.index--;
  }
  nexHandle() {
    console.log('下一张');
    this.curImgInfo.index++;
  }

  async imgLoad(data) {
    await this.$nextTick();
    const offsetWidth = (this.$refs.previewImgRef as any).offsetWidth;
    console.log('outWidth', this.outWidth, 'offsetWidth', offsetWidth);
    const rowRadio = this.outWidth / offsetWidth;
    this.curImgInfo.imgWidth = NP.times(offsetWidth, rowRadio);
    this.curImgInfo.imgHeight = NP.times((this.$refs.previewImgRef as any).offsetHeight, rowRadio);
    // 设置外层盒子宽高
    (this.$refs.previewImgBodyRef as any).style.width = this.curImgInfo.imgWidth + 'px';
    (this.$refs.previewImgBodyRef as any).style.height = this.curImgInfo.imgHeight + 'px';

    const cRatio = this.outWidth / this.curImgInfo.imgHeight;
    this.curImgInfo.colAfterWidth = this.outWidth;
    this.curImgInfo.colAfterHeight = NP.times(this.curImgInfo.imgWidth, cRatio);
    console.log('previewImgRef', this.curImgInfo);
    console.log('data', data);
  }

  closePreview() {
    this.isPreviewImg = false;
  }
}
