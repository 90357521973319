




















import { Vue, Component, Prop } from 'vue-property-decorator';
import Sticky from './sticky.vue';

@Component({
  components: {
    Sticky,
  },
})
export default class Anniversary extends Vue {
  @Prop({ type: Boolean, default: false }) tabVisible;
  @Prop({ type: Boolean, default: false }) hotVisible;
  @Prop({ type: Array, default: () => [] }) list;
  @Prop({ type: Number, default: 80 }) headHeight;
  @Prop({ type: String, default: '' }) activeBlock;

  activeName: string = 'CPA';
  isSticky: boolean = false;
  get curData() {
    return this.list.filter(item => item.title === this.activeName)[0] || {};
  }

  created() {
    if (this.list[0]) {
      this.activeName = this.list[0].title;
    }
  }

  tabClick(name) {
    this.activeName = name;
    try {
      this.$sensors.track('bt_10615', {
        projectName: name,
      });
    } catch (e) {
      console.log('学霸同款课程tab栏点击埋点报错');
    }
  }
  goLink({ link, eventId, dialog }) {
    try {
      this.$sensors.track(`bt_${eventId}`, {
        projectName: this.activeName,
        jumpLink: link,
      });
    } catch (e) {
      console.log('学霸同款课程-详情埋点报错');
    }
    if (dialog) {
      this.$emit('dialog', dialog);
    } else if (link) {
      window.open(link);
    } else {
      this.$emit('ysf');
    }
  }
  stickChange(val) {
    this.isSticky = val;
  }
}
