









import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ScrollItem from './scroll-item.vue';

@Component({
  components: {
    ScrollItem,
  },
})
export default class MyComponent extends Vue {
  @Prop({ type: [Number, String] }) num;
  @Prop({ type: Number, default: 300 }) interval;
  @Prop({ type: Number, default: 30 }) itemHeight;
  outConHeight: number = 30;

  heighChange(val) {
    this.outConHeight = val;
  }
}
