








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TextEllipsisCom extends Vue {
  @Prop({ type: String }) text;
  @Prop({
    type: Object,
    default: () => ({
      lineHeight: 20,
      lineCount: 3,
      maxHeight: 0,
    }),
  })
  ellipsisObj;

  get ellipsisStyle() {
    const { lineHeight, lineCount } = this.ellipsisObj;

    return {
      lineHeight: lineHeight + 'px',
      '--lineHeight': lineHeight + 'px',
      '-webkit-line-clamp': lineCount,
      height: this.ellipsisObj.maxHeight && this.isEllipsised ? `${this.ellipsisObj.maxHeight}px` : 'auto',
    };
  }

  isEllipsised: boolean = false;

  async mounted() {
    await this.$nextTick();
    const ellipsisComRef = this.$refs.ellipsisComRef as any;
    const { offsetHeight, scrollHeight } = ellipsisComRef;
    const targetHeight = this.ellipsisObj.lineHeight * this.ellipsisObj.lineCount;
    // 判断 滚动高度>大于offseHeight
    this.isEllipsised = scrollHeight > offsetHeight || (targetHeight > 0 && scrollHeight == targetHeight && offsetHeight == targetHeight);
  }

  goDetail() {
    this.$emit('goHandle');
  }
}
