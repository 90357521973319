<template>
  <div class="home-carousel" v-if="posters">
    <div v-swiper="swiperOption">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(poster,index) in posters" :key="poster.id" v-if="checkVisible(poster)" :style="'background:' + poster.background">
          <div class="pc-container">
            <a :href="poster.href" target="_blank"> <img class="img-responsive" v-default-img-alt="poster.imgAlt||'轮播图'+index" :data-src="poster.src" :src="poster.src" /> </a>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
  <div class="home-carousel loading" v-else></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    let userAgent = navigator.userAgent;
    let isIE = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') === -1; // ie & !ie11
    return {
      swiperOption: {
        loop: true,
        width: window.innerWidth,
        grabCursor: true,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        effect: isIE ? 'slide' : 'fade',
        height: 360,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          init() {
            if (window.innerWidth <= 1300) {
              this.params.width = 1300;
            } else {
              this.params.width = window.innerWidth;
            }

            this.update();
          },
          resize() {
            if (window.innerWidth <= 1300) {
              this.params.width = 1300;
            } else {
              this.params.width = window.innerWidth;
            }

            this.update();
          },
          slideChange: (e) => {
            this.setImgSrc();
          },
        },
      },
    };
  },
  async serverPrefetch() {
    return this.getHomeTopBanner();
  },
  computed: {
    ...mapGetters(['posters']),
  },
  methods: {
    ...mapActions(['getHomeTopBanner']),
    checkVisible(poster) {
      let offlineTime = Number(poster.offlineTime);
      return poster.status === '1' && (!offlineTime || offlineTime > Date.now()/1000) && poster.mode === 'img';
    },
    setImgSrc() { // 根据轮播图位置加载图片
      let activeIndex = this.swiper.activeIndex;
      let slideEl = this.swiper.slides[activeIndex];
      let bannerImgEl = slideEl.getElementsByClassName('img-responsive')[0];
      let src = bannerImgEl.getAttribute('src');
      let dataSrc = bannerImgEl.getAttribute('data-src');
      // console.log('activeIndex', {activeIndex, src, dataSrc});
      if (!src) {
        bannerImgEl.setAttribute('src', bannerImgEl.getAttribute('data-src'));
      }
    }
  },
  async mounted() {
    if (!this.posters) {
      await this.getHomeTopBanner();
    }
    this.setImgSrc();
    // this.getHomeTopBanner();
  },
};
</script>
