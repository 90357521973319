



















































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { reserveOpenCourseLesson, cancelReserveCourseLesson } from '@/api/course';
import { MeModel } from '@/typings';
import Lottie from '@/pc/components/lottie/Lottie.vue';
import livingJson from '@/assets/images/live-entry/live_Ing.json';

@Component({
  components: {
    Lottie,
  },
  filters: {
    person(num) {
      if (num) {
        return `${num}`;
      } else {
        return '0';
      }
    },
    learnType(status) {
      switch (status) {
        case 'living':
          return '正在直播';
        case 'pending':
          return '即将直播';
      }
    },
  },
  computed: {
    ...mapGetters('my', ['isLogin', 'me']),
  },
  methods: {
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE']),
  },
})
export default class LiveLessonItem extends Vue {
  @PropSync('editLesson', { type: Object, default: null }) editLessonSync;
  @PropSync('isReserveShow', { type: Boolean, default: false }) isReserveShowSync;
  @Prop({ type: Object, default: () => ({}) }) data!: any;
  @Prop({ type: Boolean, default: false }) isCurLesson;
  @Prop({ type: Boolean, default: false }) showCategory;

  isLogin: any;
  SET_LOGIN_DIALOG_VISIBLE: any;
  requestLoading: boolean = false;
  Tags = {
    reserve: '直播预约',
    reserved: '已预约',
    pending: '待上直播',
    living: '观看直播',
    replay: '点击播放',
    video: '点击播放',
    finished: '已结束',
  };
  me!: MeModel;
  lottieOptions = {
    animationData: livingJson,
  };

  // 教师头像
  get teacherPicture() {
    const { originData } = this.data;
    let bigAvatar = '';
    if (originData) {
      bigAvatar = originData.imgSrc === '' ? originData.imgUpload : originData.imgSrc;
    }
    if (originData && bigAvatar) {
      return bigAvatar;
    } else {
      return 'https://tmp.btclass.cn/yunyin/o_1gia1aokr6otler1rgs1mbbrj6a.png';
    }
  }

  get lessonName(): string {
    const { lesson, originData, title } = this.data;
    // 如果未配置课时就取课程标题
    return originData.id === 0 ? title : lesson.title;
  }

  get jumpLink() {
    const { lesson, originData } = this.data;
    return originData.id === 0 ? `/course/${lesson.courseId}` : `/course/${lesson.courseId}/learn?lessonId=${lesson.id}`;
  }
  get teacherName(): string {
    const { truename, nickname } = this.data.lesson.teacher;
    return truename || nickname || '-';
  }

  get livingStatus(): string {
    const lessonItem = this.data.lesson;
    let livingStatus = '';
    if (lessonItem) {
      livingStatus = lessonItem.livingStatus || '';
      if (livingStatus === 'pending') {
        if (lessonItem.isReserved) {
          livingStatus = 'reserved';
        } else {
          livingStatus = 'reserve';
        }
      } else if (lessonItem.type === 'video' && !livingStatus) {
        livingStatus = 'video';
      }
    }
    return livingStatus;
  }

  // 首先判断是否已登录
  clickHandle() {
    // 判断是不是公开课或者免费课程
    // 如果是预约，先判断是否已登录
    if (!this.isLogin) {
      this.SET_LOGIN_DIALOG_VISIBLE(true);
    }
  }
  goLesson() {
    const lesson: any = this.data.lesson;
    // 预约功能
    if (['reserve', 'reserved'].includes(this.livingStatus)) {
      const isStarted = Math.floor(Date.now() / 1000) >= lesson.startTime;
      const isFifteen = Math.floor(Date.now() / 1000) + 15 * 60 >= lesson.startTime && Math.floor(Date.now() / 1000) - 15 * 60 <= lesson.startTime; // 是否距离开始时间前十五分钟之类，只需要在false 的情况下才去判断是否开始isStarted
      // 课时未到开始时间并且不在开始前的十五分钟
      if (!isStarted && !isFifteen) {
        if (!this.isLogin) {
          this.SET_LOGIN_DIALOG_VISIBLE(true);
          return;
        }
        window._hmt.push(['_trackEvent', '直播预约弹窗', '点击预约']);
        if (!lesson.isReserved) {
          // 没有预约则开始预约
          if (this.requestLoading) return;
          // 判断是否绑定手机号
          if (this.me.verifiedMobile) {
            this.sendReserveRequest();
          } else {
            this.editLessonSync = lesson;
            this.$emit('bindMobile');
            return;
          }
        } else {
          // 有预约则取消预约
          this.cancelReserveRequest();
        }
        return;
      }
    }
    try {
      const { lesson, originData } = this.data;
      originData.id === 0 ? `/course/${lesson.courseId}` : `/course/${lesson.courseId}/learn?lessonId=${lesson.id}`;
      const params: any = {
        lessonType: lesson.type, //直播类型
        startTime: lesson.startTime, //直播开始时间
      };
      if (originData.id === 0) {
        params.courseId = lesson.courseId;
      } else {
        params.lessonId = lesson.id;
      }
      this.$sensors.track('bt_10606', {
        description: '备考必看直播',
        ...params,
      });
    } catch (e) {
      console.log('学员经验谈点击埋点出错', e);
    }
    window.open(this.jumpLink);
  }
  // 预约逻辑
  async sendReserveRequest() {
    try {
      let lesson: any = this.data.lesson;
      if (this.requestLoading) {
        return;
      }
      this.requestLoading = true;
      await reserveOpenCourseLesson(lesson.id);
      this.isReserveShowSync = true;
      this.requestLoading = false;
      this.$emit('refresh');
    } catch (e) {
      console.log('预约失败');
    }
  }
  // 预约逻辑
  async cancelReserveRequest() {
    try {
      let lesson: any = this.data.lesson;
      if (this.requestLoading) {
        return;
      }
      this.requestLoading = true;
      await cancelReserveCourseLesson(lesson.id);
      this.$message.success('预约已取消');
      this.requestLoading = false;
      this.$emit('refresh');
    } catch (e) {
      console.log('取消预约失败');
    }
  }
}
