import parameters from '@/pc/config/parameters';

import { DirectiveOptions } from 'vue';

export const defaultImg = {
  bind: function (el, binding) {
    el.onerror = function () {
      let arg = parameters[binding.arg];
      if (arg) {
        el.onerror = () => {};
        (el as HTMLImageElement).src = arg;
      }
    };
  },
} as DirectiveOptions;

export const defaultImgAlt = {
  bind: function (el, binding) {
    el.onload = function () {
      el.alt = `BT教育${binding.value ? '-' + binding.value : ''}`;
    };
  },
};
